import React, { useState } from 'react'
import { List, notification } from 'antd'
import { Project, ProjectSortKey } from '@pollination-solutions/pollination-sdk'
import { useAuth } from 'auth-context'
import usePaginatedList, { PaginatedQuery } from 'hooks/paginatedList'
import { ProjectCard } from 'components/Project'
import TabsToolbar from 'components/TabsToolbar'

const ProjectGallery = () => {
  const { client } = useAuth()

  const [projects, setProjects] = useState<Project[]>([])
  const [total, setTotal] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const [pageSize, setPageSize] = useState<number>(10)

  const queryApi = (query: PaginatedQuery) => {
    setLoading(true)
    return client.projects.listProjects({
      search: query.search,
      owner: query.owner ? [query.owner] : undefined,
      _public: query.isPublic,
      sortBy: query.sortBy,
      sortOrder: query.sortOrder,
      page: query.page,
      perPage: query.perPage,
    }).then(({ data }) => {
      setTotal(data.total_count)
      setProjects(data.resources)
      setLoading(false)
    }).catch((err) => {
      if (err.response.data.detail) {
        notification.error({
          message: err.response.data.detail,
        })
      }
      setLoading(false)
    })
  }

  const { updateQuery, setPage } = usePaginatedList({
    queryApi,
    defaultSortKey: ProjectSortKey.UpdatedAt,
    perPage: pageSize
  })

  return (
    <>
      <TabsToolbar
        updateQuery={updateQuery}
        sortEnum={ProjectSortKey}
        defaultSortKey={ProjectSortKey.UpdatedAt}
        searchPlaceholder="Search Projects..."
        hasAccount />
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 2,
          xl: 2,
          xxl: 2,
        }}
        style={{ marginTop: 16 }}
        dataSource={projects}
        renderItem={(item) => <List.Item>
          <ProjectCard project={item} />
        </List.Item>}
        loading={loading}
        pagination={{
          onChange: page => {
            setPage(page)
          },
          showSizeChanger: true,
          onShowSizeChange: (c, size) => setPageSize(size),
          pageSize,
          total
        }}
      />
    </>
  )
}

export default ProjectGallery
