import React from 'react'

import { Application, ApplicationVersion, ApplicationVersionList, BuildStatusEnum } from '@pollination-solutions/pollination-sdk'

import { Col, Divider, List, Row, Skeleton, Space, Tag, Tooltip, Typography } from 'antd'

import useSWR from 'swr'
import dayjs from 'dayjs'

import { AvatarLink } from 'atoms'


interface ApplicationVersionsProps {
  application: Application
  fetchApplicationVersions: (owner: string, slug: string, page?: number, perPage?: number) => Promise<ApplicationVersionList>
}

export const ApplicationVersions: React.FC<ApplicationVersionsProps> = ({ application, fetchApplicationVersions }) => {

  const { data, isValidating } = useSWR(
    [application.owner.name, application.slug], 
    fetchApplicationVersions, 
    { revalidateOnFocus: false }
  )

  return (
    isValidating ? 
      <Skeleton active loading={isValidating}/>
      :
      <List
        loading={isValidating}
        dataSource={data ? data.resources : []}
        rowKey={(item: ApplicationVersion) => item.id}
        size='small'
        grid={{ gutter: 16, column: 1 }}
        style={{ width:'100%' }}
        renderItem={(item: ApplicationVersion) => (
          <Row gutter={[24, 24]} style={{ margin:0 }}>
            <Col style={{ textAlign: 'right' }}>
              <Space direction='vertical' align='end'>
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  {dayjs(item.created_at).format('DD MMMM YYYY')}
                </Typography.Title>
                <Tag
                  style={{ marginRight: 0 }}
                  color={
                    ((status: BuildStatusEnum) => {
                      switch(status) {
                        case BuildStatusEnum.Pending: {
                          return 'processing'
                        }
                        case BuildStatusEnum.Running: {
                          return 'default'
                        }
                        case BuildStatusEnum.Success: {
                          return 'success'
                        }
                        case BuildStatusEnum.Failure: {
                          return 'error'
                        }
                        case BuildStatusEnum.Cancelled: {
                          return 'grey'
                        }
                        case BuildStatusEnum.AwaitingPackageUpload: {
                          return 'warning'
                        }
                        case BuildStatusEnum.Unknown: {
                          return 'error'
                        }
                        default:
                          return '#000000'
                      }
                    })(item.build_status.status)
                  }
                >{item.build_status.status}</Tag>
              </Space>
            </Col>
            <List.Item
              style={{
                border: '1px solid #d0d7de',
                borderRadius: 4,
                padding: 16,
                backgroundColor:'white'
              }}
            >
              <List.Item.Meta 
                title={
                  <span>
                    <strong>
                      {item.author.display_name}
                    </strong> deployed 
                    {item.tag.length > 10 ?
                      <Tooltip title={item.tag}>
                        <Typography.Text code>
                          {item.tag.length > 10 ? item.tag.substring(0, 10) + '...' : item.tag}
                        </Typography.Text>
                      </Tooltip>
                      :
                      <Typography.Text code>
                        {item.tag}
                      </Typography.Text>
                    }
                  </span>
                }
                avatar={<AvatarLink link={item.author.name} src={item.author.picture_url} />}
                description={
                  <>
                    <Divider style={{ marginBottom: 8, marginTop: 8 }}/>
                    <Space direction={'vertical'}>
                      <Typography.Text>
                        {item.release_notes}
                      </Typography.Text>
                      <Space>
                        {item.build_status.created_at &&
                        <Tooltip title={`Application deployment created at ${dayjs(item.build_status.created_at).format('hh:mm:ss a on DD-MM-YYYY')}`}>
                          <Tag>
                            Created: {dayjs(item.build_status.created_at).format('hh:mm:ss')}
                          </Tag>
                        </Tooltip>
                        }
                        {item.build_status.started_at &&
                    <Tooltip title={`Application deployment started at ${dayjs(item.build_status.started_at).format('hh:mm:ss a on DD-MM-YYYY')}`}>
                      <Tag>
                        Started: {dayjs(item.build_status.started_at).format('hh:mm:ss')}
                      </Tag>
                    </Tooltip>
                        }
                        {item.build_status.finished_at &&
                    <Tooltip title={`Application deployment finished at ${dayjs(item.build_status.finished_at).format('hh:mm:ss a on DD-MM-YYYY')}`}>
                      <Tag>
                        Finished: {dayjs(item.build_status.finished_at).format('hh:mm:ss')}
                      </Tag>
                    </Tooltip>
                        }
                      </Space>
                    </Space>
                  </>
                }
              />
            </List.Item>
          </Row>
        )}
      />
  )
}