import React, { useEffect, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { Avatar, Space, Row, Divider, Typography, Col, Card, Button } from 'antd'

import { Organization, OrganizationMember, OrgsApiListOrgsRequest, Team } from '@pollination-solutions/pollination-sdk'

import { useAuth } from 'auth-context'

import { AvatarLink } from 'atoms'
import { useOrganizations } from 'hooks'
import { Loading } from 'atoms'
import { TeamAvatarGroup } from 'components/Organization/TeamList'
import { PropertySafetyFilled } from '@ant-design/icons'

export interface SideCardProps {
  accountName: string
  refresh?: boolean
}

export const UserOrgs: React.FunctionComponent<SideCardProps> = ({ accountName, refresh }) => {

  const query: OrgsApiListOrgsRequest = useMemo(() => ({ member: [accountName] }), [accountName, refresh])

  const { loading, orgs } = useOrganizations(query)

  if (loading) {
    return (
      <>
        <Divider />
        <Loading />
      </>
    )
  }

  if (!loading && (!orgs || !orgs?.resources.length)) {
    return (
      <>
        <Divider />
        <Space direction='vertical'>
          <Typography.Title level={5}>{'You\'re not part of any organizations yet.'}</Typography.Title>
          <Typography.Link href='https://www.pollination.solutions' target='_blank'>Learn more about Pollination.</Typography.Link>
        </Space>
      </>
    )
  }

  return (
    <>
      <Divider orientation="left">Organizations</Divider>
      <Space direction="vertical" wrap>
        {orgs && orgs.resources.map(org => (
          <Link to={`/${org.owner.name}`} key={org.account_name}>
            <Space size={'small'}>
              <Avatar 
                src={org.owner.picture_url} 
                size={{ xs: 16, sm: 16, md: 24, lg: 24, xl: 30, xxl: 30 }} 
              />
              <Typography.Text>{org.name}</Typography.Text>
            </Space>
          </Link>
        ))}
      </Space>
    </>
  )
}

export const OrgMembers: React.FunctionComponent<SideCardProps> = (props) => {
  const { client } = useAuth()

  const [loading, setLoading] = useState<boolean>(true)
  const [members, setMembers] = useState<OrganizationMember[]>([])

  useEffect(() => {
    client.orgs.getOrgMembers({ name: props.accountName }).then(({ data }) => {
      setMembers(data.resources)
      setLoading(false)
    })
  }, [client.orgs, props.accountName, props.refresh])

  return (
    <>
      <Divider orientation="left">Members</Divider>

      {loading ? <Loading /> :
        <Avatar.Group>
          <Row wrap={true} justify='center'>
            {
              members.map((member, i) => (
                <Col key={`${member.user.username}-${i}`}>
                  <AvatarLink 
                    src={member.user.picture} 
                    link={`/${member.user.username}`} 
                    size={{ xs: 16, sm: 16, md: 24, lg: 24, xl: 30, xxl: 30 }} 
                    style={{ margin: 2 }} />
                </Col>
              ))
            }
          </Row>
        </Avatar.Group>
      }
    </>
  )
}

export const OrgTeams: React.FunctionComponent<SideCardProps> = (props) => {
  const { client } = useAuth()
  const history = useHistory()

  const [loading, setLoading] = useState<boolean>(true)
  const [teams, setTeams] = useState<Team[]>([])
  const [limit, setLimit] = useState(true)
  const [organization, setOrganization] = useState<Organization>()

  useEffect(() => {
    client.orgs.getOrg({ name: props.accountName })
      .then(({ data }) => setOrganization(data))
      .catch((err: any) => console.log(err))
  }, [props.accountName, client.teams])

  useEffect(() => {
    if (!organization || !organization.account_name) return

    client.teams.listOrgTeams({ 
        orgName: organization.account_name }).then(({ data }) => {
      setTeams(data.resources)
      setLoading(false)
    })
  }, [organization, props.refresh])

  if (!teams || teams.length === 0) return <></>

  return (
    <>
      <Divider orientation="left">Teams</Divider>

      {loading ? <Loading /> :
        <Row wrap={true} justify='start' gutter={[12, 12]}>
          {
            organization && teams && teams.map((team, index) => {
              if (limit && index > 1) {
                return
              }

              return <Card size='small' 
                hoverable
                onClick={() => history.push(`/${organization.owner.name}/teams/${team.slug.split('/')[1]}`)}
                style={{
                  width: '100%'
                }}>
                <Space size='small' direction='vertical'>
                  <Typography.Text type='secondary'>
                    {team.name}
                  </Typography.Text>
                  <TeamAvatarGroup org={organization} team={team} />
                </Space>
              </Card>
            })
          }
          {teams && teams?.length > 2 && <Button type='link' onClick={() => setLimit((prev: boolean) => !prev)}>
            Show {limit ? 'more' : 'less'}
          </Button>}
        </Row>
      }
    </>
  )
}
