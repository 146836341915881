import React, { useCallback, useMemo } from 'react'

import { Col, Divider, List, Row, Space, Typography, Button, Tooltip, Card } from 'antd'
import { AppstoreAddOutlined, UserSwitchOutlined } from '@ant-design/icons'

import { Loading } from 'atoms'

import { useHistory } from 'react-router-dom'
import { AccountPublic, LicensePoolPublic } from '@pollination-solutions/pollination-sdk'

import { AvatarLink, Empty } from 'atoms'

export interface LicensePoolMemberProps {
  loading: boolean
  memberPools: LicensePoolPublic[]
  account?: AccountPublic
}

export const LicensePoolMember: React.FunctionComponent<LicensePoolMemberProps> = ({ loading, memberPools, account }) => {

  const history = useHistory()

  const productPools = useMemo(() => {
    return memberPools.reduce((p: string[], c) => {
      if (!p.includes(c.product)) p.push(c.product)
      return p
    }, [])
  }, [memberPools])

  const formattedProductKey = useCallback((productKey: string) => {
    return productKey.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
  }, [])

  return (
    <Row justify='space-between' style={{ width: '100%', alignItems: 'baseline' }}>
      <Card bordered style={{ width: '100%' }}
        bodyStyle={{ padding: 0 }}
        title={
          <Row justify='space-between' align='middle' style={{ width: '100%' }}>
            <Space direction='vertical'>
              <Typography.Title level={4} style={{ marginBottom: 0 }}>License Pool Membership</Typography.Title>
              <Typography.Text type='secondary'>You have access to the license pools listed below.</Typography.Text>
            </Space>
            <Tooltip title={'Download Installers and Try Free for 14 Days.'}>
              <Button href={`/${account?.name}/cad-plugins`} disabled={typeof account === 'undefined'}><AppstoreAddOutlined /> Try Plugins</Button>
            </Tooltip>
          </Row>
        }
      >
        {productPools && productPools.length ?
          <Row style={{ width: '100%', padding: '24px 16px' }}>
            {
              productPools.map((product, i) => (
                <Col span={24} style={i === 0 ? undefined : { marginTop: 24 }} key={`${product}-${i}`}>
                  <Typography.Title level={4}>{formattedProductKey(product)}</Typography.Title>
                  <Divider style={{ margin: 0 }} />
                  <List
                    dataSource={memberPools.filter(pool => pool.product === product).sort((a, b) => {
                      if (a.permissions.admin && b.permissions.admin) return 0
                      if (a.permissions.admin) return -1
                      return 1
                    })}
                    renderItem={(item, index) => (
                      <List.Item
                        actions={item.permissions.admin ? [
                          <Tooltip key={`${item.id}-${i}`} 
                            title={`Edit the ${item.owner.display_name} License Pool.`} 
                            overlayInnerStyle={{ textAlign: 'center' }}
                          >
                            <Button shape='circle' onClick={() => history.push(`/${item.owner.name}/licenses`)} >
                              <UserSwitchOutlined />
                            </Button>
                          </Tooltip>
                        ] : undefined}
                      >
                        <List.Item.Meta
                          avatar={<AvatarLink src={item.owner.picture_url} link={`/${item.owner.name}`} />}
                          title={item.owner.display_name}
                          description={item.description}
                        />
                      </List.Item>
                    )}
                  />
                </Col>
              ))
            }
          </Row>
          :
          <Row justify='center' align='middle' style={{ width: '100%', padding: '24px 16px' }}>
            {loading ?
              <Loading />
              :
              <Empty description={'You\'re not a member of any License Pools.'} />
            }
          </Row>
        }
      </Card>
    </Row>
  )
}