import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'

import slugify from 'slugify'

import { sendEmailVerification } from 'firebase/auth'
import { useUser } from 'reactfire'

import { Button, Divider, Row, Col, Typography, Form, Input, Avatar, Card, Grid, Space, notification } from 'antd'

import { UserCreate } from '@pollination-solutions/pollination-sdk'

import { useAuth } from 'auth-context'

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 10 },
}

const Copy = {
  usernameHelp: 'Must be lowercase without spaces or special characters'
}

const RegistrationForm: React.FunctionComponent = () => {
  const { registered, client, refreshIdToken } = useAuth()

  const history = useHistory()
  const screens = Grid.useBreakpoint()

  const authUser = useUser({
    initialData: {
      loading: true
    }
  })

  if (registered) {
    history.push('/')
  }

  const [picture, setPicture] = useState<string>('https://robohash.org/default')
  const [username, setUsername] = useState<string | undefined>()

  useEffect(() => {    
    if (!authUser.data) {
      return
    }
    // JWT Token must be refreshed so it contains the
    // Name and Email Verified property before creating
    // the user account
    refreshIdToken()
    if (authUser.data.photoURL) {
      setPicture(authUser.data.photoURL as string)
    }
  }, [authUser.data, refreshIdToken])

  useEffect(() => {
    if ((!picture || (picture.startsWith('https://robohash.org') && username))) {
      setPicture(`https://robohash.org/${username}`)
    }
  }, [username, picture])

  const registerUser = (userCreate: UserCreate) => {
    if (!authUser.data) return

    userCreate.picture_url = picture
    userCreate.name = authUser.data.displayName || userCreate.username
    client.user.createUser({ userCreate })
      .then(() => {
        refreshIdToken()
      })
      .then(() => {
        history.push('/')
      })
      .catch((err) => {
        notification.error({
          message: 'Failed to create account',
          description: err.response.data.detail
        })
      })
  }

  const usernameCheck = (name: string) => {
    return client.accounts.checkAccountName({ name })
      .then(() => Promise.resolve(`${name} is available 🎉`))
      .catch(() => Promise.reject(`${name} is already taken 😢`))
  }

  return (
    <>
      <Row justify="center">
        {
          screens.xs ? (
            <Space direction="vertical" align="center" size={-25}>
              <Typography.Title>Welcome</Typography.Title>
              <Typography.Title>to</Typography.Title>
              <Typography.Title>Pollination</Typography.Title>
            </Space>
          ) : (
            <Typography.Title>Welcome to Pollination</Typography.Title>
          )
        }
      </Row>
      <Row justify="center">
        <Card style={{ minWidth: '60%' }}>
          <Row justify="center">
            <Card.Meta
              title={`Hi ${authUser.data ? authUser.data.displayName : ''} 👋`}
              description={'One last step and you will be fully registered!'}
              style={{ justifyContent: 'center' }}
            />
          </Row>
          { authUser.data && authUser.data.emailVerified ? 
            (
              <Form
                {...layout}
                style={{ width: '100%' }}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={registerUser}
              >
                <Divider>About You</Divider>
                <Form.Item
                  label="Username"
                  tooltip={Copy.usernameHelp}
                  name="username"
                  normalize={(value) => slugify(value, { lower: true, strict: false })}
                  hasFeedback
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 18 }}
                  rules={[
                    { required: true, message: 'Please input your username' },
                    () => ({
                      validator(_, value) {
                        if (value && value !== '') {
                          return usernameCheck(value)
                        }
                        return Promise.resolve()
                      }
                    }),
                    () => ({
                      validator(_, value) {
                        let matches = value.match(/^[a-z0-9-]+$/)
                        matches = matches ? matches : []
                        if (matches && matches.length !== 1) {
                          return Promise.reject(Copy.usernameHelp)
                        }
                        return Promise.resolve()
                      },
                    }),
                  ]}
                >
                  <Input onChange={(e) => setUsername(e.target.value)} />
                </Form.Item>
                <Form.Item
                  label="Avatar"
                  tooltip="A URL pointing to the image you want to use"
                  name="picture_url"
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 18 }}
                >
                  <Row justify="space-between" style={{ width: '100%' }}>
                    <Col span={2}>
                      <Avatar src={picture} />
                    </Col>
                    <Col span={20}>
                      <Input value={picture} onChange={(e) => setPicture(e.target.value)} style={{ width: '100%' }} />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item
                  label="Bio"
                  tooltip="A short description of who you are and what you do"
                  name="description"
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 18 }}
                >
                  <Input.TextArea showCount maxLength={100} />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 10, offset: 10 }}>
                  <Button type="primary" htmlType="submit">
                    Create Account
                  </Button>
                </Form.Item>
                <Row justify='center' style={{ width:'100%', marginTop:24 }}>
                  <Typography.Text>
                    {'By clicking \'Create Account\' you agree to our'}
                    <a href='https://www.pollination.solutions/terms-of-service' target={'_blank'} rel='noreferrer'>{' Terms of Service.'}</a>
                  </Typography.Text>
                </Row>
              </Form>
            ) : (
              <>
                <Divider>Verify Your Email</Divider>
                <Row justify="center">
                  <Space direction="vertical" align="center">
                    <Typography.Text>We need you to verify your email address</Typography.Text>
                    <Button
                      onClick={() => { 
                        if(authUser.data) {
                          sendEmailVerification(authUser.data).then(() => {
                            notification.info({ message: 'Sent verification email', description: `Check your inbox for ${authUser.data ? authUser.data.email : ''}` })
                          })
                        }
                      }}
                    >
                      Send Email Verification
                    </Button>
                    <Typography.Text>Refresh this page after your email is verified to create your account</Typography.Text>
                    <Button onClick={() => window.location.reload()}>
                      Refresh Page
                    </Button>
                  </Space>
                </Row>
              </>
            )
          }
        </Card>
      </Row>
    </>
  )
}

export default RegistrationForm
