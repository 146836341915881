import React, { useCallback, useEffect, useState } from 'react'
import { List, notification, Space, Typography, Row, Tooltip } from 'antd'
import { Project, ProjectRecipeFilter, Repository } from '@pollination-solutions/pollination-sdk'
import { RecipeCard } from 'atoms'
import { useAuth } from 'auth-context'
import { ClockCircleOutlined } from '@ant-design/icons'
import RecipeModal from './Modal'
import FilterList from './FilterList'

interface Props {
  project: Project
  pageSize?: number
  pageNumber?: number
  onClick?: (recipes: ProjectRecipeFilter[]) => any
}
export const RecipeCardList = (props: Props) => {
  const { project, onClick, pageNumber = 1, pageSize = 100 } = props

  const [owner, name] = project.slug.split('/') ?? []

  const { client } = useAuth()
  const [recipeFilters, setRecipeFilters] = useState<ProjectRecipeFilter[]>([])
  const [recipes, setRecipes] = useState<Repository[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const fetchProjectRecipes = useCallback(() => {
    client.projects.getProjectRecipeRepositories({
      owner: owner,
      name: name,
      page: pageNumber,
      perPage: pageSize,
    }).then(({ data }) => {
      setRecipes(data.resources)
    }).catch((err) => {
      notification.error({
        message: err.response.data.detail,
      })
    }).finally(() => setLoading(false))
  }, [owner, name, pageNumber, pageSize, client])

  const fetchRecipeFilters = useCallback(() => {
    client.projects.getProjectRecipeFilters({
      owner: owner,
      name: name,
      page: pageNumber,
      perPage: pageSize,
    }).then(({ data }) => {
      setRecipeFilters(data.resources)
    }).catch((err) => {
      notification.error({
        message: err.response.data.detail,
      })
    })
  }, [owner, name, pageNumber, pageSize, client])

  useEffect(() => {
    setLoading(true)
    fetchProjectRecipes()
    fetchRecipeFilters()
  }, [owner, name, pageNumber, pageSize, fetchProjectRecipes, fetchRecipeFilters])

  const handleOnCancel = (e: any) => {
    setIsModalOpen(false)
    setLoading(true)
    fetchProjectRecipes()
    fetchRecipeFilters()
  }

  return (
    <>
      <RecipeModal open={isModalOpen} onCancel={handleOnCancel}>
        <FilterList defaultFilters={recipeFilters} owner={owner} name={name} />
      </RecipeModal>
      <Row justify="space-between">
        <Space>
          <ClockCircleOutlined style={{ fontSize: '24px' }} />
          <Typography.Text strong>New Study</Typography.Text>
        </Space>
        {(project.permissions.write || project.permissions.admin) &&
          <Tooltip title={!project.permissions.admin && 'Only users with admin access to the project can add or remove recipes'}>
            <Space>
              <Typography.Link disabled={project.permissions.admin ? loading : true}
                style={{ fontWeight: 500 }}
                onClick={() => project.permissions.admin && setIsModalOpen(true)} >
                Add/Remove Recipes
              </Typography.Link>
            </Space>
          </Tooltip>}
      </Row>
      <List
        style={{
          paddingTop: '10px',
        }}
        grid={
          {
            gutter: 10,
            xs: 5,
            sm: 6,
            md: 8,
            lg: 10,
            xl: 12,
            xxl: 14,
          }
        }
        dataSource={recipes}
        renderItem={(item) => (
          <List.Item>
            {!loading ? <RecipeCard key={item.name + item.owner}
              recipe={item}
              onClick={(recipe) => {
                onClick && onClick(recipeFilters.filter((filter) => filter.name === recipe.name && filter.owner === recipe.owner.name))
              }} /> : <></>}
          </List.Item>
        )
        }
        loading={loading}
        pagination={false}
      />
    </>
  )
}

export default RecipeCardList