import React, { useState, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import slugify from 'slugify'
import { Form, Input, Button, Space, notification, Select, Avatar, Typography, Row, Divider, Col } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useAuth } from 'auth-context'
import { AccountPublic, RoleEnum, TeamRoleEnum, UserPublic } from '@pollination-solutions/pollination-sdk'

type CreateMemberPayload = {
  username: string
  role: TeamRoleEnum
}

type CreatePayload = {
  owner: string
  name: string
  description: string
  members: CreateMemberPayload[]
}

export const NewTeam = (): React.ReactElement => {
  const { client, user } = useAuth()
  const history = useHistory()
  const location = useLocation()

  const defaultAccount = useMemo(() => {
    if (!client || !location || !location?.pathname) return
    const user = location.pathname.split('/')[1]
    return user
  }, [location])

  const [orgOptions, setOrgOptions] = useState<AccountPublic[]>([])
  const [memberOptions, setMemberOptions] = useState<UserPublic[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const searchMembers = (search: string) => {
    return memberOptions.filter((m) => m.username.includes(search))
  }

  const searchOrgs = (search: string | undefined) => {
    const key = search ?? defaultAccount

    return client.accounts.listAccounts({
      search: key,
      type: 'org',
      role: RoleEnum.Owner
    }).then((res) => {
      //@ts-ignore
      if (!orgOptions.length) onOrgSelect(res.data.resources[0].name)
      setOrgOptions(res.data.resources)
    })
  }

  const onOrgSelect = (name: string) => {
    client.orgs.getOrgMembers({ name })
      .then(res => setMemberOptions(res.data.resources.map(m => m.user)))
  }

  const createTeam = async (payload: CreatePayload) => {
    setLoading(true)

    const slugName = slugify(payload.name, { lower: true })

    return client.teams.createTeam({
      orgName: payload.owner,
      teamCreate: {
        name: payload.name,
        description: payload.description,
      }
    }).then(() =>
      Promise.all(payload.members.map(m =>
        client.teams.upsertOrgTeamMember({
          orgName: payload.owner,
          teamSlug: slugName,
          username: m.username,
          role: m.role
        })
      ))
    ).then(() => history.push(`/${payload.owner}/teams/${slugName}`))
      .catch((err) => {
        const { data } = err.response
        notification.error({
          message: 'API Error',
          description: data.detail
        })
        setLoading(false)
      })
  }

  useEffect(() => {
    searchOrgs(undefined)
  }, [])

  return (
    <Col
      xl={{ span: 16, offset: 4 }}
      lg={{ span: 16, offset: 4 }}
      md={24}
      sm={24}
      xs={24}>
      <Typography.Title level={4}>
        New Team
      </Typography.Title>
      <Form
        layout="vertical"
        name="mainForm"
        onFinish={createTeam}
        // style={{ maxWidth: '500px' }}
        size='middle'
        initialValues={{
          owner: defaultAccount
        }}
      >
        <Space>
          <Form.Item
            name="owner" label="Owner" rules={[{ required: true }]}
          >
            <Select
              showSearch
              placeholder="Select an organization"
              onSearch={searchOrgs}
              onSelect={onOrgSelect}
              defaultValue={defaultAccount}
              disabled={defaultAccount != null}
              style={{ width: 200 }}
            >
              {orgOptions.map(org =>
                <Select.Option key={org.id} value={org.name}>
                  <Avatar size="small" shape="circle" src={org.picture_url} style={{ backgroundColor: 'white' }} /> {org.display_name}
                </Select.Option>
              )}
            </Select>
          </Form.Item>
          <Form.Item
            name="name" label="Name" rules={[{ required: true }]}
          >
            <Input placeholder="A name for the team" />
          </Form.Item>
        </Space>

        <Form.Item
          name="description" label="Description"
        >
          <Input placeholder="A short description of the team" />
        </Form.Item>
        <Divider orientation="left">Teammates</Divider>
        <Form.List
          name="members"
          initialValue={typeof user !== 'undefined' ?
            [{ username: user.username, role: 'owner' }, { username: undefined, role: 'member' }] :
            [{ username: undefined, role: 'member' }]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Row key={field.key} style={{ alignItems: index === 0 ? 'center' : 'unset', width: '100%', justifyContent: 'space-between' }}>
                  <Form.Item
                    {...field}
                    shouldUpdate={(prevValues, curValues) =>
                      // @ts-ignore
                      prevValues.owner !== curValues.owner || prevValues.members !== curValues.members
                    }
                    name={[field.name, 'username']}
                    fieldKey={[field.key, 'username']}
                    rules={[{ required: true, message: 'Missing username' }]}
                    label={index === 0 ? 'Username' : ''}
                    style={{ width: '60%', minWidth: '200px' }}
                  >
                    <Select
                      showSearch
                      placeholder="Select an user"
                      onSearch={searchMembers}
                    >
                      {memberOptions.map(m =>
                        <Select.Option key={m.username} value={m.username}>
                          <Avatar size="small" shape="circle" src={m.picture} style={{ backgroundColor: 'white' }} /> {m.username}
                        </Select.Option>
                      )}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...field}
                    initialValue="member"
                    name={[field.name, 'role']}
                    fieldKey={[field.key, 'role']}
                    rules={[{ required: true, message: 'Missing role' }]}
                    label={index === 0 ? 'Role' : ''}
                    style={{ width: '20%', minWidth: '90px' }}
                  >
                    <Select
                    >
                      <Select.Option value="member">Member</Select.Option>
                      <Select.Option value="owner">Owner</Select.Option>
                    </Select>
                  </Form.Item>
                  <Button onClick={() => remove(field.name)} danger shape="circle" style={{ backgroundColor: 'rgba(0,0,0,0)', border: 'none', marginBottom: '-6px' }}>
                    <MinusCircleOutlined />
                  </Button>
                </Row>
              ))}
              <Form.Item>
                <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                  <Button loading={loading} htmlType="submit">
                    Create
                  </Button>
                  <Button type="primary" onClick={() => add()} icon={<PlusOutlined />}>
                    Add Member
                  </Button>
                </Row>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Col>
  )
}