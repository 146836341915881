import React, { useEffect, useState } from 'react'
import Papa from 'papaparse'

import { Table, Pagination } from 'antd'

interface RawViewerProps {
  file: Blob
}

const Raw: React.FunctionComponent<RawViewerProps> = ({ file }) => {
  const [data, setData] = useState<object[]>([])
  const [columns, setColumns] = useState<object[]>([{
    title: '#',
    dataIndex: 'Num',
    key: 'Num',
    width: 65
  }])

  const appendData = (val: any) => {
    const newData = val.data.map((v: any, i: number) => ({ ...v, Num: data.length + i }))
    setData([...data, ...newData])

    const cols = [...val.meta.fields].map(val => ({
      title: val,
      dataIndex: val,
      key: val,
      ellipsis: true,
    }))
    setColumns([...columns, ...cols])
  }

  useEffect(() => {
    if (file) {
      // @ts-ignore
      Papa.parse(file as File, { header: true, chunk: appendData })
    }
  }, [file])

  const showTotal = (total: number) => {
    return `Total ${total} rows`
  }

  return <Table columns={columns}
    dataSource={data}
    pagination={{
      size: 'small',
      total: data.length,
      showTotal: showTotal,
      showSizeChanger: false,
      hideOnSinglePage: true,
      pageSize: 15,
      position: ['topRight'],
    }}
    tableLayout="fixed"
    size="small"
    bordered
    style={{ width: '100%', height: '100%', overflowY: 'auto' }} />
}

export default Raw
