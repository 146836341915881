import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Space, Divider, Typography, Card } from 'antd'
import {
  LoginOutlined,
  LogoutOutlined,
  FunctionOutlined,
  PartitionOutlined,
  ClusterOutlined,
  MonitorOutlined
} from '@ant-design/icons'
import { StepStatus } from '@pollination-solutions/pollination-sdk'
import { useAuth } from 'auth-context'

import { statusIcon, IORender } from '../utils'

dayjs.extend(relativeTime)
dayjs.extend(duration)

const statusTypeIcon = (step: StepStatus) => {
  switch (step.status_type) {
    case 'Function':
      return <FunctionOutlined />
    case 'DAG':
      return <PartitionOutlined />
    case 'Loop':
      return <ClusterOutlined />
    default:
      return <FunctionOutlined />
  }
}

const getStepLogPath = (step: StepStatus) => {
  let logPath = ''

  if (step.status_type === 'Function' || step.status_type === 'DAG' || step.status_type === 'Container') {
    step.outputs.forEach((output) => {
      if (output.name === 'main-logs') {
        // @ts-ignore
        logPath = output.source.path
      }
    })    
  }
  return logPath
}

interface RunTaskProps {
  step: StepStatus
  context: {
    owner: string
    name: string
    id: string
    jobId: string
  }
}

/**
 * Sidebar component for a job step's details and logs, if available.
 * @param {StepStatus} step The step to display information for.
 * @param context The parent job id and project information.
 */
const Task = ({ step, context }: RunTaskProps): React.ReactElement => {
  const { client } = useAuth()

  const projectSlug = `${context.owner}/projects/${context.name}`

  const [logs, setLogs] = useState<string[]>([])
  const [start, setStart] = useState<dayjs.Dayjs>()
  const [end, setEnd] = useState<dayjs.Dayjs>()
  const [duration, setDuration] = useState<duration.Duration>()


  const timer = () => {
    if (!start) {
      return
    }
    if (!end) {
      const now = dayjs(new Date(), { utc: true })
      setDuration(dayjs.duration(now.diff(start)))
      setTimeout(() => {
        timer()
      }, 1000)
    } else {
      setDuration(dayjs.duration(end.diff(start)))
    }
  }

  useEffect(() => {
    timer()
  }, [start])

  useEffect(() => {
    if (step.status_type === 'Function' || step.status_type === 'DAG' || step.status_type === 'Container') {
      client.jobs.downloadJobArtifact({
        owner: context.owner,
        name: context.name,
        jobId: context.jobId,
        path: getStepLogPath(step),
      }).then(r => {
        fetch(r.data)
          .then((res: Response) => {
            if (res.status === 404) {
              setLogs(['No logs available for this step...'])
              return
            }
            res.text()
              .then(text => {
                setLogs(text.split('\n'))
              })
          })
          .catch((err) => {
            setLogs(['No logs available for this step...'])
          })
      }).catch((err) => console.log(err))
    }
    const started_at = dayjs(step.started_at, { utc: true })
    setStart(started_at)
    if (step.finished_at) {
      const finished_at = dayjs(step.finished_at, { utc: true })
      setEnd(finished_at)
    }
  }, [step])

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Divider orientation="left">Overview</Divider>
      <Space>
        <Typography.Text strong>
          Name
        </Typography.Text>
        <Typography.Text>
          {step.name}
        </Typography.Text>
      </Space>
      <Space>
        <Typography.Text strong>
          Type
        </Typography.Text>
        {statusTypeIcon(step)}
        <Typography.Text>
          {step.status_type}
        </Typography.Text>
      </Space>
      <Space>
        <Typography.Text strong>
          Status
        </Typography.Text>
        {statusIcon(step.status)}
        <Typography.Text>
          {step.status}
        </Typography.Text>
      </Space>
      <Space wrap>
        <Typography.Text strong>Duration</Typography.Text>
        <Typography.Text>
          {duration ? `${duration.humanize()} (${duration.format('HH:mm:ss')})` : ''}
        </Typography.Text>
      </Space>
      <Space wrap>
        <Typography.Text strong>Started</Typography.Text>
        <Typography.Text>{start ? start.format('dddd, MMMM D, YYYY h:mm:ss A') : ''}</Typography.Text>
      </Space>
      <Space wrap>
        <Typography.Text strong>Ended</Typography.Text>
        <Typography.Text>{end ? end.format('dddd, MMMM D, YYYY h:mm:ss A') : ''}</Typography.Text>
      </Space>
      {step.status_type === 'Function' && (
        <Space wrap>
          <Typography.Text strong>Command</Typography.Text>
          <Typography.Text>{step.command}</Typography.Text>
        </Space>
      )}
      <Divider orientation="left">
        <Space>
          <LoginOutlined />
          <Typography.Text>Inputs</Typography.Text>
        </Space>
      </Divider>
      {step.inputs.length !== 0 ? (
        step.inputs.map((arg) => IORender(arg, projectSlug, context.jobId))
      ) : (
        <Typography.Text>No Inputs</Typography.Text>
      )}
      <Divider orientation="left">
        <Space>
          <LogoutOutlined />
          <Typography.Text>Outputs</Typography.Text>
        </Space>
      </Divider>
      {step.outputs.length !== 0 ? (
        step.outputs.map((arg) => IORender(arg, projectSlug, context.jobId))
      ) : (
        <Typography.Text>No Outputs</Typography.Text>
      )}
      {logs && (
        <>
          <Divider orientation="left">
            <Space>
              <MonitorOutlined />
              <Typography.Text>Logs</Typography.Text>
            </Space>
          </Divider>
          <Card style={{ background: 'black' }}>
            {
              <Space direction="vertical" style={{ width: '100%' }}>
                {logs.map((l, i) => (
                  <Space>
                    <Typography.Text style={{ color: 'white' }}>
                      {i}
                    </Typography.Text>
                    <Typography.Text style={{ color: 'white' }}>
                      {l}
                    </Typography.Text>
                  </Space>
                ))}
              </Space>
            }
          </Card>
        </>
      )}
    </Space>
  )
}

export default Task
