import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router'
import { Tag, Row, Col, Card, Avatar, Space, Divider, Typography, notification } from 'antd'
import {
  InfoCircleOutlined,
  FunctionOutlined,
  NumberOutlined,
  AppstoreAddOutlined
} from '@ant-design/icons'
import { PluginPackage, Repository } from '@pollination-solutions/pollination-sdk'
import Markdown from 'react-markdown'

import { useAuth } from 'auth-context'
import { Loading, Error } from 'atoms'

import Tabs from 'components/Tabs'
import ConfigCard from './ConfigCard'
import FunctionsCard from './FunctionsCard'
import PluginTagsList from './TagsList'

const iconProps = {
  mode: 'outlined' as 'outlined' | 'filled',
  size: 24,
  color: '#000000',
  strokeWidth: 2,
}

export const PluginPage: React.FunctionComponent = () => {
  // @ts-ignore
  const { accountName, pluginName, pluginTag } = useParams()
  const { client } = useAuth()

  const [loading, setLoading] = useState<boolean>(true)
  const [fetchError, setFetchError] = useState<any | undefined>()
  const [snapshot, setSnapshot] = useState<PluginPackage | undefined>()
  const [repository, setRepository] = useState<Repository | undefined>()

  useEffect(() => {
    client.plugins.getPlugin({ owner: accountName, name: pluginName })
      .then(async ({ data }) => {
        setRepository(data)
        return client.plugins.getPluginByTag({
          owner: accountName,
          name: pluginName,
          tag: pluginTag ?? 'latest'
        })
          .then(({ data }) => setSnapshot(data))
      })
      .catch((err) => setFetchError(err))
      .finally(() => setLoading(false))
  }, [accountName, pluginName, pluginTag])

  useEffect(() => {
    if (snapshot && snapshot.manifest.metadata.deprecated) {
      notification.warning({
        message: `${accountName}/${snapshot.manifest.metadata.name}:${snapshot.manifest.metadata.tag} is deprecated`,
        description: 'This plugin is no longer supported. Contact recipe author or use a different version.'
      })
    }
  }, [snapshot])

  const styles = {
    cardBody: {
      minHeight: '300px',
      overflow: 'auto',
    },
  }

  if (loading) {
    return (
      <>
        <Row>
          <Col>
            <Space wrap>
              <AppstoreAddOutlined />
              <Typography.Text style={{ fontSize: '20px' }}>
                <Link to={`/${accountName}?tab=plugins`}>{accountName}</Link>
              </Typography.Text>
              <Typography.Text style={{ fontSize: '20px' }} >/</Typography.Text>
              <Typography.Text strong style={{ fontSize: '20px' }}>
                <Link to={`/${accountName}/plugins/${pluginName}/${pluginTag ?? 'latest'}`}>{pluginName}</Link>
              </Typography.Text>
            </Space>
          </Col>
        </Row>
        <Loading />
      </>
    )
  }

  const openFunction = typeof window === 'undefined' ? '' : window.location.href.split('#')[1] ?? ''

  return (
    <>
      <Row>
        <Col>
          <Space wrap>
            <AppstoreAddOutlined />
            <Typography.Text style={{ fontSize: '20px' }}>
              <Link to={`/${accountName}?tab=plugins`}>{accountName}</Link>
            </Typography.Text>
            <Typography.Text style={{ fontSize: '20px' }} >/</Typography.Text>
            <Typography.Text strong style={{ fontSize: '20px' }}>
              <Link to={`/${accountName}/plugins/${pluginName}/${pluginTag ?? 'latest'}`}>{pluginName}</Link>
            </Typography.Text>
          </Space>
        </Col>
      </Row>
      {(fetchError || !repository || !snapshot) ? (
        <Error error={fetchError} />
      ) : (
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={8}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Divider>About</Divider>
              <Card>
                <Card.Meta
                  title={repository.name}
                  avatar={<Avatar src={repository.icon} />}
                  description={repository.description}
                />
                <br />
                <Space wrap>
                  {snapshot.manifest.metadata.keywords?.map((key) => (
                    <Tag key={`op-key-${key}`}>{key}</Tag>
                  ))}
                </Space>
              </Card>
              <Divider>Maintainers</Divider>
              <Card>
                <Space direction="vertical">
                  {snapshot.manifest.metadata.maintainers &&
                    snapshot.manifest.metadata.maintainers.map((m) => (
                      <Space>
                        <Typography.Text strong>{m.name}</Typography.Text>
                        {m.email ? <Typography.Text>: {m.email}</Typography.Text> : <></>}
                      </Space>
                    ))
                  }
                </Space>
              </Card>
              <Divider>License</Divider>
              <Card>
                {snapshot.manifest.metadata.license ? (
                  <Space>
                    <Typography.Link href={snapshot.manifest.metadata.license.url}>
                      {snapshot.manifest.metadata.license.name}
                    </Typography.Link>
                  </Space>
                ) : (
                  <Space>
                    <Typography.Text>
                      No License
                    </Typography.Text>
                  </Space>
                )}
              </Card>
              <Divider>Config</Divider>
              <Card>
                <ConfigCard styles={styles.cardBody} manifest={snapshot.manifest} />
              </Card>
            </Space>
          </Col>
          <Col xs={24} lg={16}>
            <Tabs
              tabs={[
                {
                  label: 'Functions',
                  icon: <FunctionOutlined />,
                  content: <FunctionsCard styles={styles.cardBody} manifest={snapshot.manifest} defaultOpen={openFunction} />,
                },
                {
                  label: 'Readme',
                  icon: <InfoCircleOutlined />,
                  content: <Card>{<Markdown source={snapshot.readme ? snapshot.readme : '# No Readme'} />}</Card>,
                },
                {
                  label: 'Tags',
                  icon: <NumberOutlined rotate={45} />,
                  content: <PluginTagsList repository={repository} owner={accountName} name={pluginName} />,
                },
              ]}
            />
          </Col>
        </Row>
      )}

    </>
  )
}