import React, { useEffect, useState } from 'react'


import { Grid } from 'antd'
import { FileMeta } from '@pollination-solutions/pollination-sdk'
import { FailPreview, Loading } from 'atoms'

import { getDriver } from './drivers'

interface ViewerProps {
  fileMeta: FileMeta
  fileType: string
  filePath: string
  file?: Blob
  isCloud?: boolean
}

// the "driver" or "viewer" components render twice, once with file.size === 0,
// and once when the actual file is loaded this is a pretty annoying behavior
export const Viewer: React.FunctionComponent<ViewerProps> = ({ fileMeta, fileType, filePath, file = undefined, isCloud=true }) => {
  const screens = Grid.useBreakpoint()

  const [rawData, setRawData] = useState<Blob>(new Blob)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (file) {
      setRawData(file)
      return
    }
    fetch(filePath).then(async (res) => {
      if (res.ok) {
        const data = await res.blob()
        setRawData(data)
      }
    })
  }, [filePath])

  useEffect(() => {
    if (rawData.size > 0) setLoading(false)
  }, [rawData])

  const Driver = getDriver(fileType, screens)

  const fullBleed = ['vtp', 'vtkjs', 'hbjson']

  const padding = fullBleed.includes(fileType) ? 0 : 24

  return (
    <div className="file-viewer" style={{ 
        height: '100%', 
        width: '100%', 
        textAlign: 'left', 
        display: 'flex', 
        padding }}>
      {loading ?
        <Loading />
        :
        (Driver) ? <Driver
          file={rawData}
          screens={screens}
          // @ts-ignore
          type={fileType}
          path={filePath}
          menu={true}
          sider={true}
        /> : <FailPreview
          fileMeta={fileMeta}
          downloadUrl={filePath}
          message={`Cannot preview ${fileType} files.`}
          file={file}
          isCloud={isCloud}
        />
      }
    </div>
  )
}

export default Viewer
