import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

export interface OptionList {
  decimals?: number
  pattern?: string
  units?: boolean
}

// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export const formatBytes = (val?: number, options?: OptionList) => {
  if (val === 0 || typeof val === 'undefined') return '0 Bytes'
  const decimals = typeof options?.decimals !== 'undefined' ? options?.decimals : 2
  const units = typeof options?.units !== 'undefined' ? options?.units : true

  const k = 1000
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(val) / Math.log(k))

  return units ? `${parseFloat((val / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}` : parseFloat((val / Math.pow(k, i)).toFixed(dm)).toString()
}

export const formatHoursMinutes = (val?: number, options?: OptionList) => {
  if (typeof val === 'undefined') return '--'
  const pattern = typeof options?.pattern !== 'undefined' ? options?.pattern : 'HH:mm'
  const units = typeof options?.units !== 'undefined' ? options?.units : true

  const parsed = parseHourFloat(val)
  const duration = dayjs.duration(parsed)
  const hours = duration.format('HH')
  const mins = duration.format('mm')

  return units ? `${hours}h ${mins}m` : `${hours}:${mins}`
}

// fractional hours to hours / minutes / seconds
const parseHourFloat = (hour: number) => {
  const minutes = ((hour % 1) * 60)
  const seconds = Math.round((minutes % 1) * 60)
  return {
    hours: Math.floor(hour),
    minutes: Math.round(minutes),
    seconds
  }
}

// format multiple words to lowercase, respecting acronyms
// Cumulative CPU Hours => cumulative CPU hours
export const formatStringLowercase = (val?: string, options?: OptionList) => {
  return val ? val.replace(/\b[A-Z](?![A-Z])/g, m => m.toLowerCase()) : '--'
}