import React from 'react'

import Icon, { FileTextFilled, FileUnknownFilled, FileImageFilled } from '@ant-design/icons'
import { jsonSvgFilled, csvSvgFilled, threeDimSvgFilled, weatherSvgFilled, xmlSvgFilled } from './fileIcons'

/** Given a file type, returns any specialized icons. */
export const getFileIcon = (type?: string): React.ReactNode => {
  const size = 24

  switch (type?.toLowerCase()) {
    case 'txt':
    case 'log':
    case 'idf':
    case 'osm':
    case 'rad':
    case 'mat':
    case 'err':
    case 'eio':
    case 'rdd':
    case 'mdd':
    case 'eso':
    case 'mtd':
    case 'shd':
    case 'audit':
    case 'bnd':
    case 'blk':
      return <FileTextFilled style={{ fontSize: size }} />
    case 'xml':
    case 'ddy':
    case 'epw':
    case 'rain':
    case 'stat':
    case 'clm':
    case 'wea':
      return <Icon component={weatherSvgFilled} style={{ fontSize: size, color: '#A61E4E' }} />
    case 'csv':
    case 'ill':
    case 'da':
    case 'cda':
    case 'udi':
    case 'res':
      return <Icon component={csvSvgFilled} style={{ fontSize: size, color: '#2B8A3E' }} />
    case 'json':
    case 'osw':
      return <Icon component={jsonSvgFilled} style={{ fontSize: size, color: '#1D64AB' }} />
    case 'hbjson':
      return <Icon component={jsonSvgFilled} style={{ fontSize: size, color: '#F5B34C' }} />
    case '3dm':
    case 'vtkjs':
    case 'vtp':
    case 'vsf':
      return <Icon component={threeDimSvgFilled} style={{ fontSize: size, color: '#F08C00' }} />
    case 'jpg':
    case 'png':
    case 'svg':
    case 'hdr':
      return <FileImageFilled style={{ fontSize: size, color: '#862E9C' }} />
    default:
      return <FileUnknownFilled style={{ fontSize: size }} />
  }
}
