import React, { useEffect, useState } from 'react'
import { Button, Collapse, Input, List, Select, SelectProps, Space, Tag, Typography, notification } from 'antd'
import { useAuth } from 'auth-context'
import { ProjectRecipeFilter, RecipesApiListRecipesRequest, Repository } from '@pollination-solutions/pollination-sdk'
import { RecipeCard } from './Card'
import isEqual from 'lodash.isequal'
import debounce from 'lodash.debounce'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect'
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons'
import { duration } from 'dayjs'

interface FilterListProps {
  owner: string,
  name: string,
  defaultFilters?: ProjectRecipeFilter[]
}

export const FilterList: React.FC<FilterListProps> = ({
  owner,
  name,
  defaultFilters,
}) => {
  const { client } = useAuth()

  const [recipes, setRecipes] = useState<Repository[]>([])
  const [total, setTotal] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>()
  const [poolLoading, setPoolLoading] = useState<boolean>(false)

  const [pageSize, setPageSize] = useState<number>(4)
  const [page, setPage] = useState<number>(1)
  const [search, setSearch] = useState<string>()

  useEffect(() => {
    setLoading(true)
    const query = {
      search: search,
      page: page,
      perPage: pageSize,
    } as RecipesApiListRecipesRequest
    client.recipes.listRecipes({ ...query })
      .then(({ data }) => {
        setTotal(data.total_count)
        setRecipes(data.resources)
        setLoading(false)
      }).catch((err) => {
        notification.error({
          message: err.response.data.detail,
        })
        setLoading(false)
      })
  }, [page, pageSize, search])

  const options: SelectProps['options'] = []

  const [selection, setSelection] = useState<ProjectRecipeFilter[]>(defaultFilters ?? [])

  const addFilter = (filter: ProjectRecipeFilter) => {
    client.projects.createProjectRecipeFilter({
      owner,
      name,
      projectRecipeFilter: filter
    })
      .then(() => {
        notification.success({
          message: 'Recipe added!',
          duration: 1
        })
      })
  }

  const delFilter = (filter: ProjectRecipeFilter) => {
    setPoolLoading(true)
    client.projects.deleteProjectRecipeFilter({
      owner,
      name,
      projectRecipeFilter: filter
    })
      .then(() => setPoolLoading(false))
  }

  const handleClick = (sel: ProjectRecipeFilter) => {
    if (!selection) return
    if (!selection.find(el => isEqual(el, sel))) {
      setSelection([...selection, sel])
      addFilter(sel)
    }
  }

  const removeRecipeFilter = (text: string) => {
    const [owner, name_tag] = text.split('/')
    const [name, tag] = name_tag.split(':')
    const sel = {
      owner: owner,
      name: name,
      tag: tag,
    } as ProjectRecipeFilter
    const index = selection.findIndex(el => isEqual(el, sel))
    if (index >= 0) {
      selection.splice(index, 1)
      delFilter(sel)
      setSelection(selection)
    }
  }

  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault()
      event.stopPropagation()
    }

    return (
      <div style={{
        display: 'flex',
        verticalAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '3px'
      }}>
        <Tag
          onMouseDown={onPreventMouseDown}
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            height: '24px',
            width: '100%',
            fontSize: '13px',
            fontWeight: 600,
            fontVariantNumeric: 'tabular-nums'
          }}
        >
          {label}
        </Tag>
        <Button shape='circle'
          size='small'
          type='primary'
          onClick={onClose}><DeleteOutlined /></Button>
      </div>
    )
  }

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <Collapse style={{ width: '100%' }}>
        <Collapse.Panel key={'recipes-pool'} header='Project recipes'>
          <Typography.Text>
            The following recipes can be used to run studies in this project.
          </Typography.Text>
          <Select
            mode='multiple'
            allowClear={false}
            tagRender={tagRender}
            showArrow={false}
            options={undefined}
            loading={poolLoading}
            open={false}
            style={{ width: '100%', marginTop: '15px', textOverflow: 'ellipsis' }}
            placeholder='Please select'
            defaultValue={[]}
            onDeselect={removeRecipeFilter}
            value={selection?.map(el => `${el.owner}/${el.name}:${el.tag}`)
              .sort()
              .reverse()}
          />
        </Collapse.Panel>
      </Collapse>
      <Typography.Text>
        Add new recipes to project.
      </Typography.Text>
      <Input
        type='text'
        onChange={debounce((e: any) => setSearch(e.target.value), 1000)}
        placeholder='Search Recipes...' />
      <List
        style={{ marginTop: '10px' }}
        grid={{ gutter: 16, column: 1 }}
        dataSource={recipes}
        renderItem={(item) => <List.Item>
          {!loading && <RecipeCard repository={item} onClick={handleClick} />}
        </List.Item>}
        loading={loading}
        pagination={{
          onChange: page => {
            setPage(page)
          },
          showSizeChanger: true,
          onShowSizeChange: (c, size) => setPageSize(size),
          pageSize,
          total
        }}
      />
    </Space>
  )
}

export default FilterList