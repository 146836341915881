import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react'

import { notification } from 'antd'

import { useAuth } from 'auth-context'

import { AccountPublic, Organization } from '@pollination-solutions/pollination-sdk'
import { useParams } from 'react-router'

// default functions to keep ts happy
const defaultUpdateAccount = (account: Partial<AccountPublic>) => {
  return new Promise<void>((resolve, reject) => {
    reject()
  })
}

const defaultFetchAccount = (name?: string) => {
  return new Promise<void>((resolve, reject) => {
    reject()
  })
}

const defaultDeleteAccount = () => {
  return new Promise<void>((resolve, reject) => {
    reject()
  })
}

interface AccountContextProps {
  loading: boolean
  canWrite: boolean
  canRead: boolean
  fetchAccount: (name?: string) => Promise<void>
  updateAccount: (update: Partial<AccountPublic>) => Promise<void>
  deleteAccount: () => Promise<void>
  account?: AccountPublic
  organization?: Organization
  accountStatus?: boolean
}

const AccountContext = React.createContext<AccountContextProps>({ 
  loading: false, 
  canWrite: false, 
  canRead: false, 
  fetchAccount: defaultFetchAccount, 
  updateAccount: defaultUpdateAccount,
  deleteAccount: defaultDeleteAccount, 
})

interface AccountProviderProps {
  children: React.ReactNode
}

const AccountProvider = ({ children }: AccountProviderProps): React.ReactElement => {

  const { user: authUser, client, setUser: setAuthUser, loading: noauth } = useAuth()

  const [loading, setLoading] = useState(false)

  const [canWrite, setCanWrite] = useState(false)
  const [canRead, setCanRead] = useState(false)
  const [account, setAccount] = useState<AccountPublic | undefined>()
  const [accountStatus, setAccountStatus] = useState<boolean>(false)
  const [organization, setOrganization] = useState<Organization | undefined>()

  const params = useParams()

  // @ts-ignore
  const accountName: string | undefined = useMemo<string>(() => params.accountName, [params.accountName])

  const fetchAccount = useCallback(async (_name?: string) => {
    const name = _name || accountName

    const { data: account } = await client.accounts.getAccount({ name })

    let org: Organization | undefined = undefined

    if (account.account_type === 'org') {
      const { data } = await client.orgs.getOrg({ name: account.name })
      setOrganization(data)
      org = data
    } else {
      setOrganization(undefined)
    }

    const write = org ? org.role === 'owner' : name === authUser?.username
    const read = org ? org.role === 'member' : name === authUser?.username

    setCanWrite(write)
    setCanRead(read)
    setAccount(account)

  }, [accountName, authUser?.username, client.accounts, client.orgs])

  const deleteAccount = useCallback(async () => {
    if (!account) return
    if (account.account_type !== 'user') return

    const name = account.name

    setLoading(true)
    client.accounts.deleteAccount({ name })
      .catch((error) => {
        notification.error({
          message: 'We failed to delete account',
          description: error,
        })
      })
      .finally(() => {
        setLoading(false)
      })

  }, [account, accountName, authUser, client.orgs, client.user, fetchAccount, setAuthUser])


  useEffect(() => {

    if (!accountName) return

    setLoading(true)

    fetchAccount(accountName)
      .finally(() => setLoading(false))

  }, [fetchAccount, accountName])

  const updateAccount = useCallback(async (update: Partial<AccountPublic>) => {
    if (!account) return

    const cleanUpdate = {
      name: update.display_name ?? account.display_name ?? account.name,
      description: update.description ?? account.description,
      picture_url: update.picture_url ?? account.picture_url ?? `https://robohash.org/${update.name}`,
    }

    try {
      switch (account.account_type) {
        case 'user':
          client.user.updateUserProfile({ userUpdate: cleanUpdate })
            .then((val) => {
              fetchAccount(accountName)
              if (authUser && authUser.username === account.name) {
                setAuthUser({ ...authUser, ...cleanUpdate, picture: cleanUpdate.picture_url })
              }
            })
          break
        case 'org':
          client.orgs.updateOrg({
            name: account?.name,
            organizationUpdate: cleanUpdate
          })
            .then((val) => {
              fetchAccount(accountName)
            })
      }
    } catch (err: any) {
      notification.error({
        message: 'Failed',
        description: `Change to ${account.name} settings failed: ${err.response.detail}`
      })
    }
  }, [account, accountName, authUser, client.orgs, client.user, fetchAccount, setAuthUser])

  return (
    <AccountContext.Provider value={{ 
      loading, 
      account, 
      fetchAccount, 
      updateAccount, 
      deleteAccount,
      organization, 
      canWrite, 
      canRead, 
      accountStatus 
    } as AccountContextProps}>{children}</AccountContext.Provider>
  )
}

export default AccountProvider

export const useAccount = (): AccountContextProps => {
  return useContext(AccountContext)
}