import React from 'react'

import { CenteredHeader } from 'atoms'
import { Col, Divider, Row, Skeleton, Space, Tabs, Typography } from 'antd'
import { useAccount } from 'hooks'
import { useHistory } from 'react-router-dom'

import { CreateOrgForm } from 'molecules'

export const CreateOrg: React.FunctionComponent = () => {
  const history = useHistory()

  const { loading, account } = useAccount()

  return (
    <Col lg={24} xl={{ span: 16, offset: 4 }}>
      <CenteredHeader
        title='Create a Pollination Organization'
        subtitle='Freedom and Flexibility to Model Real World Complexity'
      />
      <Divider />
      <Skeleton active loading={loading}>
        <Tabs activeKey={'create'} renderTabBar={() => <></>} style={{ width: '100%' }}>
          <Tabs.TabPane tab='Create' key='create'>
            <Row justify='start'>
              <Space direction='vertical' size={24} style={{ width: '100%' }}>
                <Typography.Title level={4} style={{ marginBottom: 0 }}>
                  Setup your organization profile
                </Typography.Title>
                {account &&
                  <CreateOrgForm account={account}
                    onSuccess={(orgName?: string) => {
                      history.push(`/${orgName}?tab=subscription`)
                    }} />
                }
              </Space>
            </Row>
          </Tabs.TabPane>
        </Tabs>
      </Skeleton>
    </Col>
  )

}
