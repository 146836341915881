import { ApplicationSortKey, ApplicationsApiListApplicationsRequest, 
  JobStatusEnum, RunStatusEnum, SortEnum } from '@pollination-solutions/pollination-sdk'
import { TargetPlaform } from 'pollination-react-io'
import { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export interface ParamsConfiguration {
  search?: boolean,
  ids?: boolean,
  names?: boolean,
  owner?: boolean,
  _public?: boolean,
  permissions?: boolean,
  keywords?: boolean,
  sortBy?: boolean,
  sortOrder?: boolean,
  perPage?: boolean,
  page?: boolean,
  status?: boolean,
  platform?: boolean,
}

export interface Params extends ApplicationsApiListApplicationsRequest  {
  status?: RunStatusEnum | JobStatusEnum | undefined
  platform?: TargetPlaform | undefined
}

export const usePaginationHistory = (initialValues?: Params, 
  configuration?: ParamsConfiguration) => {
  const location = useLocation()
  const history = useHistory()

  const defaultConfiguration = {
    search: false,
    ids: false,
    names: false,
    owner: false,
    _public: false,
    permissions: false,
    keywords: false,
    sortBy: false,
    sortOrder: false,
    perPage: true,
    page: true,
    status: true,
    platform: false,
    ...configuration
  }

  const defaultValues = {
    search: undefined,
    ids: undefined,
    names: undefined,
    owner: undefined,
    _public: undefined,
    permissions: undefined,
    keywords: undefined,
    sortBy: ApplicationSortKey.UpdatedAt,
    sortOrder: SortEnum.Descending,
    perPage: 5,
    page: 1,
    status: undefined,
    platform: TargetPlaform.all,
    ...initialValues
  }

  /**
   * Query params set
   */
  const setParams = (params: Params) => {
    const query = new URLSearchParams(location.search)
    
    Object.entries(params).forEach(([k, v]) => {
      if (!defaultConfiguration[k]) return

      if (query.get(k)) {
        // if =
        if (v?.length === 0 || v == null) {
          query.delete(k)
        } else {
          query.set(k, v)
        }
      } else {
        query.append(k, v)
      } 
    })

    history.push({
      search: query.toString()
    })
  }
  
  /**
   * Query params get from history and search
   */
  const parameters = useMemo(() => {

    let values = defaultValues

    Object.entries(defaultValues).forEach(([k, v]) => {
      if (!defaultConfiguration[k]) return

      const value = new URLSearchParams(location.search).get(k)

      // Decode methods
      let val: any = value
      if (value && value !== 'null' && value !== 'undefined') {
        switch (k) {
          case 'page':
          case 'perPage':
            val = parseInt(value)
            break
          case 'keywords':
          case 'search':
          case 'ids':
          case 'names':
          case 'owner':
            val = value.split('%C2').filter(v => v !== '%C2')
            break
          case '_public':
            val = (/true/i).test(value)
            break
          case 'platform':
            val = value
            break
          default:
            break
        }
      values = { ...values, [k]: val } }
    })

    return values
  }, [location.search])

  return {
    setParams,
    ...parameters,
    parameters
  }
}
