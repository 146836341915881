// @flow
import React from 'react'
import { useFlags } from 'hooks/useFlags'

type FeatureProps = {
  name: string,
  Fallback?: Function
};

export const Feature: React.FunctionComponent<FeatureProps> = ({ name, Fallback, children }) => {
  const { flags } = useFlags()
  
  if (flags[name]) {
    return <>{children}</>
  } else if (Fallback) {
    return <Fallback />
  }

  return <></>
}

export default Feature

export const withFeature = (name: string) => {
  // eslint-disable-next-line react/display-name
  return (Component: Function, Fallback?: Function) => (props: React.ComponentProps<any>) => {
    return (
      <Feature name={name} Fallback={Fallback}>
        <Component {...props} />
      </Feature>
    )
  }
}
