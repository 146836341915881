import React, { CSSProperties, FC, useEffect, useMemo, useState } from 'react'

import { Select, SelectProps } from 'antd'

import debounce from 'lodash.debounce'

import { useAuth } from 'auth-context'
import { OrganizationMemberList, OrganizationMember } from '@pollination-solutions/pollination-sdk'

import AvatarLabel from 'atoms/AvatarLabel'

export interface SearchMembersProps {
  organizationName: string
  page?: number
  perPage?: number
  value?: OrganizationMember[] | string[]
  setParentMember?: React.Dispatch<React.SetStateAction<OrganizationMember[]>>
  onChange?: (value?: string[]) => void
  multiple?: boolean
  size?: 'large' | 'small'
  style?: CSSProperties
  maxTags?: number
  selectProps?: Partial<SelectProps>
}

export const SearchMembers: FC<SearchMembersProps> = ({
  organizationName,
  value,
  setParentMember,
  onChange,
  multiple,
  maxTags,
  size,
  style,
  selectProps
}) => {

  const { client } = useAuth()

  const [loading, setLoading] = useState<boolean>(false)
  const [members, setMembers] = useState<OrganizationMember[]>([])

  const [search, setSearch] = useState<string>('')

  useEffect(() => {
    setLoading(true)
    client.orgs.getOrgMembers({ name: organizationName }).then(({ data }) => {
      const d = data as OrganizationMemberList
      const members = d.resources.filter(m => m.user.username.startsWith(search))
      setMembers(members)
    }).finally(() => setLoading(false))

  }, [client.orgs, organizationName, search])

  const debounceSearch = useMemo(() => debounce((search: string) => setSearch(search), 200), [])

  return (
    <Select
      showSearch
      showArrow
      onSearch={debounceSearch}
      style={{ ...style, width: '100%' }}
      loading={loading}
      allowClear={true}
      maxTagCount={maxTags}
      placeholder={'Find members'}
      mode={multiple ? 'multiple' : undefined}
      // @ts-ignore
      value={value?.reduce((p: string[], c: OrganizationMember | string) => {
        return typeof c === 'string' ? c : [...p, c.user.name]
      }, [] as string[])}
      size={size}
      onSelect={(value) => {
        const member = members.find(m => m.user.username === value)
        if (!member) return
        if (setParentMember) setParentMember(state => [...state, member])
        if (onChange) onChange([value])
      }}
      onDeselect={(value) => {
        if (setParentMember) setParentMember(state => state.filter(member => member.user.username !== value))
        if (onChange) onChange()
      }}
      onClear={() => {
        if (setParentMember) setParentMember([])
      }}
      {...selectProps}
    >
      {
        members?.map(({ user }, i) => (
          <Select.Option value={user.username} key={`${user.username}-${i}`}>
            <AvatarLabel account={user} />
          </Select.Option>
        ))
      }
    </Select>
  )
}
