import React, { useState, useEffect, useContext } from 'react'

import { UserPermission } from '@pollination-solutions/pollination-sdk'
import { useArtifacts } from 'hooks'
import { Loading } from 'atoms'
import Folder from 'components/Folder'

import context from './context'
import { Col } from 'antd'

/** Shallow wrapper for `ProjectFolder` component on page. */
const FilesTab = (): React.ReactElement => {
  const { project } = useContext(context)

  // Construct project folder props
  const [loading, setLoading] = useState<boolean>(true)
  const [owner, setOwner] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [permissions, setPermissions] = useState<UserPermission>({})

  const {
    listArtifacts,
    downloadArtifact,
    uploadArtifact,
    deleteArtifact
  } = useArtifacts(owner, name)

  useEffect(() => {
    if (!project) {
      return setLoading(true)
    }
    const [o, n] = project.slug.split('/')
    setOwner(o)
    setName(n)
    setPermissions(project.permissions)
    setLoading(false)
  }, [project])

  if (loading) {
    return <Loading />
  }

  return (
    <Col>
      <Folder
        listArtifacts={listArtifacts}
        downloadArtifact={downloadArtifact}
        // @ts-ignore
        uploadArtifact={(permissions.admin || permissions.write) ? uploadArtifact : undefined}
        deleteArtifact={(permissions.admin || permissions.write) ? deleteArtifact : undefined}
      />
    </Col>
  )
}

export default FilesTab
