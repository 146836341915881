import React, { CSSProperties, useEffect, useState } from 'react'
import { Row, List, Space, Typography, Select } from 'antd'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { ClockCircleOutlined } from '@ant-design/icons'
import { CloudJob, RunStatusEnum } from '@pollination-solutions/pollination-sdk'

import { useAuth } from 'auth-context'

import { RunRow, useRuns } from 'pollination-react-io'
import { FileCardProps } from 'pollination-react-io/build/RunDetails/Cards/FileCard'
import { FolderCardProps } from 'pollination-react-io/build/RunDetails/Cards/FolderCard'
import { Loading } from 'atoms'
import useSWR from 'swr'
import { usePaginationHistory } from 'hooks/usePaginationHistory'

dayjs.extend(duration)

export interface RunListProps {
  projectOwner: string
  projectName: string
  job: CloudJob
  style?: CSSProperties
  getFileCard?: (card: FileCardProps) => any
  getFolderCard?: (card: FolderCardProps) => any
  extra?: any[]
}

export const RunsList: React.FunctionComponent<RunListProps> = ({ 
  projectOwner,
  projectName,
  job,
  style,
  getFileCard,
  getFolderCard,
  extra,
}) => {
  const { client, user: authUser } = useAuth()
  const { setParams, page, perPage, status } = usePaginationHistory()
  
  // @ts-ignore
  const { listRuns } = useRuns(client)
  const [total, setTotal] = useState<number>(0)

  const [isLoading, setIsLoading] = useState(false)

  const { data, mutate, error } = useSWR(
    authUser && job ? [projectOwner, projectName, job.id, 
      page, perPage, status]: undefined, 
    listRuns,
    {
      revalidateOnFocus: false,
      errorRetryCount: 3,
      onSuccess: (run) => {
        setTotal(run.total_count)
        setIsLoading(false)
      } 
    }
  )
  
  useEffect(() => {
    if (!job) return
    
    // Force swr fetch
    mutate()
  }, [job])

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <Row justify='space-between'>
        <Space>
          <ClockCircleOutlined style={{ fontSize: '24px' }} />
          <Typography.Text strong>Runs</Typography.Text>
        </Space>
        <Space>
          {extra && extra.map(e => e)}
          <Select
            allowClear
            placeholder='Filter by status'
            value={status as RunStatusEnum}
            onClear={() => setParams({ page: 1, status: undefined })}
            onSelect={(value: RunStatusEnum) => {
              // Set values by query
              setParams({ page: 1, status: value })
            }}
            style={{ minWidth: '200px' }}
          >{(Object.keys(RunStatusEnum) as (keyof typeof RunStatusEnum)[]).map((k) => {
              return <Select.Option value={k} key={k}>{k}</Select.Option>
            })}
          </Select>
        </Space>
      </Row>
      {!data && <Loading />}
      <List
        style={{ marginTop: '-10px' }}
        dataSource={data?.resources}
        loading={isLoading}
        split={false}
        grid={{ gutter: 0, column: 1 }}
        pagination={{
          onChange: (page, pageSize) => {
            // Set values by query
            setParams({ 
              perPage: pageSize, 
              page: page, 
            })
          },
          current: page,
          showSizeChanger: true,
          pageSize: perPage,
          total: total,
        }}
        renderItem={(item, index) => (
          <RunRow 
            key={`run-${item.id}-${index}`}
            projectOwner={projectOwner}
            projectName={projectName}
            // @ts-ignore
            index={(index + 1) + (page - 1) * perPage}
            style={style}
            getFileCard={getFileCard}
            getFolderCard={getFolderCard}
            // @ts-ignore
            run={item}
            // @ts-ignore
            client={client}
            authUser={authUser}
          />
        )}
      />
    </Space>
  )
}


export default RunsList
