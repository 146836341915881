import React, { useEffect, useState } from 'react'

import ReactJson from 'react-json-view'

import { Col, Spin } from 'antd'

interface JsonViewerProps {
  file: Blob
  screens: any
}

export const JsonViewer: React.FunctionComponent<JsonViewerProps> = ({ file, screens }) => {
  const [json, setJson] = useState<object>(JSON.parse('{}'))
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    file.text().then((fileString) => {
      if (fileString.length > 0) setJson(JSON.parse(fileString))
    })
  }, [file])

  useEffect(() => {
    if (Object.values(json).length !== 0) setLoading(false)
  }, [json])

  return (
    <>{
      loading ?
        <Spin /> :
        <Col style={{ width: '100%', height: '100%', overflowY: 'auto', border: '1px solid #F0F0F0', borderRadius: '4px', backgroundColor: '#FAFAFA' }}>
          <ReactJson src={json} collapsed={!screens.xs ? 3 : 1} collapseStringsAfterLength={!screens.xs ? false : 7} enableClipboard={!screens.xs} />
        </Col>
    }</>
  )
}
