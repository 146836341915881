import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ApplicationVersion } from '@pollination-solutions/pollination-sdk'
import { notification } from 'antd'
import useSWR from 'swr'

import { useAuth } from 'auth-context'
import useApplication from 'hooks/useApplication'

interface ContextProps {
    loading: boolean
    applicationVersions?: ApplicationVersion[]
    latestVersion?: ApplicationVersion
    reloadApplicationVersions: () => Promise<void>
}

interface ProviderProps {
    children: React.ReactNode
}

export const ApplicationVersionsContext = createContext<ContextProps>({} as ContextProps)


export const ApplicationVersionsProvider = ({ children }: ProviderProps) => {
    const [loading, setLoading] = useState<boolean>(true)
    const [versions, setVersions] = useState<ApplicationVersion[]>([])

    const { loading: authLoading, client, idToken } = useAuth()
    const { loading: applicationLoading, application } = useApplication()

    const fetchApplicationVersions = useCallback(async (owner: string, slug: string) => {
        return client.applications.getApplicationVersions({ owner, slug })
            .then(({ data }) => data.resources as ApplicationVersion[])
    }, [client.applications])


    const reloadApplicationVersions = useCallback(async () => {
        if (!application) return Promise.reject(new Error('Application not defined'))
        return fetchApplicationVersions(application.owner.name, application.slug)
            .then((applicationVersions) => {
                setVersions(applicationVersions)
            })
    }, [fetchApplicationVersions, application])

    useEffect(() => {
        if (authLoading || applicationLoading || !application) return

        reloadApplicationVersions().catch((err) => {
            if (err.message.includes('404')) {
                return
            }
            notification.error({
                message: 'Error',
                description: err.message
            })
        }).finally(() => {
            setLoading(false)
        })
    }, [authLoading, applicationLoading, reloadApplicationVersions, application])

    useSWR(application ? application.id : 'empty-app', reloadApplicationVersions, { errorRetryCount: 3, revalidateOnFocus: false, refreshInterval: 10000 })

    const latestVersion = useMemo(() => {
        if (!versions) return
        return versions[0]
    }, [versions])

    return (
        <ApplicationVersionsContext.Provider
            value={{
                loading,
                applicationVersions: versions,
                latestVersion,
                reloadApplicationVersions: reloadApplicationVersions
            }}
        >
            {children}
        </ApplicationVersionsContext.Provider>
    )
}

const useApplicationVersions = () => {
    return useContext(ApplicationVersionsContext)
}

export default useApplicationVersions
