import React from 'react'
import { FolderFilled, FolderOutlined, RightOutlined } from '@ant-design/icons'
import { Typography, Button, Row } from 'antd'

interface BreadcrumbProps {
  root?: string
  path?: string
  onSelectPath: (path?: string) => void
  screens: any
}

/**
 * Given a folder path, returns a clickable set of breadcrumbs for each folder.
 * @param {string[] | undefined} path Array of folder names in path order.
 * @param onSelectPath Callback that modified parent component path in state.
 */
export const BreadCrumb = ({ root, path, onSelectPath, screens }: BreadcrumbProps): React.ReactElement => {
  const handleSelectPath = (index?: number): void => {
    if (index === undefined || !path) {
      // Reset path to home folder
      onSelectPath(undefined)
      return
    }

    // Select slice of current path up to declared subpath
    let newPath = path?.split('/').filter(p => p !== '').slice(0, index + 1).join('/')

    if (root) {
      newPath = [root, newPath].join('/')
    }
    onSelectPath(newPath)
  }

  const formatCrumb = (crumb: string) => {
    if (!screens.xs) return crumb

    const length = 13
    let formatted = crumb.substring(0, length)
    if (crumb.length > length) formatted += '...'
    return formatted
  }

  return (
    <Row style={{ flexWrap: 'nowrap' }}>
      <Button icon={<FolderFilled />} type="link" onClick={(): void => handleSelectPath(undefined)} style={{ paddingRight: 8 }}>
        Root
      </Button>
      {path?.split('/').filter(p => p !== '').map((crumb, index) => (
        <Button type="link" key={`${crumb}-${index}`} icon={<RightOutlined />} onClick={(): void => handleSelectPath(index)} style={{ color: 'rgba(0, 0, 0, 0.85)', paddingLeft: 0 }}>{formatCrumb(crumb)}</Button>
      ))}
    </Row>
  )
}
