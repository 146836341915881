import React, { useEffect, useMemo, useRef, useState } from 'react'

import { Tag, Row, Col, Avatar, Space, Button, Typography, Modal, Image, Tooltip } from 'antd'

import { Application } from '@pollination-solutions/pollination-sdk'

import { AppstoreOutlined, EyeInvisibleOutlined, EyeOutlined, SettingOutlined, LockOutlined } from '@ant-design/icons'
import { Streamlit, Dash, ReactIcon } from 'atoms/Icons'
import { useAuth } from 'auth-context'
import { useWindowDimensions } from 'pollination-react-io'

import { getHost } from 'utils/getHost'
import { AvatarLink } from 'atoms/AvatarLink'

import styles from './AppCard.module.css'

interface AppCardProps {
  application: Application
  itemId?: string
  onSelect?: (option: any) => any
}

const iconMapStyle = {
  borderRadius: '100%',
  height: 24,
  width: 24
}

const iconMap = {
  'streamlit': <Streamlit style={iconMapStyle} />,
  'dash': <Dash style={iconMapStyle} />,
  'react': <ReactIcon style={iconMapStyle} />,
}

export const AppCard: React.FC<AppCardProps> = ({ application }) => {
  const [showDescription, setShowDescription] = useState(false)

  const { width } = useWindowDimensions()
  const { idToken } = useAuth()
  const host = getHost()

  const [showPopup, setShowPopup] = useState(false)
  const [loading, setLoading] = useState(false)

  const applicationUrl = useMemo(() => {
    if (application.url && idToken) {
      return `${application.url}?token=${idToken}`
    }
    return application.url
  }, [application.url, idToken])

  useEffect(() => {
    setShowDescription(showPopup)
  }, [showPopup])

  const rowRef = useRef()

  return (<>
    <Row id={styles['po-app-card-trigger']}
      //@ts-ignore
      ref={rowRef}
      onMouseEnter={() => setShowDescription(true)}
      onMouseLeave={() => setShowDescription(false)}>
      {/* Modal Window */}
      <Modal
        open={showPopup}
        destroyOnClose={true}
        onCancel={() => setShowPopup(false)}
        width={width <= 576 ? (width - 50) : undefined}
        footer={<Space size='middle' direction='horizontal'>
          <Button
            icon={<SettingOutlined />}
            key='settings' 
            type='link'
            href={`/${application.owner.name}/apps/${application.slug}`}
            disabled={!application.permissions.write}>
              Edit
          </Button>
          <Button 
            disabled={!application.has_been_deployed}
            icon={<AppstoreOutlined />}
            href={applicationUrl}
            // target={host !== 'web' ? undefined : '_blank'}
            loading={loading}
            onClick={() => setLoading(true)}
            type='link'>
              {application.has_been_deployed 
                ? (loading ? 'Loading' : 'Go to App')
                : 'Under construction'}
          </Button>
        </Space>}
        title={<Row gutter={[16, 12]}>
          <Col span={24}>
            <Space direction='horizontal' align='center'>
              <Avatar 
                src={application.owner.picture_url} 
                size={36}
                shape='circle' />
              <Typography.Title level={4}>{application.name}</Typography.Title>
            </Space>
          </Col>
          <Col span={24}>
            {application.keywords?.map((tag: string) => <Tag key={tag}>{tag}</Tag>)}
          </Col>
        </Row>}
      >
        <Space direction='horizontal'>
          {iconMap[application.sdk ?? 'streamlit']}
          |
          {/* @ts-ignore */}
          {application.public ? <><EyeOutlined /> Public </> : <><EyeInvisibleOutlined /> Private</>}
        </Space>
        <Row style={{ marginTop: 20 }}>
          <Col style={{
            overflow: 'auto',
            maxHeight: 100,
            color: '#666'
          }}>
            {application.description ?? '...'}
          </Col>
        </Row>
      </Modal>

      {/* Card Section */}
      <Col className={styles['app-card-overlay-container']}
        role='button'
        onClick={() => setShowPopup(true)}>
        <div style={{
          display: 'flex',
          alignItems: 'center'
        }}>
          <div>
            <AvatarLink 
              link={`/${application.owner.name}`} key={`${application.owner.name}`}
              size={'small'} 
              src={application.owner.picture_url} />
          </div>
          <div style={{ marginLeft: 'auto' }}>
            {/* @ts-ignore */}
            {!application.public &&
              <EyeInvisibleOutlined style={{
                marginRight: 4
              }}/>}
          </div>
        </div>
      </Col>

      <Col>
        <Image 
          placeholder={true}
          preview={false}
          style={{ borderRadius: 5 }}
          src={application.image} 
          alt={application.name} />
      </Col>

      {/* Dynamic Description */}
      {showDescription && <Col 
        onClick={() => setShowPopup(true)}
        style={{
          display: 'relative',
          marginTop: 'calc(-100% + 40px)',
          cursor: 'pointer',
          zIndex: 11,
        }} >
            <div style={{
              // @ts-ignore
              maxHeight: (rowRef?.current ? rowRef?.current.clientHeight - 48 : '70px'),
            }} className={styles['po-app-card-description']}>{application.description}</div>
        </Col>}
    </Row> 
    
    {/* Application name */}
    <Tooltip 
      placement='top'
      autoAdjustOverflow={true}
      title={application.name}
    >
      <Typography.Text 
        strong
        className={styles['po-app-card-name']}
        ellipsis>
          {application.name}
      </Typography.Text>
    </Tooltip>
    </>
  )
}