import React, { useState } from 'react'
import { Menu, Dropdown, Card, Col, Typography } from 'antd'
import { FileMeta } from '@pollination-solutions/pollination-sdk'

import { getFileIcon } from './utils'
import { formatBytes } from 'utils/formatValues'

interface ContextMenuAction {
  icon?: React.ReactNode
  label: string
  onClick: (file: FileMeta) => void
}

interface ProjectFileEntryProps {
  file: FileMeta
  onClick: (file: FileMeta) => void
  actions: ContextMenuAction[]
  downloadArtifact?: (path: string) => Promise<any>
}

/** File card for grid view in project folder.  */
export const ProjectFileEntry = ({ file, onClick, actions, downloadArtifact }: ProjectFileEntryProps): React.ReactElement => {
  const extension = file.file_name.split('.').pop()

  const [visible, setVisible] = useState(false)

  const menu = (
    <Menu>
      {actions.map(({ icon, label, onClick }, i) => (
        <Menu.Item key={i} icon={icon} onClick={() => onClick(file)}>
          {label}
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown
      overlay={menu}
      trigger={['contextMenu']}
      onVisibleChange={setVisible}
    >
      <Col
        xs={24}
        sm={12}
        md={8}
        lg={8}
        xl={8}
        style={{ height: '100%' }}
      >
        {/* <Tooltip title={file.file_name} overlayInnerStyle={{ width: 'max-content' }}> */}
        <Card
          title={
            <div>
              {getFileIcon(extension)}
              <Typography.Text style={{ marginLeft: '8px' }}>
                {file.file_name}
              </Typography.Text>
            </div>
          }
          size="small"
          hoverable
          style={{
            width: '100%',
            borderRadius: '4px'
          }}
          onClick={(e) => {
            // @ts-ignore
            if (!e.target || e.target.tagName !== 'DIV') return
            onClick(file)
          }}
        >
          <Card.Meta
            description={
              <>
                {formatBytes(file.size)}
                <br />
                {/*@ts-ignore */}
                {new Date(file.last_modified).toLocaleString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' })}
              </>
            }
          />
        </Card>
        {/* </Tooltip> */}
      </Col>
    </Dropdown>

  )
}
