import React, { useState, useContext, useEffect } from 'react'

import { Row, Col, Tooltip, Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

import { ProjectRecipeFilter } from '@pollination-solutions/pollination-sdk'
import { JobList } from 'components/Job'

import context from './context'

import NewJobForm from './NewJobForm'
import { RecipeCardList } from 'components/Recipe'

dayjs.extend(duration)


/** Displays an expandable list of project jobs and shows usage statistics about them. */
const JobsTab = (): React.ReactElement => {
  const { project } = useContext(context)
  const [newFormVisible, setNewFormVisible] = useState<boolean>(false)

  const [owner, name] = project.slug.split('/') ?? []
  const [recipeFilters, setRecipeFilters] = useState<ProjectRecipeFilter[]>()

  useEffect(() => {
    if (!recipeFilters) return
    setNewFormVisible(true)
  }, [recipeFilters])

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          {newFormVisible ? <NewJobForm
            values={recipeFilters}
            child={
              <Tooltip
                title='Cancel'>
                <Button shape='circle' type='primary'
                  onClick={() => setNewFormVisible(false)}
                >
                  <CloseOutlined />
                </Button>
              </Tooltip>} />
            : <>
              {project
                && (project.permissions.write || project.permissions.admin)
                && <RecipeCardList project={project} onClick={setRecipeFilters} />}
              <JobList projectOwner={owner} projectName={name} />
            </>}
        </Col>
      </Row>
    </>
  )
}

export default JobsTab