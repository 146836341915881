import React, { useEffect, useState } from 'react'
import { Row, List, Space, Typography, Select } from 'antd'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { ClockCircleOutlined } from '@ant-design/icons'
import { useAuth } from 'auth-context'
import { Run, RunStatusEnum } from '@pollination-solutions/pollination-sdk'

import { RunCard } from 'pollination-react-io'

dayjs.extend(duration)

export interface RunListProps {
  projectOwner: string
  projectName: string
  jobId?: string
}

/** Displays an expandable list of project runs and shows usage statistics about them. */
const RunList: React.FunctionComponent<RunListProps> = ({ projectOwner, projectName, jobId }) => {
  const { client, user: authUser } = useAuth()

  // Manage list state
  const [pageSize, setPageSize] = useState<number>(50)
  const [page, setPage] = useState<number>(1)
  const [total, setTotal] = useState<number>(0)

  const [isLoading, setIsLoading] = useState(false)
  const [runs, setRuns] = useState<Run[]>([])
  const [toggleFetch, setToggleFetch] = useState<boolean>(false)
  const [statusFilter, setStatusFilter] = useState<RunStatusEnum | undefined>()

  useEffect(() => {
    const timer = setTimeout(() => {
      client.runs.listRuns({
        owner: projectOwner,
        name: projectName,
        status: statusFilter,
        jobId: jobId ? [jobId] : undefined,
        page: 1,
        perPage: pageSize
      }).then(({ data }) => {
        setRuns(data.resources)
        setTotal(data.total_count)
        setIsLoading(false)
        setToggleFetch(!toggleFetch)
      })
    }, 5000)

    return () => {
      clearTimeout(timer)
    }
  }, [jobId, toggleFetch])

  useEffect(() => {
    setIsLoading(true)
    client.runs.listRuns({
      owner: projectOwner,
      name: projectName,
      status: statusFilter,
      jobId: jobId ? [jobId] : undefined,
      page: 1,
      perPage: pageSize
    }).then(({ data }) => {
      setRuns(data.resources)
      setTotal(data.total_count)
      setIsLoading(false)
    })
  }, [statusFilter])

  useEffect(() => {
    setIsLoading(true)
    client.runs.listRuns({
      owner: projectOwner,
      name: projectName,
      status: statusFilter,
      jobId: jobId ? [jobId] : undefined,
      page,
      perPage: pageSize
    }).then(({ data }) => {
      setRuns(data.resources)
      setTotal(data.total_count)
      setIsLoading(false)
    })
  }, [page, pageSize])

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row justify="space-between">
        <Space>
          <ClockCircleOutlined style={{ fontSize: '24px' }} />
          <Typography.Text strong>Runs</Typography.Text>
        </Space>
        <Select
          allowClear
          placeholder="Filter by status"
          onClear={() => setStatusFilter(undefined)}
          onSelect={(value: RunStatusEnum) => {
            setPage(1)
            setStatusFilter(value)
          }}
          style={{ minWidth: '200px' }}
        >
          <Select.Option value={RunStatusEnum.Created}>{RunStatusEnum.Created}</Select.Option>
          <Select.Option value={RunStatusEnum.Scheduled}>{RunStatusEnum.Scheduled}</Select.Option>
          <Select.Option value={RunStatusEnum.Running}>{RunStatusEnum.Running}</Select.Option>
          <Select.Option value={RunStatusEnum.PostProcessing}>{RunStatusEnum.PostProcessing}</Select.Option>
          <Select.Option value={RunStatusEnum.Failed}>{RunStatusEnum.Failed}</Select.Option>
          <Select.Option value={RunStatusEnum.Cancelled}>{RunStatusEnum.Cancelled}</Select.Option>
          <Select.Option value={RunStatusEnum.Succeeded}>{RunStatusEnum.Succeeded}</Select.Option>
          <Select.Option value={RunStatusEnum.Unknown}>{RunStatusEnum.Unknown}</Select.Option>
        </Select>
      </Row>
      <List
        dataSource={runs}
        loading={isLoading}
        pagination={{
          onChange: page => {
            setPage(page)
          },
          showSizeChanger: true,
          onShowSizeChange: (c, size) => setPageSize(size),
          pageSize,
          total,
        }}
        renderItem={(item) => (
          <RunCard
            projectName={projectName}
            projectOwner={projectOwner}
            jobId={jobId}
            // @ts-ignore
            run={item}
            // @ts-ignore
            client={client}
            authUser={authUser}
          />
        )}
        style={{
          marginTop: '-12px'
        }}
      />
    </Space>
  )
}


export default RunList
