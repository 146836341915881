import React, { useState, useContext, useMemo, useEffect } from 'react'

import { Row, Col, Card, Space, Typography, Tooltip, Button } from 'antd'
import { PieChartOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons'

import context from './context'

import NewJobForm from './NewJobForm'
import LocalRunList from 'components/Run/LocalList'
import { usePollinationPanel, useWindowDimensions } from 'pollination-react-io'

import grasshopper from './assets/grasshopper.svg'
import revit from './assets/rhino.svg'
import rhino from './assets/revit.svg'
import { ProjectRecipeFilter } from '@pollination-solutions/pollination-sdk'
import { RecipeCardList } from 'components/Recipe'

const LocalRunsTab = (): React.ReactElement => {
  const { width, height } = useWindowDimensions()
  const { getPlatforms } = usePollinationPanel()
  const { project } = useContext(context)
  const [newFormVisible, setNewFormVisible] = useState<boolean>(false)
  const [recipeFilters, setRecipeFilters] = useState<ProjectRecipeFilter[]>()

  useEffect(() => {
    if (!recipeFilters) return
    setNewFormVisible(true)
  }, [recipeFilters])

  const [owner, name] = project.slug.split('/') ?? []

  const platforms = useMemo(() => {
    if (!project) return
    
    return getPlatforms(project.slug)
  }, [project])

  return (
    <>
      <Row gutter={[14, 14]}>
        <Col xs={24}>
          {newFormVisible ? <NewJobForm asLocalSimulation = {true} 
            values={recipeFilters}
            child={
              <Tooltip 
                title='Cancel'>
                <Button shape='circle' type='primary'
                  onClick={() => setNewFormVisible(false)}
                >
                  <CloseOutlined />
                </Button>
              </Tooltip>} />
            : <>
              {project 
                && (project.permissions.write || project.permissions.admin) 
                && <RecipeCardList project={project} onClick={setRecipeFilters}/>}
              <LocalRunList projectOwner={owner} projectName={name}>
                <Col span={24} style={{ marginTop: 12 }}>
                  <Card size='small' style={{ width: '100%', textAlign: 'center' }}>
                    <Row>
                      <Col span={8}>
                        <Tooltip title='Rhino runs'>
                          <Space>
                            <img
                              src={rhino}
                              alt='Rhino'
                              style={{
                                maxHeight: 32,
                                maxWidth: 32,
                                backgroundColor: 'rgba(0,0,0,0)',
                                margin: '0 2px 0 0'
                              }}
                            />
                            <Typography.Text strong>{platforms && (platforms['rhino'] ?? 0)}</Typography.Text>
                          </Space>
                        </Tooltip>
                      </Col>
                      <Col span={8}>
                        <Tooltip title='Grasshopper runs'>
                          <Space>
                            <img
                              src={grasshopper}
                              alt='Grasshopper'
                              style={{
                                maxHeight: 32,
                                maxWidth: 32,
                                backgroundColor: 'rgba(0,0,0,0)',
                                margin: '0 2px 0 0'
                              }}
                            />
                            <Typography.Text strong>{platforms && (platforms['grasshopper'] ?? 0)}</Typography.Text>
                          </Space>
                        </Tooltip>
                      </Col>
                      <Col span={8}>
                        <Tooltip title='Revit runs'>
                          <Space>
                            <img
                              src={revit}
                              alt='Revit'
                              style={{
                                maxHeight: 32,
                                maxWidth: 32,
                                backgroundColor: 'rgba(0,0,0,0)',
                                margin: '0 2px 0 0',
                              }}
                            />
                            <Typography.Text strong>{platforms && (platforms['revit'] ?? 0)}</Typography.Text>
                          </Space>
                        </Tooltip>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </LocalRunList>
            </>}
        </Col>
      </Row>
    </>
  )
}

export default LocalRunsTab