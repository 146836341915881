import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Application, ApplicationUpdate } from '@pollination-solutions/pollination-sdk'
import { notification } from 'antd'
import useSWR from 'swr'

import { useAuth } from 'auth-context'
import { ApplicationVisibilityEnum } from 'hooks/useCreateApplication'

interface ContextProps {
    loading: boolean
    application?: Application
    applicationVisibility?: string
    reloadApplication: () => Promise<void>
    updateApplication: (applicationUpdate: ApplicationUpdate) => Promise<void>
    deleteApplication: () => Promise<void>
}

interface ProviderProps {
    owner?: string
    slug?: string
    children: React.ReactNode
}

export const ApplicationContext = createContext<ContextProps>({} as ContextProps)


export const ApplicationProvider = ({ owner, slug, children }: ProviderProps) => {
    const [loading, setLoading] = useState<boolean>(true)
    const [application, setApplication] = useState<Application>()

    const { loading: authLoading, client } = useAuth()

    const applicationVisibility = useMemo(() => {
        if (!application) return ApplicationVisibilityEnum.PublicWithLogin
        // @ts-ignore
        if (!application.public) {
            return ApplicationVisibilityEnum.Private
        } else if (application.deployment_config?.login_required) {
            return ApplicationVisibilityEnum.PublicWithLogin
        } else {
            return ApplicationVisibilityEnum.Public
        }
    }, [application])

    const fetchApplication = useCallback(async (owner: string, slug: string) => {
        return client.applications.getApplication({ owner, slug })
            .then(({ data }) => data as Application)
    }, [client.applications])


    const reloadApplication = useCallback(async () => {
        if (!owner || !slug) return Promise.reject(new Error('Owner or slug not defined'))
        return fetchApplication(owner, slug)
            .then((application) => {
                setApplication(application)
            })
    }, [fetchApplication, owner, slug])

    useSWR(`${owner}/${slug}`, reloadApplication, { errorRetryCount: 3, revalidateOnFocus: false, refreshInterval: 10000 })


    useEffect(() => {
        if (authLoading || !owner || !slug) return

        reloadApplication().catch((err) => {
            notification.error({
                message: 'Error',
                description: err.message
            })
        }).finally(() => {
            setLoading(false)
        })
    }, [authLoading, reloadApplication, owner, slug])


    const updateApplication = useCallback(async (applicationUpdate: ApplicationUpdate) => {
        if (!owner || !slug) {
            notification.error({
                message: 'Error',
                description: 'Owner or slug not defined'
            })
            return
        }

        if (!applicationUpdate.deployment_config) {
            applicationUpdate.deployment_config = {}
        }

        if (application?.is_paid || applicationUpdate.is_paid) {
            applicationUpdate.deployment_config.scale_to_zero = false
        } else {
            applicationUpdate.deployment_config.scale_to_zero = true
        }

        const request = {
            owner,
            slug,
            applicationUpdate,
        }

        setApplication((prev) => {
            if (!prev) return prev
            return {
                ...prev,
                ...applicationUpdate
            }
        })

        return client.applications.updateApplication(request)
            .then(() => reloadApplication())
            .then(() => {
                notification.success({
                    message: 'Success',
                    description: 'Application updated'
                })
            })
            .catch((e) => {
                notification.error({
                    message: 'Error',
                    description: e.message
                })
            })
    }, [client.applications, application, reloadApplication, owner, slug])


    const deleteApplication = useCallback(async () => {
        if (!owner || !slug) {
            notification.error({
                message: 'Error',
                description: 'Owner or slug not defined'
            })
            return
        }

        return client.applications.deleteApplication({ owner, slug })
            .then(() => {
                notification.success({
                    message: 'Success',
                    description: 'Application deleted'
                })
                return setApplication(undefined)
            })
            .catch((e) => {
                notification.error({
                    message: 'Error',
                    description: e.message
                })
            })
    }, [client.applications, owner, slug])


    return (
        <ApplicationContext.Provider
            value={{
                loading,
                application,
                applicationVisibility,
                reloadApplication,
                updateApplication,
                deleteApplication,
            }}
        >
            {children}
        </ApplicationContext.Provider>
    )
}

const useApplication = () => {
    return useContext(ApplicationContext)
}

export default useApplication
