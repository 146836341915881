import React, { useCallback } from 'react'

import { ApplicationsApiListApplicationsRequest, ApplicationSortKey, SortEnum } from '@pollination-solutions/pollination-sdk'

// could have MODEL_PROJECTS_QUERY etc.
export const MODEL_APP_QUERY: Query = {
  search: 'string',
  ids: ['array'],
  names: ['array'],
  owner: ['array'],
  // @ts-ignore
  public: true,
  permissions: ['array'],
  keywords: ['array'],
  sortBy: 'updated_at' as ApplicationSortKey,
  sortOrder: 'ascending' as SortEnum,
  page: 1,
  perPage: 25,
}

type Query = ApplicationsApiListApplicationsRequest

export const useQueryParams = (
  // the initial state
  initialQuery: Query,
  // model state for type checking, mostly array types
  modelQuery: Query = MODEL_APP_QUERY,
) => { 
  
  // set query from url
  // keep the query in sync with the url  
  const syncQueryUrl = useCallback((search: string, defaultQuery: Partial<Query> = initialQuery) => {

    const query = { ...defaultQuery }

    const params =  new URLSearchParams(search)
    
    params.forEach((val, key) => {
      if(!modelQuery[key]) return
      if (modelQuery[key].length) {
        query[key] = val.split(',')
      } else if (typeof modelQuery[key] === typeof val) {
        query[key] = val
      }
    })

    return query
  }, [initialQuery, modelQuery])

  // keep url params in sync with query
  const syncUrlQuery = useCallback((query: Partial<Query>) => {
    const url = new URL(window.location.href)
    
    Object.entries(query).forEach(([key, val]) => {
      if(!val || (initialQuery && initialQuery[key] === val)) {
        url.searchParams.delete(key)
      } else if (Array.isArray(val)) {
        url.searchParams.set(key, val.join(','))
      } else if (typeof val === 'string') {
        url.searchParams.set(key, val)
      }
    })

    // @ts-ignore
    window.history.replaceState(null, null, url)
  },[initialQuery])

  return {
    syncQueryUrl,
    syncUrlQuery,
  }
}