import React from 'react'

import { Grid, Card, Row, Typography, Space, Col, Button } from 'antd'
import { AppstoreAddOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import { AccountPublic } from '@pollination-solutions/pollination-sdk'

export interface CadPluginLinksProps {
  account: AccountPublic
}

export const CadPluginLinks: React.FC<CadPluginLinksProps> = ({ account }) => {
  const screens = Grid.useBreakpoint()

  return (
    <Row gutter={[24, 24]}>
      <Col span={screens.xs ? 24 : 8}>
        <Card style={{ height: '100%' }}>
          <Space direction="vertical" align="center">
            <Link to={'/cad-plugins'}><Button><AppstoreAddOutlined />Install Plugins</Button></Link>
            <Typography.Text type='secondary'>Download Pollination CAD Plugin installers.</Typography.Text>
          </Space>
        </Card>
      </Col>
      <Col span={screens.xs ? 24 : 8}>
        <Card style={{ height: '100%' }}>
          <Space direction="vertical" align="center">
            <Link to={`${account.name}/licenses`}><Button><AppstoreAddOutlined />License Pools</Button></Link>
            <Typography.Text type='secondary'>Manage Pollination CAD Plugin license pools and activations.</Typography.Text>
          </Space>
        </Card>
      </Col>
      <Col span={screens.xs ? 24 : 8}>
        <Card style={{ height: '100%' }}>
          <Space direction="vertical" align="center">
            <Link to={`${account.name}?tab=subscription`}><Button type='primary'><AppstoreAddOutlined />Buy Licenses</Button></Link>
            <Typography.Text type='secondary'>Add or remove Pollination CAD Plugin licenses from your subscription.</Typography.Text>
          </Space>
        </Card>
      </Col>
    </Row>
  )
}

export default CadPluginLinks
