import React, { useState } from 'react'

import { Avatar, Button, Col, Form, Input, notification, Row, Space } from 'antd'
import * as AspectRatio from '@radix-ui/react-aspect-ratio'

import { AccountPublic } from '@pollination-solutions/pollination-sdk'

import { useAuth } from 'auth-context'

import { useOrganizations } from 'hooks'
interface CreateOrgFormProps {
  account: AccountPublic
  onSuccess?: (orgName?: string) => void
}

const insects = [
  'Caterpillar',
  'Fruit Fly',
  'Tsetse',
  'Millipede',
  'Centipede',
  'Dragonfly',
  'Beetle',
  'Butterfly',
  'Bumblebee',
  'Mantis',
  'Spider',
  'Ant',
]

const groups = [
  'LLC',
  'Industries',
  'Incorporated',
  'Agency',
  'Bureau',
  'Group',
  'Board',
  'Council'
]

const initOrgName = `${insects[Math.floor(Math.random() * insects.length)]} ${groups[Math.floor(Math.random() * groups.length)]}`

export const CreateOrgForm: React.FunctionComponent<CreateOrgFormProps> = ({ account, onSuccess }) => {

  const [avatarURL, setAvatarURL] = useState(`https://robohash.org/${initOrgName.toLowerCase().replace(' ', '-')}`)

  const { client, user } = useAuth()

  const { createOrg } = useOrganizations()

  const [loading, setLoading] = useState(false)


  const onFinish = (values: any) => {
    const { account_name } = values
    createOrg(
      values
    )
      .then((val) => {
        if (onSuccess) onSuccess(account_name)
      })
      .catch((err) => {
        notification.error({
          message: `Error: ${err}`,
        })
        setLoading(false)
      })
  }

  return (
    <Row justify='space-around' align='middle' style={{ width: '100%' }}>
      <Form onFinish={onFinish} layout='vertical'
        initialValues={{
          name: initOrgName,
          account_name: initOrgName.toLowerCase().replace(' ', '-'),
          contact_email: user ? user.email : `account.name@${initOrgName.toLocaleLowerCase().split(' ')[0]}.com`,
          picture_url: avatarURL,
        }}
        style={{ width: '100%' }}>
        <Row style={{ width: '100%' }} align={'middle'} justify='space-between'>
          <Col span={4}>
            <AspectRatio.Root ratio={1}>
              <Avatar src={avatarURL} style={{ width: '100%', objectFit: 'cover', height: '100%', }} />
            </AspectRatio.Root>
          </Col>
          <Col span={16}>
            <Row style={{ width: '100%' }} gutter={[48, 0]} justify='space-between'>
              <Col span={12}>
                <Form.Item name='name' label='Display Name' required>
                  <Input size='large' />
                </Form.Item>
              </Col>
              <Col span={12} style={{ paddingRight: 0, marginRight: -24 }}>
                <Form.Item name='account_name' label='Unique Name'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your unique organization name.',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value) return Promise.resolve()
                        return client.accounts.checkAccountName({ name: value })
                          .then(() => {
                            return Promise.resolve()
                          })
                          .catch(() => {
                            return Promise.reject(new Error('Unfortunately that name is already taken.'))
                          })
                      }
                    })
                  ]}>
                  <Input size='large' />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ width: '100%' }} gutter={[48, 0]} justify='space-between'>
              <Col span={12}>
                <Form.Item name='picture_url' label='Avatar URL' required>
                  <Input onChange={(val) => setAvatarURL(val.target.value)} size='large' />
                </Form.Item>
              </Col>
              <Col span={12} style={{ paddingRight: 0, marginRight: -24 }}>
                <Form.Item name='contact_email' label='Contact Email Address' rules={[
                  {
                    type: 'email',
                    message: 'The input must be a valid e-mail address.',
                  },
                  {
                    required: true,
                    message: 'Please input your email address.',
                  }
                ]}>
                  <Input size='large' />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name='description' label='Organization Description' >
              <Input.TextArea size='large' />
            </Form.Item>
          </Col>
          <Row style={{ width: '100%' }} justify={'space-around'}>
            <Space size={'large'}>
              <Button href={`/${account.name}?tab=Subscription`} size='large'>
                Cancel
              </Button>
              <Button type='primary' htmlType='submit'
                size='large'
                loading={loading}
                onClick={() => setLoading(true)}
              >
                Create Organization
              </Button>
            </Space>
          </Row>
        </Row>
      </Form>
    </Row>
  )
}
