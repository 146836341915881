import React, { FC } from 'react'

import { Col, Row } from 'antd'

import { Route } from 'react-router-dom'
import ProjectGallery from 'pages/dashboard/projects'
import PluginGallery from 'pages/dashboard/plugins'
import RecipeGallery from 'pages/dashboard/recipes'

import { AppsGallery, UserDashboard } from 'organisms'

export const Dashboard: FC<any> = () => {
  return (
    <Row justify='space-between' gutter={[24, 0]} >
      <Col 
        xl={{ span: 16, offset: 4 }} 
        lg={{ span: 16, offset: 4 }} 
        md={24} 
        sm={24}
        xs={24}>
        <Route exact path="/" component={UserDashboard} />
        <Route exact path="/apps" component={AppsGallery}/>
        <Route exact path="/projects" component={ProjectGallery} />
        <Route exact path="/recipes" component={RecipeGallery} />
        <Route exact path="/plugins" component={PluginGallery} />
      </Col>
    </Row>
  )
}