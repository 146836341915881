import React from 'react'
import { Card, Divider, Typography, Space } from 'antd'
import { Plugin } from '@pollination-solutions/pollination-sdk'

interface ConfigCardProps {
  styles: any
  manifest: Plugin
}

const ConfigCard: React.FunctionComponent<ConfigCardProps> = ({ styles, manifest }) => (
  <Space direction="vertical">
    <Divider orientation="left">Docker</Divider>
    <Space direction="vertical">
      <Space>
        <Typography.Text strong>Image:</Typography.Text>
        <Typography.Text>{manifest.config.docker.image}</Typography.Text>
      </Space>
      <Space>
        <Typography.Text strong>Registry:</Typography.Text>
        <Typography.Text>
          {manifest.config.docker.registry ? manifest.config.docker.registry : 'Dockerhub'}
        </Typography.Text>
      </Space>
      <Space>
        <Typography.Text strong>Workdir:</Typography.Text>
        <Typography.Text>
          {manifest.config.docker.workdir}
        </Typography.Text>
      </Space>
    </Space>
    <Divider orientation="left">Local</Divider>
    <Space direction="vertical">
      <Space>
        <Typography.Text strong>Image:</Typography.Text>
        <Typography.Text>{manifest.config.docker.image}</Typography.Text>
      </Space>
    </Space>
  </Space>
)

export default ConfigCard
