import { Run } from '@pollination-solutions/pollination-sdk'
import { Col } from 'antd'
import { Loading } from 'atoms'
import { useAuth } from 'auth-context'
import { RunPageDetails } from 'organisms/RunDetails'
import { RunCard, useWindowDimensions } from 'pollination-react-io'
import { FolderCardProps } from 'pollination-react-io/build/RunDetails/Cards/FolderCard'
import React, { useCallback, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

enum RunTabs {
  details='details',
  debug='debug',
  workspace='workspace'
}

export const LocalRunPage: React.FunctionComponent = () =>  {
  const { client, user: authUser } = useAuth()
  const { search } = useLocation()
  const { width } = useWindowDimensions()

  // const toUnixPath = path => path.replace(/[\\/]+/g, '/')

  const { accountName, projectName, studyId } = useParams<{
    accountName: string, projectName: string, studyId: string }>()

  const [currTab, setCurrTab] = useState<RunTabs>(RunTabs[
    new URLSearchParams(search).get('tab') ?? 'details'])

  /**
   * From URL to initial run
   * Notes: the current UI let you submit only job without subfolder
   * Subfolder is mainly for Grasshopper (GET)
   */
  const run = useMemo(() => {
    const query = new URLSearchParams(search)
    if (query.get('path')) {
      // const path = toUnixPath(query.get('path'))
      const path = query.get('path')
      const subfolder = query.get('subfolder')
      const studyName = query.get('studyName')
      const val = (subfolder === 'null' || subfolder === 'undefined') ? undefined : subfolder

      return { id: path, studyName: studyName ?? studyId, subfolder: val }
    }
    return undefined
  }, [])

  const getTab = useCallback((tab: string) => {
    setCurrTab(RunTabs[tab])
  }, [])

  /*
   * Folder card handler
   */  
  const [currPath, setCurrPath] = useState<string>()

  const getFolderCardHandler = useCallback((card: FolderCardProps) => {
    setCurrTab(RunTabs.workspace)
    setCurrPath(card.path)
  }, [currPath, currTab])

  /**
   * Get run value
   */
  const [runCallback, setRunCallback] = useState<Run>()
  const getValueHandler = (currRun: Run) => {
    setRunCallback((prev) => {
      if (prev?.status?.finished_at !== currRun?.status?.finished_at) {
        return { ...prev, ...currRun } // rerender
      } else {
        return prev // no rerender
      }
    })
  }

  return (
    <Col 
    xl={{ span: 16, offset: 4 }} 
    lg={{ span: 16, offset: 4 }} 
    md={24} 
    sm={24}
    xs={24}>
      {!run ? (
        <Loading />
      ) : <>
        {run && <RunCard
          style={{ backgroundColor: 'white' }}
          projectName={projectName}
          projectOwner={accountName}
          localRun={true}
          loaderNode={<Loading />}
          // @ts-ignore
          run={run}
          // @ts-ignore
          client={client}
          authUser={authUser}
          enableClick={false}
          getTab={getTab}
          canWrite={true}
          interval={10000}
          getValue={getValueHandler}
          defaultTab={RunTabs.details}
        />}
        {/* Debug and Workspace as buttons */}
        {(currTab === RunTabs.details 
          || currTab === RunTabs.workspace 
          || currTab === RunTabs.debug) &&
          <RunPageDetails
            getFolderCard={getFolderCardHandler}
            accountName={accountName}
            projectName={projectName}
            run={runCallback}
            authUser={authUser}
            // @ts-ignore
            client={client}
            isCloud={false}
          />}
      </>}
    </Col>
  )
}