import React, { CSSProperties, FC, useEffect, useState } from 'react'

import { Col, Divider, Select, Typography } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'

export interface KeyWordSelectProps {
  value?: string[]
  setQuery?: React.Dispatch<React.SetStateAction<any>>
  loading?: boolean
  multiple?: boolean
  placeholder?: string
  maxTags?: number
  size?: 'large' | 'small'
  style?: CSSProperties
  onChange?: ((value: string[], option: DefaultOptionType | DefaultOptionType[]) => void)
}

const SUGGESTED_KEYWORDS = [
  'radiance',
  'comfort',
  'passive-haus',
  'hbjson',
  'photovoltaic',
  'agrivoltaic',
  'daylight',
  'glare',
  'energy',
  'eui',
  'leed',
  'irradiance',
  'dragonfly',
  'sunlight-hours',
]

export const KeywordSelect: FC<KeyWordSelectProps> = ({
  value,
  setQuery,
  loading,
  multiple = true,
  placeholder = 'Filter by keyword',
  maxTags = 5,
  size,
  style,
  onChange,
}) => {
  const [keywords, setKeywords] = useState<string[]>(value || [])

  useEffect(() => {
    if (setQuery) {
      setQuery(state => ({
        ...state,
        keywords
      }))
    }
  }, [keywords, setQuery])

  // assume that if the component value is controlled by parent
  // the the parent handles updating the query
  useEffect(() => {
    // DNA: Workaround for FlexQueryToolbar: %C2 array
    if (value && value?.length === 1) {
      const arr = value[0].split(',')
      setKeywords(arr)
    } else {
      setKeywords(value || [])
    }
  }, [value])

  return (
    <Select
      showArrow
      showSearch={true}
      style={{ ...style }}
      allowClear={false}
      maxTagCount={maxTags}
      placeholder={placeholder}
      loading={loading}
      disabled={loading}
      value={keywords}
      mode={multiple ? 'tags' : 'multiple'}
      size={size}
      onSelect={(value) => { setKeywords(keywords => [...keywords, value]) }}
      onDeselect={(value) => { setKeywords(state => state.filter(keyword => keyword !== value)) }}
      onClear={() => { setKeywords([]) }}
      onChange={onChange}
      dropdownRender={menu => (
        <>
          <Col style={{ padding: 8 }}>
            <Typography.Text type='secondary'>Type and press <Typography.Text keyboard>enter</Typography.Text> to add any tag.</Typography.Text>
            <Divider style={{ margin: '8px 0' }} />
          </Col>
          {menu}
        </>
      )}
    >
      {
        SUGGESTED_KEYWORDS?.map(keyword =>
          <Select.Option key={keyword} value={keyword}>{keyword}</Select.Option>
        )
      }
    </Select>
  )
}