import React from 'react'
import { Select, Space, Form } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import { ApplicationVisibilityEnum } from 'hooks/useCreateApplication'
import { PaidFeature } from 'atoms/Icons'

type Props = {
    initialValue?: string
    value?: string
    onChange?: (value: string) => void
    style?: React.CSSProperties
    disabled?: boolean
    loading?: boolean
    enablePaidOptions?: boolean
}



const FormItemVisibility = (props: Props) => {
    return (
        <Form.Item
            name="visibility"
            label="Visibility"
            initialValue={props.initialValue ?? ApplicationVisibilityEnum.PublicWithLogin}
        >
            <Select {...props}>
                <Select.Option value={ApplicationVisibilityEnum.PublicWithLogin}>
                    <Space>
                        <CheckCircleOutlined /> Public on Pollination
                    </Space>
                </Select.Option>
                <Select.Option value={ApplicationVisibilityEnum.Public}
                    disabled={!props.enablePaidOptions}
                >
                    <Space>
                        <PaidFeature /> Public to Everyone
                    </Space>
                </Select.Option>
                <Select.Option value={ApplicationVisibilityEnum.Private}
                    disabled={!props.enablePaidOptions}
                >
                    <Space>
                        <PaidFeature /> Private
                    </Space>
                </Select.Option>
            </Select >
        </Form.Item>
    )
}


export default FormItemVisibility