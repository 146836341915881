import React, { useEffect, useMemo, useState } from 'react'
import { Row, List, Space, Typography, Select, Card } from 'antd'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { ClockCircleOutlined } from '@ant-design/icons'

import { useAuth } from 'auth-context'

import { RunCard, TargetPlaform, usePollinationPanel } from 'pollination-react-io'
import { usePaginationHistory } from 'hooks/usePaginationHistory'
import { Loading } from 'atoms'
import { RunStatusEnum } from '@pollination-solutions/pollination-sdk'

dayjs.extend(duration)

export interface JobListProps {
  projectOwner: string
  projectName: string
  extra?: any[]
}

const LocalRunList: React.FunctionComponent<JobListProps> = ({ projectOwner, projectName, extra, children }) => {
  const { client, user: authUser } = useAuth()
  const { setParams, page, perPage, platform, status } = usePaginationHistory(undefined, { platform: true })
  const { getPaginatedJob } = usePollinationPanel()

  const [total, setTotal] = useState<number>(0)

  const [isLoading, setIsLoading] = useState(false)
  const [runsInit, setRunsInit] = useState<any[]>([])

  const projectSlug = useMemo(() => {
    const slug = `${projectOwner}/${projectName}`

    // Calculate total runs
    const total = getPaginatedJob(
      slug,
      999_999,
      1,
      status as RunStatusEnum,
      platform
    ).length
    setTotal(total)

    return slug
  }, [projectName, projectOwner, platform, status])

  useEffect(() => {
    if (!projectSlug) return

    setIsLoading(true)

    const arr = getPaginatedJob(
      projectSlug,
      perPage,
      page,
      status as RunStatusEnum,
      platform
    )
    
    // Slow down the results
    setTimeout(() => { 
      if (arr.length !== 0) {
        const runsInit = arr.map(i => {
          return { id: i.studyId, studyName: i.jobName, subfolder: i.subfolder }
        })

        setRunsInit(runsInit)
        setIsLoading(false)
      } else {
        setRunsInit([])
        setIsLoading(false)
      }
    }, 1000)
  }, [page, perPage, projectSlug, platform, status])

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row justify="space-between">
        <Space>
          <ClockCircleOutlined style={{ fontSize: '24px' }} />
          <Typography.Text strong>Previous Studies</Typography.Text>
        </Space>
        {/* TODO: Add status filter if DB shows it */}

        <Space wrap>
          <Select
            allowClear
            placeholder="Filter by platform"
            value={platform as TargetPlaform}
            onClear={() => setParams({ page: 1, platform: TargetPlaform.all })}
            onSelect={(value: TargetPlaform) => {
              // Set values by query
              setParams({ page: 1, platform: value })
            }}
            style={{ width: '150px' }}
            >{(Object.keys(TargetPlaform) as (keyof typeof TargetPlaform)[]).map((k) => {
              if (k === TargetPlaform.sketchup) return
              return <Select.Option value={k} key={k}>{k}</Select.Option>
            })}
          </Select>
          <Select
            allowClear
            placeholder="Filter by status"
            value={status as RunStatusEnum}
            onClear={() => setParams({ page: 1, status: undefined })}
            onSelect={(value: RunStatusEnum) => {
              // Set values by query
              setParams({ page: 1, status: value })
            }}
            style={{ minWidth: '200px' }}
            >
            <Select.Option value={RunStatusEnum.Succeeded}>{RunStatusEnum.Succeeded}</Select.Option>
            <Select.Option value={RunStatusEnum.Failed}>{RunStatusEnum.Failed}</Select.Option>
            <Select.Option value={RunStatusEnum.Unknown}>{RunStatusEnum.Unknown}</Select.Option>
            <Select.Option value={RunStatusEnum.Cancelled}>{RunStatusEnum.Cancelled}</Select.Option>
          </Select>
          {extra && extra.map(e => e)}
        </Space>
        {children}
      </Row>
      <List
        dataSource={runsInit || []}
        loading={isLoading}
        split={false}
        pagination={{
          onChange: (page, pageSize) => {
            // Set values by query
            setParams({ 
              perPage: pageSize, 
              page: page
            })
          },
          current: page,
          showSizeChanger: true,
          pageSize: perPage,
          total: total,
        }}
        renderItem={(item) => (
          <List.Item>
            {!isLoading ?<RunCard
              style={{ width:'100%', backgroundColor: 'white' }}
              projectName={projectName}
              projectOwner={projectOwner}
              localRun={true}
              loaderNode={<Loading />}
              run={item}
              // @ts-ignore
              client={client}
              authUser={authUser}
              enableClick={true}
              getTab={(tab: string) => undefined}
              canWrite={true}
              interval={10000}
              getValue={(run: any) => undefined}
              // @ts-ignore
              defaultTab={'details'}
            /> : <Card style={{ width: '100%',
              height: '215px',
              borderRadius: '6px'
            }} loading={true}></Card>}
          </List.Item>
        )}
        style={{
          marginTop: '-7px'
        }}
        
      />
    </Space>
  )
}


export default LocalRunList
