import React, { ReactElement, useState, useEffect, useMemo } from 'react'

import { Card, Typography, Button, Space, Skeleton } from 'antd'
import { AppstoreAddOutlined, CheckCircleFilled, DeleteOutlined, InfoCircleFilled } from '@ant-design/icons'
import { CardTabListType } from 'antd/lib/card'

import {
  Subscription,
  SubscriptionPlan,
  PlanType,
  AccountPublic,
} from '@pollination-solutions/pollination-sdk'

import { useAccount } from 'hooks'
import useSubscription from 'hooks/useSubscription'

import { Feature, PaymentMethodForm, InputLabel } from 'atoms'
import { CreateSubscriptionForm, ManageSubscriptionForm } from 'molecules'
import PaymentTable from 'molecules/PaymentTable'
import UsageReport from 'organisms/UsageReport'

type Props = {
  plans: SubscriptionPlan[]
  subscription?: Subscription
  subscriptionPlan?: SubscriptionPlan
}

const planTypeDisplayName = {
  [PlanType.Cloud]: 'Cloud',
  [PlanType.RhinoPlugin]: 'Rhino Plugin',
  [PlanType.RevitPlugin]: 'Revit Plugin',
  [PlanType.BundledPlugin]: 'Bundled Plugins',
  [PlanType.Application]: 'Application Resource',
  [PlanType.Seat]: 'Organization Seat',
  [PlanType.CloudCompute]: 'Compute Package',
}

const planTypeDescription = {
  [PlanType.Cloud]: 'Run recipes on the cloud and share results with your team',
  [PlanType.RhinoPlugin]: 'Share Rhino Plugin floating licenses with your team',
  [PlanType.RevitPlugin]: 'Share Revit Plugin floating licenses with your team',
  [PlanType.BundledPlugin]: 'Share Multi Plugin floating licenses with your team',
  [PlanType.Application]: 'Boost your application performance and unlock new features',
  [PlanType.Seat]: 'Add team members to your organization',
  [PlanType.CloudCompute]: 'Buy more compute hours and boost the parallel CPUs you have access to (up to 1200 CPUs)',
}

const pluginSideSection = (subscription: Subscription) => (
  <div style={{
    display: 'flex',
    flexDirection: 'column',
    gap: 8
  }}>
    <InputLabel label='Download Pollination Plugin installers.'>
      <Button href={'/cad-plugins'}>
        <AppstoreAddOutlined />
        Plugins
      </Button>
    </InputLabel>
    <InputLabel label={'Manage floating license.'}>
      <Button type='primary' href={`/${subscription.owner.name}/licenses`}>
        <AppstoreAddOutlined />
        Licenses
      </Button>
    </InputLabel>
  </div>
)

const sideSection = {
  [PlanType.Application]: (subscription: Subscription) => (
    <InputLabel label='Check out other Pollination Apps!'>
      <Button href={'/apps'}>
        <AppstoreAddOutlined />
        Apps
      </Button>
    </InputLabel>
  ),
  [PlanType.Cloud]: (subscription: Subscription, adjustPlan: () => void, canUpgrade?: boolean) =>
    <Feature name='cloud'>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 8
      }}>
        {adjustPlan && typeof canUpgrade !== 'undefined' && !canUpgrade &&
          <InputLabel label={'Downgrade to a Free-Tier subscription.'}>
            <Button danger={true}
              onClick={() => adjustPlan && adjustPlan()}>
              {
                <Space size={8}>
                  <DeleteOutlined />
                  Downgrade
                </Space>
              }
            </Button>
          </InputLabel>
        }
      </div>
    </Feature>
  ,
  [PlanType.RhinoPlugin]: pluginSideSection,
  [PlanType.RevitPlugin]: pluginSideSection,
  [PlanType.BundledPlugin]: pluginSideSection
}


export const SubscriptionCard = ({ plans, subscription: initialSubscription, subscriptionPlan }: Props) => {

  const { account } = useAccount()

  const {
    loading,
    subscription,
    paidByInvoice,
    createSubscription,
    updateSubscription,
    cancelSubscription,
  } = useSubscription(initialSubscription)

  const [activeKey, setActiveKey] = useState('subscription')

  const planType = useMemo(() => plans[0].type, [plans])

  const canBuy = useMemo(() => {
    return (!loading && !subscription)
  }, [loading, subscription])

  const canManage = useMemo(() => {
    return !loading && subscription && subscription.billing_info
  }, [loading, subscription])

  useEffect(() => {
    setActiveKey(canBuy ? 'new' : 'subscription')
  }, [canBuy])


  const { tabs, content } = useMemo(() => {

    const tabs: CardTabListType[] = []
    const content: { [index: string]: ReactElement } = {}

    if (canBuy) {
      tabs.push({
        key: 'new',
        tab: 'New Subscription'
      })

      content['new'] = (
        <CreateSubscriptionForm
          plans={plans}
          createSubscription={createSubscription}
          account={account}
          loading={loading}
        />
      )

    } else if (subscription && subscriptionPlan) {
      tabs.push(
        {
          key: 'subscription',
          tab: 'Subscription'
        }
      )

      content['subscription'] = (
        <ManageSubscriptionForm
          subscription={subscription}
          plans={plans}
          paidByInvoice={paidByInvoice}
          updateSubscription={updateSubscription}
          cancelSubscription={cancelSubscription}
          loading={loading}
        />
      )
    }

    if (canManage && subscription) {
      tabs.push({
        key: 'payment-method',
        tab: subscription.billing_info?.payment_information.payment_method.toLowerCase() === 'paypal' ? 'PayPal' : 'Card',
      })
      content['payment-method'] =
        <PaymentMethodForm
          subscription={subscription}
          loading={loading}
        />
      tabs.push({
        key: 'payments',
        tab: 'Payments',
      })
      content['payments'] =
        <PaymentTable
          subscription={subscription}
          loading={loading}
        />
      if (
        account?.account_type === 'org' &&
        // @ts-ignore
        [PlanType.CloudCompute, PlanType.Seat, PlanType.RhinoPlugin, PlanType.RevitPlugin, PlanType.BundledPlugin].includes(planType)
      ) {
        tabs.push({
          key: 'usage-report',
          tab: 'Usage Report',
        })
        content['usage-report'] = (
          <Skeleton active loading={loading}>
            <UsageReport
              account={account as AccountPublic}
              licenceType={planType}
            />
          </Skeleton>
        )
      }
    }
    return { tabs, content }
  }, [
    subscriptionPlan,
    plans,
    canBuy,
    canManage,
    loading,
    subscription,
    account,
    cancelSubscription,
    updateSubscription,
    createSubscription,
    planType,
    paidByInvoice,
  ])
  return (
    <Card
      loading={loading}
      title={
        <Space direction='vertical' style={{ gap: '0px' }}>

          <Space>
            {
              canBuy ?
                <InfoCircleFilled style={{ color: '#1890ff' }} />
                :
                <CheckCircleFilled style={{ color: 'rgb(82, 196, 26)' }} />
            }
            <Typography.Title level={5} style={{ marginBottom: 0 }}>{planTypeDisplayName[planType]} Subscription</Typography.Title>
          </Space>
          <Typography.Text type='secondary' style={{ fontSize: '14px', fontWeight: '400' }}>{planTypeDescription[planType]}</Typography.Text>
        </Space>
      }
      tabList={tabs}
      activeTabKey={activeKey}
      onTabChange={key => {
        setActiveKey(key)
      }}
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
      bodyStyle={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
      tabProps={{
        size: 'small',
      }}
    >
      {content && content[activeKey]}
    </Card>
  )

}
