import { Collapse, Divider, Row, Space, Typography } from 'antd'
import React from 'react'

interface FAQsProps {
  faqs: {
    question: string,
    answer: string
  }[]
}

const FAQs: React.FunctionComponent<FAQsProps> = ({ faqs }) => {

  return (
    <Row justify='space-between' align='middle' style={{ width: '100%' }}>
      <Row justify='center' style={{ width: '100%', padding: '16px 0px' }}>
        <Typography.Title level={2} style={{ textAlign: 'center' }}>Frequently Asked Questions</Typography.Title>
      </Row>
      <Collapse defaultActiveKey={0} ghost style={{ width: '100%' }}>
        {faqs.map((item, i) => (
            <Collapse.Panel key={i} header={<Space><Typography.Title level={4}>{item.question}</Typography.Title></Space>}>
            <Typography.Text style={{ fontSize: '1.1rem' }}>{item.answer}</Typography.Text>
            <Divider />
          </Collapse.Panel>
        ))
        }
      </Collapse>
    </Row>
  )
}

export default FAQs