import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { SortEnum } from '@pollination-solutions/pollination-sdk'

import debounce from 'lodash.debounce'

export interface Query {
  search?: string
  owner?: string
  isPublic?: boolean
  sortBy: any
  sortOrder: SortEnum
}

export interface PaginatedQuery extends Query {
  page: number
  perPage: number
}

interface Props {
  queryApi: (query: PaginatedQuery) => Promise<void>
  defaultSortKey: string
  perPage: number
}

const usePaginatedList = (props: Props) => {

  const { queryApi, defaultSortKey, perPage } = props

  const [page, setPage] = useState<number>(1)

  const [owner, setOwner] = useState<string | undefined>()
  const [search, setSearch] = useState<string | undefined>()
  const [isPublic, setIsPublic] = useState<boolean | undefined>()
  const [sortBy, setSortBy] = useState<string>(defaultSortKey)
  const [sortOrder, setSortOrder] = useState<SortEnum>(SortEnum.Descending)

  useEffect(() => {
    queryApi({
      search,
      owner,
      isPublic,
      sortBy,
      sortOrder,
      page,
      perPage,
    })
  }, [search, owner, isPublic, sortBy, sortOrder])

  // Seperate event for page change
  // so that when a filter is changed it always returns
  // to page 1
  useEffect(() => {
    queryApi({
      search,
      owner,
      isPublic,
      sortBy,
      sortOrder,
      page,
      perPage,
    })
  }, [page, perPage])

  const updateQuery = useCallback((query: Query) => {
    setOwner(query.owner)
    setSearch(query.search)
    setIsPublic(query.isPublic)
    setSortBy(query.sortBy)
    setSortOrder(query.sortOrder)
  }, [])

  const debounceUpdate = useMemo(() => debounce(updateQuery, 250), [updateQuery])

  return {
    updateQuery: debounceUpdate,
    setPage
  }
}

export default usePaginatedList