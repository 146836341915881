import React, { CSSProperties, FC, ReactElement, useEffect, useMemo, useState } from 'react'

import { Select, SelectProps } from 'antd'

import debounce from 'lodash.debounce'

import { useAuth } from 'auth-context'
import { AccountPublic, AccountsApiListAccountsRequest } from '@pollination-solutions/pollination-sdk'

import AvatarLabel from 'atoms/AvatarLabel'

export interface SearchAccountsProps {
  page?: number
  perPage?: number
  value?: AccountPublic[] | string[]
  setParentMember?: React.Dispatch<React.SetStateAction<AccountPublic[]>>
  onChange?: (value?: string[]) => void
  multiple?: boolean
  size?: 'large' | 'small'
  style?: CSSProperties
  maxTags?: number
  excludeSelf?: boolean
  accountType?: 'user' | 'org' | 'all'
  selectProps?: Partial<SelectProps>
}

export const SearchAccounts: FC<SearchAccountsProps> = ({
  page = 1,
  perPage = 25,
  value,
  setParentMember,
  onChange,
  multiple,
  maxTags,
  size,
  style,
  excludeSelf = true,
  accountType = 'user',
  selectProps
}) => {

  const { client, user } = useAuth()

  const [loading, setLoading] = useState<boolean>(false)
  const [members, setMembers] = useState<AccountPublic[]>([])

  const [request, setRequest] = useState<AccountsApiListAccountsRequest>({
    search: undefined,
    type: accountType === 'all' ? undefined : accountType,
    role: undefined,
    page: 1,
    perPage: 25,
  })

  useEffect(() => {
    setRequest(state => ({ ...state, page, perPage }))
  }, [page, perPage])

  useEffect(() => {
    setLoading(true)
    client.accounts.listAccounts(request)
      .then(({ data }) => {
        if (excludeSelf) {
          setMembers(data.resources.filter(account => account.id !== user?.id))
        } else {
          setMembers(data.resources)
        }
      })
      .finally(() => setLoading(false))
  }, [client.accounts, excludeSelf, request, user?.id])

  const debounceSearch = useMemo(() => debounce((search: string) => setRequest(state => ({ ...state, search })), 200), [])

  return (
    <Select
      showSearch
      showArrow
      onSearch={debounceSearch}
      style={{ ...style, width: '100%' }}
      loading={loading}
      allowClear={true}
      maxTagCount={maxTags}
      placeholder={'Find members'}
      mode={multiple ? 'multiple' : undefined}
      // @ts-ignore
      value={value?.reduce((p: string[], c: AccountPublic | string) => {
        return typeof c === 'string' ? c : [...p, c.name]
      }, [] as string[])}
      size={size}
      onSelect={(value) => {
        const account = members.find(m => m.name === value)
        if (!account) return
        if (setParentMember) setParentMember(state => [...state, account])
        if (onChange) onChange([value])
      }}
      onDeselect={(value) => {
        if (setParentMember) setParentMember(state => state.filter(account => account.name !== value))
        // TODO: implement for case where mode='multiple'
        if (onChange) onChange()
      }}
      onClear={() => {
        if (setParentMember) setParentMember([])
        // TODO: implement for case where mode='multiple'
        if (onChange) onChange([])
      }}
      {...selectProps}
    >
      {
        members?.map((item, i) => (
          <Select.Option value={item.name} key={`${item.name}-${i}`}>
            <AvatarLabel account={item} />
          </Select.Option>
        ))
      }
    </Select>
  )
}
