import React from 'react'
import { Dropdown, Menu, Button, Space } from 'antd'
import { DownOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'
import { SortEnum } from '@pollination-solutions/pollination-sdk'

interface Props {
  sortKey: any
  sortOrder: any
  sortEnum: any
  setSortKey: (key: any) => void
  setSortOrder: (order: SortEnum) => void
}

const labelFromEnum = (snakeCase: string) => snakeCase.split('_').map(str => str.charAt(0).toUpperCase() + str.slice(1)).join(' ')

export const Sort: React.FunctionComponent<Props> = ({ sortKey, sortOrder, sortEnum, setSortKey, setSortOrder }) => {

  return (
    <Space>
      <Dropdown
        overlayStyle={{ border: '1px solid #F0F0F0' }}
        overlay={
          <Menu
            onClick={(e: any) => setSortKey(e.key)}
            defaultSelectedKeys={['1']}
            selectable
            selectedKeys={[sortKey as string]}
            style={{ width: 175, padding: '4px 0px 12px' }}
          >
            <Menu.ItemGroup key="sort_options" title="Sort projects">
              {Object.values(sortEnum).map((val, i) =>
                <Menu.Item key={val as string}>{labelFromEnum(val as string)}</Menu.Item>
              )}
            </Menu.ItemGroup>
          </Menu>
        }>
        <Button>
          Sort <DownOutlined />
        </Button>
      </Dropdown>
      <Button shape="circle" onClick={() => sortOrder === SortEnum.Descending ? setSortOrder(SortEnum.Ascending) : setSortOrder(SortEnum.Descending)}>
        {sortOrder === SortEnum.Descending ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
      </Button>
    </Space>
  )
}
