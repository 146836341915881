import React, { useState } from 'react'
import { Typography, List, notification } from 'antd'
import usePaginatedList, { PaginatedQuery } from 'hooks/paginatedList'
import { ApplicationSortKey, Application } from '@pollination-solutions/pollination-sdk'
import { AppCard, Empty } from 'atoms'
import { useAuth } from 'auth-context'
import TabsToolbar from 'components/TabsToolbar'

interface Props {
  owner?: Array<string>
  search?: string
}

export const AppsList = (props: Props) => {

  const { client } = useAuth()
  const [apps, setApps] = useState<Application[]>([])
  const [total, setTotal] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [pageSize, setPageSize] = useState<number>(12)

  const queryApi = (query: PaginatedQuery) => {
    setLoading(true)
    return client.applications.listApplications({
      search: query.search ? query.search : undefined,
      owner: props.owner,
      _public: query.isPublic,
      sortBy: query.sortBy,
      sortOrder: query.sortOrder,
      page: query.page,
      perPage: query.perPage,
    }).then(({ data }) => {
      setTotal(data.total_count)
      setApps(data.resources)
      setLoading(false)
    }).catch((err) => {
      notification.error({
        message: err.response.data.detail,
      })
      setLoading(false)
    })
  }

  const { updateQuery, setPage } = usePaginatedList({
    queryApi,
    defaultSortKey: ApplicationSortKey.UpdatedAt,
    perPage: pageSize
  })

  return (
    <>
      <TabsToolbar
        updateQuery={updateQuery}
        sortEnum={ApplicationSortKey}
        defaultSortKey={ApplicationSortKey.UpdatedAt}
        searchPlaceholder="Search Apps..."
      />
      {(total > 0) ?
        <List
          grid={{
            gutter: 16,
            xs: 3,
            sm: 4,
            md: 4,
            lg: 6,
            xl: 6,
            xxl: 6,
          }}
          style={{
            paddingTop: '10px',
          }}
          dataSource={apps}
          renderItem={(item) => (
            <List.Item>
              <AppCard key={item.id} application={item} />
            </List.Item>
          )
          }
          loading={loading}
          pagination={{
            onChange: page => {
              setPage(page)
            },
            showSizeChanger: true,
            pageSizeOptions: [12, 24, 48],
            onShowSizeChange: (c, size) => setPageSize(size),
            pageSize,
            total
          }}
        /> : <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: '24px 0px'
          }}
        >
          <Empty description={
            <Typography.Link href='https://docs.pollination.solutions/user-manual/apps/introduction'
              target='_blank' referrerPolicy='no-referrer'
            >
              Want to build Apps?<br />Get started with the docs!
            </Typography.Link>
          } />
        </div>}
    </>
  )
}

export default AppsList
