import React from 'react'
import { Space, Card, Collapse, Typography, Divider, Table } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import {
  Function,
  Plugin,
} from '@pollination-solutions/pollination-sdk'

import { Empty } from 'atoms'

import { useLocation } from 'react-router-dom'

interface IOTableProps {
  func: Function
  ioType: string
}
const IOTable: React.FunctionComponent<IOTableProps> = ({ func, ioType }) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Required',
      dataIndex: 'required',
      key: 'required',
      render: (required: boolean | undefined) => <>{required && <CloseOutlined name={'icon'} />}</>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Default Value',
      dataIndex: 'default',
      key: 'default',
    },
    {
      title: 'Path',
      dataIndex: 'path',
      key: 'path',
    },
  ]

  return (
    <Table
      locale={{
        emptyText: <Empty
          description='No parameters'
        />
      }}
      pagination={false}
      size="small"
      columns={columns}
      // @ts-ignore
      dataSource={func[ioType] || []}
    />
  )
}


interface FunctionsCardProps {
  styles: any
  manifest: Plugin
  defaultOpen?: string
}

const FunctionsCard: React.FunctionComponent<FunctionsCardProps> = ({ styles, manifest, defaultOpen }) => {
  const location = useLocation()

  const getFunctionLink = (fct: Function): string => {
    return `${window.location.host}${location.pathname}?tab=functions#${fct.name}`
  }

  return (
    <Card bodyStyle={styles}>
      <Collapse accordion defaultActiveKey={defaultOpen}>
        {manifest.functions.map((fct, i) => (
          <Collapse.Panel
            header={fct.name}
            extra={[
              <Typography.Text key={`fct-link-${fct.name}`} copyable={{ text: getFunctionLink(fct) }}>
                Link
              </Typography.Text>,
            ]}
            key={fct.name}
            className="panel-item"
            id={fct.name}
          >
            <Space direction="vertical">
              <Typography.Title level={4}>Description</Typography.Title>
              <Typography.Text>{fct.description ? fct.description : 'No description provided.'}</Typography.Text>
              <Divider />
              <Typography.Title level={4}>Command</Typography.Title>
              <Typography.Text code>{fct.command}</Typography.Text>
              <Divider />
              <Typography.Title level={4}>Inputs</Typography.Title>
              <IOTable func={fct} ioType="inputs" />
              <Divider />
              <Typography.Title level={4}>Outputs</Typography.Title>
              <IOTable func={fct} ioType="outputs" />
            </Space>
          </Collapse.Panel>
        ))}
      </Collapse>
      <style>{`
        .panel-item:before {
          display: block;
          content: '';
          margin-top: -80px;
          height: 80px;
          visibility: hidden;
        }
      `}</style>
    </Card>
  )
}

export default FunctionsCard
