import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form, Input, Button, Select, Row, Col, Space, Typography, Divider, Skeleton, notification, Modal, Upload } from 'antd'
import { AccountPublic, Application, Quota, QuotaType } from '@pollination-solutions/pollination-sdk'
import Icon, { ClusterOutlined, ExclamationCircleTwoTone } from '@ant-design/icons'

import { KeywordSelect } from 'atoms'
import FormItemOwnerName from 'molecules/FormItemOwnerName'
import FormItemRadioCard from 'molecules/FormItemRadioCard'
import useCreateApplication, { ApplicationVisibilityEnum, resourceOptions, sdks } from 'hooks/useCreateApplication'
import FormItemVisibility from 'molecules/FormItemVisibility'
import { PaidFeature } from 'atoms/Icons'
import useQuotas from 'hooks/useQuotas'
import { Link } from 'react-router-dom'
import ImgCrop from 'antd-img-crop'

const layout = {
  colon: false,
  requiredMark: false
}


interface CreateApplicationFormProps {
  restrictToAccount?: string
  onSuccess: (application: Application) => void
}

const CreateApplicationForm: React.FunctionComponent<CreateApplicationFormProps> = ({ restrictToAccount, onSuccess }) => {

  const [form] = Form.useForm()

  const visibility = Form.useWatch('visibility', form)
  const resources = Form.useWatch('resources', form)

  const { loading, accounts, imageFile, createApplication, setImageFile } = useCreateApplication({ restrictToAccount })
  const [selectedAccount, setSelectedAccount] = useState<AccountPublic>()

  const [modalVisible, setModalVisible] = useState(false)

  const { quotas, setQuery } = useQuotas()
  const applicationQuota = useMemo(() => quotas?.find((quota) => quota.type === QuotaType.ApplicationCpu), [quotas]) as Quota || undefined

  const canUsePaidFeatures = useMemo(() =>
    applicationQuota && (applicationQuota.usage ?? 0) < (applicationQuota.limit ?? 0)
    , [applicationQuota])

  const availableApplicationUnits = useMemo(() => {
    if (applicationQuota) {
      return applicationQuota.limit ? applicationQuota.limit - (applicationQuota.usage ?? 0) : 0
    } else {
      return 0
    }
  }, [applicationQuota])

  const isPaid = useMemo(() => {
    if (!resourceOptions[resources]) return false
    return resourceOptions[resources].requiresPaid || visibility !== ApplicationVisibilityEnum.PublicWithLogin
  }, [resources, visibility])

  const applicationUnitsConsumed = useMemo(() => {
    if (isPaid) {
      return resourceOptions[resources].cpu
    } else {
      return 0
    }
  }, [resources, isPaid])

  useEffect(() => {
    if (selectedAccount) {
      setQuery({
        name: selectedAccount.name,
        type: [QuotaType.ApplicationCpu]
      })
    }
  }, [selectedAccount, setQuery])

  const onFinish = useCallback((values: any) => {
    setModalVisible(false)
    const payload = {
      owner: values.owner,
      name: values.name,
      description: values.description,
      sdk: values.sdk,
      keywords: values.keywords,
      resource: resourceOptions[values.resources],
      is_paid: isPaid,
      visibility: values.visibility
    }
    createApplication(payload).then((application) => {
      notification.success({ message: 'Application created successfully' })
      onSuccess(application)
    }).catch((error) => {
      notification.error({ message: 'Application creation failed', description: error.message })
    })
  }, [createApplication, onSuccess, isPaid])

  return (
    <Skeleton active loading={loading}>
      <Form {...layout} form={form} name="create-application" onFinish={onFinish} layout='vertical'>
        <Row gutter={16}>
          <Col span={24}>
            <FormItemOwnerName accounts={accounts}
              disabled={restrictToAccount !== undefined}
              onSelect={(account) => setSelectedAccount(account)}
            />
          </Col>
          <Col span={24}>
            <Form.Item name="description" label="Description">
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="keywords" label="Tags">
              <KeywordSelect placeholder={'Add keyword tags'} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <FormItemRadioCard
              name="sdk"
              label="Select the Application SDK"
              initialValue={sdks[0].value}
              rules={[{ required: true }]}
              labelCol={{ offset: 10 }}
              options={sdks}
            />
          </Col>
          <Col span={24}>
            <Typography.Text
              style={{ display: 'block', margin: '0 auto' }}
            >
              Application Image
            </Typography.Text>
            <ImgCrop>
              <Upload.Dragger
                // @ts-ignore
                customRequest={({ file }) => setImageFile(file)}
                maxCount={1}
                showUploadList={false}
                accept='image/png, image/jpeg'
              >
                {imageFile ?
                  <img src={imageFile ? URL.createObjectURL(imageFile) : undefined} alt='preview' style={{ maxWidth: '100%' }} />
                  :
                  <Typography.Text>Click or drag file to this area to upload.</Typography.Text>
                }
                {/* <Button icon={<UploadOutlined />}>Upload</Button> */}
              </Upload.Dragger>
            </ImgCrop>
          </Col>
          <Divider />
          <Col span={24}>
            <Row justify='center' gutter={[16, 16]} >
              <Col span={24}>
                <Typography.Text strong>
                  <ExclamationCircleTwoTone /> Paid Features
                </Typography.Text>
              </Col>
              <Col span={24}>
                <Typography.Text>
                  If certain options are greyed out you will need to purchase more <Link to={`/${selectedAccount?.name}?tab=subscription`}>Pollination Application Resources</Link> in order to enable them.
                </Typography.Text>
              </Col>
              <Col span={8}>
                <Form.Item name="resources" label="Resources"
                  rules={[{ required: true }]}
                  initialValue={0}
                >
                  <Select disabled={!canUsePaidFeatures}>
                    {resourceOptions.map((resource, index) => (
                      <Select.Option key={index} value={index}
                        disabled={resource.requiresPaid && !canUsePaidFeatures}
                      >
                        <Space>
                          <Icon component={() =>
                            resource.requiresPaid ? <PaidFeature /> : <ClusterOutlined />
                          } />
                          <Typography.Text
                            disabled={resource.requiresPaid && availableApplicationUnits <= resource.cpu}
                          >
                            {`${resource.cpu} CPU / ${resource.memory} GB RAM`}
                          </Typography.Text>
                        </Space>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <FormItemVisibility
                  disabled={!canUsePaidFeatures}
                  enablePaidOptions={canUsePaidFeatures}
                />
              </Col>
              <Col span={24}>
                {isPaid && 'You are using paid features'}
              </Col>
            </Row>
          </Col>
          <Divider />
          <Col span={24}>
            <Form.Item>
              <Row justify='center'>
                <Col>
                  {
                    applicationUnitsConsumed == 0 ? (
                      <Button type="primary" htmlType="submit" loading={loading}>
                        Submit
                      </Button>
                    ) : (
                      <>
                        <Modal open={modalVisible} onOk={() => form.submit()} onCancel={() => setModalVisible(false)}>
                          <Typography.Title level={3}>Confirm Application Creation</Typography.Title>
                          <Typography.Text>
                            This application will consume {applicationUnitsConsumed} Pollination Application Resources. You will have {availableApplicationUnits - applicationUnitsConsumed} remaining after creating this application.
                          </Typography.Text>
                        </Modal>
                        <Button type="primary" loading={loading}
                          onClick={() => form.validateFields().then(() => { setModalVisible(true) }).catch(() => (true))}
                        >
                          Submit
                        </Button>
                      </>

                    )
                  }
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </Form >
    </Skeleton >
  )
}

export default CreateApplicationForm