import React from 'react'
import { AxiosError } from 'axios'
import { useHistory } from 'react-router'
import { Result, Button } from 'antd'

interface Props {
    error: AxiosError
}

export const Error = (props: Props) => {
  const { error } = props
  const history = useHistory()

  let status = 500
  let title
  let subTitle

  if (error.response) {
    status = error.response.status
    subTitle = error.response.data.detail
  }

  switch (status) {
    case 404:
      title = 'Not Found'
      break
    case 403:
      title = 'Not Authorized'
      break
    default:
      title = 'Sorry, something went wrong'
  }

  return (
    <Result
      // @ts-ignore
      status={status}
      title={title}
      subTitle={subTitle}
      extra={[
        <Button type="primary" onClick={() => history.push('/')}>
                    Back Home
        </Button>
      ]}
    />
  )
}

export default Error