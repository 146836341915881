import React, { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { Row, Col, Space, Typography, Tag } from 'antd'
import { FolderOutlined, FolderOpenOutlined, SettingOutlined, ExperimentOutlined, TeamOutlined } from '@ant-design/icons'

import { Project } from '@pollination-solutions/pollination-sdk'

import { useAuth } from 'auth-context'

import { Loading, Error } from 'atoms'
import Tabs from 'components/Tabs'

import ProjectContext from './context'

import FilesTab from './FilesTab'
import JobsTab from './JobsTab'
import { getHost } from 'pollination-react-io'
import LocalRunsTab from './LocalRunsTab'
import Settings from './Settings'
import Collaborators from './Collaborators'
import { ProfileTemplate } from 'templates'
import { JobsChart } from './JobsChart'

export const ProjectPage = () => {
  const host = getHost()

  const defaultTabs = useMemo(() => {
    if (host === 'web') {
      return [
        { label: 'Studies', icon: <ExperimentOutlined />, content: <JobsTab /> },
        { label: 'Files', icon: <FolderOutlined />, content: <FilesTab /> },
      ]
    } else {
      return [
        { label: 'Studies', icon: <ExperimentOutlined />, content: <JobsTab /> },
        { label: 'Local Studies', icon: <ExperimentOutlined />, content: <LocalRunsTab /> },
        { label: 'Files', icon: <FolderOutlined />, content: <FilesTab /> },
      ]
    }
  }, [host])

  // @ts-ignore
  const { accountName, projectName } = useParams()
  const { client } = useAuth()

  const [loading, setLoading] = useState<boolean>(false)
  const [fetchError, setFetchError] = useState<any | undefined>()
  const [project, setProject] = useState<Project | undefined>()
  const [tabs, setTabs] = useState<any[]>(defaultTabs)

  useEffect(() => {
    setLoading(true)
    client.projects.getProject({ owner: accountName, name: projectName })
      .then(({ data }) => {
        setProject(data)

        if (data.permissions.admin) {
          setTabs(state => [...state,
            ...(data.owner.account_type !== 'user' 
            ? [ { label: 'Settings', icon: <SettingOutlined />, content: <Settings /> },
              { label: 'Collaborators', icon: <TeamOutlined />, content: <Collaborators /> } ] 
            : [ { label: 'Settings', icon: <SettingOutlined />, content: <Settings /> } ])
          ])
        }

      }).catch((err) => {
        setFetchError(err)
      }).finally(() => setLoading(false))
  }, [accountName, client.projects, projectName])

  return (
    <>
      <Row justify="space-between" gutter={32} style={{ 
        marginBottom: 12
       }}>
        <Col lg={24} xl={{ span: 16, offset: 4 }}>
          <Space wrap>
            <FolderOpenOutlined />
            <Typography.Text style={{ fontSize: '20px' }}>
              <Link to={`/${accountName}?tab=projects`}>{accountName}</Link>
            </Typography.Text>
            <Typography.Text style={{ fontSize: '20px' }} >/</Typography.Text>
            <Typography.Text strong style={{ fontSize: '20px' }}>
              <Link to={`/${accountName}/projects/${projectName}`}>{projectName}</Link>
            </Typography.Text>
            {!loading && project &&
              // @ts-ignore
              (project.public ? <Tag>Public</Tag> : <Tag>Private</Tag>)
            }
          </Space>
        </Col>
      </Row>

      {!loading && project ? <ProfileTemplate
        leftColumn={
          <div
            style={{ height: 'fit-content', 
              top: 24, 
              alignSelf: 'flex-start', 
              position: 'sticky',
              boxShadow: '0 3px 6px rgba(140,149,159,0.15)',
              padding: 12,
              backgroundColor: '#fff',
              borderRadius: 5,
              marginTop: 34,
            }}
          >
            <JobsChart project={project} />
        </div>
      }
      tabs={(fetchError || !project) ? (
        <Error error={fetchError} />
      ) : (
        <ProjectContext.Provider value={{
          project,
          setProject,
        }}>
          <Tabs tabs={tabs} />
        </ProjectContext.Provider>
      )} />
      : <Loading />}
    </>
  )
}