import React, { useCallback, useState, useEffect } from 'react'
import { useDropzone, FileWithPath } from 'react-dropzone'
import { FileMeta } from '@pollination-solutions/pollination-sdk'
import { Spin } from 'antd'

import { Empty } from 'atoms'

// Brought to you by
// https://github.com/Nicktho/batch-promises/blob/master/index.js
const batchPromises = (batchSize: number, collection: any[], callback: (item: any) => Promise<void>) =>
  collection
    .map((_, i) => (i % batchSize ? [] : collection.slice(i, i + batchSize)))
    .map((group) => (res: any) => Promise.all(group.map(callback)).then((r) => res.concat(r)))
    .reduce((chain, work) => chain.then(work), Promise.resolve([]))

interface DropZoneProps {
  folderRoot: FileMeta | undefined
  uploadArtifact: (folderRoot: FileMeta | undefined, file: FileWithPath) => Promise<void>
  onUploadSuccess: () => void
  onDragEnd: () => void
}

export const DropZone = ({
  folderRoot,
  uploadArtifact,
  onUploadSuccess,
  onDragEnd,
}: DropZoneProps): React.ReactElement => {
  const [loading, setLoading] = useState(false)

  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[]) => {
      setLoading(true)

      batchPromises(5, acceptedFiles, (file: FileWithPath) =>
        uploadArtifact(folderRoot, file)
      ).finally(() => {
        onUploadSuccess()
        setLoading(false)
      })
    },
    [folderRoot]
  )
  const { getRootProps, getInputProps, rootRef } = useDropzone({ onDrop })

  useEffect(() => {
    if (!rootRef.current) {
      return
    }

    rootRef.current.addEventListener('dragleave', onDragEnd)
  }, [])

  return (
    <div {...getRootProps()} className="dropzone-container">
      <input {...getInputProps()} />
      {loading ? (
        <Spin size="large" />
      ) : (
        <Empty description={'Drop files and folders here to upload them to this folder above.'} />
      )}
      <style>{`
        .dropzone-container {
          position: fixed;
          left: 0;
          top: 60px;
          z-index: 10;
          width: 100vw;
          height: calc(100vh - 60px);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: rgba(0, 0, 0, 0.4);
        }
      `}</style>
    </div>
  )
}
