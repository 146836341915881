import React, { useState, useEffect, useRef } from 'react'
import HDRImage from './utils/hdrpng.js'

import { Grid, List, Card, Slider, Typography, Popover, Button } from 'antd'

import SettingsFAB from 'components/SettingsFAB/index'

interface HDRViewerProps {
  file: Blob
  screens: any
}

export const HDRViewer: React.FunctionComponent<HDRViewerProps> = ({ file, screens }) => {

  const imageEl = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (file.size > 0 && imageEl.current) {
      const url = URL.createObjectURL(file)
      //@ts-ignore
      const img = new HDRImage(imageEl.current)
      img.src = url
      img.style.height = screens.xs ? 'unset' : '100%'
      img.style.width = screens.xs ? '100%' : 'unset'
      img.style.margin = 'auto'
    }
  }, [file])

  const settings = [
    {
      title: 'Exposure',
      component: <Slider max={10}
        min={-10}
        defaultValue={0}
        step={0.01}
        style={{ flex: 1 }}
        //@ts-ignore
        onAfterChange={val => { if (imageEl.current) imageEl.current.exposure = val }} />
    },
    {
      title: 'Gamma',
      component: <Slider max={10}
        min={-10}
        defaultValue={0}
        step={0.01}
        style={{ flex: 1 }}
        //@ts-ignore
        onAfterChange={val => { if (imageEl.current) imageEl.current.gamma = val }} />
    },
  ]

  return (
    <>
      <canvas ref={imageEl}></canvas>
      <SettingsFAB settings={settings} />
    </>
  )
}
