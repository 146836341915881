import React, { useEffect, useState } from 'react'
import { List, Radio, Row } from 'antd'
import { ApplicationSortKey } from '@pollination-solutions/pollination-sdk'
import { useApplications } from 'hooks'
import { AppCard } from 'atoms'

import FlexQueryToolbar from 'components/FlexQueryToolbar'
import { usePaginationHistory } from 'hooks/usePaginationHistory'
import { useWindowDimensions } from 'pollination-react-io'

import revit from './assets/rhino.svg'
import rhino from './assets/revit.svg'
import logo from './assets/logo.svg'

export const AppsGallery = () => {
  const { width } = useWindowDimensions()

  const [total, setTotal] = useState<number>(0)

  const CONFIG = {
    sortBy: true,
    sortOrder: true,
    keywords: true,
    owner: (width > 600),
    _public: true,
    page: true,
    perPage: true,
    search: true,
  }

  const { parameters, setParams, page, perPage } = usePaginationHistory({ perPage: 24, deployed: true }, CONFIG)

  const {
    applications,
    loading,
    query,
    setQuery
  } = useApplications(parameters)

  useEffect(() => {
    setQuery(parameters)
  }, [parameters, setQuery])

  useEffect(() => {
    if (!applications) return

    setTotal(applications.total_count)
  }, [applications])

  return (
    <Row gutter={[0, width > 600 ? 16 : 12]}>
      {/* Toolbar filter */}
      <FlexQueryToolbar
        configuration={CONFIG}
        setParams={setParams}
        sortEnum={ApplicationSortKey}
        defaultParams={query}
        searchPlaceholder={'Search App...'}
        extraAction={
          <Radio.Group buttonStyle='solid' size={'middle'}
            style={{ margin: '0 0 2px 0' }}
            value={parameters?.keywords
              ? parameters?.keywords[0]
              : undefined} onChange={(e) =>
                // @ts-ignore
                setParams({ keywords: e.target.value, page: 1 })}>
            <Radio.Button value={undefined}>
              <img
                src={logo}
                alt='Logo'
                style={{
                  maxHeight: 16,
                  maxWidth: 16,
                  backgroundColor: 'rgba(0,0,0,0)',
                  margin: '0 5px 3px 0'
                }} /> Web
            </Radio.Button>
            <Radio.Button value={'rhino'}>
              <img
                src={rhino}
                alt='Rhino'
                style={{
                  maxHeight: 18,
                  maxWidth: 18,
                  backgroundColor: 'rgba(0,0,0,0)',
                  margin: '0 5px 2px 0'
                }}
              /> Rhino
            </Radio.Button>
            <Radio.Button value={'revit'}>
              <img
                src={revit}
                alt='Revit'
                style={{
                  maxHeight: 18,
                  maxWidth: 18,
                  backgroundColor: 'rgba(0,0,0,0)',
                  margin: '0 5px 2px 0'
                }}
              /> Revit
            </Radio.Button>
          </Radio.Group>
        }
      />

      <div style={{
        width: '100%'
      }}>
        <List
          grid={{
            gutter: 16,
            xs: 3,
            sm: 4,
            md: 4,
            lg: 6,
            xl: 6,
            xxl: 6,
          }}
          dataSource={applications?.resources}
          renderItem={(item) => (
            <List.Item>
              <AppCard key={item.id} application={item} />
            </List.Item>
          )}
          loading={loading}
          pagination={{
            onChange: (page, pageSize) => {
              // Set values by query
              setParams({
                perPage: pageSize,
                page: page,
              })
            },
            current: page,
            showSizeChanger: true,
            pageSize: perPage,
            total: total,
            pageSizeOptions: [24, 48],
          }}
        />
      </div>
    </Row >
  )
}

export default AppsGallery