import React from 'react'

import { Link as ReactRouterLink } from 'react-router-dom'

const isInternalLink = (link: string | object) => { 
  if (typeof link !== 'string') return true
  
  const external = /^(https?:\/\/|\/\/)/i

  return !external.test(link)
}

export const Link = ({ children, to, ...other }) => {
  if (isInternalLink(to)) {
    return (
      <ReactRouterLink to={to} {...other}>
        {children}
      </ReactRouterLink>
    )
  }
  return (
    <a href={to} {...other}>
      {children}
    </a>
  )
}

export default Link