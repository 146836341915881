import React, { useEffect } from 'react'
import { Result } from 'antd'
import { SyncOutlined } from '@ant-design/icons'
import { useAuth } from 'auth-context'

export const SDKLogin = () => {
  const { idToken } = useAuth()

  useEffect(() => {
    if (idToken) {
      window.location.replace(`http://localhost:8645?token=${idToken}`)
    }
  }, [idToken])

  return (
    <Result
      icon={<SyncOutlined spin />}
      title="Logging you in..."
    />
  )
}