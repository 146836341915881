import React, { useContext, useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import { Typography, Form, Select, Button, Space, List, Grid, Tag, Col, Row, Card, Tooltip } from 'antd'
import { ProjectAccessPolicy, Team, SubjectType, PolicySubject, Organization } from '@pollination-solutions/pollination-sdk'
import { DeleteOutlined, FrownOutlined } from '@ant-design/icons'

import { useAuth } from 'auth-context'
import context from './context'
import { useWindowDimensions } from 'pollination-react-io'
import { TeamAvatarGroup } from 'components/Organization/TeamList'

/** Lists access policies set on the current project and allows team-based addition + modification. */
const Collaborators = (): React.ReactElement => {
  const { client } = useAuth()
  const { xs } = Grid.useBreakpoint()
  const { width } = useWindowDimensions()
  const { project } = useContext(context)

  const history = useHistory()

  // Grab top-level project information and compose machine-friendly owner + name
  // @ts-ignore
  const { accountName, projectName } = useParams()

  /**
   * Search part
   */
  const [policies, setPolicies] = useState<ProjectAccessPolicy[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [teamOptions, setTeamOptions] = useState<Team[]>([])

  /**
   * Result part
   */
  const [organization, setOrganization] = useState<Organization>()
  const [teams, setTeams] = useState<Team[]>([])
  const [resultLoading, setResultLoading] = useState<boolean>(true)
  const [disableClick, setDisableClick] = useState(false)
  
  const [form] = Form.useForm()

  /**
   * Get organization
   */
  useEffect(() => {
    client.orgs.getOrg({ name: accountName })
      .then(({ data }) => setOrganization(data))
      .catch((err: any) => console.log(err))
    }, [accountName])
    

  const fetchAccessPermissions = useCallback(async () => {
    const { data } = await client.projects.getProjectAccessPermissions({
      owner: accountName,
      name: projectName,
      page: 1,
      perPage: 100,
    })
    setPolicies(data.resources)
    setLoading(false)
  }, [accountName, client.projects, projectName])

  useEffect(() => { 
    if (!client || !accountName || !projectName) return
    fetchAccessPermissions()
    searchTeams()
  }, [accountName, fetchAccessPermissions, projectName])

  const filterTeams = (search: string) => {
    return teamOptions.filter((m) => m.name.includes(search))
  }

  // Callback on add permission
  interface AddTeamProps {
    team: string
    permission: string
  }
  const handleAddTeam = (payload: AddTeamProps): void => {
    setLoading(true)
    const postPayload = {
      subject: {
        subject_type: 'team',
        name: payload.team
      },
      permission: payload.permission
    } as ProjectAccessPolicy

    client.projects.upsertProjectPermission({
      owner: accountName,
      name: projectName,
      projectAccessPolicy: postPayload
    }).then(() => {
      fetchAccessPermissions()
    }).then(() => form.resetFields(['team']))
  }

  const searchTeams = () => {
    // TODO: restore search if pagination is needed
    client.teams.listOrgTeams({
      orgName: accountName,
      page: 1,
      perPage: 100 // Limit to 100 teams
    })
    .then(({ data }) => setTeamOptions(data.resources))
  }

  const removePermission = (projectPolicySubject: PolicySubject) => {
    setLoading(true)
    client.projects.deleteProjectOrgPermission({
      owner: accountName,
      name: projectName,
      projectPolicySubject
    }).then(() => fetchAccessPermissions())
  }

  /**
   * Set result team
   */
  useEffect(() => {
    if (!organization || !organization.account_name) return

    client.teams.listOrgTeams({ 
      orgName: organization.account_name })
    .then(({ data }) => {
      const names = policies.map((p: any) => p.subject.name)
      const teams = data.resources.filter((t: any) => names.includes(t.slug))
      teams && setTeams(teams)
    })
    .finally(() => setResultLoading(false))
  }, [organization, policies])

  return (
    <Col>
      {project.owner.account_type === 'org' ? (
        <>
          <Form labelAlign="left" 
            labelCol={{ span: 3 }} 
            form={form} 
            onFinish={handleAddTeam}
          >
            <Form.Item label="Team" 
              style={{ fontWeight: 500 }}
              name="team" 
              rules={[{ required: true }]}
            >
              <Select
                placeholder="search teams"
                showSearch
                onSearch={filterTeams}
                style={xs ? { width: `calc(${width}px - 70px)` } : {}} 
                allowClear
              >
                {teamOptions.map(t => (
                  <Select.Option key={t.id} value={t.slug}>
                    <Typography.Text>{t.slug}</Typography.Text>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Permission" 
              style={{ fontWeight: 500 }}
              name="permission" 
              rules={[{ required: true }]} 
              initialValue="read"
            >
              <Select 
                style={xs ? { width: `calc(${width}px - 70px)` } : {}} 
              >
                <Select.Option value="read">Read</Select.Option>
                <Select.Option value="write">Write</Select.Option>
                <Select.Option value="admin">Admin</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Row>
                <Col span={6} offset={!xs ? 3 : 0}>
                  <Button htmlType="submit" type='primary' 
                    style={xs ? { width: `calc(${width}px - 70px)` } : {}}
                  >
                    Add
                  </Button>
                  <Button type='link'
                    style={xs ? { width: `calc(${width}px - 70px)` } : {}}
                    href={`/${organization?.account_name}/new/team`}
                    >Create team</Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>

          {policies && policies.length > 0 && <Typography style={{ margin: '0 0 10px 0' }}>
            The following teams have permission to interact with this project
          </Typography>}

          <List
            dataSource={teams}
            loading={loading || resultLoading}
            renderItem={(item, i) => {
              return <List.Item key={i}>
                {organization && <Card size='small' 

                  extra={
                    <Tooltip title='Delete Permission'>
                      <Button shape="circle" 
                        onMouseEnter={() => setDisableClick(true)}
                        onMouseLeave={() => setDisableClick(false)}
                        onClick={() => removePermission({
                        name: item.slug,
                        subject_type: SubjectType.Team
                      })} 
                        key={`${item.slug}-${i}`}>
                        <DeleteOutlined style={{ fontSize: '18px' }} />
                      </Button>
                    </Tooltip>
                  }
                  title={<Space size={'small'}>
                    <Typography.Text>{item.name}</Typography.Text>
                    <Tag color='blue'>{policies?.find((p) => p.subject.name === item.slug)?.permission}</Tag>
                  </Space>}
                  hoverable
                  onClick={() => !disableClick && history.push(`/${organization.owner.name}/teams/${item.slug.split('/')[1]}`)}
                  style={{
                    width: '100%'
                  }}>
                  <Space size='small' direction='vertical'>
                    <TeamAvatarGroup org={organization} team={item} />
                  </Space>
                </Card>}
              </List.Item>
            }}
          />
        </>
      ) : (
        <Space>
          <FrownOutlined style={{ fontSize: '24px' }} />
          <Typography.Text>{'You can\'t add collaborators on personal projects'}</Typography.Text>
        </Space>
      )}
    </Col>
  )
}

export default Collaborators
