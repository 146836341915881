import React from 'react'
import { useParams } from 'react-router-dom'
import { useAccount } from 'hooks'
import { ApplicationProvider } from 'hooks/useApplication'
import { ApplicationDeploymentProvider } from 'hooks/useApplicationDeployment'
import { Page } from './page'
import { ApplicationVersionsProvider } from 'hooks/useApplicationVersions'

export const ApplicationPage = () => {
  const { account } = useAccount()
  // @ts-ignore
  const { applicationName } = useParams()


  return (
    <ApplicationProvider owner={account && account.name} slug={applicationName}>
      <ApplicationVersionsProvider>
        <ApplicationDeploymentProvider>
          <Page />
        </ApplicationDeploymentProvider>
      </ApplicationVersionsProvider>
    </ApplicationProvider>
  )
}
