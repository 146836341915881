import React, { useState, useEffect, useCallback, } from 'react'

import { Application, ApplicationAccessPolicy, ApplicationsApiGetApplicationAccessPermissionsRequest, Permission, PolicySubject, SubjectType } from '@pollination-solutions/pollination-sdk'

import { useAuth } from 'auth-context'

export const useAppPermissions = (application: Application, initialQuery?: ApplicationsApiGetApplicationAccessPermissionsRequest) => {

  const { client } = useAuth()
  
  const [loading, setLoading] = useState(false)

  const [permissions, setPermissions] = useState<ApplicationAccessPolicy[]>([])

  const [query, setQuery] = useState<ApplicationsApiGetApplicationAccessPermissionsRequest>(
    initialQuery ?? 
    {
      owner: application.owner.name,
      slug: application.slug,
    }
  )

  const getAppPermissionList = useCallback(async (query: ApplicationsApiGetApplicationAccessPermissionsRequest) => { 
    return client.applications
      .getApplicationAccessPermissions(query)
      .then(({ data }) => {
        setPermissions(data.resources)
      })
  },[client.applications])

  useEffect(() => {
    if(!application) return
    
    setLoading(true)
    
    getAppPermissionList(query)
      .catch((e) => {
        console.error(e)
        setLoading(false)
      })
      .then(() => {
        setLoading(false)
      })
  }, [application, getAppPermissionList, query])

  const addAppPermission = useCallback(({ team, permission }: { team: string, permission: Permission }) => {
    if(!application) return

    setLoading(true)

    return client.applications
      .upsertApplicationPermission({
        owner: application.owner.name,
        slug: application.slug,
        applicationAccessPolicy: { 
          permission,
          subject: {
            subject_type: 'team' as SubjectType,
            name: team,
          }
        }
      })
      .catch((e) => {
        console.error(e)
        setLoading(false)
      })
      .then(() => {
        getAppPermissionList(query)
      })
      .finally(() => {
        setLoading(false)
      })

  }, [application, client.applications, getAppPermissionList, query])

  const deleteAppPermission = useCallback(({ subject_type, name }: PolicySubject) => {
    if(!application) return

    setLoading(true)

    client.applications
      .deleteApplicationOrgPermission({
        owner: application.owner.name,
        slug: application.slug,
        applicationPolicySubject: {
          subject_type,
          name,
        }
      })
      .catch((e) => {
        console.error(e)
        setLoading(false)
      })
      .then(() => {
        getAppPermissionList(query)
      })
      .then(() => {
        setLoading(false)
      })
  }, [application, client.applications, getAppPermissionList, query])


  return {
    loading,
    permissions,
    setQuery,
    addAppPermission,   
    deleteAppPermission,
  }
}