// @ts-nocheck
import React, { useMemo, useRef, useCallback, useState, useEffect, useLayoutEffect } from 'react'
import dagre from 'dagre'
import { Drawer, Space, Typography } from 'antd'
import { StepStatus, StepStatusEnum } from '@pollination-solutions/pollination-sdk'
import {
  CodeOutlined
} from '@ant-design/icons'
import { getStatusColor } from '../utils'
import RunTask from './RunTask'

interface Props {
  steps: { [key: string]: StepStatus }
  direction: 'TB' | 'LR'
  owner: string
  projectName: string
  runId: string
  jobId: string
  zoom: number
}

/**
 * Large dagre-based and argo-like component responsible for drawing a job DAG.
 * Returns an SVG within some container components. If a task is selected, it also manages the details sidebar.
 */
const DAG = (props: Props): React.ReactElement => {
  const { steps, direction, owner, projectName, runId, zoom, jobId } = props

  // Store graph center position
  const [[cx, cy], setTransform] = useState<[number, number]>([0, 0])

  // Get svg icon based on task status
  const getIcon = useCallback((status: StepStatusEnum | undefined): React.ReactNode => {
    const getPath = (): React.ReactNode => {
      switch (status) {
        case StepStatusEnum.Scheduled:
          return (
            <path
              fill="white"
              d="M256,8C119,8,8,119,8,256S119,504,256,504,504,393,504,256,393,8,256,8Zm92.49,313h0l-20,25a16,16,0,0,1-22.49,2.5h0l-67-49.72a40,40,0,0,1-15-31.23V112a16,16,0,0,1,16-16h32a16,16,0,0,1,16,16V256l58,42.5A16,16,0,0,1,348.49,321Z"
            />
          )
        case StepStatusEnum.Failed:
        case StepStatusEnum.Unknown:
          return (
            <g transform="translate(60,0)">
              <path
                fill="white"
                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
              />
            </g>
          )
        case StepStatusEnum.Skipped:
          return (
            <path
              fill="white"
              d="M500.5 231.4l-192-160C287.9 54.3 256 68.6 256 96v320c0 27.4 31.9 41.8 52.5 24.6l192-160c15.3-12.8 15.3-36.4 0-49.2zm-256 0l-192-160C31.9 54.3 0 68.6 0 96v320c0 27.4 31.9 41.8 52.5 24.6l192-160c15.3-12.8 15.3-36.4 0-49.2z"
            />
          )
        case StepStatusEnum.Succeeded:
          return (
            <path
              fill="white"
              d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
            />
          )
        case StepStatusEnum.Running:
          return (
            <path
              fill="white"
              d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"
            />
          )
        default:
          return null
      }
    }

    return <g transform={`translate(${-8}, ${-8}), scale(${0.032})`}>{getPath()}</g>
  }, [])

  // Get background color for task circle based on status
  const getBackground = useCallback((phase: StepStatusEnum | undefined): string => {
    return getStatusColor(phase)
  }, [])

  // Convert task name to truncated svg text label
  const getLabel = useCallback((label: string): React.ReactNode => {
    const max = 14
    if (label.length < max) {
      return <tspan>{label}</tspan>
    }
    if (label.length <= max * 2) {
      return (
        <>
          <tspan x={0} dy="-0.2em">
            {label.substr(0, label.length / 2)}
          </tspan>
          <tspan x={0} dy="1.2em">
            {label.substr(label.length / 2)}
          </tspan>
        </>
      )
    }
    return (
      <>
        <tspan x={0} dy="-0.2em">
          {label.substr(0, max - 2)}..
        </tspan>
        <tspan x={0} dy="1.2em">
          {label.substr(label.length + 1 - max)}
        </tspan>
      </>
    )
  }, [])

  const dagRef = useRef<SVGSVGElement>(null)

  // Populate dagre graph and generate layout
  const g = useMemo(() => {
    const graph = new dagre.graphlib.Graph()
    graph.setGraph({ width: 500, height: 1000, rankdir: direction })

    graph.setDefaultEdgeLabel(() => {
      return {}
    })

    Object.keys(steps).forEach((stepId) => {
      const { id, name, status_type, children_ids, template_ref } = steps[stepId]

      if (status_type === 'Unknown' || ['queenbee/workflow', '/', 'zip/artifact'].includes(template_ref)) {
        return
      }

      graph.setNode(id, { label: name ?? '', width: 32, height: 32 })

      children_ids.forEach((child) => {
        const childTask = steps[child]
        if (!childTask || childTask.name === '[1]') {
          return
        }
        // Connect retry-child to current task
        if (!childTask.status || childTask.status === 'Skipped') {
          return
        }
        else if (childTask.status_type === 'Unknown') {
          childTask.children_ids.forEach((subChild) => {
            graph.setEdge(id, subChild)
          })
        } else {
          graph.setEdge(id, child)
        }
      })
    })

    dagre.layout(graph)
    return graph
  }, [steps, direction])

  // Track selected dag node
  const [selectedTask, setSelectedTask] = useState<StepStatus | undefined>(undefined)

  // Draw and cache dag svg
  const dag = useMemo(() => {
    return (
      <>
        {g.edges().map((edge) => {
          const { points } = g.edge(edge)
          const d = points.map((pt, i) => (i === 0 ? `M ${pt.x} ${pt.y} ` : `L ${pt.x} ${pt.y}`)).join(' ')
          return (
            <path
              key={`edge-${edge.v}-${edge.w}`}
              d={d}
              stroke="black"
              strokeWidth="1px"
              markerEnd="url(#arrow)"
              style={{
                strokeWidth: 2,
                fill: 'none',
                opacity: 0.7,
                stroke: '#ccd6dd',
              }}
              className={`${edge.v}-${edge.w}`}
            />
          )
        })}
        {g.nodes().map((node) => {
          if (!g.node(node)) {
            return
          }
          const { x, y } = g.node(node)
          const { status, name } = steps[node]

          return (
            <g
              key={`node-${node}`}
              transform={`translate(${x}, ${y})`}
              className={`node-item ${(selectedTask && selectedTask.id === node) ? 'node-item--selected' : ''}`}
              onClick={() => {
                setSelectedTask(steps[node])
              }}
              style={{ cursor: 'pointer' }}
            >
              <circle r={16} fill={getBackground(status)} />
              <g>
                {getIcon(status)}
                {status === StepStatusEnum.Running ? (
                  <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="0 0 0 "
                    to="360 0 0"
                    dur="1s"
                    additive="sum"
                    repeatCount="indefinite"
                  />
                ) : null}
              </g>
              <g transform={'translate(0, 32)'}>
                <text className="label" textAnchor="middle" fontSize={12}>
                  {getLabel(name ?? 'Unknown')}
                </text>
              </g>
            </g>
          )
        })}
      </>
    )
  }, [g, getBackground, getIcon, getLabel, steps])

  // Only draw dag after it has been centered
  const [dagReady, setDagReady] = useState(false)

  // Memoize graph centering function
  const centerGraph = useCallback((): void => {
    const svgWidth = dagRef.current?.clientWidth ?? 0
    const graphWidth = g.graph().width ?? 100

    if (graphWidth < 0) {
      // Wait for graph layout to complete before drawing
      return
    }

    setTransform([0.5 * (svgWidth - graphWidth), 15])
    setDagReady(true)
  }, [g])

  // Center svg in div by default
  useLayoutEffect(() => {
    if (dagReady) {
      return
    }
    centerGraph()
  }, [centerGraph])

  const getExtentsZoom = (): { dx: number; dy: number; z: number } => {
    const el = dagRef.current?.parentElement?.parentElement

    if (!el) {
      return { dx: 0, dy: 0, z: 1 }
    }

    const { clientWidth: svgW, clientHeight: svgH } = el
    const { width: dagW = 100, height: dagH = 100 } = g.graph()

    const z = Math.min(svgW / dagW, svgH / (dagH + 100))
    const dx = (0.5 * (svgW - dagW * z)) / z
    const dy = (0.5 * (svgH - dagH * z)) / z

    return { dx, dy, z }
  }

  return (
    <>
      {selectedTask ? (
        <Drawer
          placement="right"
          onClose={() => {
            setSelectedTask(undefined)
          }}
          visible={true}
          getContainer={false}
          title={(
            <Space>
              <CodeOutlined />
              <Typography.Text>Task Details</Typography.Text>
            </Space>
          )}
          width="50%"
          height="100%"
        >
          <RunTask
            step={selectedTask}
            context={{ owner, name: projectName, id: runId, jobId }}
          />
        </Drawer>
      ) : null}
      <div className={`dag-svg-container dag-svg-container${zoom > 0 ? '--default' : '--zte'}`}>
        <svg
          className="dag-svg"
          width={
            zoom > 0
              ? ((g.graph().width ?? 100) + 100) * zoom
              : dagRef.current?.parentElement?.clientWidth ?? g.graph().width
          }
          height={
            zoom > 0
              ? ((g.graph().height ?? 100) + 100) * zoom
              : dagRef.current?.parentElement?.clientHeight ?? g.graph().height
          }
          ref={dagRef}
          overflow="auto"
        >
          <defs>
            <marker
              id="arrow"
              viewBox="0 0 10 10"
              refX={10}
              refY={5}
              markerWidth={5}
              markerHeight={5}
              orient="auto-start-reverse"
            >
              <path d="M 0 0 L 10 5 L 0 10 z" style={{
                fill: '#ccd6dd',
                stroke: 'none',
                opacity: 0.7
              }} />
            </marker>
          </defs>
          <g
            transform={`scale(${zoom === 0 ? getExtentsZoom().z : zoom}) translate(${zoom === 0 ? getExtentsZoom().dx : cx
              }, ${zoom === 0 ? getExtentsZoom().dy : cy})`}
          >
            {dagReady ? dag : null}
          </g>
        </svg>
      </div>
    </>
  )
}

export default DAG
