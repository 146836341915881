import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { Card, Typography, Space, Row, Col, Button, Tooltip, Tag } from 'antd'
import { EyeInvisibleOutlined, 
  ClockCircleOutlined, 
  FolderOutlined, 
  SettingOutlined, InfoCircleOutlined, PlusCircleFilled } from '@ant-design/icons'
import { TinyArea } from '@ant-design/charts'

import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

import { Project } from '@pollination-solutions/pollination-sdk'

import { useAuth } from 'auth-context'
import { AvatarLink } from 'atoms'

dayjs.extend(duration)

interface ProjectCardProps {
  project: Project
}

const ProjectCard: React.FunctionComponent<ProjectCardProps> = ({ project }) => {
  const { client } = useAuth()
  const history = useHistory()
  const [usageData, setUsageData] = useState<number[]>([])

  useEffect(() => {

    if (project.usage && project.usage.daily_usage) {
      const usage = project.usage.daily_usage.sort((a, b) => {
        // @ts-ignore
        return new Date(a.date) - new Date(b.date)
      }).map(v => v.cpu ?? 0)

      // @ts-ignore
      setUsageData(usage)
    } else {
      setUsageData(new Array(30).fill(0))
    }

  }, [project, client])

  const [disableMain, setDisableMain] = useState(false)
  const [showDescription, setShowDescription] = useState(false)

  return (
    <Card
      hoverable
      onClick={() => {
        const base = `${project.slug.split('/')[0]}/projects/${project.slug.split('/')[1]}?perPage=5&page=1&status=undefined`
        !disableMain && history.push(base)
      }}
      size='small'
      title={(
        <Space size='small' direction='horizontal'>
          <AvatarLink src={project.owner.picture_url} size={28} link={`/${project.owner.name}`} />
          <Typography.Text ellipsis={true}>
            {project.name}
          </Typography.Text>
          <Tooltip title='Description'>
            <Button shape='circle' 
              type='text'
              onMouseEnter={() => setDisableMain(true)}
              onMouseLeave={() => setDisableMain(false)}
              size='small'
              onClick={() => setShowDescription((prev: boolean) => !prev)}
              >
              <InfoCircleOutlined style={{
                fontSize: 14
              }}/>
            </Button>
          </Tooltip>
        </Space>
      )}
      extra={
        <Space size='small' direction='horizontal'>
          {project.permissions.write && <Tooltip title='New Study'>
            <Link 
              onMouseEnter={() => setDisableMain(true)}
              onMouseLeave={() => setDisableMain(false)}
              to={`/${project.slug.split('/')[0]}/new/study?projectName=${project.slug.split('/')[1]}`}>
              <PlusCircleFilled style={{
                fontSize: 18
              }}/>
            </Link>
          </Tooltip>}
          <Tooltip title='Files'>
            <Button shape='circle' 
              type='text'
              onMouseEnter={() => setDisableMain(true)}
              onMouseLeave={() => setDisableMain(false)}
              size='small'
              href={`${project.slug.split('/')[0]}/projects/${project.slug.split('/')[1]}?tab=files`}
              >
              <FolderOutlined style={{
                fontSize: 18
              }}/>
            </Button>
          </Tooltip>
          {project.permissions.admin && <Tooltip title='Settings'>
            <Button shape='circle' 
              type='text'
              onMouseEnter={() => setDisableMain(true)}
              onMouseLeave={() => setDisableMain(false)}
              size='small'
              href={`${project.slug.split('/')[0]}/projects/${project.slug.split('/')[1]}?tab=settings`}
              >
              <SettingOutlined style={{
                fontSize: 18
              }}/>
            </Button>
          </Tooltip>}
        </Space>
      }
      headStyle={{ fontSize: 16 }}
      bodyStyle={{ paddingBottom: 16 }}
      style={{ borderRadius: 5 }}
    >
      <Row 
        justify="space-between" 
        align="bottom" 
        style={{ 
          width: '100%' 
        }}
        gutter={[16, 12]}
      >
        {showDescription && <Col span={24}>
          <Typography.Text type='secondary' style={{
              overflow: 'auto',
              maxHeight: 100,
              color: '#666'
            }}>
            {project.description ? project.description : 'No description'}
          </Typography.Text>
        </Col>}

        <Col span={16}>
          <Space direction='vertical'
            size='small'
          >
            <Space size='small' direction='horizontal'>
              <ClockCircleOutlined />
              <Tooltip title='Runs in the last 30 days'>
                <Typography.Text strong>{project.usage 
                  ? ((project.usage.succeeded ?? 0) + (project.usage.failed ?? 0)) 
                  : 0} runs</Typography.Text>
              </Tooltip>
              {/*@ts-ignore*/}
              {project.public 
                ? null : 
                <Tooltip title='Private'>
                  <Tag color='green'><EyeInvisibleOutlined style={{ fontSize: 12 }} /></Tag>
                </Tooltip>
              }
            </Space>
          </Space>
        </Col>
        <Col span={8}>
          <TinyArea
            line={{
              color: '#18BE94'
            }}
            height={58}
            autoFit={true}
            data={usageData}
            tooltip={false}
          />
        </Col>
      </Row>
    </Card>
  )
}

export default ProjectCard
