import React, { useState, useEffect } from 'react'
import { Dropdown, Menu, Button, Space } from 'antd'
import { DownOutlined } from '@ant-design/icons'

interface PublicFilterProps {
  onChange: (_public: boolean | undefined) => void
}

export const PublicFilter: React.FunctionComponent<PublicFilterProps> = ({ onChange }) => {
  const [selected, setSelected] = useState('all')

  // TODO: adding onChange to this dependency array is causing the component to break
  useEffect(() => {
    const _public = selected === 'all' ? undefined : selected === 'public'
    onChange(_public)
  }, [selected])

  return (
    <Space>
      <Dropdown
        overlayStyle={{ border: '1px solid #F0F0F0' }}
        overlay={
          <Menu
            onClick={(e: any) => setSelected(e.key)}
            defaultSelectedKeys={['all']}
            selectable
            selectedKeys={[selected]}
            style={{ width: 175, padding: '4px 0px 12px' }}>
            <Menu.ItemGroup key="permissions_options" title="Filter by visibility">
              <Menu.Item key={'all'}>All</Menu.Item>
              <Menu.Item key={'public'}>Public</Menu.Item>
              <Menu.Item key={'private'}>Private</Menu.Item>
            </Menu.ItemGroup>
          </Menu>
        }>
        <Button>
          Type<DownOutlined />
        </Button>
      </Dropdown>
    </Space>
  )
}

export default PublicFilter