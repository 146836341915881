import React from 'react'

interface ImageViewerProps {
  file: Blob
  path: string
  screens: any
}

export const ImageViewer: React.FunctionComponent<ImageViewerProps> = ({ file, path, screens }) => {
  const responsive = screens.xs ? { width: '100%', height: 'unset' } : { height: '100%', width: 'unset' }

  return (
    <img src={path} alt={'image ' + file.type + ' ' + file.size + ' bytes'} style={{ ...responsive, margin: 'auto' }} />
  )
}
