import React from 'react'
import { Repository } from '@pollination-solutions/pollination-sdk'
import { Avatar, Card, Tooltip } from 'antd'

export interface CardRecipeFilterProps {
  name: string
  owner: string
  tags: string[]
}

interface RecipeProps {
  recipe: Repository
  onClick?: (recipe: Repository) => any
}

export const RecipeCard: React.FC<RecipeProps> = ({
  recipe,
  onClick
}) => {

  const handleClick = (e: any) => {
    if (!onClick) return
    onClick(recipe)
  }

  return <Tooltip title={`${recipe.owner.name}/${recipe.name}`}>
    <Card
      hoverable
      style={{
        borderRadius: '10px',
        justifyContent: 'center'
      }}
      size='small'
      onClick={handleClick} >
      <Avatar src={recipe.icon}
        shape='square'
        style={{
          width: '100%',
          height: '100%',
          flexShrink: 1,
          minWidth: 24,
          minHeight: 24
        }} />
    </Card>
  </Tooltip>
}
