import { Avatar } from 'antd'
import { Dropdown, Option } from 'pollination-react-io'
import React, { useEffect } from 'react'

import styles from './Header.module.css'
import { ApiOutlined, 
  AppstoreAddOutlined, 
  KeyOutlined, 
  LogoutOutlined, 
  MenuOutlined, 
  NodeIndexOutlined, 
  ProjectOutlined, 
  SettingOutlined, 
  TeamOutlined, 
  ThunderboltOutlined, 
  UserOutlined, 
  UsergroupAddOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { useAccountInfo } from 'hooks/useAccountInfo'

interface AccountOptionsProps {
  disabled: boolean
  logout: () => void
}

export const AccountOptions = ({
  disabled,
  logout
}): React.ReactElement<AccountOptionsProps> => {
  const history = useHistory()

  const { accountInfo, setAccountOptions, accountOptions } = useAccountInfo()

  const baseOptions: Option[] = [
    {
      type:'button',
      label: 'Your Projects',
      id: 'projects',
      icon: <ProjectOutlined />,
      onSelect: () => history.push(`/${accountInfo?.name}?tab=projects`),
    }
  ]

  const devOptions: Option[] = [
    {
      type:'button',
      label: 'Your Apps',
      id: 'apps',
      icon: <AppstoreAddOutlined />,
      onSelect: () => history.push(`/${accountInfo?.name}?tab=apps`),
    },
    {
      type:'button',
      label: 'Your Recipes',
      id: 'recipes',
      icon: <NodeIndexOutlined />,
      onSelect: () => history.push(`/${accountInfo?.name}?tab=recipes`),
    },
    {
      type:'button',
      label: 'Your Plugins',
      id: 'plugins',
      icon: <AppstoreAddOutlined />,
      onSelect: () => history.push(`/${accountInfo?.name}?tab=plugins`),
    }
  ]

  const orgOwnerOptions: Option[] = [
    {
      type:'button',
      label: 'Your Members',
      id: 'members',
      icon: <UserOutlined />,
      onSelect: () => history.push(`/${accountInfo?.name}?tab=members`),
    },
    {
      type:'button',
      label: 'Your Teams',
      id: 'teams',
      icon: <TeamOutlined />,
      onSelect: () => history.push(`/${accountInfo?.name}?tab=teams`),
    }
  ]

  const ownerOptions: Option[] = [
    {
      type:'button',
      label: 'Your Settings',
      id: 'settings',
      icon: <SettingOutlined />,
      onSelect: () => history.push(`/${accountInfo?.name}?tab=settings`),
    },
    {
      type:'button',
      label: 'Your Subscription',
      id: 'subscriptions',
      icon: <ThunderboltOutlined />,
      onSelect: () => history.push(`/${accountInfo?.name}?tab=subscription`),
    }
  ]

  const basePluginOptions: Option[] = [
    {
      type:'button',
      label: 'Download CAD Plugins',
      id: 'cad-plugins',
      icon: <ApiOutlined />,
      onSelect: () => history.push('/cad-plugins'),
    }
  ]

  useEffect(() => {
    if (!accountInfo?.name) return

    let tabOptions
    if (accountInfo.type === 'user') {
      tabOptions = accountInfo?.owner
        ? [...baseOptions, ...ownerOptions]
        : [...baseOptions]
    } else {
      tabOptions = accountInfo?.owner
        ? [...baseOptions, ...orgOwnerOptions, ...ownerOptions]
        : [...baseOptions]
    }

    const pluginOptions = accountInfo?.owner
      ? [...basePluginOptions, {
        type:'button',
        label: 'License Pools',
        id: 'license-pools',
        icon: <KeyOutlined />,
        onSelect: () => history.push(`/${accountInfo?.name}/licenses`),
      }]
      : [...basePluginOptions]

    const finalOptions = [{
      options: [{
        type: 'node',
        content: <h3>{`👋 ${accountInfo?.name}!`}</h3>,
        id: `${accountInfo?.name}-label`,
      }]
    },
    { options: tabOptions },
    { options: devOptions },
    { options: pluginOptions },
    {
      options: [{
        type: 'button',
        icon: <LogoutOutlined />,
        label: 'Logout',
        id: 'logout',
        onSelect: () => logout()
      }]
    }]

    if (accountInfo.type === 'user') {
      const features = {
        options: [
          {
            type:'button',
            icon: <UsergroupAddOutlined />,
            label: 'Create an Organization',
            id: 'create-org',
            onSelect: () => history.push(`${accountInfo?.name}/create-org`)
          }
        ]
      }
      // Add special user features
      finalOptions.splice(finalOptions.length - 2, 0, features)
    }

    setAccountOptions && setAccountOptions(finalOptions)
  }, [accountInfo])

  return (
    <Dropdown
    optionGroups={accountOptions ?? []}
    contentProps={{
      align: 'end',
      sideOffset: -8,
      style: {
        borderColor: 'var(--primary)',
        zIndex: 1000,
        lineHeight: '1.25rem',
      }
    }}
    disabled={disabled}
    trigger={ 
      <button className={styles['header-avatar-button']}>
      <Avatar icon={
        <MenuOutlined style={{ 
          color: '#333',
          fontSize: 20
        }} className={styles['header-avatar-switcher']} />
      } 
        size={38} 
        shape='square'
        className={styles['header-avatar-trigger']}
        style={{ 
          backgroundColor: 'rgba(0,0,0,0)', 
          marginLeft: 2,
        }}
      />
      </button>
    }
  />)
}