import React from 'react'
import {
  ExclamationCircleFilled,
  CheckCircleFilled,
  ClockCircleFilled,
  SyncOutlined,
} from '@ant-design/icons'
import { JobStatusEnum } from '@pollination-solutions/pollination-sdk'

export const recipeLinkFromSource = (source: string): string => {
  const splitSource = source.split('/')
  const tag = splitSource.pop()
  const name = splitSource.pop()
  splitSource.pop()
  const owner = splitSource.pop()
  return `/${owner}/recipes/${name}/${tag}`
}

export const recipeSlugFromSource = (source: string): string => {
  const splitSource = source.split('/')
  const tag = splitSource.pop()
  const name = splitSource.pop()
  splitSource.pop()
  const owner = splitSource.pop()
  return `/${owner}/recipes/${name}:${tag}`
}

export const getStatusColor = (status: JobStatusEnum): string => {
  switch (status) {
    case JobStatusEnum.Failed:
      return '#E96D76'
    case JobStatusEnum.PreProcessing:
    case JobStatusEnum.Running:
      return '#0DADEA'
    case JobStatusEnum.Completed:
      return '#18BE94'
    case JobStatusEnum.Created:
      return '#F4C030'
    case JobStatusEnum.Cancelled:
      return '#D9D9D9'
    default:
      return '#D9D9D9'
  }
}

export const statusIcon = (status: JobStatusEnum) => {
  switch (status) {
    case JobStatusEnum.Failed:
    case JobStatusEnum.Cancelled:
      return <ExclamationCircleFilled style={{ color: getStatusColor(status), fontSize: 'large' }} />
    case JobStatusEnum.PreProcessing:
    case JobStatusEnum.Running:
      return <SyncOutlined spin style={{ color: getStatusColor(status), fontSize: 'large' }} />
    case JobStatusEnum.Completed:
      return <CheckCircleFilled style={{ color: getStatusColor(status), fontSize: 'large' }} />
    case JobStatusEnum.Created:
      return <ClockCircleFilled style={{ color: getStatusColor(status), fontSize: 'large' }} />
    default:
      return <ExclamationCircleFilled style={{ color: getStatusColor(status), fontSize: 'large' }} />
  }
}
