import React, { useCallback, useMemo } from 'react'

import { Row, Avatar, Col, Divider, Space } from 'antd'
import * as AspectRatio from '@radix-ui/react-aspect-ratio'

import { AccountPublic, Organization, UserPrivate, UserPublic } from '@pollination-solutions/pollination-sdk'


export interface AvatarLabelProps {
  account?: AccountPublic | Organization | UserPrivate | UserPublic
  onClick?: (account: AccountPublic | Organization | UserPrivate | UserPublic) => void
}

export const AvatarLabel: React.FunctionComponent<AvatarLabelProps> = ({ account: _account, onClick }) => {

  const account = useMemo(() => _account ? ({
    // @ts-ignore
    picture: _account.picture_url ?? _account.picture,
    // @ts-ignore
    username: _account.username ?? _account.account_name,
    // @ts-ignore
    displayName: _account.display_name ?? _account.name
  }) : undefined, [_account])

  if (!_account || !account) {
    return <></>
  }

  return (
    <Row align='middle' style={{ width: '100%' }} onClick={(e) => { if (onClick) onClick(_account) }}>
      <Space>
        <div style={{ minWidth: 20, minHeight: 20 }}>
          <AspectRatio.Root ratio={1} asChild>
            <Avatar
              size={20}
              style={{ width: '100%', objectFit: 'cover', height: '100%', }}
              src={account.picture ? account.picture : `https://robohash.org/${account.username}`}
            />
          </AspectRatio.Root>
        </div>
        <Divider type='vertical' />
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 240
          }}
        >
          {account.displayName}
        </div>
      </Space>
    </Row>
  )
}

export default AvatarLabel
