import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { List, Button, notification, Tooltip } from 'antd'
import { Project, RepositorySortKey } from '@pollination-solutions/pollination-sdk'
import { ProjectSortKey } from '@pollination-solutions/pollination-sdk'
import usePaginatedList, { PaginatedQuery } from 'hooks/paginatedList'
import { useAuth } from 'auth-context'
import { ProjectCard } from 'components/Project'
import TabsToolbar from 'components/TabsToolbar'
import { PlusOutlined } from '@ant-design/icons'
import { useAccount } from 'hooks'

interface Props {
  owner: string
  canWrite: boolean
}

const ProjectGallery = (props: Props) => {
  const { client } = useAuth()
  const history = useHistory()

  const [projects, setProjects] = useState<Project[]>([])
  const [total, setTotal] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const { account } = useAccount()

  const [pageSize, setPageSize] = useState<number>(10)

  const queryApi = (query: PaginatedQuery) => {
    setLoading(true)
    return client.projects.listProjects({
      search: query.search,
      owner: [props.owner],
      _public: query.isPublic,
      sortBy: query.sortBy,
      sortOrder: query.sortOrder,
      page: query.page,
      perPage: query.perPage,
    }).then(({ data }) => {
      setTotal(data.total_count)
      setProjects(data.resources)
      setLoading(false)
    }).catch((err) => {
      notification.error({
        message: err.response.data.detail,
      })
      setLoading(false)
    })
  }

  const { updateQuery, setPage } = usePaginatedList({
    queryApi,
    defaultSortKey: ProjectSortKey.UpdatedAt,
    perPage: pageSize
  })

  return (
    <>
      <TabsToolbar
        updateQuery={updateQuery}
        sortEnum={ProjectSortKey}
        defaultSortKey={RepositorySortKey.UpdatedAt}
        searchPlaceholder="Search Projects..."
        newButton={props.canWrite ?
          <Tooltip 
            title='New Project'>
            <Button shape='circle' type='primary'
              onClick={() => history.push(`/${account?.name}/new/project`)}
            >
              <PlusOutlined />
            </Button>
          </Tooltip>
          : undefined
        } />
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 2,
          xl: 2,
          xxl: 2,
        }}
        style={{ marginTop: 16 }}
        dataSource={projects}
        renderItem={(item) => <List.Item>
          <ProjectCard project={item} />
        </List.Item>}
        loading={loading}
        pagination={{
          onChange: page => {
            setPage(page)
          },
          showSizeChanger: true,
          onShowSizeChange: (c, size) => setPageSize(size),
          pageSize,
          total
        }}
      />
    </>
  )
}

export default ProjectGallery
