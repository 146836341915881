import React from 'react'
import { Card, Divider, Space, Tag, Typography } from 'antd'
import { Repository } from '@pollination-solutions/pollination-sdk'
import Avatar from 'antd/lib/avatar/avatar'
import { EyeInvisibleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { AvatarLink } from 'atoms'
interface RepositoryCardProps {
  reference: Repository
  type: 'recipe' | 'plugin'
  deprecated?: boolean
}

const RepositoryCard: React.FunctionComponent<RepositoryCardProps> = ({ reference, type, deprecated }) => {
  const { name, description, icon, slug, keywords, latest_tag } = reference

  const route = type === 'plugin' ? 'plugins' : 'recipes'

  const extra = [
    <Space size={18} key='visibility'>
      {/*@ts-ignore*/}
      {reference.public ? null : <EyeInvisibleOutlined style={{ marginLeft: '6px' }} />}
      <AvatarLink src={reference.owner.picture_url} link={`/${reference.owner.name}`} />
    </Space>
  ]

  const title = (
    <Space>
      <Avatar shape="circle" src={icon} />
      <Typography.Text>{name}</Typography.Text>

      {deprecated ? (
        <Tag icon={<ExclamationCircleOutlined />} color="warning">
          deprecated
        </Tag>
      ) :
        <Tag>{latest_tag}</Tag>}
    </Space>
  )

  return (
    <Link to={`/${slug?.split('/')[0]}/${route}/${slug?.split('/')[1]}/${latest_tag}`}>
      <Card
        hoverable
        title={title}
        extra={extra}
        style={{ margin: '16px 0px' }}
      >
        <Space direction="vertical">
          <Typography.Text>{description}</Typography.Text>
          <Space wrap>
            {keywords?.map((keyword) => (
              <Tag key={`tag-${keyword}`} style={{ marginTop: '6px' }}>
                {keyword}
              </Tag>
            ))}
          </Space>
        </Space>
      </Card>
    </Link>
  )
}

export default RepositoryCard
