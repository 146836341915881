import React from 'react'

import { Result, Button } from 'antd'
import { useHistory } from 'react-router-dom'

export const NotFound: React.FunctionComponent = () => {
  const history = useHistory()

  return (
    <Result
      status={404}
      title="Page Not Found"
      subTitle="The page you are looking for does not exist"
      extra={[
        <Button type="primary" onClick={() => history.push('/')} key='not-found-go-home'>
          Back Home
        </Button>
      ]}
    />
  )
}

export default NotFound