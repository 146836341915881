import React, { useCallback, useMemo } from 'react'
import { Subscription, PaymentMethod } from '@pollination-solutions/pollination-sdk'
import { Typography, Button, notification, Skeleton, Divider } from 'antd'
import { usePaddle } from 'hooks'

export interface PaymentMethodProps {
  subscription: Subscription
  loading: boolean
}

export const PaymentMethodForm: React.FunctionComponent<PaymentMethodProps> = ({ subscription, loading }) => {

  const paddle = usePaddle()

  const paymentMethod = useMemo(() => subscription.billing_info?.payment_information as PaymentMethod, [subscription])

  const update = useCallback(() => 
    paddle.openCheckout({
      url: subscription.billing_info?.update_url as string,
      onSuccess: () => { notification.success({ message: 'successfully updated card' }) }
    }),
  [paddle, subscription.billing_info?.update_url])

  return (
    <Skeleton active loading={loading}>
      <div style={{ 
        width:'100%', 
        borderRadius: 4, 
        border:'1px solid #d0d7de', 
        display:'flex', 
        flexDirection:'column', 
        boxShadow:'0 3px 6px rgba(140,149,159,0.15)',
        background: 'linear-gradient(347deg, rgba(255, 255, 255, 0) 0%, rgba(47, 169, 225, 0.784) 39%, rgba(47, 169, 225, 0.32) 40%, rgba(255, 255, 256, 0.23) 75%)', 
        margin: 'auto', 
        maxWidth: 512 
      }}>
        <div style={{ display:'flex', flexDirection:'row', padding:'12px 12px 0px' }}>
          { paymentMethod.payment_method.toLowerCase() === 'paypal'  ?
            <img src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_200x51.png" alt="PayPal" style={{ maxHeight: 24 }} />
            :
            <Typography.Title level={5}>Payment Method</Typography.Title>
          }
        </div>
        { paymentMethod.payment_method !== 'card' ?
          <Divider style={{ marginTop: 8, marginBottom: 4 }} />
          :  
          <div style={{ height: 32, width: '100%', backgroundColor: '#d0d7de' }} />
        }
        <div style={{ display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-between', gap: 24, padding: '16px 12px' }}>
          { paymentMethod.payment_method === 'card' &&
            <div className='p-backdrop-blur' style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: 8,
              border: '1px solid #d0d7de',
              padding: '4px 8px',
              width: paymentMethod.payment_method !== 'card' ? 'fit-content' : '45%',
              minWidth: 184,
              borderRadius: 4
            }}>
              <><Typography.Text><strong>Card ending in</strong></Typography.Text>
                <Typography.Text keyboard>{paymentMethod.last_four_digits}</Typography.Text>
              </>
            </div>
          }
          { paymentMethod.payment_method === 'card' &&
            <div className='p-backdrop-blur' style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: 8,
              border: '1px solid #d0d7de',
              padding: '4px 8px',
              width: '45%',
              minWidth: 184,
              borderRadius: 4
            }}>
              <Typography.Text><strong>Expires on</strong></Typography.Text>
              <Typography.Text keyboard>{paymentMethod.expiry_date}</Typography.Text>
            </div>
          }
          {update && (
            <Button className='p-backdrop-blur' onClick={update}>Update Payment Method</Button>
          )}
        </div>
      </div>
    </Skeleton>
  )
}

export default PaymentMethodForm
