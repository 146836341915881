import React, { useEffect, useMemo, useState } from 'react'
import { Button, Col, Space, Tooltip, Typography } from 'antd'

import { SelectProject } from 'pollination-react-io'
import { useAuth } from 'auth-context'
import { Project, ProjectRecipeFilter } from '@pollination-solutions/pollination-sdk'
import { useLocation, useParams } from 'react-router-dom'
import NewJobForm from 'pages/project/NewJobForm'
import { RecipeCardList } from 'components/Recipe'
import { CloseOutlined } from '@ant-design/icons'
import { Error, Loading } from 'atoms'
import { AxiosError } from 'axios'

export const NewJob = (): React.ReactElement => {
  const location = useLocation()

  const [recipeFilters, setRecipeFilters] = useState<ProjectRecipeFilter[]>()
  const [newFormVisible, setNewFormVisible] = useState<boolean>(false)
  const [selProject, setSelProject] = useState<Project | undefined>()

  // Project by search param
  const [projectName, setProjectName] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [fetchError, setFetchError] = useState<any | undefined>()

  const onChange = (project: Project) => {
    !project && setNewFormVisible(false)
    setSelProject(project)
  }

  useEffect(() => {
    if (!recipeFilters) return
    setNewFormVisible(true)
  }, [recipeFilters])

  const { client, user } = useAuth()

  const projectOwner = useMemo(() => {
    if (!user || !location || !location?.pathname) return

    // If search param ?projectName=PROJECT_NAME
    const projectName = new URLSearchParams(location.search).get('projectName')
    projectName && setProjectName(projectName)

    const accountName = location.pathname.split('/')[1]
    const name = (['apps', 'recipes', ''].includes(accountName)) 
      ? user?.username  
      : accountName
    return name
  }, [user, location])

  useEffect(() => {
    if (!client || !projectName || !projectOwner) return
    setLoading(true)
    client.projects.getProject({
      name: projectName,
      owner: projectOwner
    })
    .then(({ data }) => data && setSelProject(data))
    .catch((err) => setFetchError(err))
    .finally(() => setLoading(false))

  }, [client, projectName, projectOwner])

  if (loading) return <Loading />
  if (fetchError) return <Error error={fetchError} />

  return (
    <Col 
    xl={{ span: 16, offset: 4 }} 
    lg={{ span: 16, offset: 4 }} 
    md={24} 
    sm={24}
    xs={24}>
      <Space direction='vertical' size='large' style={{
          width: '100%'
        }}>
        <Typography.Title level={4}>New Study</Typography.Title>
        <SelectProject 
          projectOwner={projectOwner}
          authUser={user}
          value={selProject}
          // @ts-ignore 
          client={client} 
          onChange={onChange}
        />
        {newFormVisible ? <NewJobForm 
        values={recipeFilters}
        defaultProject={selProject}
        child={
          <Tooltip 
            title='Cancel'>
            <Button shape='circle' type='primary'
              onClick={() => setNewFormVisible(false)}
            >
              <CloseOutlined />
            </Button>
          </Tooltip>} />
        : <>
          {selProject 
            && ((selProject.permissions.write || selProject.permissions.admin) 
            ? <RecipeCardList project={selProject} onClick={setRecipeFilters}/>
            : <Error error={ {
              config: { },
              request: { },
              response: {
                data: 'Write permission is required :(',
                status: 403,
              } } as AxiosError<any> } />)}
        </>}
      </Space>
    </Col>
  )
}