import React from 'react'

import { CSSProperties, useEffect, useState } from 'react'
import { Col, Typography } from 'antd'

export interface InputLabelProps {
  label?: string
  success?: boolean
  successLabel?: string
  active?: boolean
  activeLabel?: string
  error?: boolean
  errorLabel?: string
  align?: 'start' | 'end'
  span?: number
  style?: CSSProperties
}

export const InputLabel: React.FC<InputLabelProps> = ({ label='', success, successLabel, active, activeLabel, error, errorLabel, align = 'start', style, span, children }) => {
  const [displayColor, setDisplayColor] = useState<string>('#f0f0f0')
  const [displayLabel, setDisplayLabel] = useState<string>(label)

  useEffect(() => {
    if (success) {
      setDisplayColor('#52c31b')
      if (successLabel) setDisplayLabel(successLabel)
    }
    else if (error) {
      setDisplayColor('#ff4d4f')
      if (errorLabel) setDisplayLabel(errorLabel)
    }
    else if (active) {
      setDisplayColor('#2F90FF')
      if (activeLabel) setDisplayLabel(activeLabel)
      else setDisplayLabel(label)
    }
    else {
      setDisplayColor('#f0f0f0')
      setDisplayLabel(label)
    }
  }, [active, activeLabel, error, errorLabel, label, success, successLabel])

  return (
    <Col span={span} style={{
      flexDirection: 'column',
      alignItems: align === 'start' ? 'flex-start' : 'flex-end',
      textAlign: align === 'start' ? 'start' : 'end',
      ...style
    }} >
      {children}
      <Col span={24} style={{ display: 'flex', justifyContent: align, marginTop: 2, paddingLeft: 0, paddingRight: 0 }}>
        <Typography.Text type='secondary' style={{ color: displayColor !== '#f0f0f0' ? displayColor : undefined }}>{displayLabel}</Typography.Text>
      </Col>
    </Col>
  )
}

export default InputLabel