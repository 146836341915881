import React, { useState, useCallback, useEffect, useMemo } from 'react'

import { notification } from 'antd'
import {
  SubscriptionCreate,
  Subscription,
  Quota,
  SubscriptionPlan,
  AccountPublic,
  PaymentMethodEnum,
  SubscriptionUpdate
} from '@pollination-solutions/pollination-sdk'

import { useAuth } from 'auth-context'
import usePaddle from './usePaddle'
import dayjs from 'dayjs'

const REFRESHDELAY = 10000

const useSubscription = (initialSubscription?: Subscription) => {

  const [subscription, setSubscription] = useState<Subscription | undefined>(initialSubscription)
  const [loading, setLoading] = useState(false)
  const [quotas, setQuotas] = useState<Quota[]>([])

  const { client } = useAuth()
  const paddle = usePaddle()

  const paidByInvoice = useMemo(() => {
    if (!subscription || !subscription.external_id) return false
    return subscription.external_id.startsWith('invoice')
  }, [subscription])

  const fetchSubscription = useCallback((subscriptionId: string) => {
    if (!subscriptionId) throw new Error('No subscription id')

    setLoading(true)

    return client.subscriptions.getSubscription({ subscriptionId })
      .then(({ data }) => {
        setSubscription(data)
        return data as Subscription
      })
      .catch(() => {
        setSubscription(undefined)
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [client.subscriptions])

  const fetchQuotas = useCallback((subscriptionId: string) => {
    if (!subscriptionId) throw new Error('No subscription id')

    setLoading(true)

    return client.subscriptions.listSubscriptionQuotas({ subscriptionId })
      .then(({ data }) => {
        setQuotas(data)
        return data as Quota[]
      })
      .catch(() => {
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [client.subscriptions])

  const refetchSubscription = useCallback((id: string) => {

    Promise.all([
      fetchSubscription(id),
      fetchQuotas(id)
    ])

  }, [fetchQuotas, fetchSubscription])

  useEffect(() => {
    if (!initialSubscription) return
    refetchSubscription(initialSubscription?.id)
  }, [initialSubscription, refetchSubscription])

  const createSubscription = useCallback(
    (subscriptionCreate: SubscriptionCreate,
      plan: SubscriptionPlan,
      account: AccountPublic,
    ) => {
      if (!subscriptionCreate ||
        !('account' in subscriptionCreate) ||
        !('plan_id' in subscriptionCreate) ||
        !('quantity' in subscriptionCreate)) {
        return Promise.reject()
      }

      return client.subscriptions.createSubscription({
        subscriptionCreate
      })
        .then(({ data }) => {
          paddle.openCheckout({
            url: data.url,
            onSuccess: () => {

              // optimistic update
              // this is a tricky one since the subscription is not yet created
              setSubscription({
                id: 'optimistic-id',
                owner: { ...account } as AccountPublic,
                plan_multiplier: subscriptionCreate.quantity,
                billing_info: {
                  cancel_url: '',
                  last_payment: {
                    amount: 0,
                    currency: 'USD',
                    date: dayjs().toISOString(),
                  },
                  payment_information: {
                    payment_method: PaymentMethodEnum.Card,
                  },
                  signup_date: dayjs().toISOString(),
                  update_url: '',
                  user_email: ''
                },
                period_end: dayjs().add(1, 'day').toISOString(),
                period_start: dayjs().toISOString(),
                plan_slug: plan.slug,
                type: plan.type,
                // @ts-ignore
                name: plan.name
              })

              setQuotas(() =>
                plan.quotas ?
                  plan.quotas.map(q => ({
                    owner: { ...account } as AccountPublic,
                    type: q.type,
                    limit: (q.limit && subscriptionCreate.quantity) ? q.limit * subscriptionCreate.quantity : undefined,
                    // @ts-ignore
                    display_name: q.display_name ?? plan.name,
                    enforced: q.enforced,
                  }))
                  :
                  []
              )

              setTimeout(() => {
                setLoading(true)
                client.subscriptions
                  .listPollinationSubscriptions({ account: account.name })
                  .then(({ data }) => {
                    const subPlans = data.filter(d => d.plan_slug === plan.slug)
                    if (subPlans.length > 0) return subPlans[0].id
                    else throw new Error('No subscription plan found')
                  })
                  .then((id) => {
                    refetchSubscription(id.slice())
                  })
                  .catch(() => {
                    setLoading(false)
                  })
                  .finally(() => {
                    setLoading(false)
                  })
              }, REFRESHDELAY)
            },
          })
        })
    }, [client.subscriptions, paddle, refetchSubscription])

  const updateSubscription = useCallback((update: SubscriptionUpdate) => {
    if (!subscription) return Promise.resolve()

    return client.subscriptions.updateSubscription({
      subscriptionId: subscription.id,
      subscriptionUpdate: update
    })
      .then(() => {
        notification.success({
          message: 'Subscription updated!'
        })
      })
      .then(() => {

        // optimistic update
        setSubscription(state => ({ ...state, plan_multiplier: update.quantity } as Subscription))
        setQuotas(state => {
          return state.map(q => ({ ...q, limit: update.quantity }))
        })

        setTimeout(() => {
          refetchSubscription(subscription.id)
        }, REFRESHDELAY)
      })
      .catch((err) => notification.error({
        message: 'Failed to update subscription',
        description: err.response.data.detail,
      }))
  }, [client.subscriptions, refetchSubscription, subscription])

  const cancelSubscription = useCallback(() => {
    if (!subscription || !subscription.billing_info) {
      return Promise.resolve()
    }

    return client.subscriptions.cancelSubscription({
      subscriptionId: subscription.id
    }).then(() => {
      setSubscription(undefined)
      setQuotas(state => {
        return state.map(q => ({ ...q, limit: 0 }))
      })
      notification.success({
        message: 'Subscription cancelled'
      })

    }).catch((error) =>
      notification.error({
        message: 'Failed to cancel subscription',
        description: error.response.data.detail,
      })
    )
  }, [subscription, client])

  return {
    loading,
    subscription,
    quotas,
    paidByInvoice,
    refetchSubscription,
    createSubscription,
    updateSubscription,
    cancelSubscription,
  }
}

export default useSubscription
