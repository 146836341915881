import React from 'react'
import { List } from 'antd'
import { Project } from '@pollination-solutions/pollination-sdk'

import Card from './Card'

interface CardListProps {
  projects: Project[]
}

const CardList: React.FunctionComponent<CardListProps> = ({ projects }) => {
  return (
    <List
      dataSource={projects}
      renderItem={(project) => <Card key={project.id} project={project} />}
      pagination={{ pageSize: 5 }}
      style={{ marginBottom: '24px' }}
    />
  )
}

export default CardList
