import { FileMeta } from '@pollination-solutions/pollination-sdk'

interface FolderComposition {
  files: FileMeta[]
  folders: FileMeta[]
}

/**
 * Given a flat list of artifacts, returns sub-arrays of its folders and files.
 * @param {FileMeta[]} artifacts A flat list of all artifacts in a project folder.
 */
export const parseFolder = (artifacts: FileMeta[]): FolderComposition => {
  const composition = artifacts.reduce(
    (composition, current) => {
      return current.file_type === 'folder'
        ? { files: composition.files, folders: [...composition.folders, current] }
        : { folders: composition.folders, files: [...composition.files, current] }
    },
    { files: [], folders: [] } as FolderComposition
  )

  return composition
}
