import React, { useEffect, useState, useContext } from 'react'
import { List } from 'antd'
import { RecipePackage, Repository } from '@pollination-solutions/pollination-sdk'
import { useAuth } from 'auth-context'
import { RepositoryCard } from 'components/Repository'

interface TagList {
  owner: string
  name: string
  repository: Repository
}

const TagList: React.FunctionComponent<TagList> = ({ owner, name, repository }) => {
  const { client } = useAuth()

  const [loading, setLoading] = useState<boolean>(true)
  const [tags, setTags] = useState<RecipePackage[]>([])

  async function fetchTags(): Promise<RecipePackage[]> {
    const result = await client.recipes.listRecipeTags({ owner, name })
    return result.data.resources
  }

  useEffect(() => {
    fetchTags()
      .then((tags) => {
        //@ts-ignore
        tags.sort((a, b) => a.created_at < b.created_at ? 1 : -1)
        setTags(tags)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [repository])

  return (
    <List
      loading={loading}
      dataSource={tags}
      renderItem={(tag): React.ReactNode => {
        const reference = {
          ...repository,
          description: tag.description,
          icon: tag.icon,
          keywords: tag.keywords,
          latest_tag: tag.tag
        }

        return (
          <RepositoryCard
            reference={reference}
            type="recipe"
            deprecated={tag.manifest.metadata.deprecated}
          />
        )
      }}
    />
  )
}

export default TagList
