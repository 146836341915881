import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { Alert, Button, Card, Divider, List, Modal, notification, Row, Col, Space, Switch, Typography } from 'antd'
import { FolderOutlined, GlobalOutlined, LineOutlined, LockOutlined, MinusCircleOutlined, PlusCircleFilled, PlusCircleOutlined, RobotOutlined, ThunderboltFilled } from '@ant-design/icons'

import { AccountType, Application, ApplicationUpdate, PlanType, Quota, QuotaType } from '@pollination-solutions/pollination-sdk'

import { useAccount, useAccountSubscriptions, useQuotas } from 'hooks'
import { getLabel } from 'hooks/useQuotas'
import CreateSubscriptionForm from 'molecules/CreateSubscriptionForm'
import useSubscription from 'hooks/useSubscription'
import { DEFAULT_DEPLOYMENT_CONFIG } from 'hooks'

interface AppsResourceManagerProps {
  application: Application
  handleUpdateApp: (config: Partial<ApplicationUpdate>, addDefault?: boolean | undefined) => Promise<void>
}

export const AppsResourceManager: FC<AppsResourceManagerProps> = ({ application, handleUpdateApp }) => {

  const { account } = useAccount()

  const { loading: loadingQuotas, quotas, refetch } = useQuotas(account, {
    name: account ? account.name : '',
    type: [QuotaType.ApplicationCpu, QuotaType.ApplicationMemory]
  })

  const { loading: loadingAccountSubscriptions, getForPlanType } = useAccountSubscriptions(account?.name)

  const { plans, subscription: initialSubscription } = useMemo(() => {
    return getForPlanType(PlanType.Application, (account?.account_type ?? 'user') as AccountType)
  }, [account?.account_type, getForPlanType])

  const { loading: loadingSubscription, subscription, createSubscription } = useSubscription(initialSubscription)

  const [loading, setLoading] = useState<boolean>(loadingQuotas || loadingAccountSubscriptions || loadingSubscription)

  const [modalVisible, setModalVisible] = useState(false)

  const handleModalOk = useCallback(() => {

    handleUpdateApp({
      // @ts-ignore
      public: true,
      is_paid: false,
      ...DEFAULT_DEPLOYMENT_CONFIG,
    })
      .then(() => {
        refetch()
      })
      .catch((e) => {
        setLoading(false)
        notification.error({
          message: 'Error',
          description: e.message,
        })
      })
      .finally(() => {
        setLoading(false)
      })

    setModalVisible(false)
  }, [handleUpdateApp, refetch])

  const handleModalCancel = useCallback(() => {
    setModalVisible(false)
  }, [])

  useEffect(() => {
    setLoading(loadingQuotas || loadingAccountSubscriptions || loadingSubscription)
  }, [loadingQuotas, loadingAccountSubscriptions, loadingSubscription])

  const handleAddAppResource = useCallback((quantity: number) => {
    let cpu_limit = application.deployment_config?.cpu_limit ?? 0
    cpu_limit += (1 * quantity)

    let memory_limit = application.deployment_config?.memory_limit ?? 0
    memory_limit += (2000 * quantity)

    if (cpu_limit < 1 || memory_limit < 2000) {
      throw new Error('Invalid application resource limit. Paid apps must have at least 1 application resource allocated.')
    }

    setLoading(true)

    handleUpdateApp({
      deployment_config: {
        // login_required: typeof application.deployment_config?.login_required !== 'undefined' ? application.deployment_config?.login_required : true,
        cpu_limit,
        memory_limit,
      }
    })
      .then(() => {
        refetch()
      })
      .catch((e) => {
        setLoading(false)
        notification.error({
          message: 'Error',
          description: e.message,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [application.deployment_config?.cpu_limit, application.deployment_config?.memory_limit, handleUpdateApp, refetch])

  if (!loading && !subscription) {
    return <CreateSubscriptionForm account={account} plans={plans} createSubscription={createSubscription} loading={loadingSubscription} />
  }

  return (
    <Row gutter={[0, 16]} justify='space-between'>
      <Card
        title='Allocated App Resources'
        style={{ width: '100%', height: '100%' }}
        bodyStyle={{ height: '100%' }}
        loading={loading}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 18
        }}>
          <Space align='center' size={12}>
            <Switch checked={application.is_paid} key='toggle-ispaid'
              checkedChildren={<LineOutlined />}
              unCheckedChildren={<ThunderboltFilled />}
              onChange={(val) => {
                if (!val) {
                  setModalVisible(true)
                  return
                }

                handleUpdateApp({ is_paid: val }, true)
                  .catch((e) => {
                    notification.error({
                      message: 'Error',
                      description: e.message,
                    })
                  })
              }}
              disabled={loading}
              loading={loading}
            />
            <Typography.Text strong={!application.is_paid}>
              {application.is_paid ?
                'Revert your app to free tier' :
                'Switch your app to paid'
              }
            </Typography.Text>
          </Space>
          <Divider style={{ marginTop: 0, marginBottom: 0 }} />
          {application.is_paid ?
            <Typography.Text type={'secondary'}>
              Paid apps require at least 1 App Resource.
            </Typography.Text>
            :
            <Space direction='vertical'>
              <Typography.Text strong>
                Paid apps can...
              </Typography.Text>
              <Space>
                <ThunderboltFilled style={{ color: '#1b90ff' }} />
                <Typography.Text type={'secondary'}>
                  Be private to a team, or public to everyone
                </Typography.Text>
              </Space>
              <Space>
                <ThunderboltFilled style={{ color: '#1b90ff' }} />
                <Typography.Text type={'secondary'}>
                  Add dedicated CPUs and more memory
                </Typography.Text>
              </Space>
            </Space>
          }
          <Divider style={{ marginTop: 0, marginBottom: 0 }} />
          <Space direction='vertical' size={10}>
            {application.deployment_config && application.is_paid &&
              <Alert
                message={
                  <Space size={8}>
                    <Typography.Text code strong>{application.deployment_config?.cpu_limit}</Typography.Text>
                    <Typography.Text style={{ color: '#52c41b' }}>
                      {
                        application.deployment_config?.cpu_limit === 1 ?
                          'App Resource Allocated' : 'App Resources Allocated'
                      }
                    </Typography.Text>
                  </Space>
                }
                type="success" showIcon icon={<PlusCircleFilled />} key={`${application.id}-resources`}
              />
            }
            <Row justify='space-between'>
              <Button icon={<PlusCircleOutlined />}
                onClick={() => handleAddAppResource(1)}
                disabled={!application.is_paid || loading}
                loading={loading}
                type='primary'
              >
                Add Resource
              </Button>
              <Button icon={<MinusCircleOutlined />}
                onClick={() => handleAddAppResource(-1)}
                disabled={!application.is_paid || loading}
                loading={loading}>
                Remove
              </Button>
            </Row>
          </Space>
        </div>
      </Card>
      <Card title={'Available App Resources'}
        style={{ width: '100%', height: '100%' }}
        loading={loadingQuotas || !quotas || !quotas.length}
      >
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
          {quotas && quotas.length > 0 &&
            quotas.map((q: Quota) => {
              const label = getLabel(q.type)
              return (
                <div key={q.id} style={{
                  display: 'flex',
                  flexDirection: 'column',
                  border: '1px solid #d0d7de',
                  borderRadius: 4,
                  padding: '8px 16px',
                  rowGap: 8,
                  width: '100%',
                  backgroundColor: 'white'
                }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'baseline',
                    columnGap: 8
                  }}>
                    <Typography.Title level={5} style={{ margin: 0 }}>
                      {label.displayName}
                    </Typography.Title>
                    <Typography.Title level={5} code style={{ margin: 0 }}>
                      {label.format(q.usage)} / {label.format(q.limit)}
                    </Typography.Title>
                  </div>
                  <Typography.Text type='secondary'>
                    {label.description}
                  </Typography.Text>
                </div>
              )
            })
          }
        </div>
      </Card>
      <Modal title="Confirm Transition App to Free"
        visible={modalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="Confirm"
      >
        <Space direction='vertical'>
          <Typography.Title level={5}>Are you sure you want to transition your app from paid to free?</Typography.Title>
          <List
            header={'Paid Apps have access to the following features:'}
            footer={'By transitioning your app to free, your app deployment will lose access to these features.'}
            split={false}
            size='small'
          >
            <List.Item>
              <Space>
                <LockOutlined style={{ color: '#52c41b' }} />
                Keep apps private to your Pollination Team.
              </Space>
            </List.Item>
            <List.Item>
              <Space>
                <GlobalOutlined style={{ color: '#52c41b' }} />
                Make apps public, no Pollination account required
              </Space>
            </List.Item>
            <List.Item>
              <Space>
                <RobotOutlined style={{ color: '#52c41b' }} />
                Boost app access and compute power with more CPUs.
              </Space>
            </List.Item>
            <List.Item>
              <Space>
                <FolderOutlined style={{ color: '#52c41b' }} />
                Cache simulation results with addtional memory.
              </Space>
            </List.Item>
          </List>
        </Space>
      </Modal>
    </Row>
  )
}

export default AppsResourceManager
