import React from 'react'

import { Row, Space, Typography } from 'antd'

interface CenteredHeaderProps {
  title: string | React.ReactElement,
  subtitle: string | React.ReactElement,
}

export const CenteredHeader: React.FunctionComponent<CenteredHeaderProps> = ({ title, subtitle }) => {

  return (
    <Row align='middle' justify='center' style={{ width: '100%' }}>
      <Space direction='vertical' align='center' style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography.Title level={2}>{title}</Typography.Title>
        <Typography.Text type='secondary'>{subtitle}</Typography.Text>
      </Space>
    </Row>
  )
}

export default CenteredHeader