import React from 'react'
import { Avatar, Card, Space, Tooltip, Typography, Grid } from 'antd'
import { Option } from 'pollination-react-io'

export interface CardButtonOption {
  label?: string
  title?: string
  icon?: React.ReactNode
  avatarStyle?: React.CSSProperties
  onClick?: (option: Option) => any
}

export const CardButton: React.FC<CardButtonOption> = ({
  label,
  title,
  icon,
  onClick,
  avatarStyle
}) => {

  const { md } = Grid.useBreakpoint()

  const handleClick = (e: any) => onClick && onClick(e)

  return <Tooltip title={title}>
    <Card
      hoverable
      style={{
        borderRadius: '10px',
        justifyContent: 'center'
      }}
      size={md ? 'default' : 'small'}
      onClick={handleClick} >
      <Space size='middle'>
        <Avatar icon={icon}
          style={{
            backgroundColor: '#2ea8e0',
            ...avatarStyle
          }}
          shape='circle'/>
        <Typography.Text>{label}</Typography.Text>
      </Space>
    </Card>
  </Tooltip>
}
