import React from 'react'
import { Card, Divider, Row, Space, Typography } from 'antd'

import { AccountPublic } from '@pollination-solutions/pollination-sdk'

import { ProfileSettings } from 'molecules'

import { APIKeys } from 'components/APIKeys'

interface ProfileSettingsProps {
  account: AccountPublic
  updateAccount: (update: Partial<AccountPublic>) => Promise<void>
  deleteAccount: () => Promise<void>
  refetch: (args?: any) => void
}

const Settings = ({ account, updateAccount, refetch, deleteAccount }: ProfileSettingsProps): React.ReactElement => {

  return (
    <>
      <Row justify='space-between' align='middle' style={{ width: '100%' }}>
        <Card title='Profile Settings' style={{ width: '100%' }}>
          <ProfileSettings 
            account={account} 
            deleteAccount={deleteAccount}
            updateAccount={updateAccount} 
            refetch={refetch} />
        </Card>
      </Row>
      {account.account_type === 'user' &&
        <>
          <Divider orientation='left' style={{ margin: '24px 0px' }}>Developer Settings</Divider>
          <Row justify='space-between' align='middle' style={{ width: '100%' }}>
            <Card title='API Keys' style={{ width: '100%' }}>
              <Space direction='vertical' style={{ width: '100%' }}>
                <Typography.Text type='secondary'>Use API Keys to authenticate requests made form command line tools.</Typography.Text>
                <APIKeys account={account} />
              </Space>
            </Card>
          </Row>
        </>}
    </>
  )
}

export default Settings
