import React from 'react'
import { Link } from 'react-router-dom'
import { Space, Card, Typography, Divider, Table, Row, Col, Tag, Avatar } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import {
  RecipeInterface,
  RecipePackage,
  Recipe
} from '@pollination-solutions/pollination-sdk'

import { Empty } from 'atoms'

interface IOTableProps {
  io: RecipeInterface['inputs'] | RecipeInterface['outputs']
  ioType: string
}
const IOTable: React.FunctionComponent<IOTableProps> = ({ io, ioType }) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Required',
      dataIndex: 'required',
      key: 'required',
      render: (required: boolean | undefined) => <>{required && <CloseOutlined name={'icon'} />}</>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ]

  return (
    <Table
      locale={{
        emptyText: <Empty
          description={`No ${ioType}`}
        />
      }}
      pagination={false}
      size="small"
      columns={columns}
      // @ts-ignore
      dataSource={io}
    />
  )
}


interface FunctionsCardProps {
  styles: any
  recipePackage: RecipePackage
  defaultOpen?: string
  recipeJson?: Recipe
}

const FunctionsCard: React.FunctionComponent<FunctionsCardProps> = ({ recipePackage, recipeJson }) => {

  const { manifest } = recipePackage

  return (
    <Row gutter={[16, 15]}>
      <Col xs={24} lg={8}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Divider>About</Divider>
          <Card>
            <Card.Meta
              title={manifest.metadata.name}
              avatar={<Avatar src={manifest.metadata.icon} />}
              description={manifest.metadata.description}
            />
            <br />
            <Space wrap>
              {manifest.metadata.keywords?.map((key: string) => (
                <Tag key={`op-key-${key}`}>{key}</Tag>
              ))}
            </Space>
          </Card>
          {recipeJson && (
            <>
              <Divider>Dependencies</Divider>
              <Card>
                <Space direction="vertical">
                  {recipeJson.dependencies?.map((dep, i) => {
                    const depOwner = dep.source.split('/').pop()
                    // this 's' is really janky...
                    const depLink = `/${depOwner}/${dep.kind}s/${dep.name}/${dep.tag}`
                    return (
                      <Space key={`${dep.name}-${i}`}>
                        <Typography.Text strong>{dep.kind}:</Typography.Text>
                        <Link to={depLink} key={i}>
                          <Typography.Link>{`/${depOwner}/${dep.name}:${dep.tag}`}</Typography.Link>
                        </Link>
                      </Space>
                    )
                  }
                  )}
                </Space>
              </Card>
            </>
          )}
          <Divider>Maintainers</Divider>
          <Card>
            <Space direction="vertical">
              {manifest.metadata.maintainers &&
                manifest.metadata.maintainers.map((m) => (
                  <Space>
                    <Typography.Text strong>{m.name}</Typography.Text>
                    {m.email ? <Typography.Text>: {m.email}</Typography.Text> : <></>}
                  </Space>
                ))
              }
            </Space>
          </Card>
          <Divider>License</Divider>
          <Card>
            {manifest.metadata.license ? (
              <Space>
                <Typography.Link href={manifest.metadata.license.url}>
                  {manifest.metadata.license.name}
                </Typography.Link>
              </Space>
            ) : (
              <Space>
                <Typography.Text>
                  No License
                </Typography.Text>
              </Space>
            )}
          </Card>
        </Space>
      </Col>
      <Col xs={24} lg={16}>
        <Space direction="vertical">
          <Divider>Inputs</Divider>
          <Card>
            <IOTable io={manifest.inputs} ioType="inputs" />
          </Card>
          <Divider>Outputs</Divider>
          <Card>
            <IOTable io={manifest.outputs} ioType="outputs" />
          </Card>
        </Space>
      </Col>
    </Row>

  )
}

export default FunctionsCard
