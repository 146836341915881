import React, { useState, useCallback, useMemo } from 'react'
import { CloudJob, UserPrivate } from '@pollination-solutions/pollination-sdk'

import { APIClient } from 'pollination-react-io'
import { FileCardProps } from 'pollination-react-io/build/RunDetails/Cards/FileCard'
import { FolderCardProps } from 'pollination-react-io/build/RunDetails/Cards/FolderCard'
import { RunsList } from './RunsList'
import { PreviewWindow } from 'components/Folder/PreviewWindow'

interface JobPageDetailsProps {
  accountName: string
  projectName: string
  job?: CloudJob
  authUser?: UserPrivate
  client?: APIClient
  getFolderCard?: (card: FolderCardProps) => any
}

interface HoverObject {
  expand: boolean
  close: boolean
}

export const JobPageDetails: React.FunctionComponent<JobPageDetailsProps> = ({
  accountName,
  projectName,
  job,
  authUser,
  client,
  getFolderCard,
}) => {
  const [currCard, setCurrCard] = useState<FileCardProps>()

  const getFileCardHandler = useCallback((card: FileCardProps) => {
    setCurrCard(card)
  }, [currCard])

  const fileMeta = useMemo(() => {
    if (!currCard) return
    return { 
      file_name: currCard?.previewName ?? currCard.path.split('/').reverse()[0],
      file_type: currCard?.previewName?.split('.')[1] ?? 'unknown',
      key: currCard.path, 
    }
  }, [currCard])

  return (
    <>
      {job && currCard &&
        fileMeta && <PreviewWindow blob={currCard.previewFile as Blob} 
        file={fileMeta} url={fileMeta.key} onClose={() => setCurrCard(undefined)} />}
      {job && client && authUser && <div style={{ margin: '10px 0 0 0' }}>
        <RunsList
          style={{ backgroundColor: '#fff', width: '100%' }}
          projectName={projectName}
          projectOwner={accountName}
          job={job}
          getFileCard={getFileCardHandler}
          getFolderCard={getFolderCard} />
      </div>}
    </>
  )
}