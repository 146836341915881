import React, { useEffect, useState } from 'react'
import { Row, List, Space, Typography, Select, Card } from 'antd'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { ClockCircleOutlined } from '@ant-design/icons'
import { CloudJob, JobStatusEnum, Project } from '@pollination-solutions/pollination-sdk'

import { useAuth } from 'auth-context'

import { StudyCard, useJobs } from 'pollination-react-io'
import { useAccount } from 'hooks'
import { usePaginationHistory } from 'hooks/usePaginationHistory'

dayjs.extend(duration)

export interface JobListProps {
  projectOwner: string
  projectName: string
  extra?: any[]
}

/** Displays an expandable list of project jobs and shows usage statistics about them. */
const JobList: React.FunctionComponent<JobListProps> = ({ projectOwner, projectName, extra }) => {
  const { client, user: authUser } = useAuth()
  const { setParams, page, perPage, status } = usePaginationHistory()

  // Manage list state
  const [total, setTotal] = useState<number>(0)

  const [isLoading, setIsLoading] = useState(false)
  const [jobs, setJobs] = useState<CloudJob[]>([])
  const [project, setProject] = useState<Project>()

  const [reload, setReload] = useState(false)

  // @ts-ignore
  const { listJobs } = useJobs(client)

  useEffect(() => {
    setIsLoading(true)
    setReload(false)
    listJobs(
      projectOwner,
      projectName,
      undefined,
      page,
      perPage,
      // @ts-ignore
      status as JobStatusEnum | undefined
    ).then((jobs) => {
      // Get IO info
      client.projects.getProject({
        name: projectName,
        owner: projectOwner
      })
      .then(({ data }) => {
        data && setProject(data)
        setJobs(jobs.resources)
        setTotal(jobs.total_count)
      })
      .finally(() => setIsLoading(false))
    })
  }, [listJobs, page, perPage, projectName, projectOwner, status, reload])

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row justify="space-between">
        <Space>
          <ClockCircleOutlined style={{ fontSize: '24px' }} />
          <Typography.Text strong>Previous Studies</Typography.Text>
        </Space>
        <Space>
          <Select
            allowClear
            placeholder="Filter by status"
            value={status as JobStatusEnum}
            onClear={() => setParams({ page: 1, status: undefined })}
            onSelect={(value: JobStatusEnum) => {
              // Set values by query
              setParams({ page: 1, status: value })
            }}
            style={{ minWidth: '200px' }}
          >{(Object.keys(JobStatusEnum) as (keyof typeof JobStatusEnum)[]).map((k) => {
            return <Select.Option value={k} key={k}>{k}</Select.Option>
          })}
          </Select>
          {extra && extra.map(e => e)}
        </Space>
      </Row>
      <List
        dataSource={jobs}
        loading={isLoading}
        split={false}
        pagination={{
          onChange: (page, pageSize) => {
            // Set values by query
            setParams({
              perPage: pageSize,
              page: page
            })
          },
          current: page,
          showSizeChanger: true,
          pageSize: perPage,
          total: total,
        }}
        renderItem={(item) => (
          <List.Item>
            {!isLoading ? <StudyCard
              style={{ width: '100%', backgroundColor: 'white' }}
              projectOwner={projectOwner}
              projectName={projectName}
              study={item}
              enableClick={true}
              enableLink={true}
              canWrite={(project && (project?.permissions.write || project?.permissions.admin))}
              // @ts-ignore
              client={client}
              authUser={authUser}
            /> : <Card style={{
              width: '100%',
              height: '215px',
              borderRadius: '6px'
            }} loading={true}></Card>}
          </List.Item>
        )}
        style={{
          marginTop: '-7px'
        }}

      />
    </Space>
  )
}


export default JobList
