import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { DeleteOutlined } from '@ant-design/icons'
import { OrganizationRoleEnum, Organization, OrganizationMember, UserPublic } from '@pollination-solutions/pollination-sdk'
import { Row, Col, Space, List, Input, Button, Avatar, Select, Form, notification, Typography, Divider } from 'antd'

import { useAuth } from 'auth-context'
import { AvatarLink } from 'atoms'
import { OrganizationContext } from '.'

interface CardlistProps {
  org: Organization
  permission: OrganizationRoleEnum | undefined
  canWrite: boolean
}

const CardList: React.FunctionComponent<CardlistProps> = ({ org, permission, canWrite }) => {
  const { client } = useAuth()
  const { setRefresh } = useContext(OrganizationContext)

  const [members, setMembers] = useState<OrganizationMember[]>([])
  const [loading, setLoading] = useState(true)
  const [userPermission, setUserPermission] = useState<OrganizationRoleEnum | undefined>(permission)

  const [form] = Form.useForm()

  const [search, setSearch] = useState<string | undefined>()
  const [roleFilter, setRoleFilter] = useState<string | undefined>()

  const [creatingMember, setCreatingMember] = useState<boolean>(false)
  const [newMemberOptions, setNewMemberOptions] = useState<UserPublic[]>([])
  const [canCreateMember, setCanCreateMember] = useState<boolean>(false)

  let cancelDeleteMember = false

  useEffect(() => {
    setLoading(true)
    client.orgs.getOrgMembers({ name: org.owner.name })
      .then(({ data }) => {
        let memberList = data.resources
        if (search && search !== '') {
          memberList = memberList.filter((m) => m.user.username.includes(search))
        }

        if (roleFilter && roleFilter !== '') {
          memberList = memberList.filter((m) => m.role == roleFilter)
        }

        setMembers(memberList)
        setLoading(false)
      })
  }, [search, roleFilter])

  useEffect(() => {
    setUserPermission(permission)
  }, [permission])

  const checkValues = (changed: any, all: any) => {
    if (all.username && all.role) {
      setCanCreateMember(true)
    } else {
      setCanCreateMember(false)
    }
  }

  const addSelectedUser = () => {
    const username = form.getFieldValue('username')
    const role = form.getFieldValue('role')
    setLoading(true)
    setRefresh && setRefresh(true)
    client.orgs
      .upsertOrgMember({ name: org.owner.name, username, role })
      .then(() =>
        notification.success({
          message: 'Success!',
          description: `${username} is now a member of ${org.owner.name}!`,
        })
      )
      .then(() =>
        client.users.getOneUser({ name: username })
      )
      .then(({ data }) => {
        const newMember = {
          user: data,
          role: role,
        } as OrganizationMember
        setMembers([...members, newMember])
      })
      .then(() => {
        setLoading(false)
        setNewMemberOptions([])
        setCreatingMember(false)
        form.resetFields()
      })
      .catch((error) =>
        notification.error({
          message: 'Error!',
          description: error.response.data.detail,
        })
      )
      .finally(() => setRefresh && setRefresh(false))
  }

  const searchNewUsers = (search: string) => {
    if (!search || search === '') {
      setNewMemberOptions([])
    }
    client.users.listUsers({
      search,
    }).then(({ data }) => data.resources.filter((u) => {
      return !members.map(m => m.user.username).includes(u.username)
    })).then(res => setNewMemberOptions(res))
  }

  function confirmDelete(member: OrganizationMember) {
    if (cancelDeleteMember) {
      cancelDeleteMember = false
      return
    }

    setLoading(true)
    setRefresh && setRefresh(true)

    client.orgs.deleteOrgMember({
      name: org.owner.name,
      username: member.user.username
    }).then(() => {
      setLoading(false)
    })
    .finally(() => setRefresh && setRefresh(false))
  }

  function cancelDelete(e: React.MouseEvent, member: OrganizationMember) {
    e.stopPropagation()
    cancelDeleteMember = true
    // can't gaurantee that setMembers() called in handleDeleteMember will or won't have completed
    setMembers([...members, member].filter((val, i, arr) => arr.findIndex((v) => v.user.username === val.user.username) === i))
    notification.close(`delete-${member.user.username}`)
  }

  const handleDeleteMember = (member: OrganizationMember) => {
    notification.warning({
      key: `delete-${member.user.username}`,
      message: `Deleted ${member.user.username}`,
      description: `${member.user.username} is no longer a member of ${org.owner.name}!`,
      btn: <Button onClick={(e) => cancelDelete(e, member)}>Undo</Button>,
      onClose: () => confirmDelete(member)
    })

    setMembers(members.filter(m => m.user.username !== member.user.username))
  }

  const disableModify = members.length < 2 || members.filter(m => m.role === OrganizationRoleEnum.Owner).length < 2

  const newMemberButton = (canWrite: boolean, creatingMember: boolean) => {
    if (!canWrite) return (<></>)

    return (
      creatingMember ?
        <Button onClick={() => {
          setCreatingMember(false)
          setCanCreateMember(false)
          form.setFieldsValue({ username: [], role: 'member' })
        }} type="default" danger>
          Cancel
        </Button> :
        <Button onClick={() => setCreatingMember(true)} type="primary">
          Add Member
        </Button>
    )
  }

  return (
    <Col style={{ padding: 0 }}>
      <Row align="middle" justify="space-between" style={{ width: '100%' }}>
        {creatingMember ?
          <Form
            form={form}
            layout="inline"
            onFinish={addSelectedUser}
            onValuesChange={checkValues}
            initialValues={{ username: [], role: 'member' }}
            requiredMark={false}
            style={{ flex: 1 }}
          >
            <Form.Item
              name="username"
              rules={[{ required: true }]}
              style={{ minWidth: '150px', maxWidth: '250px', width: '35%' }}
            >
              <Select
                showSearch
                allowClear
                placeholder="search users"
                onSearch={searchNewUsers}
              >
                {
                  newMemberOptions.map(m => (
                    <Select.Option key={m.username} value={m.username}>
                      <Space style={{ alignItems: 'end' }}>
                        <Avatar size="small" src={m.picture} style={{ display: 'flex' }} />
                        <Typography.Text>{m.username}</Typography.Text>
                      </Space>
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="role"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="select role"
                style={{ 'width': '100px' }}
              >
                <Select.Option value={'member'}>Member</Select.Option>
                <Select.Option value={'owner'}>Owner</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" disabled={!canCreateMember} type="primary">
                Add Member
              </Button>
            </Form.Item>
          </Form>
          :
          <Row align="middle" style={{ flex: 1 }}>
            <Input.Search
              placeholder="search members"
              allowClear
              onSearch={(value: string) => value !== '' ? setSearch(value) : setSearch(undefined)}
              style={{ minWidth: '150px', maxWidth: '250px', width: '35%' }}
            />
            <Divider type="vertical" />
            <Select
              allowClear
              placeholder="select role"
              onClear={() => setRoleFilter(undefined)}
              onSelect={(value) => setRoleFilter(value as string)}
            >
              <Select.Option value={'member'}>Member</Select.Option>
              <Select.Option value={'owner'}>Owner</Select.Option>
            </Select>
          </Row>
        }
        {
          newMemberButton(canWrite, creatingMember)
        }
      </Row>
      <List
        style={{ marginTop: '24px' }}
        loading={loading}
        dataSource={members}
        renderItem={(member, i) => (
          <List.Item
            key={member.user.username} className="selectable" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.2)', boxSizing: 'border-box' }}
            actions={userPermission === OrganizationRoleEnum.Owner ? (
              [
                (
                  <Select
                    key={`${member.user.username}-${i}-role`}
                    defaultValue={member.role}
                    onSelect={(value) => client.orgs.upsertOrgMember({
                      name: org.owner.name,
                      username: member.user.username,
                      role: value === 'owner' ? OrganizationRoleEnum.Owner : OrganizationRoleEnum.Member
                    })}
                    style={{ width: '100px' }}
                    disabled={disableModify && member.role === OrganizationRoleEnum.Owner}
                  >
                    <Select.Option value="member">Member</Select.Option>
                    <Select.Option value="owner">Owner</Select.Option>
                  </Select>
                ),
                (
                  <Button 
                    key={`${member.user.username}-${i}-delete`}
                    shape="circle" 
                    onClick={() => handleDeleteMember(member)} 
                    style={{ backgroundColor: 'rgba(0,0,0,0)' }}
                    disabled={disableModify && member.role === OrganizationRoleEnum.Owner}  
                  >
                    <DeleteOutlined />
                  </Button>
                )
              ]
            ) : []}
          >
            <Link to={`/${member.user.username}`} style={{ width: '20%' }}>
              <List.Item.Meta
                avatar={<AvatarLink src={member.user.picture} link={`/${member.user.username}`} />}
                title={member.user.name}
                description={member.user.username}
              />
            </Link>
          </List.Item>
        )}
      ></List>
    </Col>
  )
}

export default CardList
