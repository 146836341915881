import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ApplicationDeployment } from '@pollination-solutions/pollination-sdk'
import { notification } from 'antd'
import useSWR from 'swr'

import { useAuth } from 'auth-context'
import useApplication from 'hooks/useApplication'

interface ContextProps {
    loading: boolean
    deployment?: ApplicationDeployment
    deploymentUrl?: string
    buildLogs?: string[]
    runningLogs?: string[]
    reloadDeployment: () => Promise<void>
    restartDeployment: () => Promise<void>
}

interface ProviderProps {
    children: React.ReactNode
}

export const ApplicationDeploymentContext = createContext<ContextProps>({} as ContextProps)


export const ApplicationDeploymentProvider = ({ children }: ProviderProps) => {
    const [loading, setLoading] = useState<boolean>(true)
    const [deployment, setDeployment] = useState<ApplicationDeployment>()

    const { loading: authLoading, client, idToken } = useAuth()
    const { loading: applicationLoading, application } = useApplication()

    const fetchDeployment = useCallback(async (owner: string, slug: string) => {
        return client.applications.getApplicationDeployment({ owner, slug })
            .then(({ data }) => data as ApplicationDeployment)
    }, [client.applications])

    const reloadDeployment = useCallback(async () => {
        if (!application) return Promise.reject(new Error('Application not defined'))
        return fetchDeployment(application.owner.name, application.slug)
            .then((deployment) => {
                setDeployment(deployment)
            })
    }, [fetchDeployment, application])

    const restartDeployment = useCallback(async () => {
        if (!application) return Promise.reject(new Error('Application not defined'))
        return client.applications.restartApplicationDeployment({ owner: application.owner.name, slug: application.slug })
            .then(() => {
                return reloadDeployment()
            }).catch((err) => {
                notification.error({
                    message: 'Error',
                    description: err.message
                })
            })
    }, [client.applications, reloadDeployment, application])

    useSWR(application ? application.id : 'empty-app', reloadDeployment, { errorRetryCount: 3, revalidateOnFocus: false, refreshInterval: 10000 })


    const getBuildLogs = useCallback(() => {
        return Promise.resolve([
            '2021-08-04T18:00:00.000Z Building stuff',
            '2021-08-04T18:00:00.000Z Building stuff',
            '2021-08-04T18:00:00.000Z Building stuff',
            '2021-08-04T18:00:00.000Z Building stuff',
        ])
        // if (!deployment) return
        // return client.applications.getDeploymentBuildLogs()
        //     .then(({ data }) => data)
    }, [])

    const buildLogRefreshInterval = useMemo(() => {
        if (!deployment) return 5000
        if (deployment.ready) return undefined
    }, [deployment])

    const { data: buildLogs } = useSWR(
        deployment ? [deployment.url, 'getDeploymentBuildLogs'] : 'foo',
        getBuildLogs,
        {
            revalidateOnFocus: false,
            errorRetryInterval: 1000,
            errorRetryCount: 3,
            fallbackData: [],
            refreshInterval: buildLogRefreshInterval,
        }
    )

    const getRunningLogs = useCallback(() => {
        return Promise.resolve([
            '2021-08-04T18:00:00.000Z Running stuff',
            '2021-08-04T18:00:00.000Z Running stuff',
            '2021-08-04T18:00:00.000Z Running stuff',
            '2021-08-04T18:00:00.000Z Running stuff',
        ])
        // if (!deployment) return
        // return client.applications.getDeploymentRunningLogs()
        //     .then(({ data }) => data)
    }, [])

    const runningLogRefreshInterval = useMemo(() => {
        if (!deployment) return 0
        if (deployment.ready) return 5000
    }, [deployment])

    const { data: runningLogs } = useSWR(
        deployment ? [deployment.url, 'runningLogRefreshInterval'] : 'bar',
        getRunningLogs,
        {
            revalidateOnFocus: false,
            errorRetryInterval: 1000,
            errorRetryCount: 3,
            fallbackData: [],
            refreshInterval: runningLogRefreshInterval,
        }
    )


    useEffect(() => {
        if (authLoading || applicationLoading || !application) return

        reloadDeployment().catch((err) => {
            if (err.message.includes('404')) {
                return
            }
            notification.error({
                message: 'Error',
                description: err.message
            })
        }).finally(() => {
            setLoading(false)
        })
    }, [authLoading, applicationLoading, reloadDeployment, application])

    const deploymentUrl = useMemo(() => {
        if (!deployment) return
        const params = new URLSearchParams()
        if (idToken) params.set('token', idToken)
        return `${deployment.url}/?${params.toString()}`
    }, [deployment, idToken])

    return (
        <ApplicationDeploymentContext.Provider
            value={{
                loading,
                deployment,
                deploymentUrl,
                buildLogs,
                runningLogs,
                reloadDeployment,
                restartDeployment
            }}
        >
            {children}
        </ApplicationDeploymentContext.Provider>
    )
}

const useApplicationDeployment = () => {
    return useContext(ApplicationDeploymentContext)
}

export default useApplicationDeployment
