
import React, { FC, useEffect, useState } from 'react'

import { Badge, Card, Empty, List, Typography, Image } from 'antd'
import { LikeTwoTone } from '@ant-design/icons'

export const RecentTopics: FC = () => {
  const [data, setData] = useState<any[]>()
  const [loading, setLoading] = useState<boolean>()

  async function getData() {
    const url = 'https://discourse.pollination.solutions/c/announcements/10.json?ascending=false'
    try {
      setLoading(true)
      const response = await fetch(url, {
        mode: 'cors'
      })
      if (!response.ok) {
        setData([])
      }
    
      const topics = await response.json()
      setData(topics.topic_list.topics
        ?.filter(t => !t.archived && t.id !== 22)
        ?.slice(0, 6) ?? [])
    } catch (error: any) {
      console.error(error.message)
      setData([])
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <List
      grid={
        {
          gutter: 20,
          xs: 2,
          sm: 2,
          md: 3,
          lg: 3,
          xl: 3,
          xxl: 3,
        }
      }
      dataSource={data ?? []}
      renderItem={({ id, slug, title, image_url, like_count }, i) => (
        <List.Item>
          <Typography.Link target='_blank' href={`https://discourse.pollination.solutions/t/${slug}/${id}`}>
          <Badge.Ribbon style={{ height: 26 }} text={<span>
            {like_count}
            <LikeTwoTone style={{ fontSize: 20 }} />
          </span>}>
          <Card
            hoverable
            cover={<>
              {image_url ? <img
                style={{
                  height: 180,
                  objectFit: 'cover'
                }}
                alt={title}
                src={image_url}
              /> : <Image
                style={{
                  height: 140,
                  objectFit: 'fill'
                }}
                preview={false}
                src='/brandmark.svg'
              />}
            </>}
          >
            <Typography.Text>{title}</Typography.Text>
          </Card>
          </Badge.Ribbon></Typography.Link>
        </List.Item>)}
      locale={{
          emptyText: <Empty description={
            <Typography.Text>
              No news here... 
            </Typography.Text>
          } />
        }}
      loading={loading}
      pagination={false}
    />
  )
}
