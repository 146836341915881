import React, { useCallback, useEffect, useState } from 'react'

import { Application, SubjectType, Team } from '@pollination-solutions/pollination-sdk'

import { Button, List, Select, Space, Typography } from 'antd'

import { Loading } from 'atoms'

import { DeleteOutlined } from '@ant-design/icons'

import { useAuth } from 'auth-context'
import { Link, useParams } from 'react-router-dom'
import { useAppPermissions } from 'hooks'

import flex from 'styles/flex.module.css'

interface ApplicationAccessProps {
  application: Application
}

export const ApplicationAccess: React.FC<ApplicationAccessProps> = ({ application }) => {
  const { client } = useAuth()

  const [team, setTeam] = useState<string | undefined>(undefined)
  const [permission, setPermission] = useState<string>('read')

  // @ts-ignore
  const { accountName } = useParams()

  const {
    loading,
    permissions,
    setQuery,
    addAppPermission,
    deleteAppPermission,
  } = useAppPermissions(application, {
    owner: application.owner.name,
    slug: application.slug,
    page: 1,
    perPage: 25,
  })

  const [teamOptions, setTeamOptions] = useState<Team[]>([])

  const searchTeams = useCallback((search: [string] | undefined) => {
    client.teams.listOrgTeams({
      orgName: accountName,
      page: 1,
      perPage: 5,
      search
    }).then(({ data }) => {
      setTeamOptions(data.resources)
    })
  }, [accountName, client.teams])

  useEffect(() => {
    searchTeams(undefined)
  }, [searchTeams])

  if (!application) return <Loading />

  return (
    <div className={flex['poll-flex-column']}
      style={{
        rowGap: 16,
        display: 'flex',
      }}
    >
      <Space>
        <Select
          placeholder="Search teams..."
          showSearch
          onSearch={(value: string) => {
            searchTeams([value])
          }}
          style={{ width: 200 }}
          onSelect={(value: string) => {
            setTeam(value)
          }}
        >
          {teamOptions.map(t => (
            <Select.Option key={t.id} value={t.slug}>
              <Typography.Text>{t.slug}</Typography.Text>
            </Select.Option>
          ))}
        </Select>
        <Select
          value={permission}
          onSelect={(val: string) => {
            setPermission(val)
          }}
        >
          <Select.Option value="read">Read</Select.Option>
          <Select.Option value="write">Write</Select.Option>
          <Select.Option value="admin">Admin</Select.Option>
        </Select>
        <Button
          disabled={!team || !permission}
          onClick={() => {
            if (!team || !permission) return
            // @ts-ignore
            addAppPermission({ team, permission })
              .then(() => {
                setTeam(undefined)
              })
          }}
        >
          Add
        </Button>
      </Space>
      <List
        dataSource={permissions}
        loading={loading}
        style={{
          width: '100%'
        }}
        renderItem={(item, i) => (
          <List.Item key={i}
            actions={[
              (
                <Button
                  shape="circle"
                  onClick={() => deleteAppPermission(item.subject)} key={`${item.subject.name}-${i}`}
                >
                  <DeleteOutlined />
                </Button>
              )
            ]}
            style={{ width: '100%', borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }}
          >
            {
              item.subject.subject_type == SubjectType.User ?
                (
                  <List.Item.Meta
                    title={
                      <Link to={`/${item.subject.name}`}
                        style={{ width: '100% ' }}
                      >
                        @{item.subject.name}
                      </Link>
                    }
                    description={item.permission}
                  />
                ) : (
                  <List.Item.Meta
                    title={
                      <Link to={`/${item.subject.name}`}
                        style={{ width: '100% ' }}
                      >
                        @{item.subject.name}
                      </Link>
                    }
                    description={item.permission}
                  />
                )
            }
          </List.Item>
        )}
      />
    </div>
  )
}