import React, { useEffect, useContext } from 'react'

import { useRemoteConfig } from 'reactfire'

import { fetchAndActivate, getAll } from 'firebase/remote-config'

interface FlagsContextProps {
  flags: { [key: string]: boolean };
}

const FlagsContext = React.createContext({ flags: {} } as FlagsContextProps)

interface FeatureFlagProviderProps {
  children: React.ReactNode;
  defaults?: { [key: string]: boolean };
}

const FlagProvider = ({ children, defaults }: FeatureFlagProviderProps): React.ReactElement => {
  const [flags, setFlags] = React.useState(defaults || {})
  
  const remoteConfig = useRemoteConfig()
  
  remoteConfig.settings = {
    minimumFetchIntervalMillis: 10000,
    fetchTimeoutMillis: 10000,
  }

  useEffect(() => {
    remoteConfig.defaultConfig = defaults || {}

    fetchAndActivate(remoteConfig)
      .then(activated => {
        return getAll(remoteConfig)
      })
      .then(remoteFlags => {
        const newFlags: { [key: string]: boolean } = {}

        for (const [key, config] of Object.entries(remoteFlags)) {
          newFlags[key] = config.asBoolean()
        }

        setFlags(state => ({ ...state, ...newFlags }))
      })
      .catch(error => console.error(error))
  }, [defaults, remoteConfig])

  return (
    <FlagsContext.Provider value={{ flags }}>{children}</FlagsContext.Provider>
  )
}

export default FlagProvider

export const useFlags = (): FlagsContextProps => {
  return useContext(FlagsContext)
}
