import React, { CSSProperties, FC } from 'react'

import { Empty as AntEmpty } from 'antd'

import emptyImage from './poll_empty.svg'

interface EmptyProps {
  description?: string | React.ReactNode
  style?: CSSProperties
}

export const Empty: FC<EmptyProps> = ({
  description,
  style,
  children
}) => {
  return (
    <AntEmpty
      style={{ ...style }}
      image={emptyImage}
      imageStyle={{
        height: 60,
      }}
      description={
        <span style={{
          color: '#d0d7de'
        }}>
          {description ?? 'Nothing to display'}
        </span>
      }
    >
      {children}
    </AntEmpty>
  )
}