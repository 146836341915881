import React from 'react'

import { Dropdown } from 'pollination-react-io'

import styles from './Header.module.css'
import { Avatar } from 'antd'
import { AppstoreAddOutlined, NodeIndexOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

interface GalleryOptionsProps {
  disabled: boolean
}

export const GalleryOptions = ({
  disabled
}): React.ReactElement<GalleryOptionsProps> => {
  const history = useHistory()
  
  return (
    <Dropdown
    disabled={disabled}
    trigger={
      <button className={styles['header-avatar-button']}>
        <Avatar icon={<AppstoreAddOutlined style={{ 
            color: '#333',
            fontSize: 20
           }}/>} 
          size={30} 
          shape='circle'
          className={styles['header-avatar-trigger']}
          style={{ backgroundColor: 'rgba(0,0,0,0)' }}
        />
      </button>
    }
    optionGroups={[{
        options: [
          {
            type: 'button',
            label: 'Recipe Gallery',
            id: 'recipe-gallery',
            icon: <NodeIndexOutlined />,
            onSelect: () => history.push('/recipes'),
          },
          {
            type: 'button',
            label: 'App Gallery',
            id: 'app-gallery',
            icon: <AppstoreAddOutlined />,
            onSelect: () => history.push('/apps')
          }
        ]
      }]}
    contentProps={{
      align: 'end',
      sideOffset: -8,
      style: {
        borderColor: 'var(--primary)',
        zIndex: 1000,
        lineHeight: '1.25rem',
      }
    }}
  />)
}