import React, { useEffect, useState } from 'react'

interface RawViewerProps {
  file: Blob
  path?: string
}

const Raw: React.FunctionComponent<RawViewerProps> = ({ file }) => {
  const [rawString, setRawString] = useState('')

  useEffect(() => {
    file?.text().then((fileString) => {
      setRawString(fileString)
    })
  }, [file])

  return (
    <div 
      style={{
        width: '100%',
        display: 'flex',
        maxHeight: '100%'
      }}
    >
      <pre style={{ width: '100%', height: '100%' }}>{rawString}</pre>
    </div>
  )
}

export default Raw
