import React, { useMemo } from 'react'

import { CenteredHeader } from 'atoms'
import { Divider, Card, Row, Col } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import { Application } from '@pollination-solutions/pollination-sdk'

import CreateApplicationForm from 'organisms/CreateApplicationForm'
import { useWindowDimensions } from 'pollination-react-io'

export const CreateApplication: React.FunctionComponent = () => {
  const history = useHistory()
  const location = useLocation()

  const { width } = useWindowDimensions()

  const defaultAccount = useMemo(() => {
    if (!location || !location?.pathname) return
    return location.pathname.split('/')[1]
  }, [location])

  return (
    <Col 
    xl={{ span: 16, offset: 4 }} 
    lg={{ span: 16, offset: 4 }} 
    md={24} 
    sm={24}
    xs={24}>
      <img src="https://avatars.githubusercontent.com/u/74075017?s=200&v=4" style={{ width: 128, display: 'block', margin: '0 auto' }} />
      <CenteredHeader
        title='Create a new Application'
        subtitle='Applications allow you to share your expertise with colleagues. You can build Spaces with Python libraries like Streamlit or Dash, or using Docker images.'
      />
      <Divider />
      <Card>
        <CreateApplicationForm
          restrictToAccount={defaultAccount}
          onSuccess={(application: Application) => {
            history.push(`/${application.owner.name}/apps/${application.slug}`)
          }} />
      </Card>
    </Col>
  )

}
