import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router'
import slugify from 'slugify'
import { Button, Input, Divider, Typography, Form, Col, Row, Space, Modal, notification, Grid } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useAuth } from 'auth-context'
import context from './context'
import { DeleteOutlined, RedoOutlined } from '@ant-design/icons'
import { useWindowDimensions } from 'pollination-react-io'
import { useAccount } from 'hooks'

/** Allows user to rename, update description on, change visibility for, or delete the current project. */
const Settings = (): React.ReactElement => {
  const { client } = useAuth()
  const history = useHistory()
  const { account } = useAccount()

  const { xs } = Grid.useBreakpoint()
  const { width } = useWindowDimensions()

  const { project, setProject } = useContext(context)
  // @ts-ignore
  const { name, description, public: isPublic, slug } = project
  const [form] = useForm()

  const [projectName, setProjectName] = useState(name ?? '')
  const [projectDescription, setProjectDescription] = useState(description ?? '')

  const handleChangeProject = () => {
    const [pOwner, pName] = slug.split('/')

    client.projects.update({
      owner: pOwner,
      name: pName,
      projectUpdate: {
        name: projectName,
        description: projectDescription,
        // @ts-ignore
        public: isPublic,
      }
    }).then(() => {
      if (projectName !== project.name) {
        const newSlug = `${project.owner.name}/${slugify(projectName, { lower: true })}`
        history.push(`/projects/${newSlug}?tab=settings`)
      } else {
        setProject({
          ...project,
          name: projectName,
          description: projectDescription,
          // @ts-ignore
          public: isPublic
        })
      }
    })
  }

  const [visibilityModalOpen, setVisibilityModalOpen] = useState(false)
  const handleToggleVisibility = (): void => {
    setVisibilityModalOpen(true)
  }

  const toggleVisibility = (): void => {
    const [pOwner, pName] = slug.split('/')

    client.projects.update({
      owner: pOwner,
      name: pName,
      projectUpdate: {
        name: project.name,
        description: project.description,
        // @ts-ignore
        public: !isPublic
      }
    })
      // @ts-ignore
      .then(() => setProject({ ...project, public: !isPublic }))
      .catch((err) => notification.error({
        message: 'Failed to change project visibility',
        description: err.response.data.detail
      })).finally(() => setVisibilityModalOpen(false))
  }

  const [deletionModalOpen, setDeletionModalOpen] = useState(false)
  const handleDelete = (): void => {
    setDeletionModalOpen(true)
  }

  const requestDelete = (): void => {
    const [pOwner, pName] = slug.split('/')
    client.projects.deleteProject({ owner: pOwner, name: pName })
      .then(() => history.push(`/${pOwner}`))
      .catch((err) => notification.error({
        message: 'Failed to delete project',
        description: err.response.data.detail
      }))
  }

  return (
    <Col>
      <Form labelAlign="left" labelCol={{ span: 3 }} form={form} onFinish={handleChangeProject}>
        <Form.Item label="Name" style={{ fontWeight: 500 }}>
          <Input style={xs ? { width: `calc(${width}px - 70px)` } : {}} value={projectName} onChange={(e): void => setProjectName(e.target.value)} />
        </Form.Item>
        <Form.Item label="Description" style={{ fontWeight: 500 }}>
          <Input.TextArea style={xs ? { width: `calc(${width}px - 70px)` } : {}} value={projectDescription} onChange={(e): void => setProjectDescription(e.target.value)} />
        </Form.Item>
        <Form.Item>
          <Row>
            {!xs && <Col span={3} />}
            <Col span={6}>
              <Button htmlType="submit" type='primary' style={xs ? { width: `calc(${width}px - 70px)` } : {}}>
                Update
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
      <Divider orientation="left">Settings</Divider>
      <Row justify="space-between" gutter={[0, 16]} style={{ width: '100%' }}>
        {account && account.account_type === 'org' && <Row justify="space-between" gutter={[0, 16]} style={{ width: '100%' }}>
          <Space>
            <Typography.Text strong>Change Visibility</Typography.Text>
            <Typography.Text>Project is currently {isPublic ? 'public' : 'private'}</Typography.Text>
          </Space>
          <Button onClick={handleToggleVisibility}>
            <RedoOutlined />
            Change
          </Button>
          <Modal
            visible={visibilityModalOpen}
            onCancel={() => setVisibilityModalOpen(false)}
            onOk={toggleVisibility}
            title="Change Visibility"
          >
            <Typography.Text>Make this project {isPublic ? 'private' : 'public'}</Typography.Text>
          </Modal>
        </Row>}
        <Row justify="space-between" gutter={[0, 16]} style={{ width: '100%' }}>
          <Space>
            <Typography.Text strong>Nuclear Option</Typography.Text>
            <Typography.Text>Delete this project</Typography.Text>
          </Space>
          <Button 
            onClick={handleDelete} 
            danger
          >
            <DeleteOutlined/>
            Delete
          </Button>
          <Modal
            visible={deletionModalOpen}
            okText="Delete"
            onCancel={() => setDeletionModalOpen(false)}
            onOk={requestDelete}
            title="Delete Project"
          >
            <Typography.Text>
              Are you sure? Deletion is <Typography.Text strong>permanent</Typography.Text>
            </Typography.Text>
          </Modal>
        </Row>
      </Row>
    </Col>
  )
}

export default Settings
