import React from 'react'
import { Space, Modal } from 'antd'
import { useWindowDimensions } from 'pollination-react-io'

export interface RecipeModalProps {
  open?: boolean
  children?: React.ReactNode
  onCancel?: (e: any) => any
}

export interface AddRecipeFilterProps {
  slug: string
  tag?: string
}
export const RecipeModal: React.FunctionComponent<RecipeModalProps> = ({ 
  open,
  children,
  onCancel }) => {
  
  // Fix size for small screen 
  const { width } = useWindowDimensions()

  return (
    <Modal title='Add/Remove recipes' 
      open={open}
      destroyOnClose={true}
      onCancel={onCancel}
      width={width <= 576 ? (width - 50) : undefined}
      footer={null}
      >
        {children}
    </Modal>
  )
}

export default RecipeModal
