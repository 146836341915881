import React, { FC, useCallback, useMemo, useState } from 'react'

import { AccountPublic, OrgsApiListOrgsRequest } from '@pollination-solutions/pollination-sdk'

import { useAuth } from 'auth-context'
import { useOrganizations } from 'hooks'
import { CaretDownFilled } from '@ant-design/icons'

import { Avatar, Dropdown, OptionGroup, Option } from 'pollination-react-io'

import styles from './AccountSwitcher.module.css'

interface AccountSwitcherProps {
  onSelect?: (account: AccountPublic) => void
}

export const AccountSwitcher: FC<AccountSwitcherProps> = ({
  onSelect,
}) => {
  const { user } = useAuth()

  const query: Partial<OrgsApiListOrgsRequest> = useMemo(() => user?.username ? ({ member: [user.username] }) : ({}), [user?.username])

  const account: AccountPublic | undefined = useMemo(() => 
    user ? 
      ({
        id: user?.id,
        account_type: 'user',
        name: user?.username,
        display_name: user?.name,
        description: user?.description,
        picture_url: user?.picture, 
      }) : undefined,
  [user])

  const [selAccount, setSelAccount] = useState<AccountPublic | undefined>(account)

  const { orgs } = useOrganizations(query)

  const handleAccountSwitch = useCallback((id: string) => {
    if (!account) return
    const sel = account.id === id ? account : orgs?.resources.find(org => org.owner.id === id)?.owner
    if(!sel) return
    setSelAccount(sel)
    if(onSelect) onSelect(sel)
  }, [account, onSelect, orgs])

  const options: OptionGroup[] = useMemo(() => {
    if(!account) return []
    
    let accounts = [account]

    if (orgs) {
      accounts = [...accounts, ...orgs.resources.map(o => o.owner)]
    }
    
    // @ts-ignore
    const accountOptions: Option[] = accounts.map(a => ({
      type: 'button',
      id: a.id,
      label: a?.display_name && a?.display_name.length > 16 ?  a?.display_name.slice(0, 16) + '...' : a?.display_name,
      icon: <Avatar src={a?.picture_url} size={18} alt={a?.display_name} />,
      checked: selAccount && selAccount.id === a.id,
      onSelect: (o: Option) => handleAccountSwitch(o.id),
    }))

    return [{
      options: accountOptions
    }]
  }, [account, handleAccountSwitch, orgs, selAccount])
 
  if(!selAccount) return <></>
  
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems:'center',
      gap: 10,
      marginBottom: 28,
    }}>
      <Avatar 
        size={32}
        src={selAccount?.picture_url}
        alt={selAccount?.display_name}
        href={`${selAccount.name}`}
      />
      {options[0].options.length > 1 ?
        <Dropdown
          optionGroups={options}
          trigger={
            <button
              style={{
                maxWidth: 220
              }}
              className={styles['poll-select-trigger']}
            >
              <span
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {selAccount.display_name}
              </span>
              <CaretDownFilled/>
            </button>}
          arrow={false}
          contentProps={{ 
            align:'center',
            style:{
              borderColor: 'var(--primary)',
              stroke: 'var(--primary)',
              zIndex: 1000
            }
          }}
        />
        :
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 4,
            padding: '2px 8px',
            fontWeight: '700',
            fontSize: '1rem',
            border: '1px solid rgba(0, 0, 0, 0)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {selAccount.display_name}
        </span>
      }
    </div>
  )
}