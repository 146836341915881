import React, { useEffect, useState } from 'react'
import { ProjectRecipeFilter, Repository } from '@pollination-solutions/pollination-sdk'
import { Avatar, Button, Card, Select, Space, Typography } from 'antd'
import { useAuth } from 'auth-context'

interface RecipeProps {
  repository: Repository
  onClick?: (recipeFilter: ProjectRecipeFilter) => any
}

export const RecipeCard: React.FC<RecipeProps> = ({ repository, onClick }) => {
  const { client } = useAuth()
  const [tagOptions, setTagOptions] = useState<string[]>()
  const [selTag, setSelTag] = useState<string>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!repository || !repository.slug) return
    setLoading(true)
    const [rOwner, rName] = repository.slug.split('/')
    client.recipes.listRecipeTags({ owner: rOwner, name: rName })
      .then(({ data }) => {
        const tags = data.resources
          .filter(d => !d.manifest.metadata.deprecated)
          .map((d) => d.tag)
        setTagOptions(tags)
      })
      .finally(() => setLoading(false))
  }, [])
  
  const handleClick = (e: any) => {
    if (!onClick || !repository) return
    onClick({
      name: repository.name,
      owner: repository.owner.name,
      tag: selTag ?? '*'
    })
  }

  return (<Card
    size='small'
    title={<><Avatar shape='square' style={{
      marginRight: '5px'
     }} src={repository.icon} />
      {repository.name}</>}
    extra={
      <Space dir='horizontal'>
        <Select 
        onChange={setSelTag}
        placeholder={'Tag'}
        showSearch
        size='small'
        dropdownMatchSelectWidth={true}
        loading={loading}
        style={{ width: '100px', textOverflow: 'ellipsis' }}>
          {tagOptions && tagOptions.map(t => (
            <Select.Option value={t}>{t}</Select.Option>)
          )}
        </Select>
        <Button size='small' type='primary'
          onClick={handleClick} disabled={loading}>Add</Button>
      </Space>
    }>
      <>
        <Avatar shape='circle'
          style={{ marginRight: '5px' }}
          src={repository.owner.picture_url} />
          {repository.description}
      </>
  </Card>)
}