import React, { CSSProperties, ReactNode, useMemo } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { Grid, Tabs, Select, Row, Space, Col, Radio, Tooltip } from 'antd'

interface Tab {
  icon: React.ReactNode
  label: string
  content: React.ReactNode
  badge?: React.ReactNode
}

interface TabsProps {
  tabs: Tab[]
  extraContent?: ReactNode | {left?: ReactNode, right?: ReactNode}
  tabBarStyle?: CSSProperties
  tabPosition?: 'left' | 'right' | 'top' | 'bottom' | undefined
}

/**
 * Given a collection of `Tab` objects, creates clickable tabs to swap between their content.
 * @param {Tab[]} tabs { label: string, icon: React.ReactNode, content: React.ReactNode }
 * @remarks Clicking a tab triggers shallow nextjs routing on the page.
 */
const UrlTabs: React.FC<TabsProps> = ({ tabs, tabPosition, tabBarStyle, extraContent }) => {
  const screens = Grid.useBreakpoint()
  
  const history = useHistory()
  const { search } = useLocation()

  const query = useMemo(() => {
    return new URLSearchParams(search)
  }, [search])

  const tab = useMemo(() => {
    return query.get('tab')  ?? tabs[0].label.toLowerCase()
  },[query, tabs])
  
  const setQueryString = (tabName: string) => {

    if (query.get('tab')) {
      query.set('tab', tabName)
    } else {
      query.append('tab', tabName)
    }

    history.push({
      search: query.toString()
    })
  }

  return (
    <Tabs
      activeKey={tab}
      size='small'
      style={{ overflow: 'visible' }}
      onChange={setQueryString}
      tabPosition={tabPosition}
      tabBarStyle={{ ...tabBarStyle, borderBottom: '1px solid #d0d7de' }}
      tabBarExtraContent={extraContent}
      // renderTabBar={screens.lg ? undefined :
      //   (props) => {
      //     return (
      //       <Space direction='horizontal' style={{ 
      //         marginBottom: 20
      //       }} size={'small'}>
      //         <Radio.Group 
      //           buttonStyle='solid'
      //           size='small'
      //           onChange={(input:any) => {
      //             //@ts-ignore
      //             props.onTabClick(input.target.value)
      //           }}
      //           value={props.activeKey}>
      //           {props.panes && //@ts-ignore
      //             props.panes.map((pane: any, i) =>
      //               <Tooltip title={pane.props.tab.props.children[1]}>
      //                 <Radio.Button type='primary' value={pane.key} key={`tabs-select-${i}`}>
      //                   {pane.props.tab.props.children[0]}
      //                 </Radio.Button>
      //               </Tooltip>
      //             )}
      //         </Radio.Group>
      //         {/* @ts-ignore */}
      //         {extraContent && <Col>{extraContent?.right}</Col>}
      //       </Space>
      //     )
      //   }
      // }
    >
      {
        tabs.map(({ icon, label, content, badge }) => (
          <Tabs.TabPane
            key={label.toLowerCase()}
            tab={
              <>
                {icon}
                {label}
                {badge && !screens.xs ? badge : null}
              </>
            }
          >
            {content}
          </Tabs.TabPane>
        ))
      }
    </Tabs>
  )

}


export default UrlTabs