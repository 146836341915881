import React, { CSSProperties, useMemo } from 'react'
import { Card, Row, Typography, Tooltip, Space, Progress } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Quota } from '@pollination-solutions/pollination-sdk'

import { formatStringLowercase } from '../../utils/formatValues'

import { InputLabel } from 'atoms'
import { QuotaLabel } from 'hooks/useQuotas'

export const cardColumnSettings: CSSProperties = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}

export interface QuotaCardProps {
  quota: Quota
  label: QuotaLabel
}

export const QuotaCard: React.FunctionComponent<QuotaCardProps> = ({ quota, label }) => {

  const usagePercent = useMemo(() => {
    if (quota.limit == undefined) {
      return 100
    } else if (!quota.usage) {
      return 0
    } else {
      return quota.usage / quota.limit
    }
  }, [quota])

  const isUnlimited = useMemo(() => {
    return quota.limit == undefined
  }, [quota])

  const usageText = useMemo(() => {
    if (quota.limit == undefined) {
      return 'You have an unlimited quota'
    } else {
      return `You have used ${Math.round(usagePercent * 100)}% of your quota limit.`
    }
  }, [quota, usagePercent])

  const limitText = useMemo(() => {
    if (isUnlimited) {
      return 'unlimited'
    } else {
      return label.format ? label.format(quota.limit, { units: true, pattern: 'HH:mm' }) : quota.limit?.toFixed(2)
    }
  }, [label, isUnlimited, quota])

  return (
    <Card key={label?.displayName + '-card'} title={label ? label.displayName : 'Quota'}
      extra={[
        <Tooltip placement='bottomRight' title={label?.description} trigger='hover' arrowPointAtCenter key={label.displayName + 'desc-tooltip'}>
          <InfoCircleOutlined onClick={() => undefined} />
        </Tooltip>
      ]}
      size={'small'}
      loading={!quota || typeof label === 'undefined'}
      bodyStyle={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}
      style={{ ...cardColumnSettings }}
    >
      <Row gutter={[0, 24]} style={{ height: '100%' }}>
        <Row>
          {
            label.type === 'usage' && !isUnlimited ?
              <Space direction='vertical'>
                <Typography.Text type='secondary'>{'You have used'}</Typography.Text>
                <Tooltip title={
                  `${typeof quota.usage === 'number' ?
                    label.format(quota.usage, { units: true, decimals: 4, pattern: 'HH:mm' }) :
                    label.format(0, { units: true, decimals: 4, pattern: 'HH:mm' })}
                    used of ${label.format(quota.limit, { units: true, decimals: 4, pattern: 'HH:mm' })} total`}
                  arrowPointAtCenter={true}
                  placement='right'
                >
                  <Space>
                    <Typography.Title level={5} style={{ marginBottom: 0, marginLeft: 0 }} code>
                      <strong>
                        {typeof quota.usage === 'number' ?
                          label.format(quota.usage, { units: true, pattern: 'HH:mm' }) :
                          label.format(0, { units: true, pattern: 'HH:mm' })
                        } / {label.format(quota.limit, { units: true, pattern: 'HH:mm' })}
                      </strong>
                    </Typography.Title>
                  </Space>
                </Tooltip>
                <Typography.Text type='secondary'>available through the end of your billing period.</Typography.Text>
              </Space>
              :
              <Typography.Text type='secondary'>Available to you</Typography.Text>
          }
        </Row>
        <Row justify='center' align='middle' style={{ width: '100%' }}>
          {
            label.type === 'usage' && !isUnlimited ?
              <InputLabel label={usageText} style={{ width: '100%' }} active={true} error={usagePercent > .95}>
                <Progress percent={usagePercent * 100} showInfo={false} status={(usagePercent < .95) ? 'active' : 'exception'} />
              </InputLabel>
              :
              <Typography.Title code style={{ color: '#2F90FF', textAlign: 'center' }} >{label.format(quota[label.type])}</Typography.Title>
          }
        </Row>
        <Row>
          <Space direction='vertical' size={24}>
            <Typography.Text type='secondary'>Your current Pollination Subscription includes {limitText} {formatStringLowercase(quota.display_name)}.</Typography.Text>
          </Space>
        </Row>
      </Row>
    </Card>
  )
}
