import React, { useCallback, useEffect, useState } from 'react'

import { ApplicationsApiListApplicationsRequest, ApplicationsApiUpdateApplicationRequest, ApplicationSortKey, ApplicationUpdate, SortEnum, ApplicationList, Application, ApplicationVersionList, ApplicationDeployment } from '@pollination-solutions/pollination-sdk'

import { useAuth } from 'auth-context'

const defaultQuery = {
  search: undefined,
  ids: undefined,
  names: undefined,
  owner: undefined,
  // @ts-ignore
  public: undefined,
  permissions: undefined,
  keywords: undefined,
  sortBy: ApplicationSortKey.UpdatedAt,
  sortOrder: SortEnum.Descending,
  page: 1,
  perPage: 25,
}

export const DEFAULT_DEPLOYMENT_CONFIG = {
  deployment_config: {
    login_required: true,
    cpu_limit: 1,
    memory_limit: 2000
  }
}

export const useApplications = (initialQuery?: ApplicationsApiListApplicationsRequest) => {

  const { client } = useAuth()

  const [query, setQuery] = useState<ApplicationsApiListApplicationsRequest>(initialQuery ?? defaultQuery)

  const [loading, setLoading] = useState(false)

  // app deployment
  const [deploymentLoading, setDeploymentLoading] = useState(true)
  const [deployment, setDeployment] = useState<ApplicationDeployment>()
  const [deploymentError, setDeploymentError] = useState()

  const fetchApplications = useCallback((query) => {
    return client.applications.listApplications(query)
      .then(({ data }) => data as ApplicationList)
  }, [client.applications])

  const [applications, setApplications] = useState<ApplicationList>()

  const mutateApplications = useCallback(() => {
    setLoading(true)

    fetchApplications({
      ...query,
      public: query._public
    })
      .then((data) => {
        setApplications(data)
      })
      .catch(err => {
        setLoading(false)
        return {} as ApplicationList
      })
      .finally(() => {
        setLoading(false)
      })

  }, [fetchApplications, query])

  useEffect(() => {
    mutateApplications()
  }, [mutateApplications])

  // owner and slug are used for querying a single application
  const [owner, setOwner] = useState<string>()
  const [slug, setSlug] = useState<string>()
  // TODO: starting to think that the client component should hold the selected application
  const selectApplication = useCallback((owner, slug) => {
    setOwner(owner)
    setSlug(slug)
  }, [])

  const [application, setApplication] = useState<Application>()

  const fetchApplication = useCallback(async (owner: string, slug: string) => {
    return client.applications.getApplication({ owner, slug })
      .then(({ data }) => {
        return data as Application
      })
  }, [client.applications])

  const mutateApplication = useCallback((owner, slug) => {
    if (!owner || !slug) return
    setLoading(true)
    fetchApplication(owner, slug)
      .then((app) => {
        setApplication(app)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        throw new Error(err)
      })
      .finally(() => setLoading(false))
  }, [fetchApplication])

  useEffect(() => {
    mutateApplication(owner, slug)
  }, [mutateApplication, owner, slug])

  const fetchApplicationVersions = useCallback((owner: string, slug: string, page = 1, perPage = 25) => {
    return client.applications.getApplicationVersions({ owner, slug, page, perPage })
      .then(({ data }) => data as ApplicationVersionList)
  }, [client.applications])

  const updateApplication = useCallback(async (owner: string, slug: string, applicationUpdate: ApplicationUpdate) => {

    setLoading(true)
    const request: ApplicationsApiUpdateApplicationRequest = {
      owner,
      slug,
      applicationUpdate,
    }
    return client.applications.updateApplication(request)
      .then(() => {
        mutateApplication(owner, slug)
      })
      .catch((e) => {
        throw new Error(e)
      })
      .finally(() => setLoading(false))
  }, [client.applications, mutateApplication])

  const fetchApplicationDeployment = useCallback(async () => {
    if (!owner || !slug) return

    setDeploymentLoading(true)

    client.applications.getApplicationDeployment({
      owner,
      slug
    })
      .then(({ data }) => {
        setDeployment(data)
      })
      .catch((e) => {
        setDeploymentError(e.message)
        setDeploymentLoading(false)
      })
      .finally(() => setDeploymentLoading(false))
  }, [owner, slug, client.applications])

  useEffect(() => {
    fetchApplicationDeployment()
  }, [fetchApplicationDeployment])

  return {
    loading,
    applications,
    application,
    deploymentLoading,
    deploymentError,
    deployment,
    mutateApplications,
    mutateApplication,
    query,
    setQuery,
    selectApplication,
    updateApplication,
    fetchApplicationVersions,
  }
}