import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { AxiosResponse } from 'axios'

import { Col, Popconfirm, Space, Typography, Button, Input, Card, Badge, Popover } from 'antd'
import { EditOutlined, QuestionCircleOutlined, RedoOutlined, SettingOutlined } from '@ant-design/icons'

import { AccountPublic, ActivationList, LicensePoolPublic, UserPublic } from '@pollination-solutions/pollination-sdk'

import { InputLabel } from 'atoms'

import useSWR from 'swr'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

import { LicensePoolMemberList, LicensePoolActivationsList, LicensePoolSummary } from 'molecules'

export interface LicensePoolAdminProps {
  loading: boolean
  pool: LicensePoolPublic
  grantPoolAccess: (poolId?: string | undefined, account?: UserPublic[] | undefined) => Promise<void>
  revokePoolAccess: (poolId?: string | undefined, accountName?: string | undefined) => void
  getActivations: (poolId: string) => Promise<ActivationList>
  cancelActivation: (poolId: string, activationId: string) => Promise<any>
  updateLicensePool: (poolId?: string | undefined, description?: string | undefined) => Promise<any>
  regenerateLicensePool: (poolId: string) => Promise<any>
  account?: AccountPublic
}

export const LicensePoolAdmin: React.FunctionComponent<LicensePoolAdminProps> = ({
  loading,
  pool,
  grantPoolAccess,
  revokePoolAccess,
  getActivations,
  cancelActivation,
  updateLicensePool,
  regenerateLicensePool,
  account }) => {

  const screens = useBreakpoint()

  const [activeKey, setActiveKey] = useState<string>('members')

  const [editDescription, setEditDescription] = useState<boolean>(!pool.description)

  const [updateLoading, setUpdateLoading] = useState<boolean>(false)
  const newDescription = useRef<string | undefined>()

  const { data: activations, mutate: mutateActivations } = useSWR(account ? [pool.id, 'getActivations'] : null, getActivations, { errorRetryCount: 3, revalidateOnFocus: true })

  useEffect(() => {
    if (activations && activations.resources.length > 0) {
      setActiveKey('activations')
    }
  }, [activations])

  const handleUpdateDescription = useCallback(() => {
    if (typeof newDescription.current !== 'undefined') {
      setUpdateLoading(true)
      updateLicensePool(pool?.id, newDescription.current).then((data) => {
        setUpdateLoading(false)
      })
      newDescription.current = undefined
      setEditDescription(false)
    }
  }, [pool, updateLicensePool])

  const tabList = useMemo(() => [
    {
      key: 'activations',
      tab: <Space><Badge count={activations?.resources.length} showZero style={{ color: '#242425', backgroundColor: '#e8eaec' }} />Activations</Space>,
    },
    {
      key: 'members',
      tab: <Space><Badge count={pool.accessors?.length} showZero style={{ color: '#242425', backgroundColor: '#e8eaec' }} />Members</Space>,
    },
  ], [activations?.resources.length, pool.accessors?.length])

  const settingsContent = useMemo(() => {
    return (
      <Space direction='vertical' style={{ width: '100%', minWidth: '400px' }} size='small'>
        <InputLabel label='Add a license pool description.'>
          <Input.Group style={{ width: '100%' }} compact>
            <Input
              placeholder='Enter a license pool description.'
              prefix={<EditOutlined style={{ marginRight: 8 }} />}
              defaultValue={pool?.description ? pool?.description : undefined}
              onChange={(val) => newDescription.current = val.target.value}
              disabled={updateLoading}
              onPressEnter={handleUpdateDescription}
              style={{ width: '70%' }}
              allowClear
            />
            <Button
              style={{ width: '30%' }}
              disabled={updateLoading}
              onClick={handleUpdateDescription}>
              Apply
            </Button>
          </Input.Group>
        </InputLabel>
        <Popconfirm
          title={<Space direction='vertical' size={4}><Typography.Text>All Plugin users will be asked to log in again.</Typography.Text><Typography.Text>Do you want to proceed?</Typography.Text></Space>}
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          onConfirm={() => {
            regenerateLicensePool(pool.id)
              .then(() => {
                mutateActivations()
              })
          }}
          okText={'Yes'}
          cancelText={'Cancel'}
        >
          <Button danger style={{ width: '100%' }}>
            Regenerate License Pool
            <RedoOutlined />
          </Button>
        </Popconfirm>
      </Space>
    )
  }, [handleUpdateDescription, mutateActivations, pool, regenerateLicensePool, updateLoading])

  const tabContent = useMemo(() => ({
    activations: {
      content:
        <LicensePoolActivationsList pool={pool} activations={activations} mutateActivations={mutateActivations} cancelActivation={cancelActivation} />
    },
    members: {
      content:
        <LicensePoolMemberList pool={pool} grantPoolAccess={grantPoolAccess} revokePoolAccess={revokePoolAccess} />
    },
  }), [pool, activations, mutateActivations, getActivations, cancelActivation, grantPoolAccess, revokePoolAccess])

  return (
    <div style={{ marginBottom: 16 }}>
      <Card
        loading={loading}
        title={<LicensePoolSummary pool={pool} account={account} activations={activations} />}
        tabList={tabList}
        onTabChange={key => {
          setActiveKey(key)
        }}
        tabBarExtraContent={
          <Popover content={settingsContent} placement={'bottomRight'} trigger={'click'} >
            <Button shape={screens.lg ? undefined : 'circle'} icon={<SettingOutlined />}>{screens.lg ? 'Settings' : ''}</Button>
          </Popover>
        }
        tabProps={{
          activeKey: activeKey,
        }}
      >
        {tabContent[activeKey].content}
      </Card>
    </div>
  )
}
