import React, { FC } from 'react'

import { ApplicationsApiListApplicationsRequest, ProjectsApiListProjectsRequest, RecipesApiListRecipesRequest, SortEnum } from '@pollination-solutions/pollination-sdk'

import { MoreOutlined } from '@ant-design/icons'
import { Button } from 'antd'

import { Dropdown, OptionGroup } from 'pollination-react-io'

interface QueryDropdownProps {
  sortEnum: any //ApplicationSortKey | ProjectSortKey | PackageSortKey,
  query: ApplicationsApiListApplicationsRequest | ProjectsApiListProjectsRequest | RecipesApiListRecipesRequest,
  setQuery: (state: any) => any,
  filter?: Array<boolean | undefined>
}

export const QueryDropdown: FC<QueryDropdownProps> = ({ sortEnum, query, setQuery, filter }) => {

  const optionGroups: OptionGroup[] = [
    {
      type: 'radio',
      value:query._public ? 'public' : typeof query._public === 'undefined' ? 'all' : 'private',
      options: [{
        type:'label',
        id: 'public-label',
        label: 'Public',
      },{
        type:'radio',
        id: 'all',
        label: 'All',
        onSelect: ( { id }) => {
          setQuery(state => ({ 
            ...state, 
            _public: id === 'public' ? true : id === 'private' ? false : undefined, 
          }))
        }
      },{
        type:'radio',
        id: 'public',
        label: 'Public',
        onSelect: ( { id }) => {
          setQuery(state => ({ 
            ...state, 
            _public: id === 'public' ? true : id === 'private' ? false : undefined, 
          }))
        }
      },{
        type:'radio',
        id: 'private',
        label: 'Private',
        onSelect: ( { id }) => {
          setQuery(state => ({ 
            ...state, 
            _public: id === 'public' ? true : id === 'private' ? false : undefined, 
          }))
        }
      }],
    },{
      type: 'radio',
      value: query.sortBy,
      options: [
        {
          type: 'label',
          id: 'sort-by',
          label: 'SortBy'
        }, 
        // @ts-ignore
        ...Object.values(sortEnum).map((key, i) => ({
          type: 'radio',
          id: (key as string),
          label: (key as string).split('_').map(v => v.charAt(0).toUpperCase() + v.slice(1)).join(' '),
          onSelect: ({ id }) => {
            setQuery(state => ({
              ...state,
              sortBy: id,
            }))
          }
        }))
      ]
    },{
      type: 'radio',
      value: query.sortOrder,
      options: [{
        type:'label',
        id: 'sort-order-label',
        label: 'Sort Order',
      },{
        type:'radio',
        id: SortEnum.Descending,
        label: SortEnum.Descending.charAt(0).toUpperCase() + SortEnum.Descending.slice(1),
        onSelect: ({ id }) => {
          setQuery(state => ({
            ...state,
            sortOrder: id,
          }))
        }
      },{
        type:'radio',
        id: SortEnum.Ascending,
        label: SortEnum.Ascending.charAt(0).toUpperCase() + SortEnum.Ascending.slice(1),
        onSelect: ({ id }) => {
          setQuery(state => ({
            ...state,
            sortOrder: id,
          }))
        }
      }],
    },
  ]

  return (
    <Dropdown
      trigger={<Button shape='circle' icon={<MoreOutlined />} type='text'/>}
      optionGroups={filter 
        ? optionGroups.filter((v, i) => filter[i]) 
        : optionGroups}
      contentProps={{
        align:'end',
        style:{
          zIndex: 1000
        }
      }}
    />
  )
}