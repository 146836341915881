import React from 'react'
import { Spin } from 'antd'

export const Loading = () => {
  return (
    <div className="p-loader">
      <Spin />
      <style>{`
          .p-loader {
            padding: 24px 48px;
            min-height: 96px;
            min-width: 96px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        `}</style>
    </div>
  )
}

export default Loading