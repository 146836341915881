import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Row, Space, Typography, Table, Select } from 'antd'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { ClockCircleOutlined } from '@ant-design/icons'
import { useAuth } from 'auth-context'
import { Run, RunStatusEnum, RecipeInterface, CloudJob } from '@pollination-solutions/pollination-sdk'
import { TableIORender, statusIcon, getDuration } from './utils'

dayjs.extend(duration)

export interface RunListProps {
  projectOwner: string
  projectName: string
  job: CloudJob
  recipe?: RecipeInterface
}

/** Displays an expandable list of project runs and shows usage statistics about them. */
const RunList: React.FunctionComponent<RunListProps> = ({ job, projectOwner, projectName }) => {
  const { client } = useAuth()
  const jobId = job.id
  const { recipe } = job
  // Manage list state
  const [pageSize, setPageSize] = useState<number>(20)
  const [page, setPage] = useState<number>(1)
  const [total, setTotal] = useState<number>(0)

  const [isLoading, setIsLoading] = useState(false)
  const [runs, setRuns] = useState<Run[]>([])
  const [toggleFetch, setToggleFetch] = useState<boolean>(false)
  const [statusFilter, setStatusFilter] = useState<RunStatusEnum | undefined>()

  const [columns, setColumns] = useState<ColumnsType[]>([])
  const [rows, setRows] = useState<any[]>([])

  useEffect(() => {
    let cols = [
      {
        title: 'run id',
        dataIndex: 'id',
        render: (text: string) => {
          return (
            <Link to={`/${projectOwner}/projects/${projectName}/studies/${jobId}/runs/${text}`}>
              <Typography.Link>{text.split('-')[0]}</Typography.Link>
            </Link>
          )
        }
      },
      {
        title: 'status',
        dataIndex: 'status',
        render: (text: RunStatusEnum) => (
          <Space wrap>
            {statusIcon(text)}
            <Typography.Text>{text}</Typography.Text>
          </Space>
        ),
      },
      {
        title: 'duration',
        dataIndex: 'duration'
      },
    ] as any[]

    let recipeInputs = [] as string[]

    if (recipe && recipe.inputs) {
      recipeInputs = recipe.inputs.map(i => i.name)
    }

    const extraInputCols = {} as { [key: string]: any }

    const inputCols = {} as { [key: string]: any }

    const outputCols = {} as { [key: string]: any }

    const rows = [] as any[]

    runs.forEach((r) => {
      if (r.status) {
        const s = r.status

        const row = {
          status: s.status,
          duration: getDuration(s),
          id: s.id,
        } as { [key: string]: any }

        s.inputs.forEach((input) => {
          row[`inputs-${input.name}`] = input

          if (recipeInputs.includes(input.name)) {
            inputCols[input.name] = {
              title: input.name,
              dataIndex: `inputs-${input.name}`,
              render: (data: any) => data && TableIORender(data, `${projectOwner}/projects/${projectName}`, s.job_id)
            }
          } else {
            extraInputCols[input.name] = {
              title: input.name,
              dataIndex: `inputs-${input.name}`,
              render: (data: any) => data && TableIORender(data, `${projectOwner}/projects/${projectName}`, s.job_id)
            }
          }


        })

        s.outputs.forEach((output) => {
          row[`outputs-${output.name}`] = output
          outputCols[output.name] = {
            title: output.name,
            dataIndex: `outputs-${output.name}`,
            render: (data: any) => data && TableIORender(data, `${projectOwner}/projects/${projectName}`, s.job_id)
          }
        })

        rows.push(row)
      }
    })

    if (Object.keys(extraInputCols).length !== 0) {
      cols = [
        ...cols,
        {
          title: 'extra inputs',
          children: Object.keys(extraInputCols).map(k => extraInputCols[k])
        },
      ]
    }

    cols = [
      ...cols,
      {
        title: 'inputs',
        children: Object.keys(inputCols).map(k => inputCols[k])
      },
      {
        title: 'outputs',
        children: Object.keys(outputCols).map(k => outputCols[k])
      }
    ]

    setColumns(cols)
    setRows(rows)

  }, [runs])

  useEffect(() => {
    const timer = setTimeout(() => {
      client.runs.listRuns({
        owner: projectOwner,
        name: projectName,
        status: statusFilter,
        jobId: jobId ? [jobId] : undefined,
        page,
        perPage: pageSize
      }).then(({ data }) => {
        setRuns(data.resources)
        setTotal(data.total_count)
        setIsLoading(false)
        if (!job.status || !job.status.finished_at) {
          setToggleFetch(!toggleFetch)
        }
      })
    }, 5000)

    return () => {
      clearTimeout(timer)
    }
  }, [jobId, toggleFetch])

  useEffect(() => {
    setIsLoading(true)
    client.runs.listRuns({
      owner: projectOwner,
      name: projectName,
      status: statusFilter,
      jobId: jobId ? [jobId] : undefined,
      page: 1,
      perPage: pageSize
    }).then(({ data }) => {
      setRuns(data.resources)
      setTotal(data.total_count)
      setIsLoading(false)
    })
  }, [statusFilter])

  useEffect(() => {
    setIsLoading(true)
    client.runs.listRuns({
      owner: projectOwner,
      name: projectName,
      status: statusFilter,
      jobId: jobId ? [jobId] : undefined,
      page,
      perPage: pageSize
    }).then(({ data }) => {
      setRuns(data.resources)
      setTotal(data.total_count)
      setIsLoading(false)
    })
  }, [page, pageSize])

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row justify="space-between">
        <Space>
          <ClockCircleOutlined style={{ fontSize: '24px' }} />
          <Typography.Text strong>Runs</Typography.Text>
        </Space>
        <Select
          allowClear
          placeholder="Filter by status"
          onClear={() => setStatusFilter(undefined)}
          onSelect={(value: RunStatusEnum) => {
            setPage(1)
            setStatusFilter(value)
          }}
          style={{ minWidth: '200px' }}
        >
          <Select.Option value={RunStatusEnum.Created}>{RunStatusEnum.Created}</Select.Option>
          <Select.Option value={RunStatusEnum.Scheduled}>{RunStatusEnum.Scheduled}</Select.Option>
          <Select.Option value={RunStatusEnum.Running}>{RunStatusEnum.Running}</Select.Option>
          <Select.Option value={RunStatusEnum.PostProcessing}>{RunStatusEnum.PostProcessing}</Select.Option>
          <Select.Option value={RunStatusEnum.Failed}>{RunStatusEnum.Failed}</Select.Option>
          <Select.Option value={RunStatusEnum.Cancelled}>{RunStatusEnum.Cancelled}</Select.Option>
          <Select.Option value={RunStatusEnum.Succeeded}>{RunStatusEnum.Succeeded}</Select.Option>
          <Select.Option value={RunStatusEnum.Unknown}>{RunStatusEnum.Unknown}</Select.Option>
        </Select>
      </Row>
      <Table
        // @ts-ignore
        columns={columns}
        dataSource={rows}
        loading={isLoading}
        bordered
        scroll={{
          x: true,
        }}
        pagination={{
          onChange: page => {
            setPage(page)
          },
          showSizeChanger: true,
          onShowSizeChange: (c, size) => setPageSize(size),
          pageSize,
          total,
        }}
      />
    </Space>
  )
}

export default RunList
