import React from 'react'
import { Dropdown } from 'pollination-react-io'

import styles from './Header.module.css'
import { Avatar } from 'antd'
import { DollarCircleOutlined, GithubOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import { discourseIcon, twitterIconX, userManual } from 'atoms/Icons'

import logo from './assets/logo_bw_1.svg'

interface HelpOptionsProps {
  disabled: boolean
}

export const HelpOptions = ({
  disabled
}): React.ReactElement<HelpOptionsProps> => {

  return (
    <Dropdown
    disabled={disabled}
    trigger={
      <button className={styles['header-avatar-button']}>
        <Avatar icon={<QuestionCircleOutlined style={{ 
            color: '#333',
            fontSize: 20
           }}/>} 
          size={30} 
          shape='circle'
          className={styles['header-avatar-trigger']}
          style={{ backgroundColor: 'rgba(0,0,0,0)' }}
        />
      </button>
    }
    optionGroups={[
      {
        options: [
          {
            type: 'link',
            label: 'Discourse',
            id: 'discourse',
            icon: discourseIcon(),
            to: 'https://discourse.pollination.solutions/',
          },
          {
            type: 'link',
            label: 'User Manual',
            id: 'user-manual',
            icon: userManual(),
            to: 'https://docs.pollination.solutions/user-manual',
          }
        ]
      },
      {
        options: [
          {
            type: 'link',
            label: 'Website',
            id: 'website',
            icon: <img src={logo} style={{ color: '#000', 
              height: '16px',
            }} 
              alt='Pollination logo.' />,
            to: 'https://www.pollination.solutions',
          },
          {
            type: 'link',
            label: 'Pricing',
            id: 'pricing',
            icon: <DollarCircleOutlined />,
            to: 'https://www.pollination.solutions/pricing',
          }
        ]
      },
      {
        options: [
          {
            type: 'link',
            label: 'Github',
            id: 'github',
            icon: <GithubOutlined />,
            to: 'https://github.com/pollination',
          },
          {
            type: 'link',
            label: 'Twitter',
            id: 'twitter',
            icon: twitterIconX(),
            to: 'https://twitter.com/_pollination',
          },

        ]
      }]}
    contentProps={{
      align: 'end',
      sideOffset: -8,
      style: {
        borderColor: 'var(--primary)',
        zIndex: 1000,
        lineHeight: '1.25rem',
      }
    }}
  />)
}
