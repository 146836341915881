import React, { useEffect, useMemo } from 'react'
import { Form, Input, Select, Row, Col } from 'antd'
import { AccountPublic } from '@pollination-solutions/pollination-sdk'

import { AvatarLabel } from 'atoms'

type Props = {
    accounts: AccountPublic[]
    onSelect?: (value: AccountPublic) => void
    disabled?: boolean
}

const FormItemOwnerName = ({ accounts, onSelect, disabled }: Props) => {
    const initialAccount = useMemo(() => accounts.length > 0 ? accounts[0] : undefined, [accounts])
    useEffect(() => {
        if (initialAccount && onSelect) {
            onSelect(initialAccount)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Row align='middle' gutter={16}>
            <Col span={12}>
                <Form.Item
                    name="owner"
                    label="Owner"
                    rules={[{ required: true }]}
                    initialValue={initialAccount?.name}
                >
                    <Select
                        disabled={disabled}
                        onSelect={(accountName: string) => {
                            const selectedAccount = accounts.find((account) => account.name === accountName)
                            if (selectedAccount && onSelect) {
                                return onSelect(selectedAccount)
                            }

                        }}>
                        {accounts.map((account) => (
                            <Select.Option key={account.id} value={account.name}>
                                <AvatarLabel account={account} />
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
            </Col>
        </Row >
    )
}

export default FormItemOwnerName