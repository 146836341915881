import { JsonViewer } from './JsonViewer'
import { ImageViewer } from './ImageViewer'
import { HDRViewer } from './HDRViewer'
import CSVViewer from './CSVViewer'
import RawViewer from './Raw'
import { VTKPollination } from './VTKPollination'

export const getDriver = (fileType: string, screens: any) => {
  switch (fileType.toLowerCase()) {
    case 'hbjson':
      return VTKPollination
    case 'json':
    case 'osw':
      return JsonViewer
    case 'jpg':
    case 'png':
    case 'gif':
    case 'svg':
      return ImageViewer
    case 'hdr':
      return HDRViewer
    case 'csv':
    case 'ill':
    case 'da':
    case 'cda':
    case 'udi':
    case 'res':
      return !screens.xs ? CSVViewer : RawViewer
    case 'vtp':
      return VTKPollination
    case 'vtkjs':
      return VTKPollination
    case 'vsf':
      return VTKPollination
    case 'log':
    case 'txt':
    case 'idf':
    case 'osm':
    case 'rad':
    case 'mat':
    case 'err':
    case 'eio':
    case 'rdd':
    case 'mdd':
    case 'eso':
    case 'mtd':
    case 'shd':
    case 'audit':
    case 'bnd':
    case 'wea':
    case 'epw':
      return RawViewer
    default:
      return null
  }
}
