import React, { useCallback, useState } from 'react'

import { Col, Divider, List, Space, Typography, Button, Tooltip, notification } from 'antd'
import { DeleteOutlined, UserAddOutlined } from '@ant-design/icons'

import { LicensePoolPublic, OrganizationMember, UserPublic } from '@pollination-solutions/pollination-sdk'

import { InputLabel } from 'atoms'
import { SearchMembers } from 'atoms'

import { AvatarLink } from 'atoms'
export interface LicensePoolMemberListProps {
  pool: LicensePoolPublic
  grantPoolAccess: (poolId?: string | undefined, user?: UserPublic[] | undefined) => Promise<void>
  revokePoolAccess: (poolId?: string | undefined, accountName?: string | undefined) => void
}

export const LicensePoolMemberList: React.FunctionComponent<LicensePoolMemberListProps> = ({ pool, grantPoolAccess, revokePoolAccess, }) => {

  const [membersToAdd, setMembersToAdd] = useState<OrganizationMember[]>([])

  const [updateMembersLoading, setUpdateMembersLoading] = useState<boolean>(false)

  const handleUpdateMembers = useCallback(() => {
    if (!pool) return
    setUpdateMembersLoading(true)
    grantPoolAccess(pool.id, membersToAdd.map(m => m.user))
      .then(() => {
        setMembersToAdd([])
        setUpdateMembersLoading(false)
      })
      .catch(error => {
        notification.error({
          message: error.data.details
        })
      })
  }, [grantPoolAccess, pool, membersToAdd])

  return (
    <List
      style={{ width: '100%' }}
      loading={updateMembersLoading}
      dataSource={pool.accessors}
      header={
        <Col span={24}>
          <Space style={{ width: '100%' }} direction='vertical'>
            <Typography.Text>Members who have access to this license pool.</Typography.Text>
            {pool.owner.account_type === 'org' &&
              <Space>
                <AvatarLink src={pool.owner.picture_url} link={`/${pool.owner.name}`} />
                <Divider type='vertical' />
                <Typography.Text type='secondary'>In addition to the users below, owners of {pool.owner.display_name} have access to this license pool.</Typography.Text>
              </Space>
            }
          </Space>
        </Col>
      }
      footer={
        pool.owner.account_type === 'org' &&
        <InputLabel label='Add License Pool Members' >
          <Space align='center'>
            <Button icon={<UserAddOutlined />} shape='circle'
              disabled={!membersToAdd.length}
              type='primary'
              onClick={handleUpdateMembers} />
            <Divider type='vertical' />
            <SearchMembers style={{ minWidth: 250 }} organizationName={pool.owner.name} value={membersToAdd} setParentMember={setMembersToAdd} multiple={true} maxTags={4} />
          </Space>
        </InputLabel>
      }
      renderItem={(item, i) =>
        <>
          <List.Item actions={[
            <Tooltip key={item.subject.id} title='Remove Access'>
              <Button shape='circle'
                onClick={() => {
                  if (!pool) return
                  revokePoolAccess(pool.id, item.subject.name)
                }}
                danger
                ghost
              >
                <DeleteOutlined style={{ display: 'flex', justifyContent: 'center' }} />
              </Button>
            </Tooltip>
          ]}>
            <List.Item.Meta
              // @ts-ignore
              avatar={<AvatarLink src={item.subject.picture_url} link={`/${item.subject.name}`} />}
              // @ts-ignore
              title={item.subject.display_name}
            />
          </List.Item>
        </>
      }
    />
  )
}
