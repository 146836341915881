import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useAnalytics, useUser, useAuth as useFirebaseAuth } from 'reactfire'
import { User } from 'firebase/auth'
import { setUserProperties } from 'firebase/analytics'

import {
  Configuration,
  UserPrivate,
  ConfigurationParameters,
  AccountsApi,
  APITokensApi,
  UserApi,
  UsersApi,
  OrgsApi,
  TeamsApi,
  PluginsApi,
  RecipesApi,
  ProjectsApi,
  RunsApi,
  JobsApi,
  ArtifactsApi,
  RegistriesApi,
  SubscriptionsApi,
  LicensesApi,
  SubscriptionPlansApi,
  ApplicationsApi,
  ReportsApi
} from '@pollination-solutions/pollination-sdk'

export class APIClient {
  config: Configuration
  accounts: AccountsApi
  user: UserApi
  apiKeys: APITokensApi
  users: UsersApi
  orgs: OrgsApi
  teams: TeamsApi
  plugins: PluginsApi
  recipes: RecipesApi
  registries: RegistriesApi
  projects: ProjectsApi
  jobs: JobsApi
  runs: RunsApi
  artifacts: ArtifactsApi
  subscriptionPlans: SubscriptionPlansApi
  subscriptions: SubscriptionsApi
  licenses: LicensesApi
  applications: ApplicationsApi
  reports: ReportsApi

  constructor(props: ConfigurationParameters) {
    this.config = new Configuration(props)
    this.accounts = new AccountsApi(this.config)
    this.user = new UserApi(this.config)
    this.apiKeys = new APITokensApi(this.config)
    this.users = new UsersApi(this.config)
    this.orgs = new OrgsApi(this.config)
    this.teams = new TeamsApi(this.config)
    this.plugins = new PluginsApi(this.config)
    this.recipes = new RecipesApi(this.config)
    this.registries = new RegistriesApi(this.config)
    this.projects = new ProjectsApi(this.config)
    this.jobs = new JobsApi(this.config)
    this.runs = new RunsApi(this.config)
    this.artifacts = new ArtifactsApi(this.config)
    this.subscriptionPlans = new SubscriptionPlansApi(this.config)
    this.subscriptions = new SubscriptionsApi(this.config)
    this.licenses = new LicensesApi(this.config)
    this.applications = new ApplicationsApi(this.config)
    this.reports = new ReportsApi(this.config)
  }

  private init(): void {
    this.accounts = new AccountsApi(this.config)
    this.user = new UserApi(this.config)
    this.apiKeys = new APITokensApi(this.config)
    this.users = new UsersApi(this.config)
    this.orgs = new OrgsApi(this.config)
    this.teams = new TeamsApi(this.config)
    this.plugins = new PluginsApi(this.config)
    this.recipes = new RecipesApi(this.config)
    this.registries = new RegistriesApi(this.config)
    this.projects = new ProjectsApi(this.config)
    this.jobs = new JobsApi(this.config)
    this.runs = new RunsApi(this.config)
    this.artifacts = new ArtifactsApi(this.config)
    this.subscriptionPlans = new SubscriptionPlansApi(this.config)
    this.subscriptions = new SubscriptionsApi(this.config)
    this.licenses = new LicensesApi(this.config)
    this.applications = new ApplicationsApi(this.config)
    this.reports = new ReportsApi(this.config)
  }

  /** Overwrite current configuration and re-create all api clients. */
  configure(basePath?: string, token?: string): void {
    this.config = new Configuration({ basePath, accessToken: token })
    this.init()
  }
}

interface AuthContextProps {
  user?: UserPrivate
  setUser: (user: UserPrivate) => void
  refreshIdToken: () => Promise<void>
  logout: () => void
  idToken?: string
  client: APIClient
  loading: boolean
  registered: boolean
  loggedIn: boolean
}

const AuthContext = React.createContext<AuthContextProps>({} as AuthContextProps)

interface AuthProviderProps {
  children?: React.ReactNode
}

const basePath = process.env.REACT_APP_API_BASE_PATH || 'https://api.staging.pollination.solutions'

/** Provides and updates session auth objects. */
export const AuthProvider = ({ children }: AuthProviderProps): React.ReactElement => {
  const analytics = useAnalytics()
  const auth = useFirebaseAuth()

  const authUser = useUser({
    initialData: {
      loading: true
    }
  })

  // Initialize with user undefined
  const [user, setUser] = useState<UserPrivate>()
  const [loading, setLoading] = useState<boolean>(true)
  const [loggedIn, setLoggedIn] = useState<boolean>(false)
  const [registered, setRegistered] = useState<boolean>(false)

  const [idToken, setIdToken] = useState<string | undefined>()

  // https://github.com/AntoineDao/lavender/blob/62d7a4bc3e3a848507c18839ce00a30930365eb4/src/auth-context.tsx#L135-L156
  useEffect(() => {
    // this runs when useEffect is initially invoked
    // so we get the user idToken without having to wait a full second
    if (authUser.data && authUser.data.getIdToken) {
      authUser.data.getIdToken().then((token) => {
        setIdToken(token)
      })
    }

    // TODO: factor this nonsense out
    const unsubscribe = setInterval(() => {
      if (!registered) return
      if (authUser.data && authUser.data.getIdToken) {
        authUser.data.getIdToken().then((token) => {
          setIdToken(token)
        })
      }
    }, 1000)

    // clears interval when authContext is unmounted
    return () => {
      clearInterval(unsubscribe)
    }
  }, [authUser.data, registered])

  const client = useMemo(() => new APIClient({ basePath, accessToken: idToken }), [idToken])

  const updateUser = useCallback(async (authUser: User, refreshToken: boolean | undefined) => {
    if (!authUser.getIdToken) return
    const accessToken = await authUser.getIdToken(refreshToken)
    const newClient = new APIClient({
      basePath,
      accessToken
    })
    try {
      const { data } = await newClient.user.getMe()
      setUser(data)
      setUserProperties(analytics, { email_domain: data.email.split('@')[1] })
      setRegistered(true)
    } catch (err) {
      setRegistered(false)
    }
    setLoggedIn(true)
    setLoading(false)
  }, [analytics])

  const refreshIdToken = useCallback(async () => {
    if (!authUser.data) return
    return updateUser(authUser.data, true)
      .then(() => {
        if (!authUser.data || !authUser.data.reload) return
        authUser.data.reload()
      })
  }, [authUser.data, updateUser])

  const logout = () => {
    auth.signOut()
  }

  useEffect(() => {
    if (!authUser.data) {
      setLoading(false)
      setLoggedIn(false)
      setRegistered(false)
      setUser(undefined)
      // @ts-ignore - initialData sets authUser.data.loading to true
    } else if (authUser.data.loading) {
      setLoading(true)
      setLoggedIn(false)
      setRegistered(false)
    } else {
      updateUser(authUser.data, false)
    }
  }, [authUser.data, updateUser])

  return (
    <AuthContext.Provider value={{
      user,
      setUser,
      refreshIdToken,
      logout,
      idToken,
      client,
      loading,
      loggedIn,
      registered
    }}>{children}</AuthContext.Provider>
  )
}

export const useAuth = (): AuthContextProps => {
  return useContext(AuthContext)
}
