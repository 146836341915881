import React from 'react'

interface CheckoutProps {
  url: string
  onSuccess: (data: any, err: any) => void
}


export const usePaddle = () => {
  // @ts-ignore
  const Paddle = window.Paddle

  const openCheckout = (props: CheckoutProps) => {
    Paddle.Checkout.open({
      override: props.url,
      successCallback: props.onSuccess
    })
  }

  return { openCheckout }
}

export default usePaddle