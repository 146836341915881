import React, { useState } from 'react'
import { List, notification } from 'antd'
import { Repository, RepositorySortKey } from '@pollination-solutions/pollination-sdk'
import { useAuth } from 'auth-context'
import usePaginatedList, { PaginatedQuery } from 'hooks/paginatedList'
import { RepositoryCard } from 'components/Repository'

import TabsToolbar from 'components/TabsToolbar'

interface Props {
  owner: string
}

const RecipeGallery = (props: Props) => {
  const { client } = useAuth()

  const [recipes, setRecipes] = useState<Repository[]>([])
  const [total, setTotal] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const [pageSize, setPageSize] = useState<number>(10)

  const queryApi = (query: PaginatedQuery) => {
    setLoading(true)
    return client.recipes.listRecipes({
      search: query.search ? [query.search] : undefined,
      owner: [props.owner],
      _public: query.isPublic,
      sortBy: query.sortBy,
      sortOrder: query.sortOrder,
      page: query.page,
      perPage: query.perPage,
    }).then(({ data }) => {
      setTotal(data.total_count)
      setRecipes(data.resources)
      setLoading(false)
    }).catch((err) => {
      notification.error({
        message: err.response.data.detail,
      })
      setLoading(false)
    })
  }

  const { updateQuery, setPage } = usePaginatedList({
    queryApi,
    defaultSortKey: RepositorySortKey.UpdatedAt,
    perPage: pageSize
  })


  return (
    <>
      <TabsToolbar
        updateQuery={updateQuery}
        sortEnum={RepositorySortKey}
        defaultSortKey={RepositorySortKey.UpdatedAt}
        searchPlaceholder="Search Recipes..."
      />
      <List
        dataSource={recipes}
        renderItem={(item) => <RepositoryCard reference={item} type="recipe" />}
        loading={loading}
        pagination={{
          onChange: page => {
            setPage(page)
          },
          showSizeChanger: true,
          onShowSizeChange: (c, size) => setPageSize(size),
          pageSize,
          total
        }}
      />
    </>
  )
}

export default RecipeGallery
