import React from 'react'

import { Card, List, Col, Row, Typography, Divider, Statistic, Grid } from 'antd'
import { CheckCircleFilled, InfoCircleFilled } from '@ant-design/icons'

import { ProductCard, UpgradeTemplateProps } from './ProductsTemplate.types'

import ProductTemplate from 'templates/ProductTemplate'
import FAQs from 'atoms/FAQs'
import numbro from 'numbro'

const UpgradeTemplate: React.FunctionComponent<UpgradeTemplateProps> = ({ title, cards, faqs }) => {

  const screens = Grid.useBreakpoint()

  const getCost = (item: ProductCard) => {
    if (typeof item.priceOverride !== 'undefined') {
      return numbro(item.priceOverride / 100).formatCurrency({
        mantissa: 2,
        optionalMantissa: false,
        trimMantissa: false
      })
    } else {
      return 0
    }
  }

  return (
    <Row gutter={[0, 24]}>
      <ProductTemplate 
        title={title.title} 
        subtitle={title.subtitle} 
        footer={title.footer && <Typography.Link href='https://discourse.pollination.solutions/' target='_blank' style={{ textAlign: 'center' }}>{title.footer}</Typography.Link>}>
        <Row justify={!screens.lg ? 'center' : 'space-between'} style={{ marginTop: 12, 
          height: '100%', 
          width: '100%' }} gutter={[24, 24]} wrap>
          {
            cards.map((item, i) => (
              <Col span={!screens.lg ? 20 : 8} style={!screens.lg ? { width: '75%' } : undefined} key={i}>
                <Card
                  style={{ width: '100%', height: '100%', boxShadow: '0 3px 6px rgba(140,149,159,0.15)' }}
                  bodyStyle={{ height: '100%' }}
                >
                  <Row justify='center' gutter={[0, 12]} style={{ height: '100%' }}>
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <Typography.Title level={3}>{item.title}</Typography.Title>
                      <Typography.Text type='secondary' style={{ textAlign: 'center' }}>{item.description}</Typography.Text>
                      <Divider />
                    </Col>
                    <Col span={20}>
                      <List
                        dataSource={item.features}
                        renderItem={(text, index) => {
                          return (
                            <>
                              <List.Item style={{ justifyContent: 'flex-start' }}><CheckCircleFilled style={{ color: '#2da44e', marginRight: '8px' }} /><Typography.Text>{text}</Typography.Text></List.Item>
                              {(item.features && index === item.features?.length - 1) &&
                                <List.Item style={{ justifyContent: 'flex-start' }}><InfoCircleFilled style={{ color: '#1b90ff', marginRight: '8px' }} /><Typography.Link href={item.moreInfoLink} target='_blank'>Learn More</Typography.Link></List.Item>
                              }
                            </>
                          )
                        }}
                      />
                    </Col>
                    <Row justify='center' align='bottom'>
                      {item.action}
                    </Row>
                  </Row>
                </Card>
              </Col>
            ))
          }
        </Row >
      </ProductTemplate >
      <Divider />
      <Col lg={24} xl={{ span: 16, offset: 4 }} style={{ marginTop: 16 }}>
        <FAQs faqs={faqs} />
        <Row style={{ marginTop: 48 }} justify='center'>
          <Typography.Link href='https://discourse.pollination.solutions/' target='_blank' referrerPolicy='no-referrer' style={{ textAlign: 'center' }}>{'Didn\'t find your question here? Ask on our forums!'}</Typography.Link>
        </Row>
      </ Col>
    </Row >
  )
}

export default UpgradeTemplate
