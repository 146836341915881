import React, { useCallback, useEffect, useMemo } from 'react'
import { Divider, Col, Row, Skeleton } from 'antd'

import { AccountPublic, AccountType, PlanType, Subscription } from '@pollination-solutions/pollination-sdk'

import { UsageSummaryDashboard } from 'organisms'
import { useAccountSubscriptions } from 'hooks'
import { SubscriptionCard } from 'molecules'
import { Feature } from 'atoms'

enum PlanGroup {
  Cloud = 'cloud',
  Plugin = 'plugin',
}

interface SubscriptionProps {
  account: AccountPublic
}

interface ProductType {
  group: PlanGroup
  type: PlanType
  feature?: string
}

const ACTIVE_PRODUCTS: ProductType[] = [
  {
    group: PlanGroup.Cloud,
    type: PlanType.Cloud,
  },
  {
    group: PlanGroup.Cloud,
    type: PlanType.Seat,
  },
  {
    group: PlanGroup.Cloud,
    type: PlanType.CloudCompute,
  },
  {
    group: PlanGroup.Plugin,
    type: PlanType.RhinoPlugin,
  },
  {
    group: PlanGroup.Plugin,
    type: PlanType.RevitPlugin,
  },
  {
    group: PlanGroup.Plugin,
    type: PlanType.BundledPlugin,
  },
  {
    group: PlanGroup.Cloud,
    type: PlanType.Application,
  },
]

interface SubscriptionGroupProps {
  subscribed: ProductType[]
  notSubscribed: ProductType[]
  account: AccountPublic
}
const SubscriptionGroup = ({ subscribed, notSubscribed, account }: SubscriptionGroupProps) => {
  const { getForPlanType } = useAccountSubscriptions(account.name)

  return (
    <Row gutter={[24, 24]}>
      {subscribed.map(product => {
        const res = getForPlanType(product.type, account.account_type as AccountType)
        return res.plans.length !== 0 && (
          <Col span={24}>
            <SubscriptionCard {...res} />
          </Col>
        )
      })}
      {notSubscribed.map(product => {
        const res = getForPlanType(product.type, account.account_type as AccountType)
        return res.plans.length !== 0 && (
          <Col span={24}>
            <SubscriptionCard {...res} />
          </Col>
        )
      })}
    </Row>
  )
}

const Subscription = ({ account }: SubscriptionProps): React.ReactElement => {
  const { loading, getForPlanType } = useAccountSubscriptions(account.name)

  const { cloudSubscribed, cloudNotSubscribed } = useMemo(() => {
    // @ts-ignore
    return ACTIVE_PRODUCTS.reduce((p, product) => {
      if (product.group != PlanGroup.Cloud) {
        return p
      }
      const { subscription } = getForPlanType(product.type, account.account_type as AccountType)
      if (subscription) {
        p.cloudSubscribed.push(product)
      } else {
        p.cloudNotSubscribed.push(product)
      }
      return p
    }, { cloudSubscribed: [] as any[], cloudNotSubscribed: [] as any[] })
  }, [account.account_type, getForPlanType])

  const { pluginSubscribed, pluginNotSubscribed } = useMemo(() => {
    // @ts-ignore
    return ACTIVE_PRODUCTS.reduce((p, product) => {
      if (product.group != PlanGroup.Plugin) {
        return p
      }
      const { subscription } = getForPlanType(product.type, account.account_type as AccountType)
      if (subscription) {
        p.pluginSubscribed.push(product)
      } else {
        p.pluginNotSubscribed.push(product)
      }
      return p
    }, { pluginSubscribed: [] as any[], pluginNotSubscribed: [] as any[] })
  }, [account.account_type, getForPlanType])

  if (loading) return (
    <Skeleton active loading={loading} paragraph />
  )

  return (
    <>
      <Divider orientation='left'>Cloud Subscriptions</Divider>
      <SubscriptionGroup
        subscribed={cloudSubscribed}
        notSubscribed={cloudNotSubscribed}
        account={account}
      />
      <Divider orientation='left'>Plugin Subscriptions</Divider>
      <SubscriptionGroup
        subscribed={pluginSubscribed}
        notSubscribed={pluginNotSubscribed}
        account={account}
      />
      <Divider orientation='left'>Account Usage</Divider>
      <UsageSummaryDashboard account={account} />
    </>
  )
}

export default Subscription
