import React from 'react'

import { Button, Col, Divider, Row, Space, Tooltip, Typography } from 'antd'
import Icon, { AppstoreAddOutlined, GithubOutlined, PicRightOutlined, TwitterCircleFilled } from '@ant-design/icons'

import { useAuth } from 'auth-context'

import { discourseIcon, userManual } from 'atoms/Icons'

export const WelcomeInfo: React.FunctionComponent = () => {

  const { user } = useAuth()

  return (
    <Row style={{ width: '100%' }} gutter={[0, 16]}>
      <Space size={16} direction='vertical'>
        <Typography.Title level={5}>Looking for CAD Plugins?</Typography.Title>
        <Col span={24}>
          <Space direction='vertical' size={8}>
            <Col span={24}>
              <Button href={'/cad-plugins'}>
                <AppstoreAddOutlined />
                Install Plugins
              </Button>
            </Col>
            <Col span={24}>
              <Typography.Text type='secondary'>
                Download Pollination CAD Plugin installers.
              </Typography.Text>
            </Col>
          </Space>
        </Col>
        <Col span={24}>
          <Space direction='vertical' size={8}>
            <Col span={24}>
              <Button type='primary' href={`/${user?.username}?tab=subscription`}>
                <AppstoreAddOutlined />
                Buy Licenses
              </Button>
            </Col>
            <Col span={24}>
              <Typography.Text type='secondary'>
                Add or remove Pollination CAD Plugin licenses from your subscription.
              </Typography.Text>
            </Col>
          </Space>
        </Col>
      </Space>
      <Divider style={{ margin: '8px 0px' }} />
      <Row gutter={[0, 8]} justify='space-between' style={{ width: '100%' }}>
        <Col>
          <Tooltip title='Learn More'>
            <Button shape='circle' href='https://www.pollination.solutions' target='_blank' referrerPolicy='no-referrer'>
              <img src='/logo.svg' style={{ color: '#000', height: '20px', marginBottom: '4px' }} alt="Pollination logo." />
            </Button>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip title='User Manual'>
            <Button shape='circle' href='https://docs.pollination.solutions/user-manual/' target='_blank' referrerPolicy='no-referrer'>
              <Icon component={userManual} style={{ fontSize: 20, color: '#000', lineHeight: 1.8 }} />
            </Button>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip title='Github'>
            <Button shape='circle' href='https://github.com/pollination' target='_blank' referrerPolicy='no-referrer'>
              <GithubOutlined />
            </Button>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip title='Twitter'>
            <Button shape='circle' href='https://twitter.com/_pollination' target='_blank' referrerPolicy='no-referrer'>
              <TwitterCircleFilled />
            </Button>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip title='Pollination Blog'>
            <Button shape='circle' href='https://www.pollination.solutions/blog' target='_blank' referrerPolicy='no-referrer'>
              <PicRightOutlined />
            </Button>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip title='Pollination Forum'>
            <Button shape='circle' href='https://discourse.pollination.solutions/' target='_blank' referrerPolicy='no-referrer'>
              <Icon component={discourseIcon} style={{ color: '#000' }} />
            </Button>
          </Tooltip>
        </Col>
      </Row>
    </Row >
  )
}

export default WelcomeInfo
