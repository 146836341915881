import React, { useMemo, useState } from 'react'

import { CopyOutlined, DeleteOutlined, PlusOutlined, RedoOutlined } from '@ant-design/icons'
import { APIToken, APITokenPrivate } from '@pollination-solutions/pollination-sdk'
import { Divider, List, Space, Typography, Button, Tooltip, Input, notification } from 'antd'

import { useAPITokens } from 'hooks'

import { APIKeysProps } from '.'
import { Empty } from 'atoms'

const APIKeys: React.FunctionComponent<APIKeysProps> = () => {

  const { loading, apiTokens, recentlyCreated, apiTokenCreate, apiTokenRegenerate, apiTokenDelete } = useAPITokens()

  const [apiKeyFilter, setApiKeyFilter] = useState('')

  const [newKeyName, setNewKeyName] = useState('')

  const filteredApiTokens: (APIToken | APITokenPrivate)[] = useMemo(() => {
    return apiTokens?.filter((token) => token.name.toLowerCase().includes(apiKeyFilter.toLowerCase()))
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((token) => {
        const recent = recentlyCreated.find((t) => t.token_id === token.token_id)
        return recent ? recent : token
      })
  }, [apiKeyFilter, apiTokens, recentlyCreated])

  return (
    <List dataSource={filteredApiTokens} loading={loading}
      locale={{
        emptyText: <Empty />
      }}
      header={
        <List.Item >
          <Space align='baseline'>
            <Button icon={<PlusOutlined />} disabled={!newKeyName}
              onClick={(e) => {
                setNewKeyName('')
                apiTokenCreate(newKeyName)
              }
              } shape='circle' />
            <Divider type='vertical' />
            <Input placeholder='New API key name' value={newKeyName} onChange={(e) => setNewKeyName(e.target.value)}
              onPressEnter={(e) => {
                setNewKeyName('')
                apiTokenCreate(newKeyName)
              }} />
          </Space>
        </List.Item>
      }
      renderItem={(item, index) => (
        <List.Item actions={[<Tooltip title='Delete API Key'><Button danger icon={<DeleteOutlined />} onClick={() => apiTokenDelete(item.token_id)} shape='circle' /></Tooltip>]}>
          <Space align='baseline'>
            { // @ts-ignore
              typeof item.token === 'undefined' ?
                <Tooltip title='Regenerate API Key'><Button icon={<RedoOutlined />} onClick={() => {
                  apiTokenRegenerate(item.token_id)
                }} shape='circle' /></Tooltip>
                :
                <Tooltip title='Copy API Key'>
                  <Button type='primary' ghost icon={<CopyOutlined />} shape='circle'
                    onClick={() => {
                      // @ts-ignore
                      const blob = new Blob([item.token], { type: 'text/plain' })
                      navigator.clipboard.write([
                        // @ts-ignore
                        new ClipboardItem({ 'text/plain': blob })
                      ]).then(() => {
                        notification.success({
                          message: 'Copied to Clipboard',
                        })
                      })
                    }}
                  />
                </Tooltip>
            }
            <Divider type='vertical' />
            <Typography.Title level={5} code>{item.name}</Typography.Title>
            {
              // @ts-ignore
              typeof item.token !== 'undefined' && <Typography.Text type='secondary' code >{item.token}</Typography.Text>
            }
          </Space>
        </List.Item>
      )}>
    </List>
  )
}

export default APIKeys