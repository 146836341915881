import React, { useEffect, useState } from 'react'
import { Card, Button, Space, Row, Col, Typography } from 'antd'
import { ExpandAltOutlined, SwapOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons'

import { Run, StepStatus } from '@pollination-solutions/pollination-sdk'

import { useAuth } from 'auth-context'
import { Loading } from 'atoms'
import DAG from './DAG'

interface Props {
  run: Run
  owner: string
  projectName: string
}

/**
 * Top-level content component for job tab. Manages controls and props passed to the DAG viewer.
 */
const RunDebugger = (props: Props): React.ReactElement => {
  const { client } = useAuth()

  const { run, owner, projectName } = props

  const [direction, setDirection] = useState<'TB' | 'LR'>('TB')
  // ( Chuck ) When zoom is set to zero, children interpret that as 'zoom to extents'
  const [zoomBool, setZoomBool] = useState(false)
  const [zoom, setZoom] = useState(1)
  const [zoomCache, setZoomCache] = useState(1)

  const [loading, setLoading] = useState(true)
  const [steps, setSteps] = useState<{ [key: string]: StepStatus }>({})


  const min = 0.25
  const max = 1.75

  /** Given an amount to change the zoom, guarantee it stays within bounds and apply it. */
  const handleZoomDelta = (delta: number): void => {
    const z = zoom + delta
    const clampedZoom = z <= min ? min : z >= max ? max : z
    setZoom(clampedZoom)
  }

  const fetchSteps = async () => {
    const { data } = await client.runs.getAllRunSteps({
      owner,
      name: projectName,
      runId: run.id,
    })

    if (data) {
      const newSteps = {}
      data.forEach(step => {
        newSteps[step.id] = step
      })
      setSteps(newSteps)
    }

    setLoading(false)
  }

  useEffect(() => {
    fetchSteps()
  }, [run.id])

  useEffect(() => {
    switch (zoomBool) {
      case true: {
        setZoomCache(zoom)
        setZoom(0)
        return
      }
      case false: {
        setZoom(zoomCache)
        return
      }
    }
  }, [zoomBool])


  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Card>
        <Row>
          <Col>
            <Space>
              <div className="dag-controls-control">
                <Button onClick={() => handleZoomDelta(-0.1)}>
                  <ZoomOutOutlined color="#555555" size={18} />
                </Button>
                <input
                  type="range"
                  min={min}
                  max={max}
                  value={zoom === 0 ? zoomCache : zoom}
                  step={0.05}
                  onChange={(e): void => setZoom(Number.parseFloat(e.target.value))}
                />
                <Button onClick={() => handleZoomDelta(0.1)}>
                  <ZoomInOutlined color="#555555" size={18} />
                </Button>
              </div>
              <div className="dag-controls-control">
                <Button onClick={() => setZoomBool(!zoomBool)}>
                  <ExpandAltOutlined color={zoom === 0 ? '#555555' : '#C4C4C4'} size={18} />
                </Button>
              </div>
              <div className="dag-controls-control">
                <Button onClick={() => setDirection((dir) => (dir === 'LR' ? 'TB' : 'LR'))}>
                  {direction === 'LR' ? (
                    <SwapOutlined
                      color={direction === 'LR' ? '#555555' : '#C4C4C4'}
                      size={18}
                    />
                  ) : (
                    <SwapOutlined
                      color={direction === 'TB' ? '#555555' : '#C4C4C4'}
                      size={18}
                      rotate={90}
                    />
                  )}
                </Button>
              </div>
            </Space>
          </Col>
        </Row>
      </Card>
      <Card>
        <div className="dag-container">
          <div className={`dag-image-container ${zoom > 0 ? 'dag-image-container--default' : 'dag-image-container--zte'}`}>
            {(loading) ? <Loading /> :
              (Object.keys(steps).length === 0) ?
                <Typography>No debug graph available for this run.</Typography>
                :
                <DAG
                  zoom={zoom}
                  owner={owner}
                  projectName={projectName}
                  direction={direction}
                  steps={steps}
                  runId={run.id}
                  // @ts-ignore
                  jobId={run.status.job_id}
                />
            }
          </div>
          <style>{`
            .dag-controls-control * {
              pointer-events: all;
            }
          `}</style>
          <style>{`
            .dag-container {
              position: relative;
              width: 100%;
              height: calc(100vh - 320px);
            }

            .dag-controls-container {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              z-index: 5;
              pointer-events: none;
            }

            .dag-controls {
              margin-top: 21px;
              height: 30px;
              width: 100%;
              display: flex;
              align-items: center;
            }

            .dag-controls-control {
              padding: 6px;
              padding-left: 9px;
              padding-right: 9px;
              margin-right: 14px;
              background: var(--white-2);
              border: 1px solid var(--black-8);
              border-radius: 4px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }

            .dag-controls-control > input {
              margin-left: 6px;
              margin-right: 6px;
            }

            .dag-controls-control > button {
              height: 18px;
              width: 18px;
              padding: 0;
              border: none;
              background: none;
            }

            .dag-controls-control > button:hover {
              cursor: pointer;
            }

            .dag-controls__icon {
              height: 18px;
              width: 18px;
            }

            .dag-controls__icon--left {
              margin-right: 9px;
            }

            .dag-controls__icon--right {
              margin-left: 9px;
            }

            .dag-image-container {
              position: absolute;
              top: 0;
              width: 100%;
              height: 100%;
              display: flex;
              overflow: auto;
            }
          `}</style>
        </div>
      </Card>
    </Space>
  )
}


export default RunDebugger
