import { useState, useEffect, useCallback } from 'react'

import {
  SubscriptionPlan,
  PlanType, AccountType, Subscription
} from '@pollination-solutions/pollination-sdk'

import { useAuth } from 'auth-context'
import useSWR from 'swr'

const DEPRECATD_PLAN_SLUGS = [
  'zero-tier', 'organization-tier', 'professional-tier'
] as string[]

const useAccountSubscriptions = (accountName?: string) => {
  const { client } = useAuth()

  const [plans, setPlans] = useState<SubscriptionPlan[]>([])

  const fetchSubscriptions = useCallback((name: string, key: string) => {
    return client.subscriptions.listPollinationSubscriptions({ account: name })
      .then(({ data }) => data as Subscription[])
  }, [client.subscriptions])

  const { data: subscriptions, isValidating: loading, error, mutate: refreshSubscriptions } = useSWR(
    accountName ? [accountName, 'listPollinationSubscriptions'] : null, fetchSubscriptions,
    { revalidateOnFocus: false, errorRetryInterval: 1000 })

  // this doesn't need useSWR because it doesn't change
  // it's basically our inventory
  useEffect(() => {
    client.subscriptionPlans.listSubscriptionPlans()
      .then(({ data }) => setPlans(data))
  }, [client])

  const getForPlanType = useCallback((planType: PlanType, accountType: AccountType) => {
    if (!plans || !subscriptions) return { plans: [], subscription: undefined }

    const subscription = subscriptions.filter(s => s.type == planType).pop()

    const filteredPlans = plans.filter(p => (
      // @ts-ignore
      p.type == planType && p.account_types.includes(accountType)
    )).filter(p => !DEPRECATD_PLAN_SLUGS.includes(p.slug) || (subscription && subscription.plan_slug == p.slug))

    const subscriptionPlan = plans.find(p => p.slug == subscription?.plan_slug)

    return { plans: filteredPlans, subscription, subscriptionPlan }
  }, [subscriptions, plans])

  return { loading, subscriptions, plans, getForPlanType, refreshSubscriptions }
}

export default useAccountSubscriptions
