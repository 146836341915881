import React, { useEffect, useState } from 'react'

import { Grid, Typography, Space, Modal, Row, Button, Divider } from 'antd'
import { CloseOutlined, ExpandAltOutlined, ShrinkOutlined } from '@ant-design/icons'

import { FileMeta } from '@pollination-solutions/pollination-sdk'

import FileView from 'components/FileView'

import { FailPreview } from 'atoms'
import { getFileIcon } from './utils'
import { formatBytes } from 'utils/formatValues'

interface PreviewWindowProps {
  file: FileMeta
  url: string
  onClose: () => void
  blob?: Blob
  isCloud?: boolean
}

/* Shallow wrapper for common `FileView` component that shows a file title and close button. */
export const PreviewWindow = ({ file, url, onClose, blob = undefined, isCloud = true }: PreviewWindowProps): React.ReactElement => {

  const screens = Grid.useBreakpoint()

  const [fullScreen, setFullScreen] = useState<boolean>(false)

  useEffect(() => {
    if (screens.xs) setFullScreen(true)
  }, [screens])

  const type = file.file_name.split('.').pop() ?? 'unknown'
  const size = file.size ?? blob?.size
  const noLimits = ['hbjson', 'vtkjs', 'vtp', 'vsf']
  const fileSizeLimit = noLimits.includes(type) ? 10000000000 : 4000000
  const icon = getFileIcon(type)

  const title = (
    <Row justify='space-between' align='middle' style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
      <Space>
        {icon}
        <Typography.Text style={{ marginLeft: '8px' }}>{file.file_name}</Typography.Text>
        {!screens.xs ? <Typography.Text style={{ marginLeft: '8px' }} type="secondary">{formatBytes(size)}</Typography.Text> : <></>}
      </Space>
      {
        !screens.xs ? <Space style={{ marginRight: 24 }}><Button icon={fullScreen ? <ShrinkOutlined /> : <ExpandAltOutlined />} onClick={() => setFullScreen(!fullScreen)} style={{ border: 'none' }} size='small' /> <Divider type='vertical' /></Space> : <></>
      }
    </Row>
  )

  return (
    <>
      <style>{`
        .ant-modal-content{
          height:100% !important;
        }
      `}</style>
      <Modal title={title}
        width={fullScreen ? '100%' : '90%'}
        visible
        centered={fullScreen ? false : true}
        maskClosable={false}
        mask={fullScreen ? false : true}
        footer={null}
        closeIcon={screens.xs ? <ShrinkOutlined /> : <CloseOutlined />}
        afterClose={onClose}
        onCancel={onClose}
        style={{
          height: fullScreen ? '100%' : '85%',
          overflow: 'hidden',
          padding: 0,
          margin: 0,
          top: fullScreen ? '0px' : 'default',
          maxWidth: fullScreen ? '100%' : 'default'
        }}
        bodyStyle={{ height: 'calc(100% - 59px)', width: '100%', padding: 0, margin: 0, }}>
        {
          typeof size === 'undefined' || size > fileSizeLimit ?
            <FailPreview fileMeta={file} downloadUrl={url} 
              message={`File of size ${formatBytes(size)} cannot be previewed`} 
              file={blob}
              isCloud={isCloud}
              /> :
            <FileView fileMeta={file} filePath={url} fileType={type} file={blob} isCloud={isCloud} />
        }
      </Modal>
    </>
  )
}
