import React, { useState, useEffect, useCallback, useRef } from 'react'
import { notification } from 'antd'

import { useUser } from 'reactfire'

const UTILSENDPOINT = process.env.REACT_APP_UTILS_ENDPOINT || 'https://utilities.staging.pollination.solutions'

const useDownloadPlugin = () => {

  const [loading, setLoading] = useState(false)

  const { data } = useUser()
  const token = useRef<string | null>(null)

  useEffect(() => {
    if (!data) return

    const asyncToken = async () => {
      token.current = await data.getIdToken()
    }

    asyncToken()
  }, [data])

  const downloadPlugin = useCallback((plugin: 'grasshopper' | 'rhino' | 'revit') => {
    setLoading(true)
    notification.success({
      message: 'Your download will begin shortly.'
    })
    fetch(`${UTILSENDPOINT}/download-plugin/${plugin}?version=latest`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': `bearer ${token.current}`
      },
    })
      .then(async (res) => {
        if (res.ok) {
          const { url } = await res.json()
          const link = document.createElement('a')
          link.href = url
          document.body.appendChild(link)
          link.click()
          if (link.parentNode) link.parentNode.removeChild(link)
        }
        else {
          notification.error({
            message: 'Failed to download your CAD plugin. Please refresh the page and try again.'
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [token])

  return {
    loading,
    downloadPlugin
  }
}

export default useDownloadPlugin
