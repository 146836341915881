import React, { useState, useEffect, useMemo } from 'react'

import { InputNumber, Select, Button, Typography, Skeleton, Row, Col, List, Divider, Space } from 'antd'
import { ThunderboltFilled } from '@ant-design/icons'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

import { AccountPublic, BillingOption, PlanType, QuotaPlan, SubscriptionCreate, SubscriptionPlan } from '@pollination-solutions/pollination-sdk'

import numbro from 'numbro'

import { InputLabel } from 'atoms'
import { getLabel } from 'hooks/useQuotas'


const billingTypeDisplay = {
  'month': 'Monthly',
  'year': 'Yearly',
}

const calculateQuotaMultiplier = (quota: QuotaPlan, quantity: number): number | undefined => {
  if (quota.limit) {
    const val = quota.limit * quantity
    if (quota.max_limit) {
      return Math.min(val, quota.max_limit)
    }
    return val
  }
  return
}

const planTypeWithQuantity = (planType: PlanType): boolean => {
  switch (planType) {
    case PlanType.Cloud:
      return false
    default:
      return true
  }
}

interface CreateSubscriptionFormProps {
  plans: SubscriptionPlan[]
  createSubscription: (subscriptionCreate: SubscriptionCreate, plan: SubscriptionPlan, account: AccountPublic, onSuccess?: (() => void) | undefined) => Promise<void>
  account?: AccountPublic
  loading?: boolean
}

// TODO: this is pretty much already ready to be unified with manage subscription form
export const CreateSubscriptionForm: React.FunctionComponent<CreateSubscriptionFormProps> = ({
  plans,
  createSubscription,
  account,
  loading,
}) => {

  const [plan, setPlan] = useState(plans[0])
  const { xs } = useBreakpoint()

  useEffect(() => {
    if (!plan && plans.length !== 0) {
      setPlan(plans[0])
    }
  }, [plans])

  const billingOptions = useMemo(() => {
    if (plan && plan.billing_options) {
      return plan.billing_options
    } else {
      return []
    }
  }, [plan])
  const [billingPeriod, setBillingPeriod] = useState<BillingOption>()
  const [currency, setCurrency] = useState<string>('USD')
  const [quantity, setQuantity] = useState<number>(1)

  const unitCost = useMemo(() => {
    if (!billingPeriod || !billingPeriod.recurring_price[currency]) {
      return null
    }

    return billingPeriod.recurring_price[currency]
  }, [billingPeriod, currency])

  const totalCostText = useMemo(() => {
    if (!unitCost) {
      return ''
    }
    const ammount = numbro(unitCost * quantity).formatCurrency({
      thousandSeparated: true,
      mantissa: 2
    })
    return (
      <strong>
        {`${ammount} ${currency}`}
      </strong>
    )
  }, [unitCost, currency, quantity])

  useEffect(() => {
    if (plan && plan.billing_options && plan.billing_options.length > 0) {
      const index = Math.max(plan.billing_options.findIndex(o => o.billing_type === 'year'), 0)
      setBillingPeriod(plan.billing_options[index])
    }
  }, [plan])

  if (billingOptions.length === 0) return <></>

  return (
    <>
      {loading ?
        <Skeleton active />
        :
        <>
          <Row justify='space-between'>
            <Col span={xs ? 24 : 16}>
              <Row gutter={[16, 16]} justify='space-between'>
                <Col span={24}>
                  <Row gutter={16}>
                    <Col>
                      <InputLabel label='Plan' style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Select
                          dropdownMatchSelectWidth={false}
                          value={plan.slug}
                          onSelect={(v) => {
                            const selectedPlan = plans.filter((p) => p.slug == v).pop()
                            if (selectedPlan) {
                              setPlan(selectedPlan)
                              setQuantity(1)
                            }
                          }}
                        >
                          {
                            plans.map((p, i) => (
                              <Select.Option key={i} value={p.slug}>{p.name}</Select.Option>
                            ))
                          }
                        </Select>
                      </InputLabel>
                    </Col>
                    {
                      planTypeWithQuantity(plan.type) && (
                        <Col>
                          <InputLabel label='Quantity' style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <InputNumber
                              min={1} max={100000} defaultValue={1} value={quantity}
                              onChange={(v) => setQuantity(v as number)} />
                          </InputLabel>
                        </Col>

                      )
                    }
                    {
                      billingOptions.length !== 0 && (
                        <Col>
                          <InputLabel label='Billing Period' style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <Select value={billingPeriod?.billing_type ?? billingOptions[0].billing_type}
                              onSelect={(value) => {
                                setBillingPeriod(billingOptions.find((option) => option.billing_type === value))
                              }}
                            >
                              {billingOptions.map((b, i) => (
                                <Select.Option key={i} value={b.billing_type}>{billingTypeDisplay[b.billing_type]}</Select.Option>
                              ))}
                            </Select>
                          </InputLabel>
                        </Col>
                      )
                    }
                  </Row>
                </Col>
                <Col span={24}>
                  <Button
                    type="primary"
                    style={{
                      maxWidth: 512,
                      alignSelf: 'center'
                    }}
                    ghost={!billingPeriod}
                    disabled={typeof account === 'undefined'}
                    onClick={() => {
                      if (billingPeriod && account) {
                        createSubscription({ account: account.name, plan_id: billingPeriod.id, quantity }, plan, account)
                      }
                    }}
                  >
                    <ThunderboltFilled />
                    Subscribe
                  </Button>
                </Col>
                <Col span={24}>
                  <Space>
                    <Typography.Text strong>{totalCostText}</Typography.Text>
                    <Typography.Text type='secondary'>{billingPeriod ? ` + tax / ${billingPeriod.billing_type}` : ''}</Typography.Text>
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col span={xs ? 24 : 8}>
              {xs && <Divider />}
              <List size='small'
                dataSource={plan.quotas}
                rowKey={(record) => record.type}
                split={false}
                renderItem={item => {
                  const { icon, displayName, format } = getLabel(item.type)
                  const limit = calculateQuotaMultiplier(item, quantity)
                  return (
                    <List.Item
                      key={item.type}
                      style={{ padding: '6px 0px 4px', justifyContent: xs ? 'flex-start' : 'flex-end' }}
                    >
                      <Space>
                        {xs && icon}
                        <Typography.Text>{limit ? `${format(limit)} ${displayName}` : `Unlimited ${displayName}`}</Typography.Text>
                        {!xs && icon}
                      </Space>
                    </List.Item>
                  )
                }}
              />
            </Col>
          </Row>
        </>
      }
    </>
  )
}

export default CreateSubscriptionForm
