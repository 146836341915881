import React, { useState, useEffect, useCallback } from 'react'

import { useAuth } from 'auth-context'

import { APIToken, APITokenPrivate, APITokensApiListTokensRequest } from '@pollination-solutions/pollination-sdk'

const useAPITokens = () => {
  const { client } = useAuth()

  const [loading, setLoading] = useState<boolean>(false)

  const [apiTokens, setAPITokens] = useState<APIToken[]>([])

  const [recentlyCreated, setRecentlyCreated] = useState<APITokenPrivate[]>([])

  const [query, setQuery] = useState<APITokensApiListTokensRequest>({})

  const apiTokensFetch = useCallback((query) => {
    setLoading(true)

    client.apiKeys.listTokens(query)
      .then(({ data }) => {
        setAPITokens(data.resources)
      })
      .finally(() => setLoading(false))
  }, [client.apiKeys])

  const apiTokenCreate = useCallback((name: string) => {

    setLoading(true)

    client.apiKeys.createToken({ aPITokenCreate: { name, token_id: name } })
      .then(({ data }) => {
        setRecentlyCreated(state => [...state, data])
        apiTokensFetch(query)
      })

  }, [apiTokensFetch, client.apiKeys, query])

  const apiTokenRegenerate = useCallback((tokenId: string) => {
    setLoading(true)

    client.apiKeys.regenerateToken({ tokenId })
      .then(({ data }) => {
        setRecentlyCreated(state => [...state, data])
        apiTokensFetch(query)
      })

  }, [apiTokensFetch, client.apiKeys, query])

  const apiTokenDelete = useCallback((tokenId: string) => {

    setLoading(true)

    client.apiKeys.deleteToken({ tokenId })
      .then(() => apiTokensFetch(query))

  }, [apiTokensFetch, client.apiKeys, query])

  useEffect(() => {
    apiTokensFetch(query)
  }, [apiTokensFetch, query])

  return { loading, apiTokens, recentlyCreated, setQuery, apiTokenCreate, apiTokenRegenerate, apiTokenDelete }
}

export default useAPITokens