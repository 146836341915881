import React from 'react'
import { List } from 'antd'
import { CardButton } from './CardButton'
import { Option } from 'pollination-react-io'

interface OptionListProps {
  options: Option[]
  loading: boolean
}

export const OptionList: React.FC<OptionListProps> = ({
  options,
  loading
}) => {
  return (
    <List
      grid={
        {
          gutter: 20,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 2,
          xl: 3,
          xxl: 3,
        }
      }
      dataSource={options}
      renderItem={({ label, icon, id, onSelect }, i) => (
        <List.Item>
          <CardButton 
            title={label} 
            avatarStyle={{
              backgroundColor: id === 'projects' 
                ? '#05a64f' 
                : '#2ea8e0'
            }}
            label={label} 
            icon={icon} 
            onClick={onSelect}
          />
        </List.Item>
      )
      }
      loading={loading}
      pagination={false}
    />
  )
}