import React, { useState } from 'react'

import { Row, Popover, Button, List, Typography } from 'antd'
import { CloseOutlined, SettingOutlined } from '@ant-design/icons'

interface Setting {
  title: string,
  component: React.ReactElement
}

interface SettingsFABProps {
  settings: Setting[]
}

const SettingsFAB: React.FunctionComponent<SettingsFABProps> = ({ settings }) => {
  const [showSettings, setShowSettings] = useState(false)

  return (
    <Popover
      content={<List dataSource={settings}
        size="small"
        renderItem={(item: Setting) => (
          <List.Item style={{ display: 'flex', justifyContent: 'space-between', minWidth: 300 }} key={item.title}>
            <Typography.Text style={{ width: '30%', marginRight: '8px' }}>{item.title}</Typography.Text>
            {item.component}
          </List.Item>)} />
      }
      placement="topLeft"
      title={
        <Row align="middle">
          <SettingOutlined />
          <Typography.Text style={{ marginLeft: '8px', fontSize: 'medium' }}>Settings</Typography.Text>
          <Row justify="end" style={{ flex: 1 }}>
            <Button shape="circle" size="small" style={{ border: 'none' }}>
              <CloseOutlined onClick={() => setShowSettings(false)} />
            </Button>
          </Row>
        </Row>}
      trigger="click"
      visible={showSettings}
      onVisibleChange={(val: boolean) => setShowSettings(val)}
      style={{ minWidth: '300px' }}
    >
      <Button
        icon={<SettingOutlined />}
        size="large"
        style={{
          margin: '22px 0px',
          position: 'absolute',
          bottom: '0px',
          boxShadow: '0 1px 2px -2px #00000029,0 3px 6px #0000001f,0 5px 12px 4px #00000017'
        }} />
    </Popover>
  )
}

export default SettingsFAB
