import React, { createContext, useContext, useState } from 'react'
import { OptionGroup } from 'pollination-react-io'

export interface AccountInfo {
  name?: string
  picture?: string
  owner?: boolean
  display_name?: string
  type?: 'org' | 'user'
}

interface AccountInfoProviderProps {
  children?: React.ReactNode
}

interface AccountInfoContextProps {
  accountInfo?: AccountInfo
  setAccountInfo?: (value: AccountInfo) => any
  newOptions?: OptionGroup[]
  setNewOptions?: (value: OptionGroup[]) => any
  accountOptions?: OptionGroup[]
  setAccountOptions?: (value: OptionGroup[]) => any
}

const AccountInfoContext = createContext<AccountInfoContextProps>({ })

export const AccountInfoProvider = ({ children }: AccountInfoProviderProps): React.ReactElement => {

  const [accountInfo, setAccountInfo] = useState<AccountInfo>()
  const [newOptions, setNewOptions] = useState<OptionGroup[]>([])
  const [accountOptions, setAccountOptions] = useState<OptionGroup[]>([])

  return (
    <AccountInfoContext.Provider value={{
      accountInfo: accountInfo,
      setAccountInfo: setAccountInfo,
      newOptions: newOptions, 
      setNewOptions: setNewOptions,
      accountOptions: accountOptions, 
      setAccountOptions: setAccountOptions,
    }}>{children}</AccountInfoContext.Provider>
  )
}

export const useAccountInfo = (): AccountInfoContextProps => {
  return useContext(AccountInfoContext)
}