import React, { useState, useEffect, FormEvent } from 'react'
import { Grid, Row, Space, Input, Select, Divider, Avatar } from 'antd'
import { AccountPublic, SortEnum } from '@pollination-solutions/pollination-sdk'
import { useAuth } from 'auth-context'
import { Sort } from 'atoms'
import { PublicFilter } from 'atoms'
import { Query } from 'hooks/paginatedList'

interface TabsToolbarProps {
  updateQuery: (query: Query) => void
  sortEnum: any
  defaultSortKey: string
  searchPlaceholder: string
  hasAccount?: boolean
  newButton?: React.ReactElement
}

const TabsToolbar = (props: TabsToolbarProps) => {

  const { updateQuery, sortEnum, defaultSortKey,
    searchPlaceholder, hasAccount, newButton } = props
  const screens = Grid.useBreakpoint()
  const { client } = useAuth()

  const [accountOptions, setAccountOptions] = useState<AccountPublic[]>([])

  const [owner, setOwner] = useState<string | undefined>()
  const [search, setSearch] = useState<string | undefined>()
  const [isPublic, setIsPublic] = useState<boolean | undefined>()
  const [sortKey, setSortKey] = useState<string>(defaultSortKey)
  const [sortOrder, setSortOrder] = useState<SortEnum>(SortEnum.Descending)

  useEffect(() => {
    updateQuery({
      search,
      owner,
      isPublic,
      sortBy: sortKey,
      sortOrder,
    })
  }, [owner, search, isPublic, sortKey, sortOrder, updateQuery])


  const searchAccounts = (search: string | undefined) => {
    if (search === '') {
      search = undefined
    }
    client.accounts.listAccounts({ search })
      .then((res) => setAccountOptions(res.data.resources))
  }

  useEffect(() => {
    searchAccounts(undefined)
  }, [])

  return (
    <Row wrap justify="space-between" style={{ width: '100%' }}>
      <Space wrap>
        <Input.Search
          placeholder={searchPlaceholder || 'Search projects...'}
          allowClear
          onSearch={(value: string) => value !== '' ? setSearch(value) : setSearch(undefined)}
          onInput={(e: FormEvent<HTMLElement>) => {
            if (e.nativeEvent.target) {
              // @ts-ignore
              const val = e.nativeEvent.target.value
              if (val !== search) {
                setSearch(val ? val : undefined)
              }
            }
          }}
          style={{ minWidth: '175px', maxWidth: '275px' }}
        />
        <Divider type="vertical" />
        {hasAccount &&
          <Select
            showSearch
            allowClear
            placeholder="Filter by account"
            onSearch={searchAccounts}
            onClear={() => setOwner(undefined)}
            onSelect={(value) => value !== '' ? setOwner(value as string) : setOwner(undefined)}
            style={{ minWidth: '150px' }}
          >
            {accountOptions.map(account =>
              <Select.Option key={account.id} value={account.name}>
                <Avatar size="small" shape="circle" src={account.picture_url ? account.picture_url : `https://robohash.org/${account.name}`} style={{ backgroundColor: 'white' }} /> {account.name}
              </Select.Option>
            )}
          </Select>}
        <PublicFilter onChange={setIsPublic} />
        <Sort
          sortKey={sortKey}
          sortOrder={sortOrder}
          sortEnum={sortEnum}
          setSortKey={setSortKey}
          setSortOrder={setSortOrder}
        />
        {screens.xs && newButton ? newButton : <></>}
      </Space>
      {!screens.xs && newButton ? newButton : <></>}
    </Row>
  )
}

export default TabsToolbar
