import React, { useState } from 'react'
import { Menu, Space, Dropdown, Card, Col, Typography } from 'antd'
import { FileMeta } from '@pollination-solutions/pollination-sdk'
import { FolderFilled, FolderOutlined } from '@ant-design/icons'

interface ContextMenuAction {
  icon?: React.ReactNode
  label: string
  onClick: (file: FileMeta) => void
}

interface ProjectFolderEntryProps {
  folder: FileMeta
  onClick: (file: FileMeta) => void
  actions: ContextMenuAction[]
}

/** Folder card for grid view in project folder. */
export const ProjectFolderEntry = ({ folder, onClick, actions }: ProjectFolderEntryProps): React.ReactElement => {
  const { file_name: name, key } = folder

  const [visible, setVisible] = useState(false)

  const menu = (
    <Menu>
      {actions.map(({ icon, label, onClick }, i) => (
        <Menu.Item key={i} icon={icon} onClick={() => onClick(folder)}>
          {label}
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown
      overlay={menu}
      trigger={['contextMenu']}
      onVisibleChange={setVisible}
    >
      <Col
        xs={24}
        sm={12}
        md={8}
        lg={8}
        xl={8}
        style={{ height: '100%' }}
      >
        <Card
          hoverable
          style={{
            width: '100%',
            height: '70px',
            borderRadius: '4px'
          }}
          onClick={() => onClick(folder)}

        >
          <Card.Meta
            avatar={<FolderFilled style={{ fontSize: 24 }} />}
            title={
              <Typography.Text style={{ fontSize: '0.85rem' }}>
                {name}
              </Typography.Text>
            }
          />
        </Card>
      </Col>
    </Dropdown>
  )
}
