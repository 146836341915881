import React, { useEffect } from 'react'
import { useAuth } from 'reactfire'

export const useUpdateImage = () => {
  const [loading, setLoading] = React.useState(false)
  const [response, setResponse] = React.useState(null)

  const [token, setToken] = React.useState<string>()
  const data = useAuth()

  useEffect(() => {
    data.currentUser?.getIdToken().then((value) => {
      setToken(value)
    })
  }, [data.currentUser])

  const updateImage = async (url: string, data: Blob) => {
    if(!token) return
    setLoading(true)

    const formData = new FormData()
    formData.append('file', data)

    return fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'accept': '*/*',
        'accept-encoding': 'gzip, deflate, br'
      },
      body: formData,
    })
      .then((response) => {
        setLoading(false)
        return response
      })
      .catch((err) => {
        setLoading(false)
        throw new Error(err)
      })

  }

  return {
    loading,
    response,
    updateImage
  }
}