import React from 'react'

import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'

import { Link, useLocation } from 'react-router-dom'

import { Space, Typography, Table } from 'antd'
import {
  ExclamationCircleFilled,
  CheckCircleFilled,
  ClockCircleFilled,
  SyncOutlined,
  FolderOutlined,
  FileOutlined,
  BorderlessTableOutlined,
  TableOutlined
} from '@ant-design/icons'

import { RunStatus, RunStatusEnum, StepStatusEnum } from '@pollination-solutions/pollination-sdk'

dayjs.extend(relativeTime)
dayjs.extend(duration)

export const getStatusColor = (status: RunStatusEnum | StepStatusEnum | undefined): string => {
  switch (status) {
    case RunStatusEnum.Failed:
      return '#FF4D4F'
    case RunStatusEnum.Running:
    case RunStatusEnum.PostProcessing:
      return '#1890FF'
    case RunStatusEnum.Succeeded:
      return '#52C41A'
    case RunStatusEnum.Created:
    case RunStatusEnum.Scheduled:
      return '#FADB14'
    case RunStatusEnum.Cancelled:
      return '#D9D9D9'
    default:
      return '#D9D9D9'
  }
}


export const statusIcon = (status: RunStatusEnum | StepStatusEnum | undefined) => {
  switch (status) {
    case RunStatusEnum.Failed:
    case RunStatusEnum.Cancelled:
      return <ExclamationCircleFilled style={{ color: getStatusColor(status), fontSize: 'large' }} />
    case RunStatusEnum.Running:
    case RunStatusEnum.PostProcessing:
      return <SyncOutlined spin style={{ color: getStatusColor(status), fontSize: 'large' }} />
    case RunStatusEnum.Succeeded:
      return <CheckCircleFilled style={{ color: getStatusColor(status), fontSize: 'large' }} />
    case RunStatusEnum.Created:
    case RunStatusEnum.Scheduled:
      return <ClockCircleFilled style={{ color: getStatusColor(status), fontSize: 'large' }} />
    default:
      return <ExclamationCircleFilled style={{ color: getStatusColor(status), fontSize: 'large' }} />
  }
}

export const getArtifactHref = (artifact: any, projectSlug: string, jobId: string, query: URLSearchParams): string => {

  const { path } = artifact.source
  const crumbs = path.split('/')
  while (crumbs[0] === '') {
    crumbs.shift()
  }
  const jobFolderRoot = crumbs[0]

  // Remove the file section of an artifact if it's a file
  if (artifact.type.includes('File') || artifact.type.includes('Path')) {
    crumbs.pop()
  }

  let p = crumbs.join('/')

  // Hacky method to resolve relative paths
  p = new URL(p, 'http://example.com').href.split('http://example.com').pop()

  query.set('path', `${p}`)

  const runCrumbs = p.split('/')
  const runId = runCrumbs[2]

  switch (jobFolderRoot) {
    case 'inputs':
      query.set('tab', 'folder')
      return `/${projectSlug}/studies/${jobId}?${query.toString()}`
    case 'runs':
      p = runCrumbs.slice(4).join('/')
      p = new URL(p, 'http://example.com').href.split('http://example.com').pop()
      query.set('tab', 'workspace')
      return `/${projectSlug}/studies/${jobId}/runs/${runId}?${query.toString()}`
    default:
      return `/${projectSlug}/studies/${jobId}?tab=folder`
  }
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index
}

export const IORender = (io: any, projectSlug: string, jobId: string) => {
  const { search } = useLocation()
  const query = new URLSearchParams(search)

  if (io.type.includes('Folder') || io.type.includes('File') || io.type.includes('Path')) {
    return (
      <Space wrap>
        {io.type.includes('Folder') ? <FolderOutlined /> : <FileOutlined />}
        <Link to={getArtifactHref(io, projectSlug, jobId, query)}>
          <Typography.Link strong>{io.name}</Typography.Link>
        </Link>
      </Space>
    )
  } else if (io.type.includes('Array')) {
    let cols = [] as string[]
    io.value.forEach((v: object) => {
      cols = cols.concat(Object.keys(v))
    })
    cols = cols.filter(onlyUnique)
    const columns = cols.map(c => ({ title: c, dataIndex: c, key: c }))
    return (
      <Space wrap>
        <TableOutlined />
        <Table
          dataSource={io.value}
          columns={columns}
          pagination={false}
        />
      </Space>
    )
  } else if (io.type.includes('JSON')) {
    return (
      <Space wrap>
        <BorderlessTableOutlined />
        <Typography.Text>{JSON.stringify(io.value)}</Typography.Text>
      </Space>
    )
  } else {
    return (
      <Space wrap>
        <BorderlessTableOutlined />
        <Typography.Text>{io.value}</Typography.Text>
      </Space>
    )
  }
}

export const TableIORender = (io: any, projectSlug: string, jobId: string) => {
  const { search } = useLocation()
  const query = new URLSearchParams(search)

  if (io.type.includes('Folder') || io.type.includes('File') || io.type.includes('Path')) {
    return (
      <Space wrap>
        {io.type.includes('Folder') ? <FolderOutlined /> : <FileOutlined />}
        <Link to={getArtifactHref(io, projectSlug, jobId, query)}>
          <Typography.Link strong>{io.name}</Typography.Link>
        </Link>
      </Space>
    )
  } else if (io.type.includes('Array')) {
    let cols = [] as string[]
    io.value.forEach((v: object) => {
      cols = cols.concat(Object.keys(v))
    })
    cols = cols.filter(onlyUnique)
    const columns = cols.map(c => ({ title: c, dataIndex: c, key: c }))
    return (
      <Table
        dataSource={io.value}
        columns={columns}
        pagination={false}
      />
    )
  } else if (io.type.includes('JSON')) {
    return (
      <Typography.Text>{JSON.stringify(io.value)}</Typography.Text>
    )
  } else {
    return (
      <Typography.Text>{io.value}</Typography.Text>
    )
  }
}

export const getDuration = (status: RunStatus): string => {
  const start = dayjs(status.started_at, { utc: true })
  const end = status.finished_at ?
    dayjs(status.finished_at, { utc: true }) : dayjs(new Date(), { utc: true })

  const duration = dayjs.duration(end.diff(start))

  return `${duration.humanize()} (${duration.format('HH:mm:ss')})`
}
