import React, { FC, useEffect, useState } from 'react'

import { CheckCircleOutlined, EditOutlined, RedoOutlined } from '@ant-design/icons'

interface UpdatingIconProps {
  loading: boolean
}

export const UpdatingIcon: FC<UpdatingIconProps> = ({
  loading,
}) => {

  const [updated, setUpdated] = useState(false)

  useEffect(() => {
    if (loading) setUpdated(true)
  }, [loading])

  useEffect(() => {
    if (!updated) return
    
    const timeout = setTimeout(() => {
      setUpdated(false)
    }, 1000)

    return () => clearTimeout(timeout)
  }, [updated])

  if (loading) return <RedoOutlined spin style={{ color: '#1b90ff' }}/>
  
  return updated ?
    <CheckCircleOutlined style={{ color: '#52c31b' }}/> :
    <EditOutlined />
}