import React from 'react'

import { Avatar, Col, Typography, Row } from 'antd'

import * as AspectRatio from '@radix-ui/react-aspect-ratio'

import { AccountPublic } from '@pollination-solutions/pollination-sdk'

import { UserOrgs, OrgMembers, OrgTeams } from '../../components/SideCards/SideCards'
import { Loading } from 'atoms'
import { useWindowDimensions } from 'pollination-react-io'

export interface ProfileSummaryCardProps {
  account?: AccountPublic
  abbreviated?: boolean
  refresh?: boolean
  isAdmin?: boolean
  isMember?: boolean
}

export const ProfileSummary: React.FunctionComponent<ProfileSummaryCardProps> = ({ 
  account, 
  abbreviated,
  refresh,
  isAdmin,
  isMember
}) => {
  const { height } = useWindowDimensions()

  if (!account) return <Loading />

  return (
    <Col
      style={{ 
        overflow: 'auto',
        maxHeight: height - 160,
        padding: '12px 6px',
      }}>
      <Row gutter={[12,0]}>
        <Col xs={8} 
          style={{ 
            maxHeight: 96, 
            maxWidth: 96 
          }}
        >
          <AspectRatio.Root ratio={1}>
            <Avatar 
              src={`${account.picture_url}?${performance.now()}`} 
              size={50}
            />
          </AspectRatio.Root>
        </Col>
        <Col xs={16}>
          <div>
            <Typography.Title 
              level={5} 
              ellipsis={true}
            >
              {account.display_name ?? account.name}
            </Typography.Title>
          </div>
          <div style={{
            lineHeight: 1.35,
            color: '#666'
          }}>
            {account.description}
          </div>
        </Col>
      </Row>
      {isAdmin && account.account_type === 'user' && !abbreviated &&
        <UserOrgs accountName={account.name} refresh={refresh} />
      }
      {(isAdmin || isMember) && account.account_type === 'org' && !abbreviated &&
        <OrgMembers accountName={account.name} refresh={refresh} />
      }
      {/* {isAdmin && account.account_type === 'org' && !abbreviated &&
        <OrgTeams accountName={account.name} refresh={refresh} />
      } */}
    </Col>
  )
}

export default ProfileSummary 