import React, { FormEvent } from 'react'

import { Grid, Space, Input, Row } from 'antd'
import { ApplicationsApiListApplicationsRequest } from '@pollination-solutions/pollination-sdk'
import { InputLabel, KeywordSelect, SearchAccounts } from 'atoms'
import { QueryDropdown } from 'molecules/QueryDropdown'

interface QueryToolbarProps {
  query: Partial<ApplicationsApiListApplicationsRequest>
  setQuery: any
  sortEnum: any
  searchPlaceholder?: string
  collapsed?: boolean
  extraAction?: React.ReactElement
}

export const QueryToolbar: React.FC<QueryToolbarProps> = ({
  query,
  setQuery,
  sortEnum,
  searchPlaceholder = 'Search Apps...',
  collapsed = false,
  extraAction,
}) => {
  const { xs } = Grid.useBreakpoint()

  return (
    <Row
      justify={'space-between'}
      style={{
        width: '100%',
        padding: '0px 8px'
      }}
    >
      <Space
        wrap={xs}
        direction={xs ? 'vertical' : 'horizontal'}
        style={{ justifyContent: 'space-between' }}
        align='start'
        size={12}
      >
        <InputLabel>
          <Input.Search
            placeholder={searchPlaceholder || 'Search projects...'}
            allowClear
            onSearch={(val: string) => {
              const search = val ? val : undefined
              setQuery({ search }, 'replaceIn')
            }}
            onInput={(e: FormEvent<HTMLElement>) => {
              if (e.nativeEvent.target) {
                // @ts-ignore
                const val = e.nativeEvent.target.value
                if (val !== query.search) {
                  const search = val ? val : undefined
                  setQuery({ search }, 'replaceIn')
                }
              }
            }}
            style={{
              minWidth: 150,
              maxWidth: 250
            }}
          />
        </InputLabel>
        {!collapsed &&
          <>
            <InputLabel>
              <SearchAccounts
                onChange={(val?: string[]) => {
                  setQuery({ owner: val }, 'replaceIn')
                }}
                value={query.owner}
                excludeSelf={false}
                accountType={'all'}
                style={{
                  minWidth: 150,
                  maxWidth: 250
                }}
              />
            </InputLabel>
            <InputLabel>
              <KeywordSelect
                style={{
                  minWidth: 150,
                  maxWidth: 250
                }}
                value={query.keywords}
                onChange={(val?: string[]) => {
                  setQuery({ keywords: val }, 'replaceIn')
                }}
              />
            </InputLabel>
            <QueryDropdown
              sortEnum={sortEnum}
              query={query}
              setQuery={setQuery}
            />
          </>
        }
      </Space>
      {extraAction && !collapsed &&
        <>
          {extraAction}
        </>
      }
    </Row>
  )
}
