import React, { useMemo } from 'react'
import { Column } from '@ant-design/charts'
import { PieChartOutlined } from '@ant-design/icons'
import { Col, Row, Space, Typography, Grid } from 'antd'

import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

import { formatHoursMinutes } from 'utils/formatValues'

import { RunStatusEnum } from '@pollination-solutions/pollination-sdk'
import { RunUtils } from 'components/Run'

dayjs.extend(duration)

const columnChartConfig = {
  autoFit: true,
  isStack: true,
  height: 200,
  xAxis: { type: 'time' },
  xField: 'date',
  yField: 'value',
  seriesField: 'category',
  smooth: true,
  colorField: 'category',
  color: (row: any): string => {
    switch (row.category.toLowerCase()) {
      case 'failed':
        return RunUtils.getStatusColor(RunStatusEnum.Failed)
      case 'succeeded':
        return RunUtils.getStatusColor(RunStatusEnum.Succeeded)
      default:
        return RunUtils.getStatusColor(RunStatusEnum.Cancelled)
    }
  },
  legend: false,
}

export const JobsChart = ({ project }): React.ReactElement => {
  const { xs } = Grid.useBreakpoint()

  const usageData = useMemo(() => {
    if (!project.usage || !project.usage.daily_usage) return []
    
    const sorted = project.usage.daily_usage.sort((a, b) => {
      // @ts-ignore
      return new Date(a.date) - new Date(b.date)
    })

    const usage: { order: number, date: string, category: 'succeeded' | 'failed', value: number }[] = []

    sorted.forEach((v, i) => {
      usage.push({
        order: i,
        date: v.date,
        category: 'succeeded',
        value: v.succeeded ?? 0,
      })

      usage.push({
        order: i,
        date: v.date,
        category: 'failed',
        value: v.failed ?? 0,
      })
    })

    return usage

  }, [project])

  const usage = useMemo(() => {
    if (!project.usage || !project.usage.cpu) return '0 minutes'
    return formatHoursMinutes(project.usage?.cpu / 3600)
  }, [project])

  return <div style={{ padding: 4 }}>
      <Row gutter={[12, 8]}>
        <Col>
          {/* <PieChartOutlined style={{ fontSize: 16 }} /> */}
          <Typography.Title level={5}>Usage for the last 30 days</Typography.Title>
        </Col>
        <Col span={24}>
          {/* @ts-ignore */}
          <Column data={usageData} {...columnChartConfig} />
        </Col>
        <Typography.Text type="secondary" italic>CPU usage data includes usage for runs that have previously been deleted.</Typography.Text>
        <Col span={24}>
          <Space >
            <Typography.Text strong>Compute Time:</Typography.Text>
            <Typography.Text>{usage}</Typography.Text>
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            <Typography.Text strong>Run Count:</Typography.Text>
            <Typography.Text>{project.usage ? ((project.usage.succeeded ?? 0) + (project.usage.failed ?? 0)) : 0}</Typography.Text>
          </Space>
        </Col>
      </Row>
    </div>
}