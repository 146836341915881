import React, { FC, useEffect, useState } from 'react'
import { Button, ButtonProps, Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import { UploadOutlined } from '@ant-design/icons'

import { useAuth } from 'reactfire'
import { Loading } from 'atoms'
import { useUpdateImage } from 'hooks'

interface ImageUploadProps {
  endpoint: string
  refetch: () => void
  buttonProps?: Partial<ButtonProps>
  buttonText?: string
}

export const ImageUpload: FC<ImageUploadProps> = ({ endpoint, refetch, buttonProps, buttonText }) => {

  const data = useAuth()

  const [ token, setToken ] = useState<string>()

  const {
    updateImage,
  } = useUpdateImage()

  useEffect(() => {
    data.currentUser?.getIdToken().then((value) => {
      setToken(value)
    })
  }, [data.currentUser])

  const handleUpdateImage = (file: File) => {
    const reader = new FileReader()

    reader.onloadend = (e) => {
      const arrayBuffer = e.target?.result
      const fileType = file.type
      if(!arrayBuffer) return
      const blob = new Blob([arrayBuffer], { type: fileType })

      updateImage( endpoint, blob)
        .then(() => {
          refetch()
        })
    }

    reader.readAsArrayBuffer(file)
  }

  if(!token) return <Loading/>

  return (
    <ImgCrop>
      <Upload
        customRequest={(e) => {
          // @ts-ignore
          handleUpdateImage(e.file)
        }}
        listType="text"
        accept='jpg jpeg png'
        showUploadList={false}
      >
        <Button icon={<UploadOutlined />} {...buttonProps}>{typeof buttonText !== 'undefined' ? buttonText : 'Upload' }</Button>
      </Upload>
    </ImgCrop>
  )
}