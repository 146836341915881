import React, { FC } from 'react'
import { Typography, Space, Button } from 'antd'
import { ExceptionOutlined } from '@ant-design/icons'
import { FileMeta } from '@pollination-solutions/pollination-sdk'
import { usePollinationPanel } from 'pollination-react-io'
import { FileCardProps } from 'pollination-react-io/build/RunDetails/Cards/FileCard'

export interface FailPreviewProps {
  fileMeta: FileMeta
  downloadUrl: string
  message: string
  isCloud?: boolean
  file?: Blob
}

const downloadFile = (blob: Blob, name: string) => {
  const a = document.createElement('a')
  a.href = URL.createObjectURL(blob)
  a.download = name
  a.click()
  a.remove()
}

export const FailPreview: FC<FailPreviewProps> = ({ fileMeta, downloadUrl, message, file = undefined, isCloud = true }) => {
  const { fileExplorer } = usePollinationPanel()

  return (
    <Space direction="vertical"
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyItems: 'center',
        justifyContent: 'center'
      }}>
      <ExceptionOutlined style={{ fontSize: '2.5rem' }} />
      <Typography.Text style={{ marginLeft: '8px', display: 'flex', textAlign: 'center' }}>
        {message}
      </Typography.Text>
      <Space>
        <Typography.Text type="secondary" style={{ marginLeft: '8px' }}>
          {isCloud && 'Please download this file to view it locally.'}
          {!isCloud && 'Please open this file locally.'}
        </Typography.Text>
        <Button
          onClick={() => {
            if (isCloud && !file) {
              fetch(downloadUrl)
              .then(response => response.blob())
              .then(blob => {
                downloadFile(blob, fileMeta.file_name)
              })
            } else if (isCloud && file) {
              downloadFile(file, fileMeta.file_name)
            } else {
              fileExplorer(downloadUrl)
            }
          }}
        >
          {isCloud && 'Download'}
          {!isCloud && 'Open'}
        </Button>
      </Space>
    </Space>
  )
}

export default FailPreview


