import { Col, Input, Row, Space } from 'antd'
import { InputLabel, KeywordSelect, SearchAccounts } from 'atoms'
import { Params, ParamsConfiguration } from 'hooks/usePaginationHistory'
import { QueryDropdown } from 'molecules'
import { useWindowDimensions } from 'pollination-react-io'
import React, { FormEvent, useEffect, useState } from 'react'

interface FlexQueryToolbarProps {
  configuration?: ParamsConfiguration
  defaultParams?: Params
  setParams: (params: Params) => any
  sortEnum?: any
  extraAction?: React.ReactElement
  searchPlaceholder?: string
}

const FlexQueryToolbar = (props: FlexQueryToolbarProps) => {
  const { width } = useWindowDimensions()

  const { configuration, setParams, sortEnum, 
    defaultParams, extraAction, searchPlaceholder } = props

  /**
   * Dropdown data
   */
  const [query, setQuery] = useState<Params | undefined>()

  useEffect(() => {
    query && setParams(query)
  }, [query])

  return (
    <Row style={{ width: '100%' }}>
      <Col flex="auto">
        <Space wrap>
          {/* Extra widget from parent */}
          {extraAction &&
            <>
              {extraAction}
            </>
          }

          {/* Default widgets */}
          {configuration?.search && <InputLabel>
            <Input.Search
              placeholder={searchPlaceholder || 'Search projects...'}
              allowClear
              onSearch={(val: string) => {
                const search = val ? val : undefined
                setParams({ search: search, page: 1 })
              }}
              value={defaultParams?.search}
              onInput={(e: FormEvent<HTMLElement>) => {
                if (e.nativeEvent.target) {
                  // @ts-ignore
                  const val = e.nativeEvent.target.value
                  if (val !== query?.search) {
                    const search = val ? val : undefined
                    setParams({ search: search, page: 1 })
                  }
                }
              }}
              style={{
                minWidth: 100,
                maxWidth: 170
              }}
            />
          </InputLabel>}

          {configuration?.keywords && <InputLabel>
            <KeywordSelect
              style={{
                minWidth: 150,
                maxWidth: 250
              }}
              value={defaultParams?.keywords}
              onChange={(val: string[]) => {
                setParams({ keywords: val, page: 1 })
              }}
            />
          </InputLabel>}
          
          {configuration?.owner && <InputLabel>
            <SearchAccounts
              onChange={(val?: string[]) => {
                setParams({ owner: val })
              }}
              value={defaultParams?.owner}
              excludeSelf={false}
              accountType={'all'}
              style={{
                minWidth: 150,
                maxWidth: 250
              }}
            />
          </InputLabel>}
          
          {sortEnum && <QueryDropdown
            sortEnum={sortEnum}
            // @ts-ignore
            query={defaultParams}
            setQuery={setQuery}
            filter={[configuration?._public, 
              configuration?.sortBy, configuration?.sortOrder]}
          />}
        </Space>
      </Col>
    </Row>
  )
}

export default FlexQueryToolbar