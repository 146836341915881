import React, { useEffect, useState } from 'react'
import { Badge } from 'antd'
import { isRegExp } from 'util'

//default to count if countFetch is not defined
interface BadgeCountProps {
  accountName: string
  countFetch?: (a: string) => Promise<number>
  count?: number
}

const BadgeCount: React.FunctionComponent<BadgeCountProps> = ({ accountName, countFetch, count }) => {

  const [status, setStatus] = useState<{
    loading: boolean
    count: number
    error?: Error
  }>({ loading: false, count: count || 0 })

  const fetchCount = (accountName: string, countFetch: (a: string) => Promise<number>) => {
    setStatus({ ...status, loading: true })
    //@ts-ignore
    countFetch(accountName).then((count) =>
      setStatus({
        loading: false,
        count: count
      })
    )
      .catch((error: Error) => {
        setStatus({ loading: false, count: 0, error: error })
      })
  }

  useEffect(() => {
    if (typeof count !== 'undefined' || typeof countFetch === 'undefined') return
    if (accountName && typeof countFetch !== 'undefined') {
      fetchCount(accountName, countFetch)
    }
  }, [accountName, countFetch])

  return (
    <Badge showZero count={status.loading ? 0 : status.count} offset={[6, 0]} style={{ color: '#242425', backgroundColor: '#E8EAEC' }} />
  )
}

export default BadgeCount