import React, { CSSProperties, useMemo } from 'react'
import { Grid, Row, Col } from 'antd'
import { AccountPublic, } from '@pollination-solutions/pollination-sdk'

import { useQuotas } from 'hooks'
import { Loading } from 'atoms'
import { getLabel } from 'hooks/useQuotas'
import { QuotaCard } from 'molecules'


export interface UsageSummaryProps {
  account: AccountPublic,
}

export const cardColumnSettings: CSSProperties = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}

export const UsageSummaryDashboard = ({ account }: UsageSummaryProps): React.ReactElement => {
  const screens = Grid.useBreakpoint()

  const { loading, quotas: allQuotas } = useQuotas(account)

  const quotas = useMemo(() => {
    const included = ['compute_hours', 'storage', 'application_cpu', 'rhino_plugin_license', 'revit_plugin_license',]
    if (account.account_type == 'org') {
      included.push('members')
    }
    return allQuotas?.filter(val => included.includes(val.type))
  }, [allQuotas, account])

  return (
    <Row justify='space-between' gutter={[24, 24]}>
      {
        loading || !quotas ?
          <Loading />
          :
          quotas.map((quota, i, arr) => (
            <Col span={screens.md ? 8 : 24} key={quota.id + '-col'}>
              <QuotaCard quota={quota} label={getLabel(quota.type)} key={quota.id + '-card'} />
            </Col>
          ))
      }
    </Row>
  )
}
