import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import { Row, Col, Space, Typography } from 'antd'
import { TeamOutlined, UserOutlined } from '@ant-design/icons'

import { Team } from '@pollination-solutions/pollination-sdk'

import { useAuth } from 'auth-context'

import Tabs from 'components/Tabs'
import { Loading, Error } from 'atoms'

import TeamMembers from './Members'

export const TeamPage = (): React.ReactElement => {
  // @ts-ignore
  const { accountName, teamName } = useParams()
  const { client } = useAuth()

  const [loading, setLoading] = useState<boolean>(true)
  const [fetchError, setFetchError] = useState<any | undefined>()
  const [team, setTeam] = useState<Team | undefined>()
  const [tabs, setTabs] = useState<any[]>([])


  useEffect(() => {
    client.teams.getTeam({
      orgName: accountName,
      teamSlug: teamName
    })
      .then(({ data }) => {
        setTeam(data)
        setTabs([{
          icon: <UserOutlined />,
          label: 'Members',
          content: <TeamMembers owner={accountName} team={teamName} />
        }])
      })
      .catch((err) => setFetchError(err))
      .finally(() => setLoading(false))
  }, [accountName, teamName])

  if (loading) {
    return <Loading />
  }


  return (
    <>
      <Row justify="space-between">
        <Col lg={24} xl={{ span: 16, offset: 4 }}>
          <Space wrap size='small'>
            <TeamOutlined style={{ fontSize: '20px' }} />
            <Typography.Text style={{ fontSize: '20px' }}>
              <Link to={`/${accountName}?tab=projects`}>{accountName}</Link>
            </Typography.Text>
            <Typography.Text style={{ fontSize: '20px' }} >/</Typography.Text>
            <Typography.Text strong style={{ fontSize: '20px' }}>
              <Link to={`/${accountName}/teams/${teamName}`}>{teamName}</Link>
            </Typography.Text>
          </Space>
        </Col>
      </Row>
      {
        (fetchError || !team) ? (
          <Error error={fetchError} />
        ) : (
        <Col lg={24} xl={{ span: 16, offset: 4 }}>
          <Space direction="vertical" style={{ width: '100%' }} size='small'>
            <Typography.Text>
              {team.description}
            </Typography.Text>
            <Tabs tabs={tabs} />
          </Space>
        </Col>)
      }
    </>
  )
}