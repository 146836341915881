import React, { FC, useMemo, useState, useEffect } from 'react'
import { RouteChildrenProps } from 'react-router'
import { Button, Tooltip } from 'antd'
import { FolderOutlined, NodeIndexOutlined,
  AppstoreAddOutlined, 
  TeamOutlined, UserOutlined, 
  SettingOutlined, ThunderboltOutlined, 
  CodeFilled } from '@ant-design/icons'
import { useAuth } from 'auth-context'
import Tabs from 'components/Tabs'
import { Loading, NotFound } from 'atoms'
import { MemberList, TeamList, OrganizationContext } from 'components/Organization'
import BadgeCount from 'components/BadgeCount/BadgeCount'

import ProjectList from './ProjectList'
import RecipeList from './RecipeList'
import PluginList from './PluginsList'
import Settings from './Settings'
import Subscription from './Subscription'

import ProfileSummary from 'atoms/ProfileSummary'
import { useAccount } from 'hooks'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { ProfileTemplate } from 'templates'
import { useFlags } from 'hooks/useFlags'
import { AppsList } from 'components/Apps'
import { useHistory, useLocation } from 'react-router-dom'

interface ProfileProps extends RouteChildrenProps {
  foo?: string
}

const devSearch = [ 
  '?tab=apps', 
  '?tab=plugins', 
  '?tab=recipes' 
]

export const ProfilePage: FC<ProfileProps> = () => {
  const [enableDevMode, setEnableDevMode] = useState(false)
  const history = useHistory()
  const location = useLocation()

  const [childRefresh, setChildRefresh] = useState(false)

  /**
   * Auto enable dev mode if navigation by link
   */
  useEffect(() => {
    if (enableDevMode) return
    
    if (devSearch.includes(location.search)) {
      setEnableDevMode(true)
    }
  }, [location.search])

  const handleOnClick = () => {
    setEnableDevMode((prev: boolean) => {
      const val = !prev
      // Go to project if tab is apps/plugins/recipes
      if (devSearch.includes(location.search) && !val) {
        history.push('?tab=projects')
      }
      return val
    })
  }

  const { client } = useAuth()

  const screens = useBreakpoint()

  const { flags } = useFlags()

  const { 
    loading, 
    canWrite, 
    canRead, 
    account, 
    fetchAccount, 
    updateAccount, 
    deleteAccount,
    organization 
  } = useAccount()

  const tabs = useMemo(() => {
    if (!account) return undefined

    const getAppsCount = (accountName: string) =>
      client.applications.listApplications({ owner: [accountName] })
        .then(({ data }) => data.total_count)

    const getProjectsCount = (accountName: string) =>
      client.projects.listProjects({ owner: [accountName] })
        .then(({ data }) => data.total_count)

    const getRecipesCount = (accountName: string) =>
      client.recipes.listRecipes({ owner: [accountName] })
        .then(({ data }) => data.total_count)

    const getPluginsCount = (accountName: string) =>
      client.plugins.listPlugins({ owner: [accountName] })
        .then(({ data }) => data.total_count)

    const devTabsArray = [
      {
        label: 'Apps',
        icon: <AppstoreAddOutlined />,
        content: account ? <AppsList owner={[account.name]} /> : <Loading />,
        badge: <BadgeCount accountName={account?.name} countFetch={getAppsCount} />
      },
      {
        label: 'Recipes',
        icon: <NodeIndexOutlined />,
        content: <RecipeList owner={account?.name} />,
        badge: <BadgeCount accountName={account?.name} countFetch={getRecipesCount} />
      },
      {
        label: 'Plugins',
        icon: <AppstoreAddOutlined />,
        content: <PluginList owner={account?.name} />,
        badge: <BadgeCount accountName={account?.name} countFetch={getPluginsCount} />
      }
    ]

    const tabsArray = [
      {
        label: 'Projects',
        icon: <FolderOutlined />,
        content: <ProjectList canWrite={canWrite} owner={account?.name} />,
        badge: <BadgeCount accountName={account?.name} countFetch={getProjectsCount} />
      }
    ]

    // account?.account_type === 'org' &&
    if (typeof organization !== 'undefined' && canWrite && account) {
      tabsArray.push(
        {
          label: 'Members',
          icon: <UserOutlined />,
          content: <MemberList org={organization} permission={organization.role} canWrite={canWrite} />,
          badge: <BadgeCount accountName={account?.name} count={organization.member_count} />
        }
      )

      tabsArray.push(
        {
          label: 'Teams',
          icon: <TeamOutlined />,
          content: <TeamList org={organization} canWrite={canWrite} />,
          badge: <BadgeCount accountName={account?.name} count={organization.team_count} />
        }
      )
    }

    if (canWrite && account) {
      tabsArray.push({
        label: 'Settings',
        icon: <SettingOutlined />,
        content: <Settings 
          account={account} 
          updateAccount={updateAccount} 
          deleteAccount={deleteAccount}
          refetch={fetchAccount} />,
        badge: <></>
      })

      tabsArray.push({
        label: 'Subscription',
        icon: <ThunderboltOutlined />,
        content: <Subscription account={account} />,
        badge: <></>
      })
    }

    return enableDevMode 
      ? [ ...tabsArray, ...devTabsArray ]
      : tabsArray
  }, [
    account, 
    canWrite, 
    client.applications, 
    client.plugins, 
    client.projects, 
    client.recipes, 
    fetchAccount, 
    flags, 
    organization, 
    updateAccount,
    enableDevMode
  ])

  if (loading) {
    return <Loading />
  }

  if (!loading && typeof account === 'undefined') {
    return <NotFound />
  }

  return (
    <OrganizationContext.Provider value={{
      refresh: childRefresh,
      setRefresh: setChildRefresh
    }}>
    <ProfileTemplate
      leftColumn={
        <div
          style={(screens.xs || !screens.lg) 
            ? { height: 'fit-content', 
              padding: 12,
              backgroundColor: '#fff',
              borderRadius: 5,
              marginTop: 34
            } 
            : { height: 'fit-content', 
              top: 24, 
              alignSelf: 'flex-start', 
              position: 'sticky',
              boxShadow: '0 3px 6px rgba(140,149,159,0.15)',
              maxWidth: 500,
              padding: 12,
              backgroundColor: '#fff',
              borderRadius: 5,
              marginTop: 34
            }}
        >
          <ProfileSummary 
            account={account} 
            refresh={childRefresh} 
            isAdmin={canWrite} 
            isMember={canRead} />
        </div>
      }
      tabs={
        tabs ? <Tabs tabs={tabs} extraContent={
          { right: <Tooltip 
              placement='left'
              title='Dev features'>
              <Button
                type='text'
                size='small'
                style={{ backgroundColor: 'rgba(0,0,0,0)' }}
                onClick={handleOnClick}
              >
                <CodeFilled style={{ fontSize: 16 }}/>
              </Button>
              </Tooltip> }
        } /> : undefined
      }
    />
    </OrganizationContext.Provider>
  )
}
