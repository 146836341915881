import React, { useCallback } from 'react'

import { useAuth } from 'auth-context'
import { FileMeta } from '@pollination-solutions/pollination-sdk'
import { FileWithPath } from 'react-dropzone'

export const useArtifacts = (owner: string, name: string) => {

  const { client } = useAuth()

  const listArtifacts = useCallback((path: string | undefined) => {
    let key: string[] | undefined = undefined
    if (path) {
      key = [path]
    }
    return client.artifacts.listArtifacts({
      owner,
      name,
      page: 1,
      perPage: 1000,
      path: key
    }).then(r => r.data.resources)
  }, [client.artifacts, owner, name])

  const downloadArtifact = useCallback((path: string) => {
    return client.artifacts.downloadArtifact({
      owner,
      name,
      path
    }).then(r => r.data)
  }, [client.artifacts, owner, name])

  const deleteArtifact = useCallback(async (path: string) => {
    let key: string[] | undefined = undefined
    if (path) {
      key = [path]
    }

    await client.artifacts.deleteArtifact({
      owner,
      name,
      path: key
    })
  }, [client.artifacts, owner, name])

  const uploadArtifact = useCallback(async (folderRoot: FileMeta | undefined, file: FileWithPath) => {
    let filePath = file.path || file.name

    if (!filePath) {
      return
    }

    // folder file paths start with "/" which
    // must be removed before merging string with rootkey
    if (filePath.startsWith('/')) {
      filePath = filePath.slice(1)
    }

    const rootKey = folderRoot ? folderRoot.key : ''
    let uploadKey = filePath
    if (rootKey.endsWith('/')) {
      uploadKey = `${rootKey}${filePath}`
    } else if (rootKey !== '') {
      uploadKey = `${rootKey}/${filePath}`
    }

    // TODO: could refactor using .then() instead of await 
    const result = await client.artifacts.createArtifact({
      owner,
      name,
      keyRequest: { key: uploadKey }
    })

    const { url, fields } = result.data
    const payload = new FormData()
    Object.keys(fields).forEach((key) => payload.append(key, fields[key]))
    payload.append('file', file)

    await fetch(url, {
      method: 'POST',
      mode: 'no-cors',
      body: payload,
    })

    return result.data

  }, [client.artifacts, owner, name])

  return {
    listArtifacts,
    deleteArtifact,
    downloadArtifact,
    uploadArtifact
  }
}