import React, { useEffect, Suspense } from 'react'

import {
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom'

import { useAnalytics } from 'reactfire'

import { logEvent } from 'firebase/analytics'

import { useAuth } from 'auth-context'
import { Loading } from 'atoms'
import SignIn from 'components/SignIn'
import SignUp from 'components/SignUp'

import { Dashboard } from './dashboard'

import {
  PluginPage,
  RecipePage,
  ProjectPage,
  TeamPage,
  JobPage,
  RunPage,
  NewProject,
  NewTeam,
  NewJob,
  SDKLogin,
  CADPlugins,
  LicensePoolsAdmin,
  ProfilePage,
  CreateOrg,
  ApplicationPage,
  LocalRunPage,
  CreateApplication,
} from '../pages'

import AccountProvider from 'hooks/useAccountProvider'
import { withFeature } from 'atoms/Feature'
import { JobWorkspacePage } from 'pages/job-workspace'

function PageViewLogger() {
  const analytics = useAnalytics()
  const location = useLocation()

  // By passing `location.pathname` to the second argument of `useEffect`,
  // we only log on first render and when the `pathname` changes
  useEffect(() => {
    logEvent(analytics, 'page-view', { path_name: location.pathname })
  }, [analytics, location.pathname])

  return null
}

const AuthCheck: React.FunctionComponent = (props) => {
  const { loggedIn, registered, loading } = useAuth()

  if (loading) {
    return <Loading />
  }

  if (!loggedIn) {
    return <SignIn />
  }

  if (loggedIn && !registered) {
    return <SignUp />
  }

  return (
    <>{props.children}</>
  )
}

const ScrollToTop: React.FunctionComponent = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

const Routes = () => {

  const location = useLocation()

  return (
    <Suspense fallback={<Loading />}>
      <ScrollToTop />
      <AuthCheck>
        <Switch>
          <Route exact path="/sign-up" component={SignUp} />
          <Route exact path="/sdk-login" component={SDKLogin} />
          <Route exact path="/licenses" component={LicensePoolsAdmin} />
          <Route exact path="/cad-plugins" component={CADPlugins} />

          <Route exact path="/:accountName/new/application" component={CreateApplication} />

          <Route exact path="/:accountName/new/project" component={NewProject} />
          <Route exact path="/:accountName/new/team" component={NewTeam} />

          <Redirect exact from="/new/job" to="/new/study" />
          <Route exact path="/new/study" component={NewJob} />
          <Route exact path="/:accountName/new/study" component={NewJob} />

          <Route exact path="/apps" component={Dashboard} />
          <Route exact path="/projects" component={Dashboard} />
          <Route exact path="/recipes" component={Dashboard} />
          <Route exact path="/plugins" component={Dashboard} />

          <Redirect exact from="/plugins/:accountName/:pluginName" to="/:accountName/plugins/:pluginName" />
          <Redirect exact from="/recipes/:accountName/:recipeName" to="/:accountName/recipes/:recipeName" />

          <Redirect exact from="/projects/:accountName/:projectName/jobs/:jobId/runs/:runId" to="/:accountName/projects/:projectName/studies/:studyId/runs/:runId" />
          <Redirect exact from="/projects/:accountName/:projectName/jobs/:jobId/runs" to="/:accountName/projects/:projectName/studies/:studyId" />
          <Redirect exact from="/projects/:accountName/:projectName/jobs/:jobId" to="/:accountName/projects/:projectName/studies/:studyId" />

          <Redirect exact from="/projects/:accountName/:projectName" to="/:accountName/projects/:projectName" />

          <Redirect exact from="/:accountName/projects/:projectName/jobs/:studyId/runs/:runId"
            to={{
              ...location,
              pathname: location.pathname.replace('jobs', 'studies')
            }}
          />
          <Redirect exact from="/:accountName/projects/:projectName/jobs/:jobId/runs"
            to={{
              ...location,
              pathname: location.pathname.replace('jobs', 'studies')
            }}
          />
          <Redirect exact from="/:accountName/projects/:projectName/jobs/:jobId"
            to={{
              ...location,
              pathname: location.pathname.replace('jobs', 'studies')
            }}
          />

          <Route exact path="/" component={Dashboard} />

          <Route path="/:accountName">
            <Switch>
              <AccountProvider>

                <Route path="/:accountName/teams/:teamName" component={TeamPage} />
                <Route path="/:accountName/plugins/:pluginName" component={PluginPage} />
                <Route path="/:accountName/recipes/:recipeName" component={RecipePage} />

                <Route exact path="/:accountName/projects/:projectName/studies/:studyId/runs/:runId" component={RunPage} />

                {/* Local study page */}
                <Route exact path="/:accountName/projects/:projectName/localStudies/:studyId" component={LocalRunPage} />

                <Route exact path="/:accountName/projects/:projectName/studies/:studyId/runs" component={JobPage} />
                <Route exact path="/:accountName/projects/:projectName/studies/:studyId" component={JobPage} />
                <Route exact path="/:accountName/projects/:projectName/studies/:studyId/workspace" component={JobWorkspacePage} />

                <Route exact path="/:accountName/projects/:projectName" component={ProjectPage} />

                <Route path="/:accountName/apps/:applicationName" component={ApplicationPage} />

                <Route exact path="/:accountName/licenses" component={LicensePoolsAdmin} />
                <Route exact path="/:accountName/create-org" component={withFeature('cloud')(CreateOrg)} />
                <Route exact path="/:accountName/" component={ProfilePage} />
              </AccountProvider>

            </Switch>
          </Route>
        </Switch>
      </AuthCheck>
      <PageViewLogger />
    </Suspense >
  )
}

export default Routes
