import React, { useState, useEffect, useCallback } from 'react'

import { useAuth } from 'auth-context'

import { notification } from 'antd'
import { UserPublic, LicensePoolAccessPolicy, LicensePoolPolicySubject, LicensePoolPublic, LicensePoolUpdate, LicensesApiGetAvailablePoolsRequest, Permission, SubjectType, } from '@pollination-solutions/pollination-sdk'

const useLicenses = () => {
  const { client } = useAuth()

  const [loading, setLoading] = useState<boolean>(false)

  const [licensePools, setLicensePools] = useState<LicensePoolPublic[]>([])

  const [query, setQuery] = useState<LicensesApiGetAvailablePoolsRequest>()

  const getLicensePools = useCallback((query) => {
    setLoading(true)

    client.licenses.getAvailablePools(query)
      .then(({ data }) => {
        setLicensePools(data.resources)
      })
      .finally(() => setLoading(false))
  }, [client.licenses])

  const getActivations = useCallback(async (poolId: string) => {

    return await client.licenses.getLicenseActivations({ poolId })
      .then(({ data }) => {
        return data
      })

  }, [client.licenses])

  const grantPoolAccess = useCallback(async (poolId?: string, user?: UserPublic[]) => {
    if (!poolId || !user) return

    setLoading(true)

    const licensePoolAccessPolicies: LicensePoolAccessPolicy[] = user.map((u) => {
      const accessPolicy = {
        permission: 'read' as Permission,
        subject: {
          subject_type: SubjectType.User,
          name: u.username,
        }
      }
      return accessPolicy
    })

    const licensePoolAccessPolicyList = {
      resources: licensePoolAccessPolicies
    }

    return client.licenses.grantAccessToPool({ poolId, licensePoolAccessPolicyList })
      .then(({ data }) => {
        setLicensePools((pools: LicensePoolPublic[]) => {
          const index = pools.findIndex(p => p.id === data.id)
          const newState = [...pools] as LicensePoolPublic[]
          newState[index] = data
          return newState
        })
      })
      .catch((e) => {
        notification.error({
          message: 'Error adding a member to the license pool.'
        })
      })
      .finally(() => setLoading(false))

  }, [client.licenses])

  const revokePoolAccess = useCallback((poolId?: string, accountName?: string) => {
    if (!poolId || !accountName) return

    setLoading(true)

    const licensePoolPolicySubject: LicensePoolPolicySubject = {
      subject_type: 'user' as SubjectType,
      name: accountName,
    }

    const licensePoolPolicySubjectList = {
      resources: [licensePoolPolicySubject]
    }

    client.licenses.revokeAccessToPool({ poolId, licensePoolPolicySubjectList })
      .then(({ data }) => {
        setLicensePools((pools: LicensePoolPublic[]) => {
          const index = pools.findIndex(p => p.id === data.id)
          const newState = [...pools] as LicensePoolPublic[]
          newState[index] = data
          return newState
        })
      })
      .catch((e) => {
        notification.error({
          message: 'Error removing a member to the license pool.'
        })
      })
      .finally(() => setLoading(false))

  }, [client.licenses])

  const cancelActivation = useCallback(async (poolId: string, activationId: string) => {
    return client.licenses.deleteActivation({ poolId, activationId })
  }, [])

  const updateLicensePool = useCallback(async (poolId?: string, description?: string) => {
    if (!poolId || typeof description === 'undefined') return

    const licensePoolUpdate: LicensePoolUpdate = {
      description: description
    }

    return await client.licenses.updateLicensePool({ poolId, licensePoolUpdate })
      .then((data) => {
        getLicensePools(query)
        return data
      })

  }, [client.licenses, getLicensePools, query])

  const regenerateLicensePool = useCallback(async (poolId: string) => {

    return await client.licenses.regenerateLicensePool({ poolId })
      .then((result) => {
        notification.success({
          message: 'Successfully renewed license pool.'
        })
      })
      .catch((error) => {
        notification.error({
          message: 'Error renewing your license pool. Please try again.'
        })
      })

  }, [client.licenses])

  useEffect(() => {
    getLicensePools(query)
  }, [getLicensePools, query])

  return {
    loading,
    licensePools,
    grantPoolAccess,
    revokePoolAccess,
    getActivations,
    cancelActivation,
    updateLicensePool,
    regenerateLicensePool,
    setQuery
  }
}

export default useLicenses
