import React, { FC, ReactNode, useEffect } from 'react'

import { Input, List, Typography, Tag } from 'antd'
import { Loading3QuartersOutlined } from '@ant-design/icons'

import debounce from 'lodash.debounce'
import { Empty } from 'atoms'

import flexStyles from 'styles/flex.module.css'

export interface AssetListProps {
  title: string
  loading: boolean
  assets: any[]
  footer: { text: string, link: string }
  setQuery: (...args) => any
  renderItem: (...args) => ReactNode
  showSearch?: boolean
}

export const AssetList: FC<AssetListProps> = ({
  title,
  loading,
  assets,
  footer,
  setQuery,
  renderItem,
  showSearch = true
}) => {

  const searchRecentApps = debounce((e) => {
    setQuery(state => ({
      ...state,
      search: e.target.value
    }))
  }, 1000)

  return (
    <div className={flexStyles['poll-flex-column']} style={{ display: 'flex' }}>
      <div className={flexStyles['poll-flex-row']} style={{ justifyContent: 'space-between' }} > 
        <Typography.Title level={5} style={{ marginBottom: 0 }}>{title}</Typography.Title>
      </div>
      {!loading && showSearch &&
        <Input
          type='search' 
          placeholder='Search Apps...'
          allowClear={true}
          onChange={searchRecentApps}
          suffix={
            loading &&
              <Loading3QuartersOutlined spin style={{ color: '#d0d7de' }} />
          }
          style={{
            marginBottom: 12,
          }}
        />
      }
      <List
        loading={loading}
        dataSource={assets ? assets : []}
        split={false}
        style={{ width: '100%' }}
        locale={{
          emptyText: <Empty description={
            <Typography.Link href='https://docs.pollination.solutions/user-manual/apps/introduction'
              target='_blank' referrerPolicy='no-referrer'
            >
                  Want to build Apps?<br/>Get started with the docs!
            </Typography.Link>
          } />
        }}
        renderItem={renderItem}
      />
      <div className={flexStyles['poll-flex-row']} style={{ justifyContent: 'center' }} >
        <Typography.Link type='secondary' href={footer.link}>
          {loading ? 'Loading...' :  footer.text}
        </Typography.Link>
      </div>
    </div>
  )
}

export default AssetList
