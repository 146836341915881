import React, { useMemo } from 'react'

import { Link } from 'react-router-dom'

import { Row, Col, Space, Typography, Tag } from 'antd'
import { DeploymentUnitOutlined, SettingOutlined, OrderedListOutlined, LinkOutlined, ThunderboltFilled, UserAddOutlined, AppstoreAddOutlined } from '@ant-design/icons'

import Tabs from 'components/Tabs'

import { Loading, NotFound } from 'atoms'
import { ApplicationSettings, ApplicationVersions } from 'organisms'
import { useApplications } from 'hooks'
import { ApplicationAccess } from 'organisms/ApplicationAccess'
import useApplication from 'hooks/useApplication'
import ApplicationStatus from './ApplicationStatus'

type Tab = {
    label: string
    icon: JSX.Element
    content: JSX.Element
}

export const Page = () => {
    const {
        fetchApplicationVersions
    } = useApplications()

    const { loading, application } = useApplication()

    const tabs = useMemo(() => {
        const t = [{
            label: 'Status',
            icon: <DeploymentUnitOutlined />,
            content: <ApplicationStatus />
        }] as Tab[]

        if (application && application.permissions && application.permissions.admin) {
            t.push({ label: 'Settings', icon: <SettingOutlined />, content: <ApplicationSettings /> })
            t.push({ label: 'Versions', icon: <OrderedListOutlined />, content: <ApplicationVersions application={application} fetchApplicationVersions={fetchApplicationVersions} /> })

            if (application.owner.account_type === 'org') {
                t.push({ label: 'Access', icon: <UserAddOutlined />, content: <ApplicationAccess application={application} /> })
            }
        }
        return t
    }, [application, fetchApplicationVersions])


    if (loading) return <Loading />

    if (!application) return <NotFound />

    return (
        <Row justify="space-between" gutter={[16, 16]}>
            <Col span={24}>
                <Space wrap align='center'>
                    <AppstoreAddOutlined />
                    <Typography.Text style={{ fontSize: '20px' }}>
                        <Link to={`/${application.owner.name}`}>{application.owner.name}</Link>
                    </Typography.Text>
                    <Typography.Text style={{ fontSize: '20px' }} >/</Typography.Text>
                    <Typography.Text strong style={{ fontSize: '20px' }}>
                        <Link to={`/${application.owner.name}/apps/${application.slug}`}>{application.name}</Link>
                    </Typography.Text>
                    {
                        // @ts-ignore
                        !application.deployment_config?.login_required ?
                            <Tag color='green'><ThunderboltFilled style={{ color: '#52c41b' }} /> Public to Everyone</Tag> :
                            // @ts-ignore
                            application.public ?
                                <Tag color='geekblue'>Public on Pollination</Tag> :
                                <Tag color='green'><ThunderboltFilled style={{ color: '#52c41b' }} /> Private</Tag>
                    }
                </Space>
            </Col>
            <Col span={24}>
                <Tabs tabs={tabs} />
            </Col>
        </Row>
    )
}
