import React, { useCallback } from 'react'
import dayjs from 'dayjs'

import {
  Skeleton,
  Table, Tag, Typography
} from 'antd'

import { Subscription, Payment } from '@pollination-solutions/pollination-sdk'
import { Breakpoint } from 'antd/lib/_util/responsiveObserve'
import { useAuth } from 'auth-context'
import useSWR from 'swr'
import numbro from 'numbro'

interface PaymentTableProps {
  subscription: Subscription
  loading: boolean
}

export const PaymentTable: React.FunctionComponent<PaymentTableProps> = ({ subscription }) => {
  const { client } = useAuth()

  const fetchSubscriptionPayments = useCallback((subscriptionId: string) => {
    return client.subscriptions.listSubscriptionPayments({ subscriptionId })
      .then(({ data }) => {
        return data.reverse() as Payment[]
      })
  }, [client.subscriptions])

  const { data: payments, isValidating } = useSWR(subscription ? [subscription.id, 'listSubscriptionPayments'] : null,
    fetchSubscriptionPayments, {
    revalidateOnFocus: false,
    errorRetryInterval: 1000,
    errorRetryCount: 3,
    fallbackData: [],
  })

  const columns = [
    {
      title: 'Date',
      dataIndex: 'payout_date',
      key: 'payout_date',
      render: (d) => {
        return dayjs(d).format('DD MMMM YYYY')
      },
      responsive: ['xs', 'sm'] as Breakpoint[]
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount, record) => (<Typography.Text>{`${numbro(amount).formatCurrency()} ${record.currency}`}</Typography.Text>),
      responsive: ['xs', 'sm'] as Breakpoint[]
    },
    {
      title: 'Status',
      dataIndex: 'is_paid',
      key: 'is_paid',
      render: (is_paid) => (is_paid ? <Tag color="success">Paid</Tag> : <Tag color="processing">Upcoming</Tag>),
      responsive: ['xs', 'sm'] as Breakpoint[]
    },
    {
      title: 'Receipt',
      dataIndex: 'receipt_url',
      key: 'receipt_url',
      render: (link, record) => (record.is_paid && <Typography.Link href={link} target="_blank">Link</Typography.Link>),
      responsive: ['sm'] as Breakpoint[]
    },
  ]

  return (
    <Skeleton active loading={isValidating}>
      <Table
        columns={columns}
        dataSource={payments}
        pagination={{
          pageSize: 5,
          size: 'small',
          hideOnSinglePage: true
        }}
      />
    </Skeleton>
  )
}

export default PaymentTable
