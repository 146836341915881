import React from 'react'
import { Repository } from '@pollination-solutions/pollination-sdk'
import { List } from 'antd'

import RepositoryCard from './Card'

interface CardListProps {
  repositories: Repository[]
  type: string
}

const CardList: React.FunctionComponent<CardListProps> = ({ repositories, type }) => {
  return (
    <List
      dataSource={repositories}
      renderItem={(item): React.ReactNode => <RepositoryCard key={item.slug} reference={item} type={type as 'recipe' | 'plugin'} />}
      itemLayout="vertical"
    ></List>
  )
}

export default CardList
