import React, { useCallback } from 'react'

import { useAuth } from 'auth-context'

import { AccountPublic, PlanType, CADLicenseUsage } from '@pollination-solutions/pollination-sdk'
import { notification } from 'antd'

type UsageReport = {
  data: Array<Record<string, any>>
}

const triggerDownload = (filename: string, content: string) => {
  const blob = new Blob([content], { type: 'text/csv' })
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  URL.revokeObjectURL(url)
}

const planTypeMap = {
  [PlanType.RhinoPlugin]: 'rhino_plugin',
  [PlanType.RevitPlugin]: 'revit_plugin'
}

const useAccountReport = (account: AccountPublic) => {
  const { client } = useAuth()

  const getReport = useCallback((
    startDate: Date,
    endDate: Date,
    planType: PlanType,
  ) => {
    // Fetch the account report
    const fromDate = startDate.toISOString().split('T')[0]
    const toDate = endDate.toISOString().split('T')[0]
    if (planType === PlanType.CloudCompute) {
      return client.reports.getComputeUsageReport({
        name: account.name,
        fromDate,
        toDate,
      })
    } else if (planType === PlanType.Seat) {
      return client.reports.getOrgMemberUsageReport({
        name: account.name,
      })
    } else if (planType === PlanType.RhinoPlugin || planType === PlanType.RevitPlugin) {
      return client.reports.getCadLicenseUsageReport({
        name: account.name,
        fromDate,
        toDate,
      }).then(({ data }) => {
        const filteredData = data.data.filter((d: CADLicenseUsage) => d.product_name === planTypeMap[planType])
        data.data = filteredData
        return { data }
      })
    } else if (planType === PlanType.BundledPlugin) {
      return client.reports.getCadLicenseUsageReport({
        name: account.name,
        fromDate,
        toDate,
      })
    }
    throw new Error('Invalid report type')

  }, [client, account])

  const reportToCsv = (report: UsageReport) => {
    // Convert the json array into a CSV and use they keys as the header
    const { data } = report
    if (data.length === 0) {
      notification.warn({ message: 'There is no data to export. An empty reported will be generated.' })
      return ''
    }
    const fields = Object.keys(data[0])
    const replacer = function (key, value) { return value === null ? '' : value }
    const csv = data.map(function (row) {
      return fields.map(function (fieldName) {
        return JSON.stringify(row[fieldName], replacer)
      }).join(',')
    })
    csv.unshift(fields.join(',')) // add header column
    return csv.join('\r\n')
  }


  return { getReport, reportToCsv, triggerDownload }
}

export default useAccountReport