import React, { useState, useCallback, useEffect } from 'react'
import useSWR from 'swr'

import { AccountPublic, AccountsApiListQuotasRequest } from '@pollination-solutions/pollination-sdk'

import { useAuth } from 'auth-context'

import { formatBytes, formatHoursMinutes, OptionList } from '../utils/formatValues'
import Icon, { BuildFilled, ContainerFilled, CrownFilled, FolderFilled, LockFilled, RobotFilled, TeamOutlined, UserOutlined } from '@ant-design/icons'
import { rhinoIcon, revitIcon } from 'atoms/Icons'

export declare type QuotaLabel = {
  displayName: string
  description: string
  units: string
  type: 'limit' | 'usage'
  icon: React.ReactElement
  format: (val?: number, options?: any) => string
}

export declare type QuotaLabelMap = {
  [index: string]: QuotaLabel
}

const quotaLabels: QuotaLabelMap = {
  'application_units': {
    displayName: 'App Units',
    description: 'The number of applications that can be deployed to Pollination.',
    units: 'Apps',
    type: 'usage',
    format: (val) => val ? val.toString() : '0',
    icon: <CrownFilled style={{ color: '#52C41A', marginRight: 16, fontSize: '1rem' }} />,
  },
  'application_cpu': {
    displayName: 'App CPUs',
    description: 'Concurrent CPUs available to power paid apps.',
    units: 'CPUs',
    type: 'usage',
    format: (val) => typeof val !== 'undefined' ? val.toString() : '',
    icon: <ContainerFilled style={{ color: '#52C41A', marginRight: 16, fontSize: '1rem' }} />
  },
  'application_memory': {
    displayName: 'App Memory',
    description: 'Memory available to power paid apps.',
    units: 'GB',
    type: 'usage',
    format: (val) => typeof val !== 'undefined' ? formatBytes(val * 1000000) : '0 GB',
    icon: <FolderFilled style={{ color: '#52C41A', marginRight: 16, fontSize: '1rem' }} />,
  },
  'compute_hours': {
    displayName: 'Compute Hours',
    description: 'The cumulative hours of CPU core usage available to the account in the billing period.',
    units: 'hours',
    type: 'usage',
    format: formatHoursMinutes,
    icon: <RobotFilled style={{ color: '#52C41A', marginRight: 16, fontSize: '1rem' }} />
  },
  'cpu_limit': {
    displayName: 'Concurrent CPU Limit',
    description: 'The number of simultaneous CPU cores available to the account.',
    units: 'Concurrent CPUs',
    type: 'limit',
    format: (val?: number, options?: OptionList) => {
      if (typeof val !== 'number') return '--'
      const units = typeof options?.units !== 'undefined' ? options?.units : true
      return units ? val.toFixed(0).toString() + ' CPUs' : val.toFixed(0).toString()
    },
    icon: <BuildFilled style={{ color: '#52C41A', marginRight: 16, fontSize: '1rem' }} />,
  },
  'storage': {
    displayName: 'Data Storage',
    description: 'The total amount of storage space for inputs and outputs of simulations.',
    units: 'GB',
    type: 'usage',
    format: formatBytes,
    icon: <FolderFilled style={{ color: '#52C41A', marginRight: 16, fontSize: '1rem' }} />,
  },
  'private_repositories': {
    displayName: 'Private Recipes',
    description: '',
    units: '',
    type: 'usage',
    format: (val) => val ? val.toString() : '0',
    icon: <ContainerFilled style={{ color: '#52C41A', marginRight: 16, fontSize: '1rem' }} />,
  },
  'private_projects': {
    displayName: 'Private Projects',
    description: '',
    units: '',
    type: 'usage',
    format: (val) => val ? val.toString() : '0',
    icon: <LockFilled style={{ color: '#52C41A', marginRight: 16, fontSize: '1rem' }} />,
  },
  'teams': {
    displayName: 'Teams',
    description: '',
    units: '',
    type: 'usage',
    format: (val) => val ? val.toString() : '0',
    icon: <TeamOutlined style={{ color: '#52C41A', marginRight: 16, fontSize: '1rem' }} />,
  },
  'members': {
    displayName: 'Additional Seats',
    description: 'The number of additional users that can be added to an organization, not counting the original founding member.',
    units: '',
    type: 'usage',
    format: (val) => val ? val.toString() : '0',
    icon: <UserOutlined style={{ color: '#52C41A', marginRight: 16, fontSize: '1rem' }} />,
  },
  'parallel_workflow_containers': {
    displayName: 'Parallel Run Tasks',
    description: 'The number of tasks that can be executed in parallel for a given run.',
    units: '',
    type: 'limit',
    format: (val) => val ? val.toString() : '0',
    icon: <BuildFilled style={{ color: '#52C41A', marginRight: 16, fontSize: '1rem' }} />,
  },
  'rhino_plugin_license': {
    displayName: 'Rhino Licenses',
    description: 'The number of Rhino Plugin licenses that can be used in parallel',
    units: '',
    type: 'limit',
    format: (val) => val ? val.toString() : '0',
    icon: <Icon component={rhinoIcon} style={{ color: '#52C41A', marginRight: 16, fontSize: '1rem' }} />
  },
  'revit_plugin_license': {
    displayName: 'Revit Licenses',
    description: 'The number of Revit Plugin licenses that can be used in parallel',
    units: '',
    type: 'limit',
    format: (val) => val ? val.toString() : '0',
    icon: <Icon component={revitIcon} style={{ color: '#52C41A', marginRight: 16, fontSize: '1rem' }} />
  },
}

export const getLabel: (key: string) => QuotaLabel = (key: string) => {
  const label = quotaLabels[key]
  if (!label) {
    return {
      displayName: key,
      description: '',
      units: '',
      type: 'usage',
      format: (val) => val ? val.toString() : '',
      icon: <CrownFilled style={{ color: '#52C41A', marginRight: 16, fontSize: '1rem' }} />,
    }
  }
  return label
}

const useQuotas = (account?: AccountPublic, defaultQuery?: AccountsApiListQuotasRequest) => {
  const { client } = useAuth()

  const [loading, setLoading] = useState(false)

  const [query, setQuery] = useState<AccountsApiListQuotasRequest | undefined>(account ? { ...{ name: account.name }, ...defaultQuery } : undefined)

  useEffect(() => {
    if (!account) return
    setQuery(state => ({ ...state, name: account.name }))
  }, [account])

  const fetchQuotas = useCallback(
    async (query: AccountsApiListQuotasRequest) => {
      const { data } = await client.accounts.listQuotas(query)
      return data.resources.sort((a, b) => a.type < b.type ? -1 : 1)
    },
    [client.accounts])

  const { data: quotas, error, mutate: refetch } = useSWR(query, fetchQuotas, { errorRetryCount: 3, revalidateOnFocus: false })

  return { loading, quotas, error, refetch, setQuery }
}

export default useQuotas
