import React, { useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router'

import { CloudJob, JobStatusEnum, Project } from '@pollination-solutions/pollination-sdk'

import { useAuth } from 'auth-context'
import { Error } from 'atoms'
import { Loading } from 'atoms'
import Folder from 'components/Folder'
import { StudyCard } from 'pollination-react-io'

export const JobWorkspacePage: React.FunctionComponent = () => {
  const { client, user: authUser } = useAuth()
  const { search } = useLocation()

  // @ts-ignore
  const { accountName, projectName, studyId: jobId } = useParams()

  const [loading, setLoading] = useState<boolean>(true)
  const [fetchError, setFetchError] = useState<any>()
  const [project, setProject] = useState<Project | undefined>()
  const [job, setJob] = useState<CloudJob | undefined>()
  const [toggleFetch, setToggleFetch] = useState<boolean>(false)

  // Declare fetchers
  const fetchJob = (owner: string, name: string, jobId: string): Promise<void> => {
    return client.jobs.getJob({ owner, name, jobId })
      .then(({ data }) => {
        setJob(data)
        if (!data.status) {
          setTimeout(() => setToggleFetch(!toggleFetch), 5000)
        } else {
          switch (data.status.status) {
            case undefined:
            case JobStatusEnum.Completed:
            case JobStatusEnum.Failed:
            case JobStatusEnum.Cancelled:
              break
            default:
              setTimeout(() => setToggleFetch(!toggleFetch), 5000)
              break
          }
        }
      })
  }

  useEffect(() => {
    setLoading(true)
    client.projects.getProject({
      owner: accountName,
      name: projectName,
    }).then(({ data }) => {
      setProject(data)
      fetchJob(accountName, projectName, jobId)
        .catch((err) => setFetchError(err))
        .finally(() => setLoading(false))
    })
  }, [accountName, projectName, jobId])

  useEffect(() => {
    fetchJob(accountName, projectName, jobId)
      .catch((err) => setFetchError(err)
      )
  }, [accountName, jobId, projectName])

  useEffect(() => {
    const pathParam = new URLSearchParams(search).get('path')
    if (!pathParam?.toString()) return
  }, [search])

  const listArtifacts = (path: string | undefined) => {
    let key: string[] | undefined = undefined
    if (path) {
      key = [path]
    }
    return client.jobs.searchJobFolder({
      owner: accountName,
      name: projectName,
      jobId,
      page: 1,
      perPage: 1000,
      path: key
    }).then(r => r.data.resources)
  }

  const downloadArtifact = (path: string) => {
    return client.jobs.downloadJobArtifact({
      owner: accountName,
      name: projectName,
      jobId,
      path
    }).then(r => r.data)
  }

  return (!loading ?
    <>
      {(fetchError || !job || !project) ? (
        <Error error={fetchError} />
      ) : (<>
        {job ?
          <StudyCard
            style={{ width: '100%', backgroundColor: 'white' }}
            projectName={projectName}
            projectOwner={accountName}
            study={job}
            // @ts-ignore
            client={client}
            authUser={authUser}
            showMenu={true}
          />
          : <Loading />}
        {job ?
          <Folder
            listArtifacts={listArtifacts}
            downloadArtifact={downloadArtifact} />
          : <Loading />}
      </>)}
    </>
    : <></>)
}