import React from 'react'
import { Link } from 'react-router-dom'
import { Avatar } from 'antd'

export interface AvatarLinkProps {
  src?: string
  link: string
  size?: number | 'default' | 'small' | 'large' | { xs: number, sm: number, md: number, lg: number, xl: number, xxl: number }
  style?: object
}

export const AvatarLink: React.FunctionComponent<AvatarLinkProps> = ({ src, link, size, style }) => (
  <Link to={link} className="avatar-link" style={style}>
    <Avatar src={src} size={size} className="ant-card-hoverable" />
  </Link>
)