import React, { useState, useEffect, useCallback } from 'react'
import { useParams, useLocation, useHistory } from 'react-router'

import { CloudJob, Project } from '@pollination-solutions/pollination-sdk'

import { StudyCard } from 'pollination-react-io'

import { useAuth } from 'auth-context'
import { Error } from 'atoms'
import { Loading } from 'atoms'
import Folder from 'components/Folder'

import { FolderCardProps } from 'pollination-react-io/build/RunDetails/Cards/FolderCard'
import { JobPageDetails } from './Parts/Details'
import { useAccount } from 'hooks'
import { Col } from 'antd'

enum JobTabs {
  details = 'details',
  workspace = 'workspace'
}

export const JobPage: React.FunctionComponent = () => {
  const { client, user: authUser } = useAuth()
  const { search } = useLocation()
  const history = useHistory()
  const [project, setProject] = useState<Project>()

  const { accountName, projectName, studyId: jobId } = useParams<
    { accountName: string, projectName: string, studyId: string }>()

  /*
   * Query param for first run
   */
  const [currTab, setCurrTab] = useState<JobTabs>(JobTabs[
    new URLSearchParams(search).get('tab') ?? 'details'])

  const setQueryString = (tab: string,
    path: string | undefined = undefined) => {
    const query = new URLSearchParams(search)

    if (query.get('tab')) {
      query.set('tab', tab)

      if (tab !== JobTabs.workspace) {
        query.delete('path')
      } else {
        path && query.set('path', path) // optional path
      }

    } else {
      query.append('tab', tab)
    }

    history.push({
      search: query.toString()
    })
  }

  useEffect(() => {
    // Get tab from history
    setCurrTab(JobTabs[new URLSearchParams(search).get('tab') ?? 'details'])
  }, [search])

  const getTab = useCallback((tab: string) => {
    setQueryString(tab)
    setCurrTab(JobTabs[tab])
  }, [currTab])

  
  /*
   * Fetchers 
   */
  const fetchJob = useCallback((owner: string, name: string, jobId: string): void => {
    if (!client || !client.runs) return

    // Fetch the project to get IO info
    client.projects.getProject({
      name: projectName,
      owner: accountName
    })
    .then(({ data }) => {
      data && setProject(data)
      // Fetch the job
      client.jobs.getJob({ owner, name, jobId })
        .then(({ data }) => data && setJob(data))
        .catch((err) => setFetchError(err))
    })
    .catch((err) => setFetchError(err))
  }, [client])

  const [job, setJob] = useState<CloudJob>()
  const [fetchError, setFetchError] = useState<any>()

  useEffect(() => {
    if (!accountName || !projectName || !jobId) return

    fetchJob(accountName, projectName, jobId)
  }, [accountName, projectName, jobId])

  /*
  * Workspace 
  */
  const listArtifacts = useCallback((path: string | undefined) => {
    if (!client || !client.jobs) return Promise.reject()

    let key: string[] | undefined = undefined

    if (path) {
      key = [path]
    }

    return client.jobs.searchJobFolder({
      owner: accountName,
      name: projectName,
      jobId: jobId,
      page: 1,
      perPage: 1000,
      path: key
    }).then(r => r.data.resources)
  }, [accountName, client, jobId, projectName])

  const downloadArtifact = useCallback((path: string) => {
    if (!client || !client.jobs) return Promise.reject()

    return client.jobs.downloadJobArtifact({
      owner: accountName,
      name: projectName,
      jobId: jobId,
      path
    }).then(r => r.data)
  }, [accountName, client, jobId, projectName])

  /*
    * Folder card handler
    */
  const [currPath, setCurrPath] = useState<string>()

  const getFolderCardHandler = useCallback((card: FolderCardProps) => {
    setCurrTab(JobTabs.workspace)
    setCurrPath(card.path)
    setQueryString(JobTabs.workspace, card.path) // Push to history
  }, [currPath, currTab])

  /**
   * Get Job value
   */
  const [jobValue, setJobValue] = useState<CloudJob>()
  const getValueHandler = (job: CloudJob) => {
    setJobValue(job)
  }

  return (
    <Col
      xl={{ span: 16, offset: 4 }}
      lg={{ span: 16, offset: 4 }}
      md={24}
      sm={24}
      xs={24}>
      {fetchError ? (
        <Error error={fetchError} />
      ) : <>
        {job ? <StudyCard
          style={{ width: '100%', backgroundColor: 'white' }}
          projectName={projectName}
          projectOwner={accountName}
          enableClick={false}
          canWrite={(project && (project?.permissions.write || project?.permissions.admin))}
          getTab={getTab}
          interval={10000}
          getValue={getValueHandler}
          defaultTab={currTab} // Hightlight the tab from url
          study={job}
          // @ts-ignore
          client={client}
          authUser={authUser}
        /> : <Loading />}

        {/* Tabs logic */}
        {job && currTab === JobTabs.details &&
          <JobPageDetails
            getFolderCard={getFolderCardHandler}
            accountName={accountName}
            projectName={projectName}
            job={jobValue}
            authUser={authUser}
            // @ts-ignore
            client={client}
          />}

        {job && currTab === JobTabs.workspace &&
          <div style={{ margin: '10px 0 0 0' }}>
            {/* Use ?path=runs/run.id */}
            <Folder
              initialPath={currPath ?? ''}
              listArtifacts={listArtifacts}
              downloadArtifact={downloadArtifact} />
          </div>}
      </>}
    </Col>
  )
}