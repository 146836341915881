import React, { useCallback, useEffect, useState } from 'react'
import { AccountPublic, RoleEnum, SDKEnum } from '@pollination-solutions/pollination-sdk'
import { notification } from 'antd'

import { useAuth } from 'auth-context'
import { useUpdateImage } from 'hooks'

export const ApplicationVisibilityEnum = {
    PublicWithLogin: 'public_with_login',
    Public: 'public',
    Private: 'private'
}

export const resourceOptions = [{
    cpu: 1,
    memory: 2,
    requiresPaid: false
}, {
    cpu: 2,
    memory: 4,
    requiresPaid: true
}, {
    cpu: 4,
    memory: 8,
    requiresPaid: true
},]

export const sdks = [{
    value: 'streamlit',
    label: 'Streamlit',
    icon: 'https://avatars.githubusercontent.com/u/45109972'
}, {
    value: 'dash',
    label: 'Dash',
    icon: 'https://avatars.githubusercontent.com/u/5997976'
}, {
    value: 'react',
    label: 'React',
    icon: 'https://avatars.githubusercontent.com/u/102812'
}]

interface CreateApplication {
    owner: string,
    name: string,
    description: string,
    keywords: string[],
    sdk: string,
    resource: any,
    is_paid: boolean,
    visibility: string,
}

interface Props {
    restrictToAccount?: string,
}

const APIBASEPATH = process.env.REACT_APP_API_BASE_PATH ? process.env.REACT_APP_API_BASE_PATH : 'https://api.staging.pollination.solutions'


const useCreateApplication = (props: Props) => {

    const { client, user } = useAuth()
    const { updateImage } = useUpdateImage()

    const [loading, setLoading] = useState(false)

    const [accounts, setAccounts] = useState<AccountPublic[]>([])

    const [imageFile, setImageFile] = useState<File>()

    const createApplication = useCallback(async (payload: CreateApplication) => {
        setLoading(true)
        return client.applications.createApplication({
            owner: payload.owner,
            applicationCreate: {
                name: payload.name,
                description: payload.description,
                sdk: payload.sdk as SDKEnum,
                is_paid: payload.is_paid,
                // @ts-ignore
                public: payload.visibility !== ApplicationVisibilityEnum.Private,
                deployment_config: {
                    scale_to_zero: !payload.is_paid,
                    cpu_limit: payload.resource.cpu,
                    memory_limit: payload.resource.memory * 1000,
                    login_required: payload.visibility !== ApplicationVisibilityEnum.Public,
                }
            }
        }).then(({ data }) => {
            if (imageFile) {
                const endpoint = `${APIBASEPATH}/applications/${data.owner.name}/${data.slug}/image`
                const reader = new FileReader()

                reader.onloadend = (e) => {
                    const arrayBuffer = e.target?.result
                    const fileType = imageFile.type
                    if (!arrayBuffer) return
                    const blob = new Blob([arrayBuffer], { type: fileType })

                    updateImage(endpoint, blob)
                }

                reader.readAsArrayBuffer(imageFile)
            }
            setLoading(false)
            return data
        }).catch((error) => {
            setLoading(false)
            if (error.response.status === 409) {
                throw new Error('Application name already exists')
            }
            throw error
        })
    }, [client, imageFile, updateImage])

    useEffect(() => {
        if (!client) return
        setLoading(true)
        client.accounts.listAccounts({
            role: RoleEnum.Owner
        }).then(({ data }) => {
            let accounts = data.resources
            if (props.restrictToAccount) {
                accounts = accounts.filter((account) => account.name === props.restrictToAccount)
            }
            // make account matching user-id first in the list by sorting
            if (user) {
                accounts.sort((a, b) => {
                    if (a.id === user.id) return -1
                    if (b.id === user.id) return 1
                    return 0
                })
            }
            setAccounts(accounts)
            setLoading(false)
        }).catch((error) => {
            notification.error({ message: 'Failed to load accounts', description: error.message })
        })
    }, [client, props.restrictToAccount, user])

    return {
        loading,
        accounts,
        sdks,
        imageFile,
        createApplication,
        setImageFile,
    }
}




export default useCreateApplication
