import React, { FC, useEffect, useState } from 'react'

import { Avatar, Empty, List, Typography } from 'antd'

import { SortEnum, ProjectSortKey, ProjectList, 
  AccountPublic } from '@pollination-solutions/pollination-sdk'

import { useAuth } from 'auth-context'
import { CardButton } from './CardButton'
import { useHistory } from 'react-router-dom'
interface RecentActivitiesProps {
  account: AccountPublic
}

export const RecentActivities: FC<RecentActivitiesProps> = ({
  account,
}) => {
  const history = useHistory()
  const { user, client } = useAuth()

  const [projectsLoading, setProjectsLoading] = useState<boolean>(false)
  const [projects, setProjects] = useState<ProjectList>()

  useEffect(() => {
    if(!user) return
    setProjectsLoading(true)
    client.projects.listProjects({
      owner: [account.name],
      perPage: 3,
      page: 1,
      sortOrder: SortEnum.Descending,
      sortBy: ProjectSortKey.UpdatedAt
    })
      .then(({ data }) => { 
        setProjects(data)
      })
      .catch(err => {
        console.error(err)
        setProjectsLoading(false)
      })
      .finally(() => setProjectsLoading(false))
  }, [client.projects, user])

  return (
    <List
      grid={
        {
          gutter: 20,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 2,
          xl: 3,
          xxl: 3,
        }
      }
      dataSource={projects?.resources ?? []}
      renderItem={({ name, owner, slug }, i) => (
        <List.Item>
          <CardButton 
            title={slug} 
            label={name} 
            avatarStyle={{
              backgroundColor: '#f4f4f4'
            }}
            icon={<Avatar src={ owner.picture_url } />} 
            onClick={() => history.push(`/${owner.name}/projects/${slug.split('/')[1]}/`)}
          />
        </List.Item>)}
      locale={{
          emptyText: <Empty description={
            <Typography.Link href={`/${account?.name}/new/project`}
              referrerPolicy='no-referrer'
            >
                Want to create a Project?
            </Typography.Link>
          } />
        }}
      loading={projectsLoading}
      pagination={false}
    />
  )
}
