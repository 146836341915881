import React, { useState, useContext, useCallback, useEffect, useMemo } from 'react'

import { Row, Col, Space, Typography, notification, Empty, Select, Tag } from 'antd'

import { Project, ProjectRecipeFilter, RecipeInterface } from '@pollination-solutions/pollination-sdk'

import context from './context'

import { SelectRecipe, useCreateStudy, RecipeForm, getHost, LocalConfig, ProjectJobInfo } from 'pollination-react-io'

import { useAuth } from 'auth-context'
import { Loading } from 'atoms'

const toUnixPath = path => path.replace(/[\\/]+/g, '/').replace(/^([a-zA-Z]+:|\.\/)/, '')


interface NewJobFormProps {
  child?: React.ReactNode
  asLocalSimulation?: boolean
  values?: ProjectRecipeFilter[]
  defaultProject?: Project
}

/** Displays an expandable list of project jobs and shows usage statistics about them. */
const NewJobForm: React.FunctionComponent<NewJobFormProps> = ({
  child,
  values,
  asLocalSimulation = false,
  defaultProject=undefined
}) => {
  const host = getHost()
  
  /**
   * Sort tags
   */
  const sortedValues = useMemo(() => {
    if (!values) return []
    
    return values
    .sort((a, b) => {
      if (!a.tag || !b.tag) return -1
      return a.tag.localeCompare(b.tag)
    })
    .reverse()
  }, [values])
  
  const { project } = useContext(context)
  
  const [owner, name] = defaultProject 
    ? defaultProject.slug.split('/') 
    : project.slug.split('/') 
    ?? []

  const { user, client } = useAuth()

  const [recipe, setRecipe] = useState<RecipeInterface>()
  const [loading, setLoading] = useState<boolean>(true)
  const [hasError, setHasError] = useState<boolean>(false)
  
  const [option, setOption] = useState<string>()
  const [selRecipeFilter, setSelRecipeFilter] = useState<ProjectRecipeFilter>(sortedValues[0])

  useEffect(() => {
    if (!option) return
    setSelRecipeFilter(JSON.parse(option))
  }, [option])

  useEffect(() => {
    setLoading(true)
    setHasError(false)
    
    if(!selRecipeFilter) {
      setRecipe(undefined)
      return
    }
    
    // If * use latest
    const conditionalTag = selRecipeFilter.tag === '*'
      ? 'latest'
      : selRecipeFilter.tag

    client.recipes.getRecipeByTag({ 
      owner: selRecipeFilter.owner, 
      name: selRecipeFilter.name, 
      tag: conditionalTag ?? 'latest' 
    })
    .then(d => {
      setRecipe(d.data.manifest)
    })
    .catch(err => {
      notification.error({
        message: 'Recipe not found. Try a new tag!'
      })
      setHasError(true)
      setRecipe(undefined)
    })
    .finally(() => setLoading(false)) 

  }, [selRecipeFilter])

  // @ts-ignore
  const { createStudy, createLocalStudy } = useCreateStudy(owner, name, client)

  const _onSuccessCloud = (projectJobInfo: ProjectJobInfo) => {
    if (!projectJobInfo.studyId) return

    notification.success({ message: 'Study submitted!' })

    setTimeout(() => { 
      window.location.href = `/${projectJobInfo.accountName}/projects/${projectJobInfo.projectName}/studies/${projectJobInfo.studyId}?tab=details&perPage=5&status=null&page=1`
    }, 3000)
  }

  const _onSuccessLocal = (projectJobInfo: ProjectJobInfo) => {
    if (!projectJobInfo.studyId) return

    notification.success({ message: `Study is running! Do not close the console.\nYou will find output here ${projectJobInfo.studyId}.` })
    // Formatted name of the job
    const jobName = toUnixPath(projectJobInfo.studyId).split('/').reverse()[0]

    // Path is the full path of the local machine where the run input/output are
    setTimeout(() => { 
      window.location.href = `/${projectJobInfo.accountName}/projects/${projectJobInfo.projectName}/localStudies/${jobName}/?path=${projectJobInfo.studyId}&studyName=${projectJobInfo.jobName}`
    }, 3000)
  }

  const handleCreateStudy = useCallback((
    name: string, 
    jobArgs: any[], 
    description?: string, 
    localConfig?: LocalConfig,
    userRecipe?: RecipeInterface) => {
    if (!recipe) {
      return Promise.reject()
    }

    if (host !== 'web' && localConfig) {
      return createLocalStudy(
        {
          name,
          recipe: recipe,
          description: description ?? 'Study created from pollination',
          recipeFilter: selRecipeFilter,
          authUserName: user?.username
        },
        jobArgs,
        localConfig,
        localConfig.isLocalJob ? _onSuccessLocal : _onSuccessCloud,
        userRecipe
      )
    } else {
      return createStudy(
        {
          name,
          recipe: recipe,
          description: description ?? 'Study created from pollination',
        },
        jobArgs,
        _onSuccessCloud,
      )
    }

  }, [recipe, host, createLocalStudy, createStudy])

  return (
    <>
      <Row justify="center" style={{ width: '100%' }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Row>
            <Col span={22}>
              <Typography.Text strong>New Study</Typography.Text>
            </Col>
            <Col span={2} style={{ textAlign: 'right' }}>
              {child}
            </Col>
          </Row>
          {(!loading && recipe) ?
            <RecipeForm 
              recipe={recipe}
              projectName={name}
              projectOwner={owner}
              onSubmit={handleCreateStudy}
              style={{ backgroundColor: '#fff' }}
              initAsLocal={asLocalSimulation}
              // @ts-ignore
              client={client}
            >
              {sortedValues && (sortedValues.length > 1 ? <Select 
                onChange={setOption}
                showSearch
                defaultValue={option ?? JSON.stringify(sortedValues[0])}
                size='small'
                dropdownMatchSelectWidth={true}
                style={{ 
                  width: '100px', 
                  marginBottom: '4px', 
                  textAlign: 'center',
                  textOverflow: 'ellipsis' }}>
                  {sortedValues && sortedValues
                    .map(t => (
                    <Select.Option value={JSON.stringify(t)}>
                      {t.tag}
                    </Select.Option>)
                  )}
              </Select>
              : <Tag style={{ 
                  width: '100px', 
                  height: '24px', 
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  textAlign: 'center' }}>
                {sortedValues[0].tag}
              </Tag>)}
            </RecipeForm>
          : (!hasError ? <Loading /> : <Empty description={
            <Typography.Text>
              Recipe not found. Try a new tag!
            </Typography.Text>
          } />)}
        </Space>
      </Row>
    </>
  )
}


export default NewJobForm