import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import useSWR from 'swr'
import { FileWithPath } from 'react-dropzone'

import { Spin, Modal, Row, Typography, Divider, Grid, Button, notification } from 'antd'
import { EyeOutlined, DownloadOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons'

import { FileMeta } from '@pollination-solutions/pollination-sdk'

import { BreadCrumb } from './BreadCrumb'
import { DropZone } from './DropZone'
import { PreviewWindow } from './PreviewWindow'
import { ProjectFileEntry } from './ProjectFileEntry'
import { ProjectFolderEntry } from './ProjectFolderEntry'

import { parseFolder } from './utils'

interface FolderProps {
  listArtifacts: (path: string | undefined) => Promise<FileMeta[]>
  downloadArtifact: (path: string) => Promise<any>
  initialPath?: string
  uploadArtifact?: (folderRoot: FileMeta | undefined, file: FileWithPath) => Promise<void>
  deleteArtifact?: (path: string) => Promise<void>
}

/**
 * Large component responsible for interacting with the contents of a project folder.
 * @param {string?} initialPath Optional `/` delimited path to load the component at.
 * @param {string?} root In a job context, an optional hard-coded root path declaration.
 * @param {boolean} allowDelete If set to true, exposes options for deleting files.
 * @param {boolean} allowUpload If set to true, exposes upload dragover UI.
 * @param {boolean} allowSubmit (Unused) If set to true, allows files to be selected and submitted for a form.
 */
const Folder: React.FunctionComponent<FolderProps> = ({
  listArtifacts,
  downloadArtifact,
  uploadArtifact,
  deleteArtifact,
  initialPath
}) => {
  const { search } = useLocation()
  const history = useHistory()
  const screens = Grid.useBreakpoint()

  const [loading, setLoading] = useState<boolean>(false)
  const [dropZoneVisible, setDropZoneVisible] = useState(false)

  const [fileToDelete, setFileToDelete] = useState<FileMeta>()
  const [fileToPreview, setFileToPreview] = useState<{ file?: FileMeta, url?: string }>()

  const path = useMemo(() => {
    return new URLSearchParams(search).get('path') ?? initialPath
  },[search])

  /**
   * Given the current path, return the `FileMeta` for its folder.
   * @remarks Returns `undefined` if folder is root folder.
   */
  const fetchFolderReference = async (): Promise<FileMeta | undefined> => {
    if (!path) {
      return undefined
    }

    const pathArray = path.split('/').filter(p => p !== '')
    const current = pathArray.pop()

    const contents = await listArtifacts(pathArray.join('/'))

    const folder = contents.find((item) => item.file_type === 'folder' && item.file_name === current)

    return folder
  }

  const { data: parent } = useSWR('parent-dir', fetchFolderReference)

  const [artifacts, setArtifacts] = useState<FileMeta[] | undefined>(undefined)

  const { folders, files } = parseFolder(artifacts ?? [])

  const updateQueryString = useCallback((name: string, value: string) => {
    const query = new URLSearchParams(search)

    // early out on no change
    if (query.get(name) === value) return

    query.set(name, value)

    history.push({
      search: query.toString()
    })
  }, [history, search])

  // to preview a file, update query string
  // this useEffect is then triggered
  // setsFileToPreview either to file in files or undefined
  useEffect(() => {
    const fileQuery = new URLSearchParams(search).get('file')
    const fileIndex = files.findIndex(f => f.key === fileQuery)
    const file = { ...files[fileIndex] } as FileMeta

    if (fileToPreview && fileToPreview.file &&
      fileToPreview.file.key === fileQuery) {
      return
    }

    if (fileIndex !== -1) {
      downloadArtifact(file.key).then((url) => {
        setFileToPreview({ file, url })
      })
    } else {
      setFileToPreview(undefined)
    }
  }, [search, files]) //intentionally omitting fileToPreview

  // handles change in path query parameter
  const handleLoadPath = useCallback((path: string | undefined) => {
    setLoading(true)
    const pathArray = path ? path.split('/').filter(p => p !== '') : []

    listArtifacts(pathArray.join('/'))
      .then((res) => setArtifacts(res))
      .then(() => setLoading(false))
  },[listArtifacts])

  useEffect(() => {
    handleLoadPath(path)
  }, [path])

  const handleSelectPath = (newPath?: string): void => {

    if(!newPath) newPath = ''

    return updateQueryString('path', newPath )
  }

  const handleDownload = useCallback((file: FileMeta): void => {
    downloadArtifact(file.key).then((url) => {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const a = document.createElement('a')
          a.href = URL.createObjectURL(blob)
          a.download = file.file_name
          a.click()
          a.remove()
        })
        .catch(() => {
          notification.error({
            message: 'Failed to download your file. Please refresh the page and try again.',
          })
        })
    })
  }, [])

  // Confirm delete modal state
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)

  const handleDelete = useCallback((): void => {
    if (!fileToDelete || !deleteArtifact) return

    // Immediately remove file from ui
    setArtifacts( state => state?.filter((a) => a.key !== fileToDelete.key))
    setDeleteModalVisible(false)

    deleteArtifact(fileToDelete.key)
      .then(() => {
        const pathArray = path ? path.split('/').filter(p => p !== '') : []
        return listArtifacts(pathArray.join('/'))
      })
      .then((res) => setArtifacts(res))

  }, [deleteArtifact, fileToDelete, listArtifacts, path])

  const folderContextMenuActions = useMemo(() => {
    const deleteAct = {
      icon: <DeleteOutlined />,
      label: 'Delete folder',
      onClick: (file: FileMeta) => {
        setFileToDelete(file)
        setDeleteModalVisible(true)
      },
    }

    const allowedActions: {
      icon: React.ReactElement,
      label: string,
      onClick: (file: FileMeta) => void
    }[] = []

    if (deleteArtifact) {
      allowedActions.push(deleteAct)
    }

    return allowedActions

  }, [deleteArtifact])

  // Collect actions to show in context menu based on current context and configuration.
  const fileContextMenuActions = useMemo(() => {

    const previewAct = {
      icon: <EyeOutlined />,
      label: 'Preview file',
      onClick: (file: FileMeta) => updateQueryString('file', file.key ),
    }

    const downloadAct = {
      icon: <DownloadOutlined />,
      label: 'Download file',
      onClick: handleDownload,
    }

    const deleteAct = {
      icon: <DeleteOutlined />,
      label: 'Delete file',
      onClick: (file: FileMeta) => {
        setFileToDelete(file)
        setDeleteModalVisible(true)
      },
    }

    const allowedActions = [
      previewAct, downloadAct
    ]

    if (deleteArtifact) {
      allowedActions.push(deleteAct)
    }

    return allowedActions
  }, [handleDownload, deleteArtifact, updateQueryString])

  const handleDragEnter = (): void => {
    setDropZoneVisible(true)
  }

  const handleDragLeave = (): void => {
    setDropZoneVisible(false)
  }

  useEffect(() => {
    document.body.addEventListener('dragover', handleDragEnter)

    return () => {
      document.body.removeEventListener('dragover', handleDragEnter)
    }
  }, [])

  const handleUploadSuccess = (): void => {
    const pathArray = path ? path.split('/').filter(p => p !== '') : []

    listArtifacts(pathArray.join('/'))
      .then((res) => setArtifacts(res))
      .then(() => setDropZoneVisible(false))
  }

  return (
    <>
      {uploadArtifact && dropZoneVisible ? (
        <DropZone
          uploadArtifact={uploadArtifact}
          folderRoot={parent}
          onUploadSuccess={handleUploadSuccess}
          onDragEnd={handleDragLeave}
        />
      ) : null}
      {fileToPreview && fileToPreview.file && fileToPreview.url ? (
        <Row>
          <PreviewWindow file={fileToPreview.file} url={fileToPreview.url} onClose={(): void => {
            updateQueryString('file', '' )
          }} />
        </Row>
      ) : null}
      <Modal
        centered
        closable={false}
        visible={deleteModalVisible}
        bodyStyle={{ textAlign: 'center' }}
        onOk={handleDelete}
        okText="Delete"
        onCancel={() => setDeleteModalVisible(false)}
      >
        <Typography>Permanently delete {fileToDelete?.file_name}</Typography>
      </Modal>
      <Row justify="space-between">
        <BreadCrumb
          path={path}
          onSelectPath={handleSelectPath}
          screens={screens}
        />
        {uploadArtifact && (
          <div>
            <input
              id="folderUploadInput"
              type='file'
              hidden
              onChange={(event) => {
                setLoading(true)
                // @ts-ignore
                uploadArtifact(parent, event.target.files?.item(0))
                  .then(() => handleLoadPath(path))
                  .finally(() => setLoading(false))
              }}
            />
            <Button
              icon={<UploadOutlined />}
              onClick={() => {
                document.getElementById('folderUploadInput')?.click()
              }}
            >
              Upload
            </Button>
          </div>
        )}
      </Row>
      <div className="project-files-container">
        {loading ?
          (
            <Row justify='center' align='middle' style={{ width: '100%', height: '60vh' }}>
              <Spin />
            </Row>
          )
          :
          (
            <>
              {folders.length > 0 ? (
                <>
                  <Divider orientation="left">
                    <Typography.Text strong>Folders</Typography.Text>
                  </Divider>
                  <Row gutter={[16, 16]} style={{ padding: '0px 12px 12px', margin: 0 }}>
                    {folders.map((folder) => (
                      <ProjectFolderEntry
                        key={`folder-${folder.key}`}
                        folder={folder}
                        actions={folderContextMenuActions}
                        onClick={(file: FileMeta) => handleSelectPath(file.key)}
                      />
                    ))}
                  </Row>
                </>
              ) : null}
              {files.length > 0 ? (
                <>
                  <Divider orientation="left">
                    <Typography.Text strong>Files</Typography.Text>
                  </Divider>
                  <style>{`
                    .pollination-grid-fill::after{
                      content:'';
                      height:0;
                      flex:1;
                    }
                  `}</style>
                  <Row className="pollination-grid-fill" gutter={[16, 16]} justify='space-between' style={{ padding: '0px 12px 12px', margin: 0 }}>
                    {files.map((file) => (
                      <ProjectFileEntry
                        key={`file-${file.key}`}
                        file={file}
                        actions={fileContextMenuActions}
                        onClick={(file: FileMeta) => updateQueryString('file', file.key )}
                        downloadArtifact={downloadArtifact}
                      />
                    ))}
                  </Row>
                </>
              ) : <></>}
            </>
          )}
      </div>
    </>
  )
}

export default Folder