import React from 'react'

import { Grid, Col, Row } from 'antd'
import { Loading } from 'atoms'

interface ProfileTemplateProps {
  leftColumn?: React.ReactElement
  tabs?: React.ReactElement
}

const ProfileTemplate: React.FunctionComponent<ProfileTemplateProps> = ({ leftColumn, tabs }) => {
  const screens = Grid.useBreakpoint()

  return (
    <Row justify='space-between' gutter={[24, 16]} >
      {screens.xl &&
        <Col xl={4}>
          {leftColumn ? leftColumn : <Loading />}
        </Col>
      }
      <Col lg={24} 
        md={24} 
        xs={24} 
        xl={{ span: 16, pull: 4 }} 
        xxl={{ span: 16, pull: 4 }} >
        {tabs ? tabs : <Loading />}
      </Col>
    </Row>
  )
}

export default ProfileTemplate
