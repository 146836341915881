import React from 'react'
import { Form, Row, Col, Space, Typography, Radio, Card, FormItemProps, Image } from 'antd'
import Icon from '@ant-design/icons'

type Option = {
    value: string
    label: string
    icon: string
}

interface Props extends FormItemProps {
    options: Option[]

}

const FormItemRadioCard = (props: Props) => {
    return (
        <Form.Item
            {...props}
            labelCol={{
                style: {
                    textAlign: 'center'
                }
            }}
        >
            <Radio.Group style={{ width: '100%' }}>
                <Row gutter={[16, 16]} justify="center">
                    {props.options.map((option) => (
                        <Col key={option.value}>
                            <Radio.Button value={option.value} style={{ 
                                    height: '100%',
                                    padding: 10
                                }}>
                                <Card bordered={false} style={{
                                    width: 120,
                                    height: 120,
                                    verticalAlign: 'center',
                                    textAlign: 'center'
                                }}>
                                    <Icon component={() => <img src={option.icon} alt={option.label} width="50" />} />
                                    <Typography.Title level={5}>{option.label}</Typography.Title>
                                </Card>
                            </Radio.Button>
                        </Col>
                    ))}
                </Row>
            </Radio.Group>
        </Form.Item>
    )
}

export default FormItemRadioCard