import React, { useContext, useEffect, useState } from 'react'
import { Organization, Team, TeamMember } from '@pollination-solutions/pollination-sdk'
import { Link } from 'react-router-dom'

import { Avatar, Row, List, Spin, Space, Input, Typography, Button, notification, Col } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

import { useAuth } from 'auth-context'

import { AvatarLink } from 'atoms'
import { useAccount } from 'hooks'
import OrganizationContext from './OrganizationContext'

const nameFromSlug = (team: Team): string => team.slug.split('/').pop() as string

interface TeamAvatarGroup {
  org: Organization
  team: Team
  gap?: number
}

export const TeamAvatarGroup: React.FunctionComponent<TeamAvatarGroup> = ({ org, team, gap }) => {
  const { client } = useAuth()

  const [status, setStatus] = useState<{
    loading: boolean
    members?: any
    error?: Error
  }>({ loading: false })

  const fetchMembers = (org: Organization, team: Team) => {
    setStatus({ ...status, loading: true })
    client.teams.getOrgTeamMembers({ orgName: org.owner.name, teamSlug: nameFromSlug(team) }).then(({ data }) => {
      setStatus({
        loading: false,
        members: data.resources,
      })
    })
      .catch((error: Error) => {
        setStatus({ loading: false, error: error })
      })
  }

  useEffect(() => {
    if (typeof team !== 'undefined' && typeof org !== 'undefined') {
      fetchMembers(org, team)
    }
  }, [team, org])

  return (
    typeof status.members === 'undefined' ? <Spin /> :
      <Avatar.Group>
        <Row wrap={true} justify='center'>
        {
          status.members.map((member: TeamMember, i: number) => (
            <Col key={`${member.user.username}-${i}`}>
              <AvatarLink 
                src={member.user.picture} 
                link={`/${member.user.username}`} 
                size={{ xs: 16, sm: 16, md: 24, lg: 24, xl: 30, xxl: 30 }} 
                key={`${member.user.username}-${i}`} />
            </Col>
          ))
        }
        </Row>
      </Avatar.Group>
  )
}

interface TeamListProps {
  org: Organization
  canWrite: boolean
}

const TeamList = ({ org }: TeamListProps): React.ReactElement => {
  const { client, user } = useAuth()
  const { account } = useAccount()
  const { setRefresh } = useContext(OrganizationContext)

  const [teams, setTeams] = useState<Team[]>([])
  const [search, setSearch] = useState<[string] | undefined>()

  const [loading, setLoading] = useState<boolean>(false)

  let cancelDeleteTeam = false

  useEffect(() => {
    setLoading(true)
    const fetchTeams = async (orgName: string): Promise<Team[]> => {
      const { data } = await client.teams.listOrgTeams({
        orgName,
        page: 1,
        perPage: 25,
        search,
      })
      return data.resources
    }

    fetchTeams(org.owner.name)
      .then((t) => {
        setLoading(false)
        setTeams(t)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [search])

  function confirmDelete(team: Team) {
    if (cancelDeleteTeam) {
      cancelDeleteTeam = false
      return
    }
    setLoading(true)
    setRefresh && setRefresh(true)

    client.teams.deleteTeam({
      orgName: org.owner.name,
      teamSlug: nameFromSlug(team)
    })
    .then(() => {
      setLoading(false)
    })
    .finally(() => setRefresh && setRefresh(false))
  }

  function cancelDelete(e: React.MouseEvent, team: Team) {
    e.stopPropagation()
    cancelDeleteTeam = true
    // can't gaurantee that setMembers() called in handleDeleteMember will or won't have completed
    setTeams([...teams, team].filter((val, i, arr) => arr.findIndex((v) => v.id === val.id) === i))
    notification.close(`delete-${team.id}`)
  }

  const handleDeleteTeam = (team: Team) => {
    notification.warning({
      key: `delete-${team.id}`,
      message: `Deleted ${team.name}`,
      description: `${org.owner.name}'s team ${team.name} with ${team.member_count} members has been deleted.`,
      btn: <Button onClick={(e) => cancelDelete(e, team)}>Undo</Button>,
      onClose: () => confirmDelete(team)
    })

    setTeams(teams.filter(t => t.id !== team.id))
  }

  return (
    <Col style={{ padding: 0 }}>
      <Row align="middle" justify="space-between" style={{ width: '100%' }}>
        <Input.Search
          placeholder="search teams"
          allowClear
          onSearch={(value: string) => value !== '' ? setSearch([value]) : setSearch(undefined)}
          style={{ minWidth: '150px', maxWidth: '250px', width: '35%' }}
        />
        <Link to={`/${account?.name}/new/team`}>
          <Button type="primary">New Team</Button>
        </Link>
      </Row>
      <List
        style={{ marginTop: '24px' }}
        dataSource={teams}
        renderItem={(team) => (
          <List.Item key={team.id} className="selectable" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.2)', boxSizing: 'border-box' }}>
            <List.Item.Meta
              title={<Link to={`/${org.owner.name}/teams/${nameFromSlug(team)}`} style={{ color: '#1890ff' }}>
                {team.name}
              </Link>}
              description={team.description}
            />
            <Space size="large">
              <TeamAvatarGroup org={org} team={team} />
              <Typography.Text type="secondary">{team.member_count} Members</Typography.Text>
              <Button shape="circle" onClick={() => handleDeleteTeam(team)} style={{ backgroundColor: 'rgba(0,0,0,0)' }}>
                <DeleteOutlined />
              </Button>
            </Space>
          </List.Item>
        )}
      />
    </Col>
  )
}

export default TeamList
