import React, { useState, useEffect, useCallback } from 'react'

import { OrganizationCreate, OrganizationList, OrgsApiListOrgsRequest } from '@pollination-solutions/pollination-sdk'

import { useAuth } from 'auth-context'
import { notification } from 'antd'

const defaultQuery: OrgsApiListOrgsRequest = {
  search: undefined,
  name: undefined,
  member: undefined,
  page: 1,
  perPage: 25,
}

export const useOrganizations = (initialQuery?: Partial<OrgsApiListOrgsRequest>) => {
  const { client } = useAuth()

  const [query, setQuery] = useState<Partial<OrgsApiListOrgsRequest>>(initialQuery ?? defaultQuery)

  const [loading, setLoading] = useState(false)

  const [orgs, setOrgs] = useState<OrganizationList>()

  const getOrgs = useCallback(async (query: Partial<OrgsApiListOrgsRequest>) => {
    setLoading(true)
    client.orgs.listOrgs(query)
      .then(({ data }) => {
        setOrgs(data)
      })
      .finally(() => setLoading(false))
  }, [client.orgs])

  useEffect(() => {
    if (query) {
      getOrgs(query)
    }
  }, [getOrgs, query])

  const getOneOrg = useCallback(async (name: string) => {
    return client.orgs.getOrg({ name })
  }, [client.orgs])

  const checkUnique = useCallback((name: string) => {
    return client.accounts.checkAccountName({ name })
  }, [client.accounts])

  const createOrg = useCallback(async (organizationCreate: OrganizationCreate) => {
    setLoading(true)
    return client.orgs.createOrg({ organizationCreate })
      .then(({ data }) => {
        notification.success({
          message: <><strong>{organizationCreate.name}</strong> has been created.</>,
          description: 'Next, purchase organization seats to get started.'
        })
        return data
      })
      .catch((error) => {
        notification.error({
          message: 'We failed to create organization.',
          description: error,
        })
      })
      .finally(() => {
        if (query) {
          getOrgs(query)
        }
      })
  }, [client.orgs, getOrgs, query])

  const deleteOrg = useCallback(async (name: string) => {
    setLoading(true)
    client.orgs.deleteOrg({ name })
      .catch((error) => {
        notification.error({
          message: 'We failed to delete organization',
          description: error,
        })
      })
      .finally(() => {
        if (query) {
          getOrgs(query)
        }
        setLoading(false)
      })
  }, [client.orgs, getOrgs, query])

  return { 
    loading, 
    orgs, 
    setQuery,
    createOrg, 
    deleteOrg, 
    getOrgs, 
    getOneOrg, 
    checkUnique
  }
}

