import React from 'react'

import { Col, Row } from 'antd'

import { CenteredHeader } from 'atoms'

interface ProductTemplateProps {
  title: string | React.ReactElement
  subtitle: string | React.ReactElement
  footer?: string | React.ReactElement
}

const ProductTemplate: React.FunctionComponent<ProductTemplateProps> = ({ title, subtitle, footer, children }) => {

  return (
    <Row justify='space-between' gutter={[24, 24]} >
      <Col lg={24} xl={{ span: 16, offset: 4 }}>
        <CenteredHeader title={title} subtitle={subtitle} />
        {children}
        {footer && footer}
      </Col>
    </Row>
  )
}

export default ProductTemplate